<template>
	<div class="dui-wrap-content">
		<div v-if="application.abbreviation == 'covco'" class="dui-wrap-audio-file">
			<div class="dui-wrap-audio-info">
				<div class="dui-options">
					<div class="dui-wrap-option">
						<p class="dui-option-title">{{ $t('duiFileName') }}</p>
						<p class="dui-option-content">{{ selectedFile ? selectedFile.name : $t('duiNoFileSelected') }}</p>
					</div>
					<div v-if="selectedFile" class="dui-wrap-option">
						<p class="dui-option-title">{{ $t('duiCategory') }}</p>
						<select v-model="category">
							<option :value="null" disabled selected hidden>{{ $t('duiSelectCategory') }}</option>
							<option value="cough">{{ $t('duiCough') }}</option>
							<option value="breath">{{ $t('duiBreath') }}</option>
							<option value="speech">{{ $t('duiSpeech') }}</option>
						</select>
					</div>
				</div>
				<button class="app-default-btn dui-record-btn" @click="showAudioRecording = true">
					<i class="fa-solid fa-microphone"></i>
					<p>{{ $t('duiDataRecording') }}</p>
				</button>
			</div>
			<div class="dui-wrap-player">
				<AudioPlayer :application="application.abbreviation" :file="selectedFile" />
			</div>
			<div v-if="selectedFile" class="dui-wrap-save-btn">
				<button class="app-success-btn" @click="saveFile">{{ $t('duiSaveChanges') }}</button>
			</div>
			<div v-if="showAudioRecording" class="dui-wrap-recorder">
				<h2>{{ $t('duiAudioRecorder') }}</h2>
				<AudioRecorder
					:application="application"
					@newPackage="
						$emit('newPackage', $event);
						showAudioRecording = false;
					"
				/>
				<i class="fas fa-times-circle" @click="showAudioRecording = false"></i>
			</div>
		</div>
		<div v-if="application.abbreviation == 'covco'">
			<COVCOMetaInformation v-if="metaToEdit" :metaToEdit="metaToEdit" @saveMeta="$emit('saveMeta', $event)" />
		</div>
		<div v-else-if="application.abbreviation == 'bwhealthapp'">
			<BWHEALTHAPPMetaInformation v-if="metaToEdit" :metaToEdit="metaToEdit" @saveMeta="$emit('saveMeta', $event)" />
		</div>
		<div v-else-if="application.abbreviation">
			<p>{{ $t('duiNoMetaAvailable') }}</p>
		</div>
	</div>
</template>

<script>
import AudioPlayer from '../data/AudioPlayer.vue';
import AudioRecorder from '../data/AudioRecorder.vue';
import COVCOMetaInformation from './COVCOMetaInformation.vue';
import BWHEALTHAPPMetaInformation from './BWHEALTHAPPMetaInformation.vue';
/**
 * @group Upload
 * Contains the data information components
 */
export default {
	name: 'DataUploadInformation',
	components: {
		AudioPlayer,
		AudioRecorder,
		COVCOMetaInformation,
		BWHEALTHAPPMetaInformation,
	},
	props: {
		application: {
			type: Object,
			required: true,
		},
		file: {
			type: Object,
			required: false,
		},
		metaToEdit: {
			type: Object,
			required: false,
		},
	},
	watch: {
		file: {
			handler: function (newVal) {
				if (newVal) {
					if (this.application.abbreviation == 'covco') {
						this.selectedFile = newVal.file;
						this.selectedFile.src = window.URL.createObjectURL(this.selectedFile.file);
						this.packageID = newVal.packageID;
						this.category = newVal.file.category;
					}
				} else this.resetVars();
			},
		},
	},
	data() {
		return {
			showAudioRecording: false,
			category: null,
			selectedFile: null,
			selectedMeta: null,
			packageID: null,
		};
	},
	methods: {
		// @vuese
		// Saves the edited file
		saveFile() {
			this.selectedFile.category = this.category;
			this.$emit('clearFile');
		},
		// @vuese
		// Resets the vars of the component
		resetVars() {
			this.showAudioRecording = false;
			this.category = null;
			this.selectedFile = null;
			this.selectedMeta = null;
			this.packageID = null;
		},
	},
};
</script>

<style scoped>
.dui-wrap-content {
	width: 100%;
	height: fit-content;
	position: relative;
}

.dui-wrap-audio-file {
	width: 100%;
	height: fit-content;
	display: block;
}

.dui-options {
	width: calc(100% - 120px);
	height: fit-content;
	margin-bottom: 10px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.dui-wrap-option {
	flex: 1 1 250px;
	text-align: start;
	margin-bottom: 10px;
}

.dui-wrap-option .dui-option-title {
	width: 100%;
	font-size: 18px;
	margin: 0px auto 5px auto;
	text-decoration: underline;
}

.dui-wrap-option select {
	width: 100%;
	max-width: 300px;
	min-width: 150px;
	height: fit-content;
	font-size: 17px;
}

.dui-wrap-player {
	width: 100%;
	height: fit-content;
	display: inline-block;
	vertical-align: top;
}

.dui-record-btn {
	width: 110px;
	height: 100%;
	margin-left: 10px;
	display: inline-block;
	font-size: 20px;
	vertical-align: top;
}

.dui-wrap-recorder {
	width: 60vw;
	height: fit-content;
	max-height: 70vh;
	position: absolute;
	top: 0vh;
	left: 20vw;
	border-radius: 20px;
	padding: 3vh 2vw 2vh 2vw;
	overflow: auto;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: -10vw -20vh 0px 10000px var(--main-color-dark-80);
	box-sizing: border-box;
	background-color: var(--main-color-4);
	text-align: center;
	z-index: 3;
	animation: slideIn 0.4s linear;
}
.dui-wrap-recorder h2 {
	font-size: 30px;
	margin: 0px auto 10px auto;
}
.dui-wrap-recorder i {
	font-size: 40px;
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-text-dark);
}
.dui-wrap-recorder i:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.dui-wrap-save-btn {
	width: 100%;
	text-align: center;
	margin: 5px auto;
}

.dui-wrap-save-btn button {
	font-size: 17px;
}
</style>
