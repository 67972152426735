<template>
	<div class="pr-wrap-content">
		<div class="pr-center-content">
			<h1>{{ $t('prPasswordReset') }}</h1>
			<div class="pr-wrap-input">
				<label>{{ $t('prPassword') }}</label>
				<input
					:type="[showPassword ? 'text' : 'password']"
					autocomplete="on"
					:placeholder="$t('prPassword')"
					v-model="password"
				/>
				<i v-if="!showPassword" class="fas fa-eye pr-smaller-eye" @mouseenter="showPassword = true"></i>
				<i v-if="showPassword" class="fas fa-eye-slash" @mouseleave="showPassword = false"></i>
			</div>
			<div class="pr-wrap-input">
				<label>{{ $t('prPasswordControl') }}</label>
				<input
					:type="[showPasswordControl ? 'text' : 'password']"
					autocomplete="on"
					:placeholder="$t('prPasswordControl')"
					v-model="passwordControl"
				/>
				<i v-if="!showPasswordControl" class="fas fa-eye pr-smaller-eye" @mouseenter="showPasswordControl = true"></i>
				<i v-if="showPasswordControl" class="fas fa-eye-slash" @mouseleave="showPasswordControl = false"></i>
			</div>
			<button class="app-success-btn" @click="resetPassword">
				{{ $t('prResetPassword') }}
			</button>
		</div>
	</div>
</template>

<script>
/**
 * @group Access
 * Provides inputs for the password-reset process
 */
export default {
	name: 'PasswordReset',
	data() {
		return {
			showPassword: false,
			showPasswordControl: false,
			resetCode: '',
			password: '',
			passwordControl: '',
		};
	},
	created() {
		if (!this.$route.query.resetCode) this.$router.push({ name: 'NotFound' });
		else this.resetCode = this.$route.query.resetCode;
	},
	methods: {
		// @vuese
		// Starts the password reset process of a user by checking the inputs and sending a request to the backend
		resetPassword() {
			if (this.password !== this.passwordControl) this.$global.showToast('error', this.$t('prPasswordDontMatch'));
			else if (this.password.length < 9) this.$global.showToast('error', this.$t('prShortPasswordLength'));
			else {
				this.$global.postData(
					'access',
					'/reset-password',
					{ password: this.password, resetCode: this.resetCode },
					null,
					(err, data) => {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							this.$global.showToast('error', this.$t(msg ? msg : 'prResetError'));
							this.resetMailPending = false;
						} else {
							this.$global.showToast('success', this.$t('prResetSuccess'));
							this.$router.push({ name: 'Login' });
							this.resetMailPending = false;
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.pr-wrap-content {
	max-width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
}

.pr-center-content {
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px;
}

.pr-wrap-content h1 {
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	color: var(--main-color-6);
}

.pr-wrap-input {
	width: fit-content;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
}

.pr-wrap-input label {
	font-size: 20px;
}

.pr-wrap-input input {
	width: 350px;
	max-width: calc(100% - 25px);
	height: 30px;
	padding: 5px;
	display: block;
	margin: 10px auto 20px auto;
	font-size: 20px;
}

.pr-wrap-input i {
	font-size: 25px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	-webkit-text-stroke: 1px var(--main-color-text-dark);
	color: var(--main-color-text-light);
}

.pr-smaller-eye {
	right: 12px !important;
}

button {
	width: 300px;
	height: 40px;
	margin: 10px auto;
	padding: 0px 15px;
	display: block;
	font-size: 25px;
	box-sizing: content-box;
}
</style>
