<template>
	<div class="ad-wrap-content">
		<div class="ad-wrap-user-control">
			<h1>{{ $t('adUserManagement') }}</h1>
			<UserTable
				v-if="users"
				:users="users"
				:allUsers="allUsersAmount"
				@updateUsers="users = $event"
				@sortUsers="sortUsers"
				@queryUsers="queryUsers"
			/>
			<div v-else class="ad-table-placeholder">{{ $t('adLoading') }}<i class="fa-solid fa-spinner"></i></div>
		</div>
		<div class="ad-wrap-helper-control">
			<h1>{{ $t('adPipelineHelper') }}</h1>
			<HelperTable
				v-if="helpers && applications && frameworks"
				:helpers="helpers"
				:allHelpers="allHelpersAmount"
				:applications="applications"
				:frameworks="frameworks"
				@sortHelpers="sortHelpers"
				@queryHelpers="queryHelpers"
			/>
			<div v-else class="ad-table-placeholder">{{ $t('adLoading') }}<i class="fa-solid fa-spinner"></i></div>
		</div>
		<div class="ad-wrap-bundle-control">
			<h1>{{ $t('adSystemDataBundles') }}</h1>
			<BundleTable
				v-if="bundles && applications"
				:bundles="bundles"
				:allBundles="allBundlesAmount"
				:applications="applications"
				:devMode="false"
				@sortBundles="sortBundles"
				@queryBundles="queryBundles"
			/>
			<div v-else class="ad-table-placeholder">{{ $t('adLoading') }}<i class="fa-solid fa-spinner"></i></div>
		</div>
	</div>
</template>

<script>
import UserTable from '../components/tables/UserTable.vue';
import HelperTable from '../components/tables/HelperTable.vue';
import BundleTable from '../components/tables/BundleTable.vue';
/**
 * @group Admin
 * Contains all admin functionality like user management and upload of new helper classes
 */
export default {
	name: 'Admin',
	components: {
		UserTable,
		HelperTable,
		BundleTable,
	},
	data() {
		return {
			applications: null,
			frameworks: null,
			user: null,
			users: null,
			helpers: null,
			bundles: null,
			allUsersAmount: 0,
			allHelpersAmount: 0,
			allBundlesAmount: 0,
		};
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			this.user = this.$global.getUser();
			this.$cr.getAllApplications((err, result) => {
				if (!err) this.applications = result;
			});
			this.$cr.getAllFrameworks((err, result) => {
				if (!err) this.frameworks = result;
			});
			this.queryUsers(0, 50);
			this.queryHelpers(0, 50);
			this.queryBundles(0, 5);
		},
		// @vuese
		// Sorts all users by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortUsers(sort) {
			this.users = this.$global.sortValues(sort, this.users);
		},
		// @vuese
		// Sorts all helpers by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortHelpers(sort) {
			this.helpers = this.$global.sortValues(sort, this.helpers);
		},
		// @vuese
		// Sorts all data bundles by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortBundles(sort) {
			this.bundles = this.$global.sortValues(sort, this.bundles);
		},
		// @vuese
		// Queries the users by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryUsers(start, amount) {
			this.$cr.getAllUsers(start, amount, (err, result) => {
				if (!err) {
					this.users = result.users;
					this.allUsersAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the helpers by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryHelpers(start, amount) {
			this.$cr.getAllHelper(start, amount, null, null, (err, result) => {
				if (!err) {
					this.helpers = result.helpers;
					this.allHelpersAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the baseline bundles by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryBundles(start, amount) {
			this.$cr.getBaselineBundles(start, amount, (err, result) => {
				if (!err) {
					this.bundles = result.bundles;
					this.allBundlesAmount = result.amount;
				}
			});
		},
	},
};
</script>

<style scoped>
.ad-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 10px;
	text-align: center;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

.ad-wrap-user-control h1,
.ad-wrap-helper-control h1,
.ad-wrap-bundle-control h1 {
	color: var(--main-color-6);
	margin: 5px 0px 10px 0px;
}

.ad-table-placeholder {
	height: 240px;
	max-width: 1800px;
	width: 90%;
	margin: 0px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
}

.ad-table-placeholder i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
</style>
