<template>
	<div class="lv-wrap-content">
		<TableOfContents :states="stateOrder" :isListView="true" @jumpToState="jumpToState" />
		<div v-for="(element, idx) in sortedElements" :key="idx">
			<div v-if="element.type == 'state'" class="lv-state">
				<h2 :id="`toc${getStateIdx(element)}${element.name.replace(/ /g, '')}`">
					{{ element.name }}
					<i
						v-if="
							!stateReportLoading.includes(element.moduleID) &&
							![$t('evCreationState'), $t('evExecutionState')].includes(element.name)
						"
						class="fa-regular fa-floppy-disk"
						@click="generateStateReport(element)"
					>
					</i
					><i
						v-else-if="![$t('evCreationState'), $t('evExecutionState')].includes(element.name)"
						class="fa-solid fa-spinner ts-loading"
					></i>
				</h2>
				<div class="lv-toggle-div">
					<i
						:class="['fa-solid', 'fa-chevron-up', stateIsCollapsed(element) ? 'lv-hidden' : '']"
						@click="collapseState(element)"
					></i>
					<i
						:class="['fa-solid', 'fa-chevron-down', !stateIsCollapsed(element) ? 'lv-hidden' : '']"
						@click="expandState(element)"
					></i>
				</div>
				<div class="lv-header">
					<div class="lv-information-bar">
						<div class="lv-duration">
							<p>{{ $t('lvDuration') }}<i class="fa-regular fa-clock"></i></p>
							<div class="lv-information">
								{{ getDuration(element) }}
							</div>
						</div>
						<div class="lv-category">
							<p>{{ $t('lvCategory') }}<i class="fa-solid fa-cubes"></i></p>
							<div class="lv-information">
								{{ $t(`ts${element.category}`) }}
							</div>
						</div>
						<div class="lv-files">
							<p>{{ $t('lvFiles') }}<i class="fa-solid fa-file-code"></i></p>
							<div class="lv-information">
								{{ element.files.join(', ') }}
								<p v-if="element.files.length == 0">{{ $t('lvNoFiles') }}</p>
							</div>
						</div>
						<div class="lv-functions">
							<p>{{ $t('lvFunctions') }}<i class="fa-solid fa-code"></i></p>
							<div class="lv-information">
								{{ getFunctionNames(element) }}
								<p v-if="element.functions.length == 0">{{ $t('lvNoFunctions') }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="element.type == 'config'" :class="['lv-config', stateIsCollapsed(element) ? 'lv-collapsed' : '']">
				<PipelineConfiguration :config="element" />
			</div>
			<div v-else-if="element.type == 'result'" :class="['lv-result', stateIsCollapsed(element) ? 'lv-collapsed' : '']">
				<PipelineResult :result="element" />
			</div>
			<div v-else-if="element.type == 'log'" :class="['lv-log', stateIsCollapsed(element) ? 'lv-collapsed' : '']">
				<div class="lv-content-col">
					<p v-for="part in getLogParts(element)" :key="part.logID" class="lv-log-content">
						<span class="lv-created">{{ parseDate(part.creation) }}</span>
						<span :class="`lv-level-${part.level}`">[{{ part.level.toUpperCase() }}]</span>
						<span class="lv-file">{{ part.file }}</span>
						<span class="lv-function">{{ part.function }}():</span>
						<span v-if="part.log.length <= 20000" class="lv-content">{{ part.log }}</span>
						<span v-else class="lv-content-large">{{ $t('lvLogToLarge') }}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PipelineConfiguration from './PipelineConfiguration.vue';
import PipelineResult from './PipelineResult.vue';
import TableOfContents from './TableOfContents.vue';
import i18n from '@/translations/i18n';
import * as uuid from 'uuid';
/**
 * @group Evaluation
 * Displays the evaluation in a list view orderd by timestamp
 */
export default {
	name: 'ListView',
	components: { PipelineConfiguration, PipelineResult, TableOfContents },
	props: {
		sortedElements: {
			type: Array,
			required: true,
		},
		stateOrder: {
			type: Array,
			required: true,
		},
		expandAllList: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		expandAllList: {
			handler: function (newVal) {
				if (newVal) this.collapsedStates = [];
				else this.collapsedStates = [...new Set(this.sortedElements.map((elem) => elem.moduleID))];
			},
		},
	},
	data() {
		return {
			collapsedStates: [],
			stateReportLoading: [],
		};
	},
	created() {
		this.collapsedStates = [...new Set(this.sortedElements.map((elem) => elem.moduleID))];
	},
	methods: {
		// @vuese
		// Gets the duration between two dates
		// @return [String] The duration as HH:mm:ss
		getDuration(element) {
			let seconds = (new Date(element.finished) - new Date(element.started)) / 1000;
			seconds = seconds < 1 ? 1 : seconds;
			let minutes = Math.round(Math.floor(seconds / 60));
			let hours = Math.round(Math.floor(minutes / 60));
			let days = Math.round(Math.floor(hours / 24));

			hours = String(hours - days * 24);
			minutes = String(minutes - days * 24 * 60 - hours * 60);
			seconds = String(Math.round(seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60));

			hours = hours.length == 1 ? `0${hours}` : hours;
			minutes = minutes.length == 1 ? `0${minutes}` : minutes;
			seconds = seconds.length == 1 ? `0${seconds}` : seconds;

			return `${hours}:${minutes}:${seconds}`;
		},
		// @vuese
		// Returns a function call string
		// @return [String] The functions string
		getFunctionNames(element) {
			return element.functions
				.map((func) => {
					return `${func}()`;
				})
				.join(', ');
		},
		// @vuese
		// Splits the log content by lines and displays the each in a seperate line
		// @arg log[String] - The full log
		// @return [Array] - The array of all log parts
		getLogParts(log) {
			let logEntries = [];
			try {
				`${log.content}`.split(/\r\n|\r|\n/).forEach((line) => {
					if (line.trim() !== '') {
						line = line.replace(/(?:\r\n|\r|\n)/g, ' ');

						logEntries.push({ logID: uuid.v4(), log: line, ...log });
					}
				});
			} catch (error) {
				logEntries.push({ logID: uuid.v4(), log: `${log.content}`, ...log });
			}
			if (logEntries.length == 0) logEntries.push({ logID: uuid.v4(), log: `${log.content}`, ...log });
			return logEntries;
		},
		// @vuese
		// Parses a date and adjusts the time zone
		// @arg date[String] - The date string
		// @returns [String] - The parsed date
		parseDate(date) {
			let d = new Date(date);
			if (this.isDST(d)) d.setTime(d.getTime() + 7200000);
			else d.setTime(d.getTime() + 3600000);

			return d.toISOString().split('.')[0];
		},
		// @vuese
		// Checks if a date is in the daytime saving zone
		// @arg date[Object] - The date
		// @returns [Boolean] - Is true if the date is in the dst
		isDST(date) {
			let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
			let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
			return Math.max(jan, jul) !== date.getTimezoneOffset();
		},
		// @vuese
		// Checks if the given state is collapsed
		// @arg element[Object] - The state element for which the collapse is checked
		// @returns [Boolean] - Is true if the state is already collapsed
		stateIsCollapsed(element) {
			if (this.collapsedStates.includes(element.moduleID)) return true;
			else return false;
		},
		// @vuese
		// Expands the state content
		expandState(element) {
			this.collapsedStates = this.collapsedStates.filter((state) => state !== element.moduleID);
		},
		// @vuese
		// Collapses the state content
		collapseState(element) {
			if (this.collapsedStates.filter((state) => state == element.moduleID).length == 0)
				this.collapsedStates.push(element.moduleID);
		},
		// @vuese
		// Catches the scroll emit event from the table of contents and expands the selected state
		// @arg stateID[String] - The ID of the state
		jumpToState(stateID) {
			this.collapsedStates = this.collapsedStates.filter((state) => state !== stateID);
		},
		// @vuese
		// Retruns the idx of the selected state
		// @arg element[Object] - The selected state
		// @returns [Number] - The idx of the selected state
		getStateIdx(element) {
			return this.stateOrder.findIndex((state) => state.moduleID == element.moduleID);
		},
		// @vuese
		// Generates the state report for the selected state
		generateStateReport(element) {
			if (!this.stateReportLoading.includes(element.moduleID)) {
				this.stateReportLoading.push(element.moduleID);
				this.$global.showToast('info', this.$t('tsPartialGenerationStarted'), true);
				let that = this;
				this.$global.getData(
					'training',
					`/trainings/${this.$route.params.trainingID}/report/${element.moduleID}`,
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
						params: { locale: i18n.locale },
					},
					null,
					function (err, result) {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							that.$global.showToast('error', that.$t(msg ? msg : 'tsReportCreationError'));
							that.stateReportLoading = that.stateReportLoading.filter((state) => state !== element.moduleID);
						} else {
							try {
								let a = document.createElement('a');
								a.href = `data:application/zip;base64, ${result}`;
								a.download = `${that.$t('evEvaluationReport')}.zip`;
								a.click();
								a.remove();
								that.$global.showToast('success', that.$t('tsPartialGenerationFinished'), true);
							} catch (error) {
								console.log(error);
								that.$global.showToast('error', that.$t('tsReportDownloadError'));
							} finally {
								that.stateReportLoading = that.stateReportLoading.filter((state) => state !== element.moduleID);
							}
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.lv-wrap-content {
	padding: 0px 0px;
}

.lv-state {
	margin: 10px 0px 5px 0px;
	padding: 5px;
	border-bottom: 1px solid var(--main-color-border-light);
	border-radius: 20px 20px 0px 0px;
	box-shadow: 0px 0px 4px 4px var(--main-color-2);
	background-color: var(--main-color-2);
	position: relative;
}

.lv-config,
.lv-result,
.lv-log {
	width: 100%;
	margin: 0px;
	padding: 0px;
	display: flex;
	box-sizing: border-box;
	background-color: var(--main-color-4);
}

.lv-collapsed {
	display: none;
}

.lv-state h2 {
	width: fit-content;
	margin: 5px auto;
	color: var(--main-color-6);
}

.lv-state h2 > i {
	margin-left: 10px;
	color: var(--main-color-text-light);
}

.lv-state h2 > i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.ts-loading {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.lv-header {
	width: 100%;
	height: fit-content;
	margin-bottom: 10px;
	text-align: center;
}

.lv-toggle-div {
	position: absolute;
	top: 0px;
	right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lv-toggle-div i {
	font-size: 25px;
}

.lv-toggle-div i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.lv-hidden {
	display: none;
}

.lv-information-bar {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
}

.lv-information-bar > div {
	padding: 5px 10px;
}

.lv-duration {
	flex: 1 1 120px;
}

.lv-category {
	flex: 1 1 130px;
}

.lv-files {
	flex: 1 1 150px;
}

.lv-functions {
	flex: 1 1 300px;
}

.lv-information-bar > div > p {
	margin-bottom: 5px;
	font-size: 20px;
	font-style: italic;
	text-decoration: underline;
}

.lv-information-bar > div > p > i {
	margin-left: 10px;
}

.lv-information-bar > div > .lv-information {
	max-height: 100px;
	overflow: auto;
}

.lv-log .lv-content-col {
	width: 100%;
	padding: 1px 20px;
	box-sizing: border-box;
	background-color: var(--main-color-dark);
}

.lv-log-content {
	flex: 1 1 100%;
	text-align: start;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
}

.lv-log-content span {
	margin-right: 5px;
}
.lv-created {
	white-space: nowrap;
	color: var(--main-color-log-timestamp);
}

.lv-level-info {
	color: var(--main-color-log-info);
	font-weight: bold;
}

.lv-level-debug {
	color: var(--main-color-log-debug);
	font-weight: bold;
}

.lv-level-warn {
	color: var(--main-color-log-warn);
	font-weight: bold;
}

.lv-level-error {
	color: var(--main-color-log-error);
	font-weight: bold;
}

.lv-level-logger {
	color: var(--main-color-log-logger);
	font-weight: bold;
}

.lv-file {
	color: var(--main-color-log-caller);
}

.lv-function {
	color: var(--main-color-log-caller);
}

.lv-content {
	color: var(--main-color-log-content);
}

.lv-content-large {
	color: var(--main-color-log-warn);
}
</style>
