import { global } from './global.js';

let context = null;
export const cr = {
	// Initializes the global functions with the vue context
	// @arg ctx[Object] - The context of the vue components
	initializeGlobal: function (ctx) {
		context = ctx;
	},
	// Checks if the user has access to a certain route
	// @arg cb[Function] - The callback to the parent function
	accessCheck: function (cb) {
		global.checkAccess(() => {
			if (!global.hasRights({ route: context.$route.name })) {
				global.showToast('warn', context.$t('apUnauthorizedWarning'));
				context.$router.back();
				if (cb) cb(false);
			} else if (cb) cb(true);
		});
	},
	// @vuese
	// Queries all applications that are registered in the system
	// @arg cb[Function] - Callback to the parent function
	getAllApplications: function (cb) {
		global.getData(
			'system',
			'/applications',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crApplicationsError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all frameworks that are registered in the system
	// @arg cb[Function] - Callback to the parent function
	getAllFrameworks: function (cb) {
		global.getData(
			'system',
			'/frameworks',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crFrameworksError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all helper that are registered in the system
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg applicationID[String] - The applicationID
	// @arg frameworkID[String] - The frameworkID
	// @arg cb[Function] - Callback to the parent function
	getAllHelper: function (offset, amount, applicationID, frameworkID, cb) {
		global.getData(
			'system',
			'/helpers',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount, applicationID: applicationID, frameworkID: frameworkID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crHelperError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a helper by a helperID
	// @arg helperID[String] - The helperID
	// @arg cb[Function] - Callback to the parent function
	getHelper: function (helperID, cb) {
		global.getData(
			'system',
			`/helpers/${helperID}`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crHelperError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all revisions of helper that are registered in the system
	// @arg applicationID[String] - The applicationID
	// @arg cb[Function] - Callback to the parent function
	getAllHelperRevisions: function (applicationID, cb) {
		global.getData(
			'system',
			'/helpers/revisions',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { applicationID: applicationID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crHelperRevisionError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all pipelines that are registered in the system
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg applicationID[String] - The applicationID
	// @arg frameworkID[String] - The frameworkID
	// @arg activated[Boolean] - Only queries activated pipelines if true
	// @arg cb[Function] - Callback to the parent function
	getAllPipelines: function (offset, amount, applicationID, frameworkID, activated, cb) {
		global.getData(
			'pipeline',
			'/pipelines',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: {
					offset: offset,
					amount: amount,
					applicationID: applicationID,
					frameworkID: frameworkID,
					activated: activated,
				},
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crPipelineError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a pipeline by a id
	// @arg pipelineID[String] - The pipelineID
	// @arg cb[Function] - Callback to the parent function
	getPipeline: function (pipelineID, cb) {
		global.getData(
			'pipeline',
			`/pipelines/${pipelineID}`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crPipelineError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all revisions of all pipelines that are registered in the system
	// @arg applicationID[String] - The applicationID
	// @arg cb[Function] - Callback to the parent function
	getAllPipelineRevisions: function (applicationID, cb) {
		global.getData(
			'pipeline',
			'/pipelines/revisions',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: {
					applicationID: applicationID,
				},
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crPipelineRevisionError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all registered users that are the displayed in a user table
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg cb[Function] - Callback to the parent function
	getAllUsers(offset, amount, cb) {
		global.getData(
			'admin',
			'/users',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crUserQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a set of data bundles by a given offset, amount and applicationID
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg applicationID[String] - The applicationID
	// @arg cb[Function] - Callback to the parent function
	getDataBundles(offset, amount, applicationID, cb) {
		global.getData(
			'data',
			'/bundles',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount, applicationID: applicationID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crBundleQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a data bundle by a id
	// @arg bundleID[String] - The bundleID
	// @arg cb[Function] - Callback to the parent function
	getBundle(bundleID, cb) {
		global.getData(
			'data',
			`/bundles/${bundleID}`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crBundleQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all revisions of data bundles by a given applicationID
	// @arg applicationID[String] - The applicationID
	// @arg cb[Function] - Callback to the parent function
	getDataBundleRevisions(applicationID, cb) {
		global.getData(
			'data',
			'/bundles/revisions',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { applicationID: applicationID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crBundleRevisionQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all data bundles that are configured by the system to be used for the pipeline verification process
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg cb[Function] - Callback to the parent function
	getBaselineBundles(offset, amount, cb) {
		global.getData(
			'data',
			'/bundles',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount, isVerification: true },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crBundleQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries meta information about all data bundles
	// @arg applicationID[String] - The applicationID
	// @arg cb[Function] - Callback to the parent function
	getDataBundleMeta(applicationID, cb) {
		global.getData(
			'data',
			'/bundles/meta',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { applicationID: applicationID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crBundleQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries meta information about all baseline bundles
	// @arg applicationID[String] - The applicationID
	// @arg cb[Function] - Callback to the parent function
	getBaselineBundleMeta(applicationID, cb) {
		global.getData(
			'data',
			'/bundles/meta',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { applicationID: applicationID, isVerification: true },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crBundleQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a set of data packages by a given offset, amount and the applicationID
	// @arg offset[Number] - The offset of the data packages
	// @arg amount[Number] - The amount of the data packages
	// @arg applicationID[String] - The ID of the application
	// @arg queryType[String] - Can be verified, unverified or all
	// @arg packageID[String] - Optional packageID. If available is used to get the index of the packageID
	// @arg cb[Function] - Callback to the parent function
	getDataPackages(offset, amount, applicationID, queryType, packageID, cb) {
		global.getData(
			'data',
			'/packages',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount, applicationID: applicationID, queryType: queryType, packageID: packageID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crPackageQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a data packages by a id
	// @arg packageID[String] - The packageID
	// @arg cb[Function] - Callback to the parent function
	getPackage(packageID, cb) {
		global.getData(
			'data',
			`/packages/${packageID}`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crPackageQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all revisions of data packages by a given applicationID
	// @arg applicationID[String] - The ID of the application
	// @arg cb[Function] - Callback to the parent function
	getDataPackageRevisions(applicationID, cb) {
		global.getData(
			'data',
			'/packages/revisions',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { applicationID: applicationID },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crPackageQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a training by a given trainingID
	// @arg trainingID[String] - The trainingID
	// @arg cb[Function] - Callback to the parent function
	getTraining(trainingID, cb) {
		global.getData(
			'training',
			`/trainings/${trainingID}`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crTrainingQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all trainings by a offset and amount
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg cb[Function] - Callback to the parent function
	getTrainings(offset, amount, cb) {
		global.getData(
			'training',
			`/trainings`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crTrainingQuery'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries a user by its userID
	// @arg userID[String] - The userID
	// @arg cb[Function] - Callback to the parent function
	getUser(userID, cb) {
		global.getData(
			'user',
			`/users/${userID}`,
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crUserQueryError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
	// @vuese
	// Queries all institutions that are registered in the system
	// @arg offset[Number] - The offset of the queried data
	// @arg amount[Number] - The amount of the queried data
	// @arg cb[Function] - Callback to the parent function
	getAllInstitutions: function (offset, amount, cb) {
		global.getData(
			'user',
			'/institutions',
			{
				headers: { userid: global.getUser().userID },
				auth: JSON.parse(sessionStorage.getItem('credentials')),
				params: { offset: offset, amount: amount },
			},
			null,
			function (err, result) {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					global.showToast('error', context.$t(msg ? msg : 'crInstitutionsError'));
					if (cb) cb(msg ? msg : err, null);
				} else if (cb) cb(null, result);
			}
		);
	},
};
