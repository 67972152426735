<template>
	<div class="pt-wrap-content">
		<div v-if="devMode" class="pt-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
			<div v-if="multiSelect" class="pt-select-all">
				<input type="checkbox" id="selectAllPackages" v-model="selectAllFlag" @change="selectAll" />
				<label for="selectAllPackages">{{ $t('ptSelectAll') }}</label>
			</div>
			<div v-if="!['Admin'].includes($route.name) && multiSelect" class="pt-select-all-groundtruth">
				<input type="checkbox" id="selectAllGroundtruthPackages" v-model="selectAllGroundtruthFlag" @change="selectAllGroundtruth" />
				<label for="selectAllGroundtruthPackages">{{ $t('ptSelectAllGroundtruth') }}</label>
			</div>
		</div>
		<div v-if="applications.length" class="pt-wrap-table">
			<div v-if="isLoading" class="pt-loading-overlay">
				<p>{{ $t('ptLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="pt-table">
				<div class="pt-wrap-header">
					<div class="pt-header-element app-no-select pt-package-id" :title="$t('ptSortBy')" @click="sortBy('packageID')">
						{{ $t('ptPackageID') }}
					</div>
					<div class="pt-header-element app-no-select pt-revision-id" :title="$t('ptSortBy')" @click="sortBy('revisionID')">
						{{ $t('ptRevisionID') }}
					</div>
					<div class="pt-header-element app-no-select pt-application" @click="sortBy('applicationID')" :title="$t('ptSortBy')">
						{{ $t('ptApplication') }}
					</div>
					<div class="pt-header-element app-no-select pt-is-groundtruth" @click="sortBy('isGroundtruth')" :title="$t('ptSortBy')">
						{{ $t('ptGroundtruth') }}
					</div>
					<div class="pt-header-element app-no-select pt-label" :title="$t('ptSortBy')" @click="sortBy('label')">
						{{ $t('ptLabel') }}
					</div>
					<div class="pt-header-element app-no-select pt-meta" :title="$t('ptSortBy')" @click="sortBy('amountMeta')">
						{{ $t('ptHasMeta') }}
					</div>
					<div class="pt-header-element app-no-select pt-data" :title="$t('ptSortBy')" @click="sortBy('amountData')">
						{{ $t('ptAmountData') }}
					</div>
					<div class="pt-header-element app-no-select pt-mime-types" :title="$t('ptSortBy')" @click="sortBy('mimeTypes')">
						{{ $t('ptMimeTypes') }}
					</div>
					<div class="pt-header-element app-no-select pt-creator" :title="$t('ptSortBy')" @click="sortBy('user', 'lastName')">
						{{ $t('ptCreator') }}
					</div>
					<div class="pt-header-element app-no-select pt-creation" :title="$t('ptSortBy')" @click="sortBy('creation')">
						{{ $t('ptCreation') }}
					</div>
					<div class="pt-header-element app-no-select pt-revision-visualization"></div>
				</div>
				<div class="pt-wrap-body">
					<div
						v-for="pack in packages"
						:key="pack.packageID"
						:id="pack.packageID"
						:class="[devMode ? 'pt-body-hover' : '', pack.selected ? 'pt-body-selected' : '', 'pt-body-element']"
						@click="devMode ? $emit('selectPackage', pack.packageID) : null"
					>
						<div class="pt-package-id">{{ pack.packageID.substring(0, 8) }}</div>
						<div class="pt-revision-id">{{ $global.shortenRevisionID(pack.revisionID) }}</div>
						<div class="pt-application">
							{{ $t(`${applications.filter((app) => app.applicationID == pack.applicationID)[0].abbreviation}Name`) }}
						</div>
						<div class="pt-is-groundtruth">{{ pack.isGroundtruth ? $t('ptYes') : $t('ptNo') }}</div>
						<div class="pt-label">
							{{
								$global.getLabelByApplication(
									applications.filter((app) => app.applicationID == pack.applicationID)[0].abbreviation,
									pack.label
								)
							}}
						</div>
						<div class="pt-meta">{{ pack.amountMeta ? $t('ptYes') : $t('ptNo') }}</div>
						<div class="pt-data">{{ pack.amountData }}</div>
						<div class="pt-mime-types">{{ pack.mimeTypes.split(',').join(', ') }}</div>
						<div class="pt-creator">
							{{ pack.user.userID == $global.getUser().userID ? $t('htYou') : `${pack.user.firstName} ${pack.user.lastName}` }}
						</div>
						<div class="pt-creation">{{ $global.parseDateShort(pack.creation) }}</div>
						<div class="pt-revision-visualization">
							<i class="fa-solid fa-diagram-predecessor" :title="$t('ptShowRevisions')" @click="showRevision($event, pack)"></i>
							<i
								class="fa-solid fa-magnifying-glass-chart"
								:title="$t('ptShowVisualization')"
								@click="showVisualization($event, pack)"
							></i>
						</div>
					</div>
					<div v-if="packages.length == 0" class="pt-wrap-no-packages">
						<div class="pt-no-packages">
							<i class="fa-solid fa-folder-open"></i>
							<p>{{ $t('ptNoPackage') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="selectedPackages" class="pt-query-more">
			<p>{{ selectedPackages.length }} / {{ allPackages }} {{ $t('ptPackagesSelected') }}</p>

			<div class="pt-pages">
				<p v-for="page in $global.getPages(allPackages, pageSize, currentPage)" :key="page" class="pt-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="pt-no-hover"> . . . </span>
					<span
						v-else
						:class="[page == currentPage ? 'pt-highlight-page' : '', isLoading ? 'pt-no-hover' : '']"
						@click="isLoading ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Tables
 * Displays all uploaded data packages as a table
 */
export default {
	name: 'PackageTable',
	props: {
		packages: {
			type: Array,
			required: true,
		},
		allPackages: {
			type: Number,
			required: false,
		},
		applications: {
			type: Array,
			required: true,
		},
		devMode: {
			type: Boolean,
			required: false,
		},
		multiSelect: {
			type: Boolean,
			required: false,
		},
		selectedPackages: {
			type: Array,
			required: false,
		},
		setSizeAndPage: {
			type: Object,
			required: false,
		},
	},
	watch: {
		packages: {
			handler: function (newVal) {
				this.selectAllFlag = false;
				this.selectAllGroundtruthFlag = false;
				this.isLoading = false;
			},
			deep: true,
		},
		setSizeAndPage: {
			handler: function (newVal) {
				if (newVal && (this.pageSize != newVal.pageSize || this.currentPage != newVal.currentPage)) {
					this.pageSize = newVal.pageSize;
					if (newVal.dontUpdate) this.currentPage = newVal.currentPage;
					else this.setPage(newVal.currentPage);
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			lastSort: {
				property: null,
				propertyB: null,
				asc: false,
			},
			selectAllFlag: false,
			selectAllGroundtruthFlag: false,
		};
	},
	created() {
		this.sortBy('creation');
	},
	mounted() {
		this.$emit('setState', { pageSize: this.pageSize, currentPage: this.currentPage });
	},
	methods: {
		// @vuese
		// Sorts the data packages by a given property
		// @arg propertyA[String] - The first property of the sorted object
		// @arg propertyB[String] - The second property of the sorted object
		sortBy(propertyA, propertyB) {
			if (this.lastSort.property == propertyA && this.lastSort.propertyB == propertyB) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: propertyA,
					propertyB: propertyB,
					asc: false,
				};
			}
			this.$emit('sortPackages', this.lastSort);
		},
		// @vuese
		// Changes the page size and requieries the packages from page 1
		changePageSize() {
			this.currentPage = 1;
			this.$emit('setState', { pageSize: this.pageSize, currentPage: this.currentPage });
			this.isLoading = true;
			this.$emit('queryPackages', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Changes the page and querries the packages
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.$emit('setState', { pageSize: this.pageSize, currentPage: this.currentPage });
			this.isLoading = true;
			this.$emit('queryPackages', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Selects or deselects all data packages
		selectAll() {
			if (this.selectAllFlag) this.selectAllGroundtruthFlag = true;
			else this.selectAllGroundtruthFlag = false;
			this.$emit('selectAll', this.selectAllFlag);
		},
		// @vuese
		// Selects or deselects all data packages that are verified as groundtruth
		selectAllGroundtruth() {
			if (!this.selectAllGroundtruthFlag) this.selectAllFlag = false;
			this.$emit('selectAllGroundtruth', this.selectAllGroundtruthFlag);
		},
		// @vuese
		// Shows the revision of the package
		// @arg e[Object] - The event that occured
		// @arg dataPackage[Object] - The selected package
		showRevision(e, dataPackage) {
			e.stopPropagation();
			if (window.location.href.includes('/configuration/'))
				window.location.href = `/configuration/data/revision/package/${dataPackage.packageID}?applicationID=${dataPackage.applicationID}`;
			else window.location.href = `/data/revision/package/${dataPackage.packageID}?applicationID=${dataPackage.applicationID}`;
		},
		// @vuese
		// Shows the visualization of the package
		// @arg e[Object] - The event that occured
		// @arg dataPackage[Object] - The selected package
		showVisualization(e, dataPackage) {
			e.stopPropagation();
			if (window.location.href.includes('/configuration/'))
				window.location.href = `/configuration/data/visualization/${dataPackage.packageID}?applicationID=${dataPackage.applicationID}`;
			else window.location.href = `/data/visualization/${dataPackage.packageID}?applicationID=${dataPackage.applicationID}`;
		},
	},
};
</script>

<style scoped>
.pt-wrap-content {
	height: fit-content;
	margin: 0px auto 20px auto;
	max-width: 1800px;
}

.pt-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.pt-options select {
	margin-right: 10px;
}

.pt-options div {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.pt-options div input {
	width: 15px;
	height: 15px;
	margin-right: 5px;
}

.pt-options div label {
	font-size: 17px;
	cursor: pointer;
}

.pt-wrap-table {
	width: 90%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.pt-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.pt-loading-overlay p {
	font-size: 30px;
}

.pt-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.pt-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.pt-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
}

.pt-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
	vertical-align: top;
}

.pt-header-element i {
	margin-left: 10px;
}

.pt-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.pt-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.pt-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.pt-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.pt-body-element div {
	height: 45px;
	padding: 3px 5px 2px 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.pt-package-id {
	width: 80px;
}
.pt-revision-id {
	min-width: 160px;
	width: calc(100% - 1220px);
}
.pt-application {
	width: 220px;
}
.pt-is-groundtruth {
	min-width: 100px;
}
.pt-label {
	width: 130px;
}

.pt-meta {
	width: 80px;
}

.pt-data {
	width: 60px;
}

.pt-mime-types {
	width: 200px;
}
.pt-creator {
	width: 160px;
}
.pt-creation {
	width: 130px;
}

.pt-revision-visualization {
	width: 60px;
}

.pt-revision-visualization i {
	margin: 0px 2px;
	font-size: 20px;
}

.pt-revision-visualization i:hover {
	cursor: pointer;
	color: var(--main-color-text-light);
}

.pt-wrap-no-packages {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pt-no-packages {
	text-align: center;
}

.pt-no-packages i {
	margin-bottom: 10px;
	font-size: 40px;
}

.pt-no-packages p {
	font-size: 20px;
}

.pt-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.pt-query-more p {
	margin: 5px auto;
}

.pt-query-more button {
	font-size: 17px;
}

.pt-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.pt-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.pt-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.pt-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.pt-highlight-page {
	color: var(--main-color-6);
	text-decoration: none !important;
	cursor: default !important;
	pointer-events: none;
}
</style>
