import io from 'socket.io-client';
import { global } from './global.js';
import { i18n } from './translations/i18n';

let connectionRoutes = ['Admin', 'Upload', 'Development', 'Configuration', 'Evaluation'];

let connectionInfo = null;
let sock = null;
let socketConnected = false;
let context = null;
export const socket = {
	// Initializes the global functions with the vue context
	// @arg ctx[Object] - The context of the vue components
	initializeGlobal: function (ctx) {
		context = ctx;
	},
	// Querries the connection information from the serer and initializes the socket connection if needed
	getConnectionInfo: function () {
		let that = this;
		global.getData('access', '/connection-information', null, null, function (err, data) {
			if (err) global.showToast('error', err.response.data.msg);
			else {
				connectionInfo = data;
				window.setTimeout(() => {
					if (connectionRoutes.includes(context.$route.name)) that.initiateSocketConnection();
				}, 1000);
			}
		});
	},
	// Initializes the socket connection to the backend server
	initiateSocketConnection: function () {
		if (!sock) {
			let that = this;
			sock = io.connect(connectionInfo.replace('/configuration', ''), {
				reconnection: true,
				transports: ['websocket'],
				auth: {
					basicAuth: JSON.parse(sessionStorage.getItem('credentials')),
					userID: global.getUser().uID,
				},
			});

			sock.on('connect', function () {
				socketConnected = true;
			});

			sock.on('connect_error', function (err) {
				global.showToast('error', i18n.tc(err.message), false);
				if (err.message == 'erUnauthorizedBasicAuth') router.push({ name: 'Login' });
				socketConnected = false;
			});

			sock.on('disconnect', function () {
				socketConnected = false;
			});

			sock.on('disconnect_error', function (msg) {
				global.showToast('error', i18n.tc(msg));
				that.closeSocketConnection();
				socketConnected = false;
				context.$router.go(-1);
			});
		}
	},
	// Returns the socket connection object to the backend if exists
	// @return [Object] - The socket connection object
	getSocket: function (cb) {
		if (sock && socketConnected) cb(sock);
		else {
			window.setTimeout(() => {
				this.getSocket(cb);
			}, 100);
		}
	},
	// Closes the socket server connection and destroys the socket
	closeSocketConnection: function () {
		if (sock) {
			sock.disconnect();
			sock = null;
		}
	},
	// Gets called if the route changes and connects to the socket server if needed
	// @arg [String] name - The name of the route
	handleRouteChange: function (name) {
		if (connectionRoutes.includes(name)) this.initiateSocketConnection();
		else this.closeSocketConnection();
	},
};
