<template>
	<div class="rdc-wrap-content">
		<div v-if="['pipelines', 'bundles'].includes(revisionKey)" class="rdc-wrap-elements">
			<div
				v-for="element in revisionData[revisionKey]"
				:key="element[`${sanatizeKey(revisionKey)}ID`]"
				class="rdc-elements-link"
				@click="
					$emit(
						`load${revisionKey.charAt(0).toUpperCase() + sanatizeKey(revisionKey).slice(1)}Content`,
						element[`${sanatizeKey(revisionKey)}ID`]
					)
				"
			>
				<p>{{ element.name }}</p>
				<p>{{ `R${element.revisionID.split('R')[1]}` }}</p>
				<p>{{ $global.parseDateFull(element.creation) }}</p>
			</div>
			<div v-if="revisionData[revisionKey].length == 0">
				<p v-if="revisionKey == 'pipelines'">{{ $t('dvNoPipelines') }}</p>
				<p v-else-if="revisionKey == 'bundles'">{{ $t('dvNoBundles') }}</p>
			</div>
		</div>
		<div v-else-if="revisionKey == 'helper'">
			<div class="rdc-elements-link" @click="$emit(`loadHelperContent`, revisionData[revisionKey].helperID)">
				<p>{{ revisionData[revisionKey].name }}</p>
				<p>{{ `R${revisionData[revisionKey].revisionID.split('R')[1]}` }}</p>
				<p>{{ $global.parseDateFull(revisionData[revisionKey].creation) }}</p>
			</div>
			<div v-if="revisionData[revisionKey].length == 0">
				<p>{{ $t('dvNoHelpers') }}</p>
			</div>
		</div>
		<p v-else-if="revisionKey == 'creation'">
			{{ $global.parseDateFull(revisionData[revisionKey]) }}
		</p>
		<p v-else-if="revisionKey == 'activated'">
			<span v-if="revisionData[revisionKey]">{{ $global.parseDateFull(revisionData[revisionKey]) }}</span>
			<span v-else>{{ $t('dvNeverActivated') }}</span>
		</p>
		<p v-else-if="revisionKey == 'applicationID'">
			{{ getApplicationName(revisionData[revisionKey]) }}
		</p>
		<p v-else-if="revisionKey == 'frameworkID'">
			{{ getFrameworkName(revisionData[revisionKey]) }}
		</p>
		<p v-else-if="revisionKey == 'user'">
			{{ revisionData[revisionKey].firstName }} {{ revisionData[revisionKey].lastName }} ({{
				$global.getUserRole(revisionData[revisionKey].role)
			}})
		</p>
		<p v-else-if="['input', 'output'].includes(revisionKey)">
			{{ revisionData[revisionKey].join(', ') }}
		</p>
		<p v-else-if="revisionKey == 'os'">
			{{ getOSName(revisionData[revisionKey]) }}
		</p>
		<p v-else>
			{{ revisionData[revisionKey] }}
		</p>
	</div>
</template>

<script>
/**
 * @group DiffViewer
 * Displays a revision column with all details about the revision
 */
export default {
	name: 'RevisionDiffColumn',
	props: {
		revisionKey: {
			type: String,
			required: true,
		},
		revisionData: {
			type: Object,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		dataType: {
			type: String,
			required: true,
		},
	},
	methods: {
		// @vuese
		// Returns the application name translation for a applicationID
		// @arg applicationID[String] - The applicationID
		// @return [String] - The name of the application
		getApplicationName(applicationID) {
			let application = this.applications.filter((app) => app.applicationID == applicationID)[0];
			return this.$t(`${application.abbreviation}Name`);
		},
		// @vuese
		// Returns the framework name translation for a frameworkID
		// @arg frameworkID[String] - The frameworkID
		// @return [String] - The name of the framework
		getFrameworkName(frameworkID) {
			let framework = this.frameworks.filter((frame) => frame.frameworkID == frameworkID)[0];
			return this.$t(`${framework.abbreviation}Name`);
		},
		// @vuese
		// Returns the translation for the os
		// @arg os[String] - The os flag
		// @return [String] - The os translation
		getOSName(os) {
			if (os == 'win32') return this.$t('rdWin32');
			else if (os == 'linux') return this.$t('rdLinux');
			else if (os == 'darwin') return this.$t('rdDarwin');
		},
		// @vuese
		// Sanatizes the object key to fit the needed syntax
		// @arg key[String] - The object key
		// @returns [String] - The sanatized key
		sanatizeKey(key) {
			if (key[key.length - 1] == 's') return key.slice(0, key.length - 1);
			else return key;
		},
	},
};
</script>

<style scoped>
.rdc-wrap-content {
	flex: 1 1 40%;
	max-width: 40%;
	max-height: 250px;
	vertical-align: top;
	overflow: auto;
}

.rdc-wrap-elements {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: wrap;
}

.rdc-elements-link {
	width: fit-content;
	min-width: 150px;
	padding: 3px;
	border-radius: 5px;
	font-size: 13px;
	border: 1px solid var(--main-color-5);
	color: var(--main-color-text-light);
	background-color: var(--main-color-3);
}

.rdc-elements-link span {
	display: inline-block;
}

.rdc-elements-link {
	margin: 5px;
}

.rdc-elements-link > p:first-child {
	font-weight: bold;
}

.rdc-elements-link:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
}
</style>
