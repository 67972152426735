<template>
	<div class="ur-wrap-content">
		<i class="fas fa-times-circle ur-close-dialog" @click="$emit('hideUploadReport')"></i>
		<div v-if="noMeta.length > 0" class="ur-report-part ur-report-no-meta">
			<h2>
				<i class="fa-solid fa-circle-question ur-info"></i>{{ noMeta.length }}
				{{ noMeta.length == 1 ? $t('urNoMetaPackage') : $t('urNoMetaPackages') }}
			</h2>
			<i
				class="fa-solid fa-circle-info"
				@click="$emit('showInfo', { title: $t(`urNoMetaInfoTitle`), text: $t(`urNoMetaInfo`) })"
			></i>
			<div class="ur-wrap-packages">
				<p v-for="(pack, idx) in noMeta" :key="pack">
					{{ $t('urDataPackage') }} {{ pack.substring(0, 8) }}<span v-if="idx !== noMeta.length - 1">,</span>
				</p>
			</div>
		</div>
		<div v-if="noFiles.length > 0" class="ur-report-part ur-report-no-files">
			<h2>
				<i class="fa-solid fa-triangle-exclamation ur-warning"></i>{{ noFiles.length }}
				{{ noFiles.length == 1 ? $t('urNoFilesPackage') : $t('urNoFilesPackages') }}
			</h2>
			<i
				class="fa-solid fa-circle-info"
				@click="$emit('showInfo', { title: $t(`urNoFilesInfoTitle`), text: $t(`urNoFilesInfo`) })"
			></i>
			<div class="ur-wrap-packages">
				<p v-for="(pack, idx) in noFiles" :key="pack">
					{{ $t('urDataPackage') }} {{ pack.substring(0, 8) }}<span v-if="idx !== noFiles.length - 1">,</span>
				</p>
			</div>
		</div>
		<div v-if="contentWarning.length > 0" class="ur-report-part ur-report-content-warning">
			<h2>
				<i class="fa-solid fa-triangle-exclamation ur-warning"></i>{{ contentWarning.length }}
				{{ contentWarning.length == 1 ? $t('urContentWarningPackage') : $t('urContentWarningPackages') }}
			</h2>
			<i
				class="fa-solid fa-circle-info"
				@click="$emit('showInfo', { title: $t(`urContentWarningInfoTitle`), text: $t(`urContentWarningInfo`) })"
			></i>
			<div class="ur-wrap-packages">
				<p v-for="(pack, idx) in contentWarning" :key="pack">
					{{ $t('urDataPackage') }} {{ pack.substring(0, 8) }}<span v-if="idx !== contentWarning.length - 1">,</span>
				</p>
			</div>
		</div>
		<div v-if="syntaxError.length > 0" class="ur-report-part ur-report-syntax-error">
			<h2>
				<i class="fas fa-times-circle ur-error"></i>{{ syntaxError.length }}
				{{ syntaxError.length == 1 ? $t('urSyntaxErrorPackage') : $t('urSyntaxErrorPackages') }}
			</h2>
			<i
				class="fa-solid fa-circle-info"
				@click="$emit('showInfo', { title: $t(`urSyntaxErrorInfoTitle`), text: $t(`urSyntaxErrorInfo`) })"
			></i>
			<div class="ur-wrap-packages">
				<p v-for="(pack, idx) in syntaxError" :key="pack">
					{{ $t('urDataPackage') }} {{ pack.substring(0, 8) }}<span v-if="idx !== syntaxError.length - 1">,</span>
				</p>
			</div>
		</div>
		<div class="ur-wrap-save-btn">
			<button
				:class="[syntaxError.length > 0 ? 'app-disabled-btn' : 'app-warn-btn', 'ur-upload-anyway']"
				@click="$emit('uploadFiles')"
			>
				{{ $t('urUploadAnyway') }}
			</button>
		</div>
	</div>
</template>

<script>
/**
 * @group Upload
 * Displays a report of the upload files if anything isnt correct
 */
export default {
	name: 'UploadReport',
	props: {
		noFiles: {
			type: Array,
			required: true,
		},
		noMeta: {
			type: Array,
			required: true,
		},
		syntaxError: {
			type: Array,
			required: true,
		},
		contentWarning: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style scoped>
.ur-wrap-content {
	max-width: 90%;
	max-height: 90%;
	position: relative;
	padding: 5px 30px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--main-color-3);
	animation: slideIn 0.4s linear;
}

.ur-close-dialog {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 30px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ur-close-dialog:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.ur-report-part {
	width: 100%;
	padding: 15px 10px;
	box-sizing: border-box;
}

.ur-report-part h2 {
	width: fit-content;
	margin: 0px 10px 5px auto;
	display: inline-block;
	font-size: 20px;
	font-weight: normal;
	text-decoration: underline;
	text-decoration-thickness: 3px;
}

.ur-report-part i {
	font-size: 20px;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ur-report-part i:hover {
	cursor: pointer;
	color: var(--main-color-info);
}

.ur-report-no-files h2 {
	text-decoration-color: var(--main-color-warn);
}

.ur-report-no-meta h2 {
	text-decoration-color: var(--main-color-info);
}

.ur-report-syntax-error h2 {
	text-decoration-color: var(--main-color-error);
}

.ur-report-content-warning h2 {
	text-decoration-color: var(--main-color-warn);
}

.ur-info {
	margin-right: 5px;
	font-size: 25px !important;
	color: var(--main-color-info);
	cursor: default !important;
	pointer-events: none;
}

.ur-warning {
	margin-right: 5px;
	font-size: 25px !important;
	color: var(--main-color-warn);
	cursor: default !important;
	pointer-events: none;
}

.ur-error {
	margin-right: 5px;
	font-size: 25px !important;
	color: var(--main-color-error);
	cursor: default !important;
	pointer-events: none;
}

.ur-wrap-save-btn {
	width: 100%;
	height: 40px;
	text-align: center;
}

.ur-wrap-save-btn button {
	font-size: 17px;
}

.ur-wrap-packages {
	width: 100%;
	max-height: 100px;
	box-sizing: border-box;
	overflow: auto;
}

.ur-wrap-packages p {
	width: fit-content;
	margin: 5px 2px;
	display: inline-block;
}
</style>
