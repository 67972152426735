<template>
	<div class="hu-wrap-content">
		<h2>{{ $t('huHelperUpload') }}</h2>
		<i class="fas fa-times-circle hu-close-dialog" @click="$emit('hideHelperUpload')"></i>
		<div class="hu-wrap-form">
			<div class="hu-wrap-select">
				<p>{{ $t('huApplications') }}</p>
				<select v-model="upload.application" @change="filterHelper">
					<option :value="null" disabled selected hidden>{{ $t('huChooseApplication') }}</option>
					<option v-for="app in applications" :key="app.applicationID" :value="app.applicationID">
						{{ $t(`${app.abbreviation}Name`) }}
					</option>
				</select>
			</div>
			<div class="hu-wrap-select">
				<p>{{ $t('huFrameworks') }}</p>
				<select v-model="upload.framework" @change="filterHelper">
					<option :value="null" disabled selected hidden>{{ $t('huChooseFramework') }}</option>
					<option v-for="framework in frameworks" :key="framework.frameworkID" :value="framework.frameworkID">
						{{ $t(`${framework.abbreviation}Name`) }}
					</option>
				</select>
			</div>
			<div class="hu-wrap-select">
				<p>{{ $t('huHelper') }}</p>
				<select v-model="upload.previousRevision" @change="setName">
					<option :value="null" disabled selected hidden>{{ $t('huChooseHelper') }}</option>
					<option :value="'NEW'">{{ $t('huNewHelper') }}</option>
					<option v-for="helper in filteredHelpers" :key="helper.helperID" :value="helper.revisionID">
						{{ helper.name }} ({{ $global.shortenRevisionID(helper.revisionID) }})
					</option>
				</select>
			</div>
			<div class="hu-wrap-input">
				<p>{{ $t('huHelperName') }}</p>
				<input v-model="upload.name" type="text" :placeholder="$t('huHelperName')" />
			</div>
			<div class="hu-wrap-upload">
				<label class="app-default-btn hu-file-upload">
					<input type="file" @input="handleHelperUpload" />
					<i class="fa fa-cloud-upload"></i> {{ $t('huUploadHelper') }}
				</label>
				<p v-if="upload.helper">{{ upload.helper.name }}</p>
			</div>
			<div class="hu-wrap-upload">
				<label class="app-default-btn hu-file-upload">
					<input type="file" @input="handleRequirementsUpload" />
					<i class="fa fa-cloud-upload"></i> {{ $t('huUploadRequirements') }}
				</label>
				<p v-if="upload.requirements">{{ upload.requirements.name }}</p>
			</div>
			<div v-if="upload.requirements" class="hu-wrap-requirements">
				<div v-if="upload.requirements.content.length > 0" class="hu-requirements">
					<pre v-for="(req, idx) in upload.requirements.content" :key="idx"><span>{{ idx+1 }}.</span> {{ req }}</pre>
				</div>
				<p v-else>{{ $t('huNoRequirements') }}</p>
			</div>
			<div v-if="upload.requirements" class="hu-wrap-btn">
				<button
					:class="[checkRequirementsCondition() ? 'app-default-btn' : 'app-disabled-btn']"
					@click="checkRequirementsCondition() ? testRequirements() : null"
				>
					{{ $t('huTestRequirements') }}
				</button>
			</div>
		</div>
		<div v-if="showRequirementsTest" class="hu-wrap-log">
			<div class="hu-wrapper">
				<h2>{{ $t('huRequirementsCheck') }}</h2>
				<div class="hu-log">
					<i class="fa-solid fa-circle-xmark" @click="showRequirementsTest = false"></i>
					<LiveLog :connect="true" />
				</div>
			</div>
		</div>
		<div class="hu-wrap-btn">
			<button
				:class="[uploadConditions() ? 'app-success-btn' : 'app-disabled-btn', 'hu-save-helper']"
				@click="isLoading ? null : saveHelper()"
			>
				{{ $t('huSaveHelper') }} <i v-if="isLoading" class="fa-solid fa-spinner hu-loading"></i>
			</button>
		</div>
	</div>
</template>

<script>
import LiveLog from '../log/LiveLog.vue';
/**
 * @group Upload
 * Lets the user upload a new helper
 */
export default {
	name: 'HelperUpload',
	components: {
		LiveLog,
	},
	props: {
		applications: {
			type: Array,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		helpers: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			showRequirementsTest: false,
			activeRequirementsCheck: false,
			upload: {
				application: null,
				framework: null,
				name: '',
				previousRevision: null,
				helper: null,
				requirements: null,
			},
			filteredHelpers: [],
			isLoading: false,
		};
	},
	created() {
		this.filteredHelpers = JSON.parse(JSON.stringify(this.helpers));
	},
	methods: {
		// @vuese
		// Filters all helpers by a selected application and framework
		filterHelper() {
			let fh;
			if (this.upload.framework) fh = this.helpers.filter((helper) => helper.frameworkID == this.upload.framework);
			if (this.upload.application) {
				if (fh) fh = fh.filter((helper) => helper.applicationID == this.upload.application);
				else fh = this.helpers.filter((helper) => helper.applicationID == this.upload.application);
			}
			if (this.upload.previousRevision && this.upload.previousRevision !== 'NEW') {
				if (fh.filter((helper) => helper.revisionID == this.upload.previousRevision).length == 0)
					this.upload.previousRevision = null;
			}
			this.filteredHelpers = fh;
		},
		// @vuese
		// Sets the name field if a user selected a previous revision
		setName() {
			if (this.upload.previousRevision !== 'NEW') {
				this.upload.name = this.filteredHelpers.filter((helper) => helper.revisionID == this.upload.previousRevision)[0].name;
			} else this.upload.name = '';
		},
		// @vuese
		// Checks if the helper can be uploaded
		// @returns [Boolean] - Is true if the helper can be uploaded
		uploadConditions() {
			if (!this.upload.application) return false;
			else if (!this.upload.framework) return false;
			else if (!this.upload.previousRevision) return false;
			else if (!this.upload.name || this.upload.name.length < 5 || this.upload.name.length > 30) return false;
			else if (!this.upload.helper) return false;
			else return true;
		},
		// @vuese
		// Handles the upload event
		// @arg e[Object] - The event that occured
		handleHelperUpload(e) {
			e.preventDefault();
			if (e.target.files) {
				let files = Array.from(e.target.files);
				this.upload.helper = files[0];
			} else this.$global.showToast('warn', this.$t('huCantUploadFile'));
		},
		// @vuese
		// Handles the upload event
		// @arg e[Object] - The event that occured
		handleRequirementsUpload(e) {
			e.preventDefault();
			if (e.target.files) {
				try {
					let that = this;
					let files = Array.from(e.target.files);
					let reader = new FileReader();
					reader.onload = function () {
						let text = reader.result;
						that.upload.requirements = {
							name: files[0].name,
							content: text.split('\n'),
						};

						e.target.value = null;
					};
					reader.readAsText(files[0]);
				} catch (error) {
					console.log(error);
					this.$global.showToast('error', this.$t('huCantReadRequirements'));
				}
			} else this.$global.showToast('warn', this.$t('huCantReadRequirements'));
		},
		// @vuese
		// Checks if the requirements can be tested
		// @returns [Boolean] - Is true if requirements can be tested
		checkRequirementsCondition() {
			if (this.upload.requirements) {
				if (this.upload.requirements.content.length > 0) {
					// Filter empty lines and comments
					if (this.upload.requirements.content.filter((req) => req !== '' && req.charAt(0) !== '#').length > 0) return true;
					else return false;
				} else return false;
			} else return false;
		},
		// @vuese
		// Tests if the specified requirements can be installed and dont have any conflicts
		testRequirements(cb) {
			this.showRequirementsTest = true;
			if (!this.activeRequirementsCheck) {
				this.activeRequirementsCheck = true;
				let that = this;
				this.$global.postData(
					'pipeline',
					`/requirements`,
					{ requirements: this.upload.requirements.content },
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
					},
					function (err, result) {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							that.$global.showToast('error', that.$t(msg ? msg : 'huRequirementsError'));
							if (cb) cb(true);
						} else {
							that.$global.showToast('success', that.$t('huRequirementsOK'), true);
							if (cb) cb(false);
						}

						that.activeRequirementsCheck = false;
					}
				);
			}
		},
		// @vuese
		// Saves the helper if all conditions are met
		saveHelper() {
			if (this.uploadConditions()) {
				this.isLoading = true;
				let that = this;
				this.testRequirements((err) => {
					if (!err) {
						that.showRequirementsTest = false;
						let bodyForm = new FormData();
						bodyForm.append(`meta`, JSON.stringify(that.upload));
						bodyForm.append(`helper`, that.upload.helper);
						that.$global.postData(
							'system',
							'/helpers',
							bodyForm,
							{
								headers: { userid: that.$global.getUser().userID },
								auth: JSON.parse(sessionStorage.getItem('credentials')),
							},
							(err, data) => {
								if (err) {
									let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
									that.$global.showToast('error', that.$t(msg ? msg : 'huUploadError'));
								} else {
									that.$global.showToast('success', that.$t('huHelperUploaded'), true);
									that.$emit('hideHelperUpload');
								}
								that.isLoading = false;
							}
						);
					} else that.isLoading = false;
				});
			} else {
				if (this.upload.name.length < 5 || this.upload.name.length > 30)
					this.$global.showToast('warn', this.$t('huNameLength'));
				else if (!this.upload.helper) this.$global.showToast('warn', this.$t('huNoFile'));
				else this.$global.showToast('warn', this.$t('huFillAllFields'));
				this.isLoading = false;
			}
		},
	},
};
</script>

<style scoped>
.hu-wrap-content {
	max-width: 90%;
	max-height: 90%;
	width: 700px;
	/* position: relative; */
	padding: 10px 10px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--main-color-4);
	animation: slideIn 0.4s linear;
}

.hu-wrap-form {
	padding: 0px 30px;
}

.hu-wrap-content h2 {
	width: calc(100% - 50px);
	float: left;
	font-size: 30px;
	color: var(--main-color-6);
}

.hu-close-dialog {
	/* position: absolute;
	top: 5px;
	right: 5px; */
	float: right;
	font-size: 30px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.hu-close-dialog:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.hu-wrap-input,
.hu-wrap-select {
	width: calc(50% - 20px);
	min-width: 250px;
	height: 50px;
	margin: 10px;
	box-sizing: border-box;
	display: inline-block;
	text-align: start;
}

.hu-wrap-input p,
.hu-wrap-select p {
	width: fit-content;
	margin: 5px;
	text-decoration: underline;
}

.hu-wrap-select select {
	width: 100%;
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.hu-wrap-input input {
	width: 100%;
	display: block;
	box-shadow: 0 0 0 2pt var(--main-color-4);
	box-sizing: border-box;
}

.hu-wrap-upload {
	display: inline-block;
	vertical-align: top;
	margin: 15px 10px 0px 10px;
}

.hu-wrap-upload p {
	margin-bottom: 10px;
	display: block;
	font-size: 17px;
}

.hu-file-upload {
	width: 250px;
	display: inline-block;
	padding: 5px 10px;
	margin-bottom: 5px;
	border-radius: 5px;
	font-size: 17px;
	background-color: var(--main-color-2);
}

.hu-file-upload:hover {
	cursor: pointer;
	background-color: var(--main-color-3);
}

.hu-file-upload input[type='file'] {
	display: none;
}

.hu-wrap-requirements {
	width: 100%;
	height: 200px;
	margin-bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
}

.hu-requirements {
	width: 100%;
	height: 100%;
	padding: 5px;
	text-align: start;
	box-sizing: border-box;
	overflow: auto;
}

.hu-requirements > pre > span {
	min-width: 35px;
	display: inline-block;
	border-right: 1px solid var(--main-color-border-light);
}

.hu-wrap-btn {
	width: 100%;
	margin: 10px 0px 5px 0px;
	text-align: center;
}

.hu-wrap-btn button {
	padding: 5px 20px;
	font-size: 17px;
}

.hu-wrap-btn > .app-default-btn {
	background-color: var(--main-color-3);
}

.hu-wrap-btn > .app-default-btn:hover {
	background-color: var(--main-color-2);
}

.hu-wrap-log {
	width: 100%;
	height: calc(100% - 50px);
	position: absolute;
	top: 50px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 11;
	background-color: var(--main-color-dark-cc);
}

.hu-wrapper {
	width: 90%;
	height: 90%;
	padding: 10px 30px 10px 30px;
	overflow: auto;
	position: relative;
	box-sizing: border-box;
	background-color: var(--main-color-2);
	border-radius: 20px;
	border: 1px solid var(--main-color-5);
	animation: slideIn 0.4s linear;
}

.hu-wrapper h2 {
	margin: 0px 0px 5px 0px;
	color: var(--main-color-6);
}

.hu-log {
	width: 100%;
	height: calc(100% - 30px);
}

.hu-log i {
	font-size: 25px;
	position: absolute;
	top: 5px;
	right: 5px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.hu-log i:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}

.hu-loading {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}
</style>
