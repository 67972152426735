<template>
	<div class="co-wrap-content">
		<div class="co-wrap-pipeline-preview">
			<PipelinePreview
				:pipeline="getPipeline()"
				:bundle="getBundle()"
				:applications="applications"
				:configuration="true"
				@configureBundle="configureBundle"
				@configurePipeline="configurePipeline"
			/>
		</div>
		<div class="co-wrap-train-btn">
			<button
				:class="[canTrainPipeline() ? 'app-success-btn' : 'app-disabled-btn']"
				@click="isLoading ? null : checkPipelineTraining()"
			>
				{{ $t('coTrainPipeline') }} <i v-if="isLoading" class="fa-solid fa-spinner"></i>
			</button>
		</div>
		<div v-if="showBundles" class="co-wrap-system-bundles">
			<h1>{{ $t('coSystemBundles') }}</h1>
			<p class="co-wrap-table-description">{{ $t('coSystemBundlesText') }}</p>
			<BundleTable
				v-if="baselineBundles"
				:bundles="baselineBundles"
				:allBundles="allBaselineBundlesAmount"
				:applications="applications"
				:devMode="true"
				:single="true"
				:selectedBundles="selectedBundle"
				@sortBundles="sortBaselineBundles"
				@selectBundle="selectBaselineBundle"
				@queryBundles="queryBaselineBundles"
			/>
			<div v-else class="co-table-placeholder">{{ $t('coLoading') }}<i class="fa-solid fa-spinner"></i></div>
		</div>
		<div v-if="showBundles" class="co-wrap-data-bundles">
			<h1>{{ $t('coDataBundles') }}</h1>
			<p class="co-wrap-table-description">{{ $t('coDataBundlesText') }}</p>
			<BundleTable
				v-if="userBundles"
				:bundles="userBundles"
				:allBundles="allUserBundlesAmount"
				:applications="applications"
				:devMode="true"
				:single="true"
				:selectedBundles="selectedBundle"
				@sortBundles="sortUserBundles"
				@selectBundle="selectUserBundle"
				@queryBundles="queryUserBundles"
			/>
			<div v-else class="co-table-placeholder">{{ $t('coLoading') }}<i class="fa-solid fa-spinner"></i></div>
		</div>
		<div v-if="showPipelines" class="co-wrap-pipelines">
			<h1>{{ $t('coPipelines') }}</h1>
			<PipelineTable
				v-if="pipelines"
				:pipelines="pipelines"
				:allPipelines="allPipelinesAmount"
				:applications="applications"
				:frameworks="frameworks"
				:devMode="true"
				:selectedPipeline="selectedPipeline"
				@sortPipelines="sortPipelines"
				@selectPipeline="selectPipeline"
				@queryPipelines="queryPipelines"
			/>
			<div v-else class="co-table-placeholder">{{ $t('coLoading') }}<i class="fa-solid fa-spinner"></i></div>
		</div>
		<div v-if="showNotification" class="co-wrap-notification">
			<div class="co-notification">
				<h2>{{ $t('coNotification') }}</h2>
				<p>{{ $t('coNotificationText') }}</p>
				<div class="co-notification-buttons">
					<button class="app-default-btn" @click="showNotification = false">{{ $t('coNo') }}</button>
					<button class="app-success-btn" @click="trainPipeline">{{ $t('coYes') }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PipelinePreview from '../components/pipeline/PipelinePreview.vue';
import PipelineTable from '../components/tables/PipelineTable.vue';
import BundleTable from '../components/tables/BundleTable.vue';
/**
 * @group Configuration
 * Lets the user configure a pipeline training
 */
export default {
	name: 'Configuration',
	components: {
		PipelinePreview,
		PipelineTable,
		BundleTable,
	},
	data() {
		return {
			showBundles: true,
			showPipelines: false,
			applications: [],
			frameworks: [],
			baselineBundles: null,
			userBundles: null,
			pipelines: null,
			allBaselineBundlesAmount: 0,
			allUserBundlesAmount: 0,
			allPipelinesAmount: 0,
			selectedBundle: null,
			selectedPipeline: null,
			showNotification: false,
			isLoading: false,
		};
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			this.$cr.getAllApplications((err, result) => {
				if (!err) this.applications = result;
			});
			this.$cr.getAllFrameworks((err, result) => {
				if (!err) this.frameworks = result;
			});
			this.queryPipelines(0, 50);
			this.queryBaselineBundles(0, 5);
			this.queryUserBundles(0, 5);
		},
		// @vuese
		// Swaps the view to the data bundle configuration
		configureBundle() {
			this.showBundles = true;
			this.showPipelines = false;
			document.getElementById('configureBundle').classList.add('pp-highlighted');
			document.getElementById('configurePipeline').classList.remove('pp-highlighted');
		},
		// @vuese
		// Swaps the view to the pipeline configuration
		configurePipeline() {
			this.showBundles = false;
			this.showPipelines = true;
			document.getElementById('configurePipeline').classList.add('pp-highlighted');
			document.getElementById('configureBundle').classList.remove('pp-highlighted');
		},
		// @vuese
		// Queries the pipelines by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryPipelines(start, amount) {
			this.$cr.getAllPipelines(start, amount, null, null, null, (err, result) => {
				if (!err) {
					this.pipelines = result.pipelines;
					this.allPipelinesAmount = result.amount;
					this.pipelines.map((pipeline) => {
						if (this.selectedPipeline == pipeline.pipelineID) pipeline.selected = true;
						return pipeline;
					});
				}
			});
		},
		// @vuese
		// Queries the data bundles by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryBaselineBundles(start, amount) {
			this.$cr.getBaselineBundles(start, amount, (err, result) => {
				if (!err) {
					this.baselineBundles = result.bundles;
					this.allBaselineBundlesAmount = result.amount;
					if (this.selectedBundle) {
						this.baselineBundles.map((bundle) => {
							if (this.selectedBundle.includes(bundle.bundleID)) bundle.selected = true;
							return bundle;
						});
					}
				}
			});
		},
		// @vuese
		// Queries the baseline bundles by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryUserBundles(start, amount) {
			this.$cr.getDataBundles(start, amount, null, (err, result) => {
				if (!err) {
					this.userBundles = result.bundles;
					this.allUserBundlesAmount = result.amount;
					if (this.selectedBundle) {
						this.userBundles.map((bundle) => {
							if (this.selectedBundle.includes(bundle.bundleID)) bundle.selected = true;
							return bundle;
						});
					}
				}
			});
		},
		// @vuese
		// Sorts all pipelines by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortPipelines(sort) {
			this.pipelines = this.$global.sortValues(sort, this.pipelines);
		},
		// @vuese
		// Sorts all baseline bundles by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortBaselineBundles(sort) {
			this.baselineBundles = this.$global.sortValues(sort, this.baselineBundles);
		},
		// @vuese
		// Sorts all data bundles by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortUserBundles(sort) {
			this.userBundles = this.$global.sortValues(sort, this.userBundles);
		},
		// @vuese
		// Selects a pipeline
		// @arg pipelineID[String] - The ID of the pipeline
		selectPipeline(pipelineID) {
			this.pipelines = this.pipelines.map((pipeline) => {
				if (pipeline.pipelineID == pipelineID) {
					pipeline.selected = true;
					this.selectedPipeline = pipelineID;
				} else pipeline.selected = false;
				return pipeline;
			});
		},
		// @vuese
		// Selects a baseline bundle
		// @arg pipelineID[String] - The ID of the baseline bundle
		selectBaselineBundle(bundleID) {
			if (this.userBundles) {
				this.userBundles = this.userBundles.map((bundle) => {
					bundle.selected = false;
					return bundle;
				});
			}
			this.baselineBundles = this.baselineBundles.map((bundle) => {
				if (bundle.bundleID == bundleID) {
					bundle.selected = true;
					this.selectedBundle = [bundleID];
				} else bundle.selected = false;
				return bundle;
			});
		},
		// @vuese
		// Selects a data bundle
		// @arg pipelineID[String] - The ID of the data bundle
		selectUserBundle(bundleID) {
			if (this.baselineBundles) {
				this.baselineBundles = this.baselineBundles.map((bundle) => {
					bundle.selected = false;
					return bundle;
				});
			}
			this.userBundles = this.userBundles.map((bundle) => {
				if (bundle.bundleID == bundleID) {
					bundle.selected = true;
					this.selectedBundle = [bundleID];
				} else bundle.selected = false;
				return bundle;
			});
		},
		// @vuese
		// Gets the configured bundle if already selected
		// @returns [Object] - The selected bundle or null
		getBundle() {
			if (this.selectedBundle) {
				let bundle = this.baselineBundles.filter((bundle) => bundle.bundleID == this.selectedBundle[0])[0];
				if (!bundle) bundle = this.userBundles.filter((bundle) => bundle.bundleID == this.selectedBundle[0])[0];
				return bundle;
			} else return null;
		},
		// @vuese
		// Gets the configured pipeline if already selected
		// @returns [Object] - The selected pipeline or null
		getPipeline() {
			if (this.selectedPipeline) return this.pipelines.filter((pipeline) => pipeline.pipelineID == this.selectedPipeline)[0];
			else return null;
		},
		// @vuese
		// Checks if the selected bundle is compatible with the selected pipeline
		// @returns [Boolean] - Is true if the parts are compatible
		canTrainPipeline() {
			if (!this.selectedBundle || !this.selectedPipeline) return false;
			else if (this.selectedBundle.applicationID !== this.selectedPipeline.applicationID) return false;
			else {
				let pipeline = this.getPipeline();
				let bundle = this.getBundle();
				let bundleInput = bundle.mimeTypes.split(',');
				let canBeTrained = true;
				bundleInput.forEach((input) => {
					if (!pipeline.input.includes(input)) canBeTrained = false;
				});
				return canBeTrained;
			}
		},
		// @vuese
		// Checks if the training can be started
		checkPipelineTraining() {
			if (this.canTrainPipeline()) {
				let bundle = this.baselineBundles.filter((bundle) => bundle.bundleID == this.selectedBundle[0])[0];
				if (!bundle) this.showNotification = true;
				else this.trainPipeline();
			} else {
				if (!this.selectedBundle) this.$global.showToast('warn', this.$t('coNoBundle'));
				else if (!this.selectedPipeline) this.$global.showToast('warn', this.$t('coNoPipeline'));
				else if (this.getPipeline().applicationID !== this.getBundle().applicationID)
					this.$global.showToast('warn', this.$t('coNoMatchingApplication'));
				else this.$global.showToast('warn', this.$t('coPipelineInput'));
			}
		},
		// @vuese
		// Starts the pipeline training
		trainPipeline() {
			this.showNotification = false;
			this.isLoading = true;
			let that = this;
			this.$global.postData(
				'training',
				'/trainings',
				{
					pipeline: this.getPipeline(),
					bundle: this.getBundle(),
					userID: this.$global.getUser().userID,
				},
				{
					headers: { userid: this.$global.getUser().userID },
					auth: JSON.parse(sessionStorage.getItem('credentials')),
				},
				function (err, result) {
					if (err) {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						that.$global.showToast('error', that.$t(msg ? msg : 'coCreateTrainingError'));
						that.isLoading = false;
					} else {
						that.$global.showToast('success', that.$t('coCreateTrainingSuccess'));
						that.$router.push({ name: 'Evaluation', params: { trainingID: result } });
						that.isLoading = false;
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.co-wrap-content {
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow: auto;
}

.co-wrap-content h1 {
	width: fit-content;
	margin: 10px auto;
	color: var(--main-color-6);
}

.co-wrap-pipeline-preview {
	width: 100%;
	margin: auto;
}

.co-wrap-train-btn {
	width: 100%;
	margin: 10px auto 20px auto;
	text-align: center;
}

.co-wrap-train-btn button {
	height: 40px;
	width: 250px;
	margin-top: 10px;
	padding: 5px 10px;
	font-size: 20px;
	box-sizing: border-box;
}

.co-wrap-train-btn button i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.co-table-placeholder {
	height: 240px;
	max-width: 1800px;
	width: 90%;
	margin: 0px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
}

.co-table-placeholder i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.co-wrap-table-description {
	width: 90%;
	margin: 10px auto;
	text-align: center;
}

.co-wrap-notification {
	width: 100vw;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: var(--main-color-dark-80);
}

.co-notification {
	max-width: 80%;
	max-height: 90%;
	position: relative;
	padding: 5px 30px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--main-color-3);
	text-align: center;
	animation: slideIn 0.4s linear;
}

.co-notification h2 {
	width: fit-content;
	margin: 10px auto;
	color: var(--main-color-6);
}

.co-notification p {
	margin: 5px auto 10px auto;
	text-align: start;
}

.co-notification button {
	width: 100px;
	margin: 0px 10px;
}
</style>
