import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Registration from '../views/Registration.vue';
import PasswordReset from '../views/PasswordReset.vue';
import Home from '../views/Home.vue';
import Upload from '../views/Upload.vue';
import Pipeline from '../views/Pipeline.vue';
import Development from '../views/Development.vue';
import Configuration from '../views/Configuration.vue';
import Evaluation from '../views/Evaluation.vue';
import Data from '../views/Data.vue';
import Profile from '../views/Profile.vue';
import Admin from '../views/Admin.vue';
import LegalNotice from '../views/LegalNotice.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '',
		name: 'Login',
		component: Login,
	},
	{
		path: '/registration',
		name: 'Registration',
		component: Registration,
	},
	{
		path: '/password-reset',
		name: 'PasswordReset',
		component: PasswordReset,
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/upload',
		name: 'Upload',
		component: Upload,
	},
	{
		path: '/pipeline',
		name: 'Pipeline',
		component: Pipeline,
	},
	{
		path: '/pipeline/development',
		name: 'Development',
		component: Development,
	},
	{
		path: '/pipeline/configuration',
		name: 'Configuration',
		component: Configuration,
	},
	{
		path: '/pipeline/evaluation/:trainingID',
		name: 'Evaluation',
		component: Evaluation,
	},
	{
		path: '/data/revision',
		name: 'Data',
		component: Data,
	},
	{
		path: '/data/revision/:dataType',
		name: 'Data',
		component: Data,
	},
	{
		path: '/data/revision/:dataType/:dataID',
		name: 'Data',
		component: Data,
	},
	{
		path: '/data/visualization',
		name: 'Data',
		component: Data,
	},
	{
		path: '/data/visualization/:packageID',
		name: 'Data',
		component: Data,
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
	},
	{
		path: '/legal-notice',
		name: 'LegalNotice',
		component: LegalNotice,
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
