<template>
	<div class="ar-wrap-content">
		<div class="ar-wrap-symbols">
			<i v-if="!isRecording" class="fa-solid fa-microphone ar-microphone" @click="record"></i>
			<i v-else class="fa-solid fa-circle-pause ar-pause" @click="pauseRecording"></i>
			<i class="fa-solid fa-circle-stop ar-stop" @click="stopRecording"></i>
		</div>
		<p class="ar-recording-duration">{{ audioDurationParsed }}</p>
		<p class="ar-recording-status">
			{{
				currentStatus ? (currentStatus == 'NONE' ? '' : currentStatus == 'RECORDING' ? $t('arRecording') : $t('arPaused')) : ''
			}}
		</p>
		<div v-if="recordings.length > 0" class="ar-wrap-recordings">
			<div
				v-for="recording in recordings"
				:key="recording.id"
				:class="[selectedFile ? (selectedFile.id == recording.id ? 'ar-selected-recording' : '') : '', 'ar-recording']"
				@click="selectedFile = recording"
			>
				<i class="fa-solid fa-xmark" @click="deleteRecording(recording.id)"></i>
				<p>{{ recording.name }}</p>
				<div class="ar-wrap-option">
					<select v-model="recording.category">
						<option :value="null" disabled selected hidden>{{ $t('arSelectCategory') }}</option>
						<option value="cough">{{ $t('arCough') }}</option>
						<option value="breath">{{ $t('arBreath') }}</option>
						<option value="speech">{{ $t('arSpeech') }}</option>
					</select>
				</div>
				<p>{{ parseTime(recording.duration / 1000) }}</p>
			</div>
		</div>
		<div v-else>
			<p>{{ $t('arNoRecordings') }}</p>
		</div>
		<div class="ar-wrap-audio-player">
			<AudioPlayer :application="application.abbreviation" :file="selectedFile" />
		</div>
		<div class="ar-wrap-btn">
			<button :class="[recordings.length > 0 ? 'app-success-btn' : 'app-disabled-btn']" @click="saveFiles">
				{{ $t('arSaveRecordings') }}
			</button>
		</div>
	</div>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { WebmToWavConverter } from 'webm-to-wav-converter';
import * as uuid from 'uuid';
/**
 * @group Audio
 * Records new audio https://github.com/tchvu3/capacitor-voice-recorder
 */
export default {
	name: 'AudioRecorder',
	components: {
		AudioPlayer,
	},
	props: {
		application: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			canRecordAudio: false,
			hasRecordingPermission: false,
			isRecording: false,
			countInterval: null,
			currentStatus: null,
			currentDuration: 0,
			recordingCount: 1,
			audioDurationParsed: '00:00',
			recordings: [],
			selectedFile: null,
		};
	},
	created() {
		this.setupRecorder();
		window.addEventListener('unhandledrejection', this.handleUnexpectedError);
	},
	beforeDestroy() {
		this.stopRecording();
		window.removeEventListener('unhandledrejection', this.handleUnexpectedError);
	},
	methods: {
		// @vuese
		// Handels unexpected errors caused by the audio recorder library
		// @arg e[Object] - The event that occured
		handleUnexpectedError(e) {
			this.$global.showToast('error', this.$t('arUnexpectedRecorderError'));
			this.resetRecorder();
		},
		// @vuese
		// Setup of the recoreder with permission check
		setupRecorder() {
			try {
				VoiceRecorder.canDeviceVoiceRecord().then((result) => {
					this.canRecordAudio = result.value;
					if (!result.value) this.$global.showToast('warn', this.$t('arCantRecordAudio'));
					else {
						VoiceRecorder.hasAudioRecordingPermission().then((result) => {
							this.hasRecordingPermission = result.value;
							if (!result.value) this.checkPermission();
						});
					}
				});
			} catch (error) {
				this.resetRecorder();
				console.log(error);
			}
		},
		// @vuese
		// Checks if the permissions to record audio are available
		// @arg cb[Function] - The callback of the function
		checkPermission(cb) {
			try {
				VoiceRecorder.requestAudioRecordingPermission().then((result) => {
					if (!result.value) this.$global.showToast('warn', this.$t('arNoRecordingPermission'));
					else this.hasRecordingPermission = true;
					if (cb) cb(result.value);
				});
			} catch (error) {
				this.resetRecorder();
				console.log(error);
			}
		},
		// @vuese
		// Starts or resumes the recording
		record() {
			if (!this.canRecordAudio) this.$global.showToast('warn', this.$t('arCantRecordAudio'));
			else if (!this.hasRecordingPermission) {
				this.checkPermission((result) => {
					if (result) this.startRecording();
				});
			} else {
				if (!this.currentStatus) this.startRecording();
				else this.resumeRecording();
			}
		},
		// @vuese
		// Starts the recording
		startRecording() {
			try {
				VoiceRecorder.startRecording()
					.then((result) => {
						this.createCounterInterval();
					})
					.catch((error) => {
						this.resetRecorder();
						console.log(error);
						this.$global.showToast('error', this.$t('arRecordingError'));
					});
			} catch (error) {
				this.resetRecorder();
				console.log(error);
			}
		},
		// @vuese
		// Pauses the recording
		pauseRecording() {
			try {
				VoiceRecorder.pauseRecording()
					.then((result) => {})
					.catch((error) => {
						this.resetRecorder();
						console.log(error);
						this.$global.showToast('error', this.$t('arRecordingPauseError'));
					});
			} catch (error) {
				this.resetRecorder();
				console.log(error);
			}
		},
		// @vuese
		// Resumes the recording
		resumeRecording() {
			try {
				VoiceRecorder.resumeRecording()
					.then((result) => {
						this.isRecording = true;
					})
					.catch((error) => {
						this.resetRecorder();
						console.log(error);
						this.$global.showToast('error', this.$t('arRecordingResumeError'));
					});
			} catch (error) {
				this.resetRecorder();
				console.log(error);
			}
		},
		// @vuese
		// Stops the recording
		stopRecording() {
			try {
				if (this.currentStatus && this.currentDuration > 0) {
					this.destroyCounterInterval();
					VoiceRecorder.stopRecording()
						.then((result) => {
							this.isRecording = false;
							if (result.value.msDuration) this.createRecordingEntry(result);
						})
						.catch((error) => {
							console.log(error);
							this.resetRecorder();
							this.$global.showToast('error', this.$t('arRecordingStopError'));
						});
				}
			} catch (error) {
				console.log(error);
				this.resetRecorder();
				this.$global.showToast('error', this.$t('arRecordingStopError'));
			}
		},
		// @vuese
		// Creates a counte interval which counts how long the recording duration is
		createCounterInterval() {
			try {
				let that = this;
				if (!this.countInterval) {
					this.countInterval = window.setInterval(function () {
						VoiceRecorder.getCurrentStatus()
							.then((result) => {
								if (result.status == 'NONE') that.currentStatus = null;
								else if (result.status == 'RECORDING') {
									that.isRecording = true;
									that.currentStatus = result.status;
									that.currentDuration += 0.01;
									that.audioDurationParsed = that.parseTime(that.currentDuration);
								} else {
									that.currentStatus = result.status;
									that.isRecording = false;
								}
							})
							.catch((error) => {
								this.resetRecorder();
								console.log(error);
							});
					}, 10);
				}
			} catch (error) {
				this.resetRecorder();
				console.log(error);
			}
		},
		// @vuese
		// Destroys the counter interval
		destroyCounterInterval() {
			if (this.countInterval) clearInterval(this.countInterval);
			this.countInterval = null;
		},
		// @vuese
		// Creates a recording entry
		// @arg result[Object] - The recording base 64 value and some meta
		async createRecordingEntry(result) {
			let currentDuration = Math.ceil(this.currentDuration);
			let recordingDuration = Math.ceil(result.value.msDuration / 1000);
			if (
				recordingDuration !== currentDuration &&
				recordingDuration + 1 !== currentDuration &&
				recordingDuration - 1 !== currentDuration
			) {
				this.$global.showToast('error', this.$t('arRecordingStopError'));
				this.resetRecorder();
			} else {
				let src = `data:${result.value.mimeType};base64,${result.value.recordDataBase64}`;
				this.recordings.push({
					id: uuid.v4(),
					name: `${this.$t('arRecordingName')} ${this.recordingCount}.wav`,
					category: null,
					duration: result.value.msDuration,
					src: src,
					creation: new Date(),
					file: await this.base64ToBlob(src),
				});

				this.resetRecorder();
				this.recordingCount += 1;
			}
		},
		// @vuese
		// Deletes a recording
		deleteRecording(id) {
			if (this.selectedFile) if (this.selectedFile.id == id) this.selectedFile = null;
			this.recordings = this.recordings.filter((rec) => rec.id != id);
		},
		// @vuese
		// Parses the given time for better readability
		// @arg time[Number] - The time in s
		// @returns [String] - The parsed time as mm:ss
		parseTime(time) {
			let minutes = Math.floor(time / 60);
			let seconds = Math.ceil(time - 60 * minutes).toFixed(0);
			minutes = minutes.toFixed(0);
			minutes = minutes.length === 1 ? `0${minutes}` : minutes;
			seconds = seconds.length === 1 ? `0${seconds}` : seconds;
			return `${minutes}:${seconds}`;
		},
		// @vuese
		// Converts a base64 file to a blob
		// @arg base64[String] - The base64 string
		// @returns [Object] - The created blob
		async base64ToBlob(base64) {
			const res = await fetch(base64);
			const blob = await res.blob();
			const wavBlob = await WebmToWavConverter(blob, true);
			return wavBlob;
		},
		// @vuese
		// Resets the recorder vars
		resetRecorder() {
			this.destroyCounterInterval();
			this.currentDuration = 0;
			this.audioDurationParsed = '00:00';
			this.currentStatus = null;
			this.isRecording = false;
		},
		// @vuese
		// Saves the recording as a new package
		saveFiles() {
			this.$emit('newPackage', { id: uuid.v4(), application: this.application, meta: null, files: this.recordings });
		},
	},
};
</script>

<style scoped>
.ar-wrap-content {
	width: 100%;
	height: fit-content;
	text-align: center;
}

.ar-wrap-symbols {
	width: fit-content;
	height: fit-content;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ar-wrap-symbols i {
	margin: 5px;
}

.ar-microphone {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 35px;
	background-color: var(--main-color-light);
	color: var(--main-color-4);
	border: 2px solid var(--main-color-border-dark);
	box-sizing: content-box;
}

.ar-microphone:hover {
	background-color: var(--main-color-6);
	cursor: pointer;
}

.ar-pause {
	font-size: 54px;
	-webkit-text-stroke: 2px var(--main-color-border-dark);
}

.ar-pause:hover {
	color: var(--main-color-6);
	cursor: pointer;
}

.ar-stop {
	font-size: 42px;
	-webkit-text-stroke: 2px var(--main-color-border-dark);
}

.ar-stop:hover {
	color: var(--main-color-success);
	cursor: pointer;
}

.ar-recording-duration {
	font-size: 25px;
	margin: 5px auto 0px auto;
}

.ar-recording-status {
	font-size: 17px;
	margin: 0px auto 10px auto;
}

.ar-wrap-recordings {
	width: fit-content;
	height: fit-content;
	max-height: 158px;
	padding-right: 5px;
	margin: 10px auto;
	overflow: auto;
	/* display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap; */
}

.ar-recording {
	width: fit-content;
	height: fit-content;
	margin: 5px auto;
	padding: 5px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* flex: 0 0 100%; */
	border: 3px solid var(--main-color-border-light);
	border-radius: 10px;
	box-sizing: border-box;
	background-color: var(--main-color-2);
}

.ar-recording:hover {
	background-color: var(--main-color-6-cc);
	border: 3px solid var(--main-color-border-dark);
	cursor: pointer;
}

.ar-recording i {
	margin: 0px 5px;
	font-size: 30px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ar-recording i:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}

.ar-recording p {
	width: fit-content;
	margin: 0px 5px;
	display: inline-block;
	font-size: 17px;
}

.ar-wrap-audio-player {
	width: 100%;
	margin: 5px auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ar-selected-recording {
	border: 3px solid var(--main-color-6);
}

.ar-selected-recording:hover {
	background-color: var(--main-color-2);
	border: 3px solid var(--main-color-6);
}

.ar-wrap-btn button {
	font-size: 17px;
}
</style>
