<template>
	<div class="bd-wrap-content">
		<div class="bd-diff-in-a-not-b">
			<h4>{{ $t('dvOnlyInOriginalRevision') }}</h4>
			<p>{{ $t('dvAmount') }}: {{ revisionDiff.inAnotB.length }} ({{ aNotBData.length }} {{ $t('bdShown') }})</p>
			<div class="bd-wrap-packages">
				<div
					v-for="pack in aNotBData"
					:key="pack.packageID"
					class="bd-elements-link"
					@click="$emit(`loadDataContent`, { packageID: pack.packageID, searchPackages: true })"
				>
					<p>{{ $t('dvDataPackage') }}</p>
					<p>{{ `R${pack.revisionID.split('R')[1]}` }}</p>
					<p>{{ $global.parseDateFull(pack.creation) }}</p>
				</div>
			</div>
			<div class="bd-show-more-btn">
				<button
					:class="[aNotBMax <= 0 ? 'app-disabled-btn' : 'app-default-btn']"
					@click="aNotBMax > 0 ? (aNotBMax -= 50) : null"
				>
					{{ $t('dvShowLess') }}
				</button>
				<button
					:class="[aNotBMax >= revisionDiff.inAnotB.length ? 'app-disabled-btn' : 'app-default-btn']"
					@click="aNotBMax < revisionDiff.inAnotB.length ? (aNotBMax += 50) : null"
				>
					{{ $t('dvShowMore') }}
				</button>
			</div>
		</div>
		<div class="bd-diff-intersection">
			<h4>{{ $t('dvIntersection') }}</h4>
			<p>{{ $t('dvAmount') }}: {{ revisionDiff.intersection.length }} ({{ intersectionData.length }} {{ $t('bdShown') }})</p>
			<div class="bd-wrap-packages">
				<div
					v-for="pack in intersectionData"
					:key="pack.packageID"
					class="bd-elements-link"
					@click="$emit(`loadDataContent`, { packageID: pack.packageID, searchPackages: true })"
				>
					<p>{{ $t('dvDataPackage') }}</p>
					<p>{{ `R${pack.revisionID.split('R')[1]}` }}</p>
					<p>{{ $global.parseDateFull(pack.creation) }}</p>
				</div>
			</div>
			<div class="bd-show-more-btn">
				<button
					:class="[intersectionMax <= 0 ? 'app-disabled-btn' : 'app-default-btn']"
					@click="intersectionMax > 0 ? (intersectionMax -= 50) : null"
				>
					{{ $t('dvShowLess') }}
				</button>
				<button
					:class="[intersectionMax >= revisionDiff.intersection.length ? 'app-disabled-btn' : 'app-default-btn']"
					@click="intersectionMax < revisionDiff.intersection.length ? (intersectionMax += 50) : null"
				>
					{{ $t('dvShowMore') }}
				</button>
			</div>
		</div>
		<div class="bd-diff-in-b-not-a">
			<h4>{{ $t('dvOnlyInCompareRevision') }}</h4>
			<p>{{ $t('dvAmount') }}: {{ revisionDiff.inBnotA.length }} ({{ bNotAData.length }} {{ $t('bdShown') }})</p>
			<div class="bd-wrap-packages">
				<div
					v-for="pack in bNotAData"
					:key="pack.packageID"
					class="bd-elements-link"
					@click="$emit(`loadDataContent`, { packageID: pack.packageID, searchPackages: true })"
				>
					<p>{{ $t('dvDataPackage') }}</p>
					<p>{{ `R${pack.revisionID.split('R')[1]}` }}</p>
					<p>{{ $global.parseDateFull(pack.creation) }}</p>
				</div>
			</div>
			<div class="bd-show-more-btn">
				<button
					:class="[bNotAMax <= 0 ? 'app-disabled-btn' : 'app-default-btn']"
					@click="bNotAMax > 0 ? (bNotAMax -= 50) : null"
				>
					{{ $t('dvShowLess') }}
				</button>
				<button
					:class="[bNotAMax >= revisionDiff.inBnotA.length ? 'app-disabled-btn' : 'app-default-btn']"
					@click="bNotAMax < revisionDiff.inBnotA.length ? (bNotAMax += 50) : null"
				>
					{{ $t('dvShowMore') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group DiffViewer
 * Displays the differences between the bundle packages
 */
export default {
	name: 'BundleDiff',
	props: {
		revisionDiff: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			aNotBMax: 0,
			intersectionMax: 0,
			bNotAMax: 0,
		};
	},
	computed: {
		// @vuese
		// Filters the inAnotB data by the given max
		// @returns [Array] - The filtered inAnotB data
		aNotBData() {
			return this.revisionDiff.inAnotB.slice(0, this.aNotBMax);
		},
		// @vuese
		// Filters the intersection data by the given max
		// @returns [Array] - The filtered intersection data
		intersectionData() {
			return this.revisionDiff.intersection.slice(0, this.intersectionMax);
		},
		// @vuese
		// Filters the inBnotA data by the given max
		// @returns [Array] - The filtered inBnotA data
		bNotAData() {
			return this.revisionDiff.inBnotA.slice(0, this.bNotAMax);
		},
	},
	created() {
		if (this.revisionDiff.inAnotB.length > 0) this.aNotBMax = 50;
		if (this.revisionDiff.intersection.length > 0) this.intersectionMax = 50;
		if (this.revisionDiff.inBnotA.length > 0) this.bNotAMax = 50;
	},
};
</script>

<style scoped>
.bd-wrap-content {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
}

.bd-diff-in-a-not-b,
.bd-diff-intersection,
.bd-diff-in-b-not-a {
	flex: 1 1 33%;
	margin: 0px 0.15%;
}

.bd-wrap-packages {
	width: 100%;
	height: 400px;
	overflow: auto;
	display: flex;
	flex-flow: wrap;
}

.bd-wrap-packages > div {
	flex: 1 1;
}

.bd-show-more-btn {
	width: 100%;
	margin: 10px 0px;
	text-align: center;
}

.bd-show-more-btn button {
	margin: 0px 5px;
	font-size: 17px;
}

.bd-elements-link {
	width: fit-content;
	min-width: 150px;
	padding: 3px;
	border-radius: 5px;
	font-size: 13px;
	border: 1px solid var(--main-color-5);
	color: var(--main-color-text-light);
	background-color: var(--main-color-3);
}

.bd-elements-link span {
	display: inline-block;
}

.bd-elements-link {
	margin: 5px;
}

.bd-elements-link > p:first-child {
	font-weight: bold;
}

.bd-elements-link:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
}
</style>
