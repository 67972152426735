<template>
	<div class="he-wrap-content">
		<div v-if="header" class="he-wrap-header">
			<div class="he-wrap-left">
				<div v-if="!isOverflowing" class="he-not-overflown">
					<div
						class="he-wrap-logo"
						@click="$global.hasRights({ route: 'Home' }) && $route.name !== 'Home' ? $router.push({ name: 'Home' }) : null"
					>
						<img :src="require(`@/assets/images/logo_transparent.png`)" alt="Logo" class="he-logo" />
					</div>
					<p
						v-for="element in header"
						:key="element.name"
						:class="[$global.hasRights({ route: element.name }) ? '' : 'he-disable-navigation', 'he-header-element']"
						@click="resolveLink(element)"
					>
						{{ $t(element.translation) }}
					</p>
				</div>
				<div v-else class="he-overflown">
					<i class="fa-solid fa-bars" @click="showHeader = !showHeader"></i>
					<div v-if="showHeader" class="he-hidden-header">
						<p
							v-for="element in header"
							:key="element.name"
							:class="[$global.hasRights({ route: element.name }) ? '' : 'he-disable-navigation', 'he-header-element']"
							@click="resolveLink(element)"
						>
							{{ $t(element.translation) }}
						</p>
					</div>
				</div>
			</div>
			<div class="he-wrap-right">
				<div class="he-wrap-translations">
					<div class="he-wrap-languages" @click="showLanguageSelect = !showLanguageSelect">
						<img
							:src="require(`@/assets/images/german.svg`)"
							alt="german"
							:class="selectedLanguage == 'de' ? 'he-selected-language' : ''"
						/>
						<img
							:src="require(`@/assets/images/english.svg`)"
							alt="english"
							:class="selectedLanguage == 'en' ? 'he-selected-language' : ''"
						/>
					</div>
					<div
						v-if="showLanguageSelect"
						class="he-language-select"
						@click="changeLocale(selectedLanguage == 'en' ? 'de' : 'en')"
					>
						<img
							v-if="selectedLanguage == 'en'"
							:src="require(`@/assets/images/german.svg`)"
							alt="german"
							class="he-selectable-language"
						/>
						<img
							v-if="selectedLanguage == 'de'"
							:src="require(`@/assets/images/english.svg`)"
							alt="english"
							class="he-selectable-language"
						/>
					</div>
				</div>
				<i v-if="showLogout" class="fas fa-sign-out-alt he-logout" :title="$t('heLogout')" @click="logout"></i>
			</div>
		</div>
	</div>
</template>

<script>
import i18n from '@/translations/i18n';
/**
 * @group Layout
 * Displays navigation options, language changes and logout functionallity
 */
export default {
	name: 'Header',
	watch: {
		$route: {
			handler: function (to, from) {
				this.header = null;
				this.loadHeader(to.name);
				this.user = this.$global.getUser();
			},
		},
	},
	data() {
		return {
			header: null,
			user: null,
			showHeader: false,
			showLogout: false,
			isOverflowing: false,
			overflowWidth: 0,
			selectedLanguage: 'de',
			showLanguageSelect: false,
		};
	},
	created() {
		this.user = this.$global.getUser();
	},
	mounted() {
		this.loadHeader(this.$route.name);
		window.addEventListener('resize', this.resizeListener);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		// @vuese
		// Resize listener for the header to check if a small heder is needed
		resizeListener() {
			this.checkOverflow();
		},
		// @vuese
		// Loads the header if a route changes
		// @arg name[String] - The name of the route
		loadHeader(name) {
			if (name == 'Login') {
				this.header = [
					{ name: 'Registration', translation: 'heRegistration' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = false;
			} else if (name == 'Registration') {
				this.header = [
					{ name: 'Login', translation: 'heLogin' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = false;
			} else if (name == 'PasswordReset') {
				this.header = [
					{ name: 'Login', translation: 'heLogin' },
					{ name: 'Registration', translation: 'heRegistration' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = false;
			} else if (name == 'LegalNotice') {
				if (sessionStorage.getItem('credentials')) {
					this.header = [{ name: 'Home', translation: 'heHome' }];
					this.showLogout = true;
				} else {
					this.header = [
						{ name: 'Login', translation: 'heLogin' },
						{ name: 'Registration', translation: 'heRegistration' },
					];
					this.showLogout = false;
				}
			} else if (name == 'Home') {
				this.header = [{ name: 'LegalNotice', translation: 'heLegalNotice' }];
				this.showLogout = true;
			} else if (name == 'Upload') {
				this.header = [
					{ name: 'Home', translation: 'heHome' },
					{ name: 'Pipeline', translation: 'hePipeline' },
					{ name: 'Data', translation: 'heData' },
					{
						name: 'Documentation',
						translation: 'heDocumentation',
						external: 'https://ai4health.reutlingen-university.de/landing/',
					},
					{ name: 'Profile', translation: 'heProfile' },
					{ name: 'Admin', translation: 'heAdmin' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = true;
			} else if (name == 'Pipeline') {
				this.header = [
					{ name: 'Home', translation: 'heHome' },
					{ name: 'Upload', translation: 'heUpload' },
					{ name: 'Data', translation: 'heData' },
					{
						name: 'Documentation',
						translation: 'heDocumentation',
						external: 'https://ai4health.reutlingen-university.de/landing/',
					},
					{ name: 'Profile', translation: 'heProfile' },
					{ name: 'Admin', translation: 'heAdmin' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = true;
			} else if (['Development', 'Configuration', 'Evaluation'].includes(name)) {
				this.header = [
					{ name: 'Home', translation: 'heHome' },
					{ name: 'Upload', translation: 'heUpload' },
					{ name: 'Pipeline', translation: 'hePipeline' },
					{ name: 'Data', translation: 'heData' },
					{
						name: 'Documentation',
						translation: 'heDocumentation',
						external: 'https://ai4health.reutlingen-university.de/landing/',
					},
					{ name: 'Profile', translation: 'heProfile' },
					{ name: 'Admin', translation: 'heAdmin' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = true;
			} else if (name == 'Data') {
				this.header = [
					{ name: 'Home', translation: 'heHome' },
					{ name: 'Upload', translation: 'heUpload' },
					{ name: 'Pipeline', translation: 'hePipeline' },
					{
						name: 'Documentation',
						translation: 'heDocumentation',
						external: 'https://ai4health.reutlingen-university.de/landing/',
					},
					{ name: 'Profile', translation: 'heProfile' },
					{ name: 'Admin', translation: 'heAdmin' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = true;
			} else if (name == 'Profile') {
				this.header = [
					{ name: 'Home', translation: 'heHome' },
					{ name: 'Upload', translation: 'heUpload' },
					{ name: 'Pipeline', translation: 'hePipeline' },
					{ name: 'Data', translation: 'heData' },
					{
						name: 'Documentation',
						translation: 'heDocumentation',
						external: 'https://ai4health.reutlingen-university.de/landing/',
					},
					{ name: 'Admin', translation: 'heAdmin' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = true;
			} else if (name == 'Admin') {
				this.header = [
					{ name: 'Home', translation: 'heHome' },
					{ name: 'Upload', translation: 'heUpload' },
					{ name: 'Pipeline', translation: 'hePipeline' },
					{ name: 'Data', translation: 'heData' },
					{
						name: 'Documentation',
						translation: 'heDocumentation',
						external: 'https://ai4health.reutlingen-university.de/landing/',
					},
					{ name: 'Profile', translation: 'heProfile' },
					{ name: 'LegalNotice', translation: 'heLegalNotice' },
				];
				this.showLogout = true;
			} else if (name == 'NotFound') {
				if (sessionStorage.getItem('credentials')) {
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'LegalNotice', translation: 'heLegalNotice' },
					];
					this.showLogout = true;
				} else {
					this.header = [
						{ name: 'Login', translation: 'heLogin' },
						{ name: 'Registration', translation: 'heRegistration' },
						{ name: 'LegalNotice', translation: 'heLegalNotice' },
					];
					this.showLogout = false;
				}
			}
			this.$nextTick(() => this.checkOverflow());
		},
		// @vuese
		// Checks if the header is overflowing
		checkOverflow() {
			let left = document.querySelector('.he-not-overflown');
			let right = document.querySelector('.he-wrap-right');
			if (this.overflowWidth) {
				this.isOverflowing = this.overflowWidth + right.clientWidth > window.innerWidth;
				if (!this.isOverflowing) {
					this.overflowWidth = null;
					this.showHeader = false;
				}
			} else {
				let itemWidth = 0;

				for (let i = 0; i < left.children.length; i++) {
					itemWidth += parseInt(left.children[i].offsetWidth, 10) + 20;
				}
				this.isOverflowing = itemWidth + right.clientWidth > window.innerWidth;
				if (this.isOverflowing) this.overflowWidth = itemWidth;
			}
		},
		// @vuese
		// Resolves the given element and redirects the user
		// @arg element[Object] - The new location
		resolveLink(element) {
			if (this.$global.hasRights({ route: element.name })) {
				if (element.external) {
					window.open(element.external, '_blank');
				} else {
					this.overflowWidth = 0;
					this.isOverflowing = false;
					this.showHeader = false;
					this.$router.push({
						name: element.name,
					});
				}
			} else this.$global.showToast('warn', this.$t('apUnauthorizedWarning'));
		},
		// @vuese
		// Logs out a user and cleans up vars
		logout() {
			sessionStorage.removeItem('credentials');
			sessionStorage.removeItem('user');
			this.$global.setUser(null);
			this.$router.push({ name: 'Login' });
		},
		// @vuese
		// Change the language used
		// @arg locale[String] - The set locale
		changeLocale(locale) {
			// if (locale == 'en') {
			// 	this.$global.showToast('info', 'English translations are not available yet');
			// 	this.showLanguageSelect = false;
			// } else {
			this.selectedLanguage = locale;
			this.showLanguageSelect = false;
			i18n.locale = locale;
			window.dispatchEvent(new Event('languageChange'));
			// }
		},
	},
};
</script>

<style scoped>
.he-wrap-content {
	width: 100vw;
	height: fit-content;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100;
	background-color: var(--main-color-1);
}

.he-wrap-header {
	height: 50px;
}

.he-wrap-left {
	width: calc(100vw - 120px);
	height: 50px;
	padding: 5px;
	display: inline-block;
	box-sizing: border-box;
	vertical-align: top;
}

.he-not-overflown {
	width: 100%;
	height: 100%;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
}

.he-wrap-logo {
	width: 50px;
	height: 40px;
	margin: 10px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.he-logo {
	max-width: 50px;
	max-height: 40px;
	cursor: pointer;
}

.he-header-element {
	vertical-align: top;
	margin: 5px;
}

.he-header-element:hover {
	cursor: pointer;
	text-decoration: underline;
	color: var(--main-color-6);
}

.he-overflown {
	width: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.he-overflown i {
	font-size: 30px;
}

.he-overflown i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.he-hidden-header {
	width: fit-content;
	height: fit-content;
	padding: 5px 10px;
	position: absolute;
	top: 45px;
	left: -5px;
	background-color: var(--main-color-3);
}

.he-wrap-right {
	width: 120px;
	height: 50px;
	padding: 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.he-wrap-translations {
	width: fit-content;
	margin-left: 10px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	box-sizing: border-box;
}

.he-wrap-languages {
	height: fit-content;
	width: fit-content;
	padding: 9px 10px;
	border-radius: 10px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
	box-sizing: border-box;
}

.he-wrap-languages:hover {
	cursor: pointer;
	background-color: var(--main-color-4);
}

.he-wrap-translations img {
	display: none;
	max-width: 30px;
	margin: 0px;
	padding: 0px;
}

.he-selected-language {
	display: block !important;
	pointer-events: auto;
}

.he-language-select {
	height: 40px;
	padding: 5px 10px;
	position: absolute;
	bottom: 0px;
	top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
	box-sizing: border-box;
}

.he-language-select:hover {
	cursor: pointer;
	background-color: var(--main-color-4);
}

.he-selectable-language {
	display: block !important;
}

.he-logout {
	font-size: 30px;
	margin: 10px;
}

.he-logout:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.he-disable-navigation {
	display: none;
}
</style>
