<template>
	<div class="it-wrap-content">
		<div class="it-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
		</div>
		<div class="it-wrap-table">
			<div v-if="isLoading" class="it-loading-overlay">
				<p>{{ $t('itLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="it-table">
				<div class="it-wrap-header">
					<div class="it-header-element app-no-select it-name" :title="$t('itSortBy')" @click="sortBy('name')">
						{{ $t('itName') }}
					</div>
					<div
						class="it-header-element app-no-select it-abbreviation"
						:title="$t('itSortBy')"
						@click="sortBy('abbreviation')"
					>
						{{ $t('itAbbreviation') }}
					</div>
					<div class="it-header-element app-no-select it-owner" :title="$t('itSortBy')" @click="sortBy('owner', 'lastName')">
						{{ $t('itOwner') }}
					</div>
					<div class="it-header-element app-no-select it-members" :title="$t('itSortBy')" @click="sortBy('members')">
						{{ $t('itMembers') }}
					</div>
					<div class="it-header-element app-no-select it-creation" :title="$t('itSortBy')" @click="sortBy('creation')">
						{{ $t('itCreation') }}
					</div>
				</div>
				<div class="it-wrap-body">
					<div
						v-for="institution in institutions"
						:key="institution.institutionID"
						:class="['it-body-hover', institution.selected ? 'it-body-selected' : '', 'it-body-element']"
						@click="$emit('selectInstitution', institution.institutionID)"
					>
						<div class="it-name">{{ institution.name }}</div>
						<div class="it-abbreviation">{{ institution.abbreviation }}</div>
						<div class="it-owner">
							{{
								institution.owner.userID == $global.getUser().userID
									? $t('itYou')
									: `${institution.owner.firstName} ${institution.owner.lastName}`
							}}
						</div>
						<div class="it-members">{{ institution.members }}</div>
						<div class="it-creation">{{ $global.parseDateShort(institution.creation) }}</div>
					</div>
					<div v-if="institutions.length == 0" class="it-wrap-no-institutions">
						<div class="it-no-institutions">
							<i class="fa-solid fa-folder-open"></i>
							<p>{{ $t('itNoInstitutions') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="it-query-more">
			<p>{{ allInstitutions }} {{ $t('itAllInstitutions') }}</p>
			<div class="it-pages">
				<p v-for="page in $global.getPages(allInstitutions, pageSize, currentPage)" :key="page" class="it-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="it-no-hover"> . . . </span>
					<span
						v-else
						:class="[
							page == currentPage ? 'it-highlight-page' : '',
							isLoading || page == currentPage ? 'it-no-hover' : '',
						]"
						@click="isLoading || page == currentPage ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Tables
 * Displays all created institutions as a table
 */
export default {
	name: 'InstitutionTable',
	props: {
		institutions: {
			type: Array,
			required: true,
		},
		allInstitutions: {
			type: Number,
			required: false,
		},
		selectedInstitution: {
			type: String,
			required: false,
		},
	},
	watch: {
		institutions: {
			handler: function (newVal) {
				this.isLoading = false;
			},
			deep: true,
		},
	},
	data() {
		return {
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			lastSort: {
				property: null,
				propertyB: null,
				asc: false,
			},
		};
	},
	created() {
		this.sortBy('creation');
	},
	methods: {
		// @vuese
		// Sorts the institutions by a given property
		// @arg propertyA[String] - The first property of the sorted object
		// @arg propertyB[String] - The second property of the sorted object
		sortBy(propertyA, propertyB) {
			if (this.lastSort.property == propertyA && this.lastSort.propertyB == propertyB) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: propertyA,
					propertyB: propertyB,
					asc: false,
				};
			}
			this.$emit('sortInstitutions', this.lastSort);
		},
		// @vuese
		// Changes the page size and requieries the institutions from page 1
		changePageSize() {
			this.currentPage = 1;
			this.isLoading = true;
			this.$emit('queryInstitutions', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Changes the page and querries the institutions
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.isLoading = true;
			this.$emit('queryInstitutions', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
	},
};
</script>

<style scoped>
.it-wrap-content {
	max-width: 1800px;
	height: fit-content;
	margin: 0px auto 20px auto;
}

.it-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.it-options select {
	margin-right: 10px;
	background-color: var(--main-color-4);
	box-shadow: 0 0 0 2pt var(--main-color-3);
}

.it-wrap-table {
	width: 100%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.it-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.it-loading-overlay p {
	font-size: 30px;
}

.it-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.it-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.it-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
}

.it-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
	vertical-align: top;
}

.it-header-element i {
	margin-left: 10px;
}

.it-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.it-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.it-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.it-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.it-body-element div {
	height: 40px;
	padding: 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.it-name {
	width: calc(100% - 490px);
	min-width: 120px;
}

.it-abbreviation {
	width: 100px;
}

.it-owner {
	width: 160px;
}

.it-members {
	width: 100px;
}

.it-creation {
	width: 130px;
}

.it-wrap-no-institutions {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.it-no-institutions {
	text-align: center;
}

.it-no-institutions i {
	margin-bottom: 10px;
	font-size: 40px;
}

.it-no-institutions p {
	font-size: 20px;
}

.it-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.it-query-more p {
	margin: 5px auto;
}

.it-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.it-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.it-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.it-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.it-highlight-page {
	color: var(--main-color-6);
}
</style>
