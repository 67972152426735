<template>
	<div class="da-wrap-content">
		<div class="da-wrap-top">
			<button id="revisionTreeBtn" class="app-default-btn da-selected-btn" @click="setViewMode($event, 'revisionTree')">
				{{ $t('daDataRevision') }}
			</button>
			<button id="dataVisualizationBtn" class="app-default-btn" @click="setViewMode($event, 'dataVisualization')">
				{{ $t('daDataVisualization') }}
			</button>
		</div>
		<div v-if="viewMode == 'revisionTree'" class="da-wrap-revision-tree">
			<select v-model="selectedApplication" @change="queryRevisions">
				<option :value="null" disabled selected hidden>{{ $t('adSelectApplication') }}</option>
				<option v-for="application in applications" :key="application.applicationID" :value="application.applicationID">
					{{ $t(`${application.abbreviation}Name`) }}
				</option>
			</select>
			<select v-model="dataType" @change="queryRevisions">
				<option :value="null" disabled selected hidden>{{ $t('adSelectDataType') }}</option>
				<option value="package">{{ $t('adDataPackages') }}</option>
				<option value="bundle">{{ $t('adDataBundles') }}</option>
				<option value="pipeline">{{ $t('adPipelines') }}</option>
				<option value="helper">{{ $t('adHelper') }}</option>
			</select>
			<div v-if="selectedApplication && dataType" class="da-wrap-data-table">
				<h2 v-if="dataType == 'package'">{{ $t('daPackages') }}</h2>
				<h2 v-if="dataType == 'bundle'">{{ $t('daBundles') }}</h2>
				<h2 v-if="dataType == 'pipeline'">{{ $t('daPipelines') }}</h2>
				<h2 v-if="dataType == 'helper'">{{ $t('daPipelineHelper') }}</h2>
				<RevisionTable
					v-if="data"
					:data="viewedData"
					:allData="allDataAmount"
					:dataType="dataType"
					:applications="applications"
					:setSizeAndPage="revisionSizePageConf"
					@setViewedData="setViewedData"
					@sortRevisions="sortRevisions"
					@selectRevision="selectData"
					@setState="revisionTabelState = $event"
				/>
				<div v-else class="da-table-placeholder">{{ $t('daLoading') }}<i class="fa-solid fa-spinner"></i></div>
			</div>
			<div class="da-wrap-tree">
				<RevisionTree
					v-if="selectedData.revisionID"
					:revisionData="{ ...selectedData, data: data }"
					:dataType="dataType"
					:applications="applications"
					:application="application"
					:frameworks="frameworks"
					@loadPipelineContent="loadPipelineContent"
					@loadHelperContent="loadHelperContent"
					@loadBundleContent="loadBundleContent"
					@loadDataContent="loadDataContent"
				/>
			</div>
		</div>
		<div v-else-if="viewMode == 'dataVisualization'" class="da-wrap-data-visualization">
			<select v-model="selectedApplication" @change="queryData">
				<option :value="null" disabled selected hidden>{{ $t('adSelectApplication') }}</option>
				<option v-for="application in applications" :key="application.applicationID" :value="application.applicationID">
					{{ $t(`${application.abbreviation}Name`) }}
				</option>
			</select>
			<div v-if="selectedApplication" class="da-wrap-data-table">
				<h2>{{ $t('daPackages') }}</h2>
				<PackageTable
					v-if="packages"
					:packages="packages"
					:allPackages="allPackageAmount"
					:applications="applications"
					:devMode="true"
					:multiSelect="false"
					:selectedPackages="selectedPackage"
					:setSizeAndPage="packageSizePageConf"
					@sortPackages="sortPackages"
					@selectPackage="selectPackage"
					@queryPackages="queryPackages"
					@setState="packageTabelState = $event"
				/>
				<div v-else class="da-table-placeholder">{{ $t('daLoading') }}<i class="fa-solid fa-spinner"></i></div>
				<div v-if="selectedData.packageID" class="da-wrap-visualization">
					<DataVisualization
						:application="applications.filter((app) => app.applicationID == selectedApplication)[0].abbreviation"
						:selectedData="selectedData"
						@loadDataRevision="loadDataRevision"
						@setDataFile="setDataFile"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RevisionTable from '../components/tables/RevisionTable.vue';
import RevisionTree from '../components/data/RevisionTree.vue';
import PackageTable from '../components/tables/PackageTable.vue';
import DataVisualization from '../components/data/DataVisualization.vue';
/**
 * @group Data
 * Contains a overview and visualization of uploaded data
 */
export default {
	name: 'Data',
	components: {
		RevisionTable,
		RevisionTree,
		PackageTable,
		DataVisualization,
	},
	data() {
		return {
			viewMode: 'revisionTree',
			dataType: null,
			applications: [],
			selectedApplication: null,
			frameworks: [],
			data: null,
			packages: null,
			allPackageAmount: 0,
			selectedPackage: [],
			viewedData: null,
			allDataAmount: null,
			selectedData: {},
			revisionTabelState: null,
			revisionSizePageConf: null,
			packageTabelState: null,
			packageSizePageConf: null,
		};
	},
	computed: {
		application() {
			return this.applications.filter((app) => app.applicationID == this.selectedApplication)[0].abbreviation;
		},
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access)=>{if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup of the component. Queries applications and frameworks
		setupComponent() {
			this.$cr.getAllApplications((err, result) => {
				if (!err) {
					this.applications = result;
					this.setupPage();
				}
			});
			this.$cr.getAllFrameworks((err, result) => {
				if (!err) this.frameworks = result;
			});
		},
		// @vuese
		// Changes the history state
		// @arg route[String] - The base route, either revision or visuaization
		// @arg dataType[String] - The dataType, can be package, bundle, pipeline, helper or null
		// @arg dataID[String] - The dataID which can be packageID, bundleID, pipelineID, helperID or null
		// @arg applicationID[String] - The applicationID which can be null
		// @arg file[String] - The file name which can be null
		changeHistoryState(route, dataType, dataID, applicationID, file) {
			let currentRoute = `${window.location.pathname}${window.location.search}`;
			let newRoute = ``;

			if (window.location.href.includes('/configuration/data')) newRoute = `/configuration/data/${route}`;
			else newRoute = `/data/${route}`;

			if (route == 'revision') {
				if (dataType) newRoute = `${newRoute}/${dataType}`;
				if (dataType && dataID) newRoute = `${newRoute}/${dataID}`;
				if (applicationID) newRoute = `${newRoute}?applicationID=${applicationID}`;
			}

			if (route == `visualization`) {
				if (dataID) newRoute = `${newRoute}/${dataID}`;
				if (applicationID) newRoute = `${newRoute}?applicationID=${applicationID}`;
				if (applicationID && file) newRoute = `${newRoute}&file=${file}`;
				else if (!applicationID && file) newRoute = `${newRoute}?file=${file}`;
			}

			if (currentRoute !== newRoute) history.pushState({}, null, newRoute);
		},
		// @vuese
		// Setup for the page that sets the right history state
		setupPage() {
			if (this.$route.query.applicationID) this.selectedApplication = this.$route.query.applicationID;
			if (this.$route.path.includes('/data/visualization')) {
				this.setViewMode(null, 'dataVisualization');
			} else if (this.$route.path.includes('/data/revision')) {
				if (this.$route.params.dataType) this.dataType = this.$route.params.dataType;
				if (this.selectedApplication && this.dataType) this.queryRevisions();
				if (this.$route.params.dataID) {
					let i = 0;
					let interval = window.setInterval(() => {
						if (this.viewedData) {
							clearInterval(interval);
							this.selectData(this.$route.params.dataID);
						} else i++;
						if (i >= 20 * 60) clearInterval(interval);
					}, 50);
				}
			}
		},
		// @vuese
		// Sets the view mode of the component
		// @arg e[Object] - The event that occured
		// @arg mode[String] - Can be revisionTree or dataVisualization
		setViewMode(e, mode) {
			this.viewMode = mode;
			this.dataType = null;
			this.resetVars();
			document.querySelectorAll('.da-wrap-top > button').forEach((btn) => {
				btn.classList.remove('da-selected-btn');
			});

			if (e) e.target.classList.add('da-selected-btn');
			else document.getElementById(`${mode}Btn`).classList.add('da-selected-btn');

			if (mode == 'dataVisualization') {
				this.changeHistoryState(
					'visualization',
					null,
					this.$route.params.packageID,
					this.selectedApplication,
					this.$route.query.file
				);
				if (this.selectedApplication) this.queryPackages(0, 50, this.$route.params.packageID);
			} else if (mode == 'revisionTree') this.changeHistoryState('revision', null, null, this.selectedApplication, null);
		},
		// @vuese
		// Queries all revisions for the current dataType
		queryRevisions() {
			let hasData = this.data ? true : false;
			this.resetVars();
			if (this.selectedApplication && this.dataType) {
				if (hasData || (!hasData && !(this.$route.params.dataID || this.$route.params.packageID)))
					this.changeHistoryState('revision', this.dataType, null, this.selectedApplication, null);
				if (this.dataType == 'package') this.queryPackageRevisions();
				else if (this.dataType == 'bundle') this.queryBundleRevisions();
				else if (this.dataType == 'pipeline') this.queryPipelineRevisions();
				else if (this.dataType == 'helper') this.queryHelperRevisions();
			} else this.changeHistoryState('revision', this.dataType, null, this.selectedApplication, null);
		},
		// @vuese
		// Resets all vars
		resetVars() {
			this.data = null;
			this.viewedData = null;
			this.selectedData = {};
			this.package = null;
			this.allPackageAmount = 0;
			this.selectedPackage = [];
		},
		// @vuese
		// Queries the helper revisions by a applicationID
		queryHelperRevisions() {
			this.$cr.getAllHelperRevisions(this.selectedApplication, (err, result) => {
				if (!err) {
					this.data = result.helpers;
					this.viewedData = this.data.slice(0, 50);
					this.allDataAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the pipeline revisions by a applicationID
		queryPipelineRevisions() {
			this.$cr.getAllPipelineRevisions(this.selectedApplication, (err, result) => {
				if (!err) {
					this.data = result.pipelines;
					this.viewedData = this.data.slice(0, 50);
					this.allDataAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the data bundle revisions by a applicationID
		queryBundleRevisions() {
			this.$cr.getDataBundleRevisions(this.selectedApplication, (err, result) => {
				if (!err) {
					this.data = result.bundles;
					this.viewedData = this.data.slice(0, 50);
					this.allDataAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the data package revisions by a applicationID
		queryPackageRevisions() {
			this.$cr.getDataPackageRevisions(this.selectedApplication, (err, result) => {
				if (!err) {
					this.data = result.packages;
					this.viewedData = this.data.slice(0, 50);
					this.allDataAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the data packages for the data visualization
		queryData() {
			this.resetVars();
			this.changeHistoryState('visualization', null, null, this.selectedApplication, null);
			if (this.selectedApplication) this.queryPackages(0, 50);
		},
		// @vuese
		// Queries the data packages by a amount, offset, application and queryType
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		// @arg packageID[String] - Optional packageID. If available is used to get the index of the packageID
		queryPackages(offset, amount, packageID) {
			this.selectedData = {};
			this.selectedPackage = [];
			this.$cr.getDataPackages(offset, amount, this.selectedApplication, 'all', packageID, (err, result) => {
				if (!err) {
					this.packages = result.packages;
					this.allPackageAmount = result.amount;
					if (Number(result.offset) !== Number(offset)) {
						this.packageTabelState = {
							currentPage: result.offset,
							pageSize: amount,
						};
						this.$nextTick(() => {
							this.packageSizePageConf = {
								currentPage: result.offset,
								pageSize: amount,
								dontUpdate: true,
							};
						});
					}
					this.navigateToPackage(packageID);
					this.packages.map((pack) => {
						if (this.selectedPackage.includes(pack.packageID)) {
							pack.selected = true;
						}
						return pack;
					});
				}
			});
		},
		// @vuese
		// Navigates to a package that got selected
		// @arg packageID[String] - The ID of the package
		navigateToPackage(packageID) {
			if (packageID) {
				this.$nextTick(() => {
					let elem = document.getElementById(packageID);
					if (elem) {
						elem.click();
						if (this.$route.query.file) {
							let loopCount = 0;
							this.$nextTick(() => {
								// Check if package has been selected
								let interval = window.setInterval(() => {
									loopCount += 1;
									elem = document.getElementById(this.$route.query.file);
									if (elem) {
										clearInterval(interval);
										elem.click();
									}
									if (loopCount >= 600) clearInterval(interval);
								}, 100);
							});
						}
					}
				});
			}
		},
		// @vuese
		// Queries a specific data package by its packageID
		// @arg packageID[String] - The packageID
		queryPackage(packageID) {
			this.$cr.getPackage(packageID, (err, result) => {
				if (!err) this.selectedData = result;
			});
		},
		// @vuese
		// Sorts all data by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortRevisions(sort) {
			this.viewedData = this.$global.sortValues(sort, this.viewedData);
		},
		// @vuese
		// Sets the viewed data by a offset and amount
		// @arg offset[Number] - The offset
		// @arg amount[Number] - The amount
		setViewedData(offset, amount) {
			this.viewedData = this.data.slice(offset, amount);
		},
		// @vuese
		// Selects a data element
		// @arg id[String] - The ID of the data element
		selectData(id) {
			if (this.selectedData.id == id) {
				this.selectedData = {};
				this.viewedData = this.viewedData.map((d) => {
					d.selected = false;
					return d;
				});
				this.changeHistoryState('revision', this.dataType, null, this.selectedApplication, null);
			} else {
				this.selectedData.id = id;
				this.selectedData.type = this.dataType;
				let page = 0;
				let viewedData = this.data.slice(
					page * this.revisionTabelState.pageSize,
					page * this.revisionTabelState.pageSize + this.revisionTabelState.pageSize
				);
				while (true) {
					if (
						viewedData.filter((d) => d.packageID == id || d.bundleID == id || d.pipelineID == id || d.helperID == id)
							.length > 0
					)
						break;
					else {
						viewedData = this.data.slice(
							page * this.revisionTabelState.pageSize,
							page * this.revisionTabelState.pageSize + this.revisionTabelState.pageSize
						);
						page += 1;
					}
					if (page * this.revisionTabelState.pageSize > this.data.length) break;
				}

				this.revisionSizePageConf = {
					currentPage: page > 0 ? page : 1,
					pageSize: this.revisionTabelState.pageSize,
				};

				this.viewedData = viewedData.map((d) => {
					if (d.packageID == id || d.bundleID == id || d.pipelineID == id || d.helperID == id) {
						d.selected = true;
						this.selectedData.revisionID = d.revisionID;
					} else d.selected = false;
					return d;
				});
				this.changeHistoryState('revision', this.dataType, id, this.selectedApplication, null);
			}
			this.$forceUpdate();
		},
		// @vuese
		// Selects a data package
		// @arg packageID[String] - The ID of the data package
		selectPackage(packageID) {
			if (this.selectedPackage[0] == packageID) {
				this.selectedPackage = [];
				this.packages.map((dp) => {
					if (dp.packageID == packageID) dp.selected = false;
					return dp;
				});
				this.selectedData = {};
				this.changeHistoryState('visualization', null, null, this.selectedApplication, null);
			} else {
				this.selectedPackage = [packageID];
				this.packages.map((dp) => {
					if (dp.packageID == packageID) dp.selected = true;
					else dp.selected = false;
					return dp;
				});
				this.selectedData = {};
				this.queryPackage(packageID);
				// this.changeHistoryState('visualization', null, packageID, this.selectedApplication, this.$route.query.file);
			}
		},
		// @vuese
		// Sorts all data by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortPackages(sort) {
			this.packages = this.$global.sortValues(sort, this.packages);
		},
		// @vuese
		// Loads the pipeline revisions
		// @arg pipelineID[String] - The pipelineID
		loadPipelineContent(pipelineID) {
			if (window.location.href.includes('/configuration/data'))
				window.location.href = `/configuration/data/revision/pipeline/${pipelineID}?applicationID=${this.selectedApplication}`;
			else window.location.href = `/data/revision/pipeline/${pipelineID}?applicationID=${this.selectedApplication}`;
		},
		// @vuese
		// Loads the helper revisions
		// @arg helperID[String] - The helperID
		loadHelperContent(helperID) {
			if (window.location.href.includes('/configuration/data'))
				window.location.href = `/configuration/data/revision/helper/${helperID}?applicationID=${this.selectedApplication}`;
			else window.location.href = `/data/revision/helper/${helperID}?applicationID=${this.selectedApplication}`;
		},
		// @vuese
		// Loads the bundle revisions
		// @arg bundleID[String] - The bundleID
		loadBundleContent(bundleID) {
			if (window.location.href.includes('/configuration/data'))
				window.location.href = `/configuration/data/revision/bundle/${bundleID}?applicationID=${this.selectedApplication}`;
			else window.location.href = `/data/revision/bundle/${bundleID}?applicationID=${this.selectedApplication}`;
		},
		// @vuese
		// Loads the data package visualization
		// @arg pack[Object] - Contains the packageID and package name
		loadDataContent(pack) {
			if (pack.name) {
				if (window.location.href.includes('/configuration/data'))
					window.location.href = `/configuration/data/visualization/${pack.packageID}?applicationID=${this.selectedApplication}&file=${pack.name}`;
				else
					window.location.href = `/data/visualization/${pack.packageID}?applicationID=${this.selectedApplication}&file=${pack.name}`;
			} else {
				if (window.location.href.includes('/configuration/data'))
					window.location.href = `/configuration/data/visualization/${pack.packageID}?applicationID=${this.selectedApplication}`;
				else window.location.href = `/data/visualization/${pack.packageID}?applicationID=${this.selectedApplication}`;
			}
		},
		// @vuese
		// Loads the package revisions
		// @arg packageID[String] - The packageID
		loadDataRevision(packageID) {
			if (window.location.href.includes('/configuration/data'))
				window.location.href = `/configuration/data/revision/package/${packageID}?applicationID=${this.selectedApplication}`;
			else window.location.href = `/data/revision/package/${packageID}?applicationID=${this.selectedApplication}`;
		},
		// @vuese
		// Sets the current data file when a data package is selected
		// @arg packageID[String] - The ID of the package
		// @arg name[String] - The name of the package
		setDataFile(packageID, name) {
			this.$route.query.file = name;
			this.changeHistoryState('visualization', null, packageID, this.selectedApplication, name);
		},
	},
};
</script>

<style scoped>
.da-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

.da-wrap-top {
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
}

.da-wrap-top button {
	flex: 1 1 200px;
	margin: 5px;
	font-size: 20px;
}

.da-selected-btn {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-light);
}

.da-wrap-revision-tree,
.da-wrap-data-visualization {
	width: 100%;
	height: fit-content;
}

.da-wrap-revision-tree select,
.da-wrap-data-visualization select {
	margin: 5px 10px 5px 0px;
	padding: 5px 10px;
	font-size: 15px;
}

.da-wrap-data-table {
	text-align: center;
}

.da-wrap-data-table h2 {
	width: fit-content;
	margin: 5px auto 10px auto;
	font-size: 30px;
	color: var(--main-color-6);
}

.da-table-placeholder {
	height: 240px;
	max-width: 1800px;
	width: 90%;
	margin: 0px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
}

.da-table-placeholder i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.da-wrap-tree {
	width: 100%;
	overflow: hidden;
}
</style>
