<template>
	<div class="covco-wrap-content">
		<p v-if="!viewMode">{{ $t('covcoMeta') }}</p>
		<div :class="[viewMode ? 'covco-view-mode' : '', 'covco-wrap-meta']">
			<div class="covco-wrap-top">
				<div class="covco-wrap-input">
					<label>{{ $t('covcoGender') }}</label>
					<select v-model="meta.gender">
						<option :value="''" disabled selected hidden>{{ $t('covcoSelectGender') }}</option>
						<option value="male">{{ $t('covcoMaleGender') }}</option>
						<option value="female">{{ $t('covcoFemaleGender') }}</option>
						<option value="divers">{{ $t('covcoDiversGender') }}</option>
					</select>
				</div>
				<div class="covco-wrap-input">
					<label>{{ $t('covcoDateOfBirth') }}</label>
					<input type="date" v-model="meta.dateOfBirth" />
				</div>
				<div class="covco-wrap-radio">
					<p>{{ $t('covcoSmoking') }}</p>
					<label>
						{{ $t('covcoYes') }}
						<input type="radio" name="smoking" :value="true" v-model="meta.smoking" />
					</label>
					<label>
						{{ $t('covcoNo') }}
						<input type="radio" name="smoking" :value="false" v-model="meta.smoking" />
					</label>
				</div>
				<div class="covco-wrap-radio">
					<p>{{ $t('covcoCOVID19') }}</p>
					<label>
						{{ $t('covcoYes') }}
						<input type="radio" name="groundtruth" :value="true" v-model="groundtruthLabel" />
					</label>
					<label>
						{{ $t('covcoNo') }}
						<input type="radio" name="groundtruth" :value="false" v-model="groundtruthLabel" />
					</label>
				</div>
				<div class="covco-wrap-input">
					<label>{{ $t('covcoExternalReference') }}</label>
					<input type="text" :placeholder="$t('covcoExternalReferencePlaceholder')" v-model="externalID" />
				</div>
			</div>
			<div class="covco-wrap-bot">
				<div class="covco-wrap-left">
					<p>{{ $t('cvocoConditions') }}</p>
					<div class="covco-wrap-lung">
						<input type="checkbox" id="lungCondition" v-model="meta.hasLungCondition" @change="checkLungConditions" />
						<label for="lungCondition">{{ $t('covcoLungCondition') }}</label>
					</div>
					<div v-if="meta.hasLungCondition" class="covco-lung-conditions">
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="asthma" @change="setLungCondition('asthma')" />
							<label for="asthma">{{ $t('covcoAsthma') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="pneumonia" @change="setLungCondition('pneumonia')" />
							<label for="pneumonia">{{ $t('covcoPneumonia') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="chronicLungDisease" @change="setLungCondition('chronic lung disease')" />
							<label for="chronicLungDisease">{{ $t('covcoChronicLungDisease') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="otherLungConditions" @change="setLungCondition('other lung conditions')" />
							<label for="otherLungConditions">{{ $t('covcoOther') }}</label>
						</div>
					</div>
					<div class="covco-wrap-heart">
						<input type="checkbox" id="heartCondition" v-model="meta.hasHeartCondition" @change="checkHeartConditions" />
						<label for="heartCondition">{{ $t('covcoHeartCondition') }}</label>
					</div>
					<div v-if="meta.hasHeartCondition" class="covco-heart-conditions">
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="ischemicHeartDisease" @change="setHeartCondition('ischemic heart disease')" />
							<label for="ischemicHeartDisease">{{ $t('covcoIschemicHeartDisease') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="heartAttack" @change="setHeartCondition('heart attack')" />
							<label for="heartAttack">{{ $t('covcoHeartAttack') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="otherHeartConditions" @change="setHeartCondition('other heart conditions')" />
							<label for="otherHeartConditions">{{ $t('covcoOther') }}</label>
						</div>
					</div>
				</div>
				<div class="covco-wrap-right">
					<p>{{ $t('cvocoSymptoms') }}</p>
					<div class="covco-wrap-covid">
						<input type="checkbox" id="covidSymptoms" v-model="meta.hasCOVIDSymptoms" @change="checkCOVIDSymptoms" />
						<label for="covidSymptoms">{{ $t('covcoCOVIDSymptoms') }}</label>
					</div>
					<div v-if="meta.hasCOVIDSymptoms" class="covco-covid-symptoms">
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="fever" @change="setCOVIDSymptoms('fever')" />
							<label for="fever">{{ $t('covcoFever') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="dryCough" @change="setCOVIDSymptoms('dry cough')" />
							<label for="dryCough">{{ $t('covcoDryCough') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="lossOfSmell" @change="setCOVIDSymptoms('loss of smell')" />
							<label for="lossOfSmell">{{ $t('covcoSmellLoss') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="lossOfTaste" @change="setCOVIDSymptoms('loss of taste')" />
							<label for="lossOfTaste">{{ $t('covcoTasteLoss') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="headache" @change="setCOVIDSymptoms('headache')" />
							<label for="headache">{{ $t('covcoHeadache') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="shortnessOfBreath" @change="setCOVIDSymptoms('shortness of breath')" />
							<label for="shortnessOfBreath">{{ $t('covcoShortnessOfBreath') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="dryThroat" @change="setCOVIDSymptoms('dry throat')" />
							<label for="dryThroat">{{ $t('covcoDryThroat') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="musclePain" @change="setCOVIDSymptoms('muscle pain')" />
							<label for="musclePain">{{ $t('covcoMusclePain') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="otherCovidSymptoms" @change="setCOVIDSymptoms('other covid symptoms')" />
							<label for="otherCovidSymptoms">{{ $t('covcoOther') }}</label>
						</div>
					</div>
					<div class="covco-wrap-other">
						<input type="checkbox" id="hasOtherSymptoms" v-model="meta.hasOtherSymptoms" @change="checkOtherSymptoms" />
						<label for="hasOtherSymptoms">{{ $t('covcoOtherSymptoms') }}</label>
					</div>
					<div v-if="meta.hasOtherSymptoms" class="covco-other-symptoms">
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="diarrhea" @change="setOtherSymptoms('diarrhea')" />
							<label for="diarrhea">{{ $t('covcoDiarrhea') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="vomitting" @change="setOtherSymptoms('vomitting')" />
							<label for="vomitting">{{ $t('covcoVomitting') }}</label>
						</div>
						<div class="covco-wrap-checkbox-radio">
							<input type="checkbox" id="otherSymptoms" @change="setOtherSymptoms('other symptoms')" />
							<label for="otherSymptoms">{{ $t('covcoOther') }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!viewMode" class="covco-save-btn">
			<button class="app-success-btn" @click="saveMeta">{{ $t('covcoSaveMeta') }}</button>
		</div>
	</div>
</template>

<script>
/**
 * @group Upload
 * Contains the COVID-19 Meta information structure
 */
export default {
	name: 'COVCOMetaInformation',
	props: {
		metaToEdit: {
			type: Object,
			required: false,
		},
		viewMode: {
			type: Boolean,
			required: false,
		},
	},
	watch: {
		metaToEdit: {
			handler: function (newVal) {
				if (newVal) {
					this.resetMeta();
					this.$nextTick(() => {
						this.setMeta(newVal.meta);
						this.packageID = newVal.packageID;
					});
				} else {
					this.packageID = null;
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			meta: {
				gender: '',
				dateOfBirth: '',
				smoking: false,
				hasLungCondition: false,
				lungConditions: [],
				hasHeartCondition: false,
				heartConditions: [],
				hasCOVIDSymptoms: false,
				covidSymptoms: [],
				hasOtherSymptoms: false,
				otherSymptoms: [],
			},
			groundtruthLabel: false,
			externalID: '',
			packageID: null,
		};
	},
	created() {
		if (this.metaToEdit) {
			this.setMeta(this.metaToEdit.meta);
			this.packageID = this.metaToEdit.packageID;
		}
	},
	methods: {
		// @vuese
		// Sets a lung condition if the condition isnt already set
		// @arg condition[String] - The set condition
		setLungCondition(condition) {
			if (this.meta.lungConditions.includes(condition))
				this.meta.lungConditions = this.meta.lungConditions.filter((cond) => cond != condition);
			else this.meta.lungConditions.push(condition);
		},
		// @vuese
		// Emptys the lung conditions if hasLungCondition is set to false
		checkLungConditions() {
			if (!this.meta.hasLungCondition) this.meta.lungConditions = [];
		},
		// @vuese
		// Sets a heart condition if the condition isnt already set
		// @arg condition[String] - The set condition
		setHeartCondition(condition) {
			if (this.meta.heartConditions.includes(condition))
				this.meta.heartConditions = this.meta.heartConditions.filter((cond) => cond != condition);
			else this.meta.heartConditions.push(condition);
		},
		// @vuese
		// Emptys the heart conditions if hasHeartCondition is set to false
		checkHeartConditions() {
			if (!this.meta.hasHeartCondition) this.meta.heartConditions = [];
		},
		// @vuese
		// Sets a covid-19 symptom if the symptom isnt already set
		// @arg symptom[String] - The set symptom
		setCOVIDSymptoms(symptom) {
			if (this.meta.covidSymptoms.includes(symptom))
				this.meta.covidSymptoms = this.meta.covidSymptoms.filter((symp) => symp != symptom);
			else this.meta.covidSymptoms.push(symptom);
		},
		// @vuese
		// Emptys the covid symptoms if hasCOVIDSymptoms is set to false
		checkCOVIDSymptoms() {
			if (!this.meta.hasCOVIDSymptoms) this.meta.covidSymptoms = [];
		},
		// @vuese
		// Sets a other symptom if the symptom isnt already set
		// @arg symptom[String] - The set symptom
		setOtherSymptoms(symptom) {
			if (this.meta.otherSymptoms.includes(symptom))
				this.meta.otherSymptoms = this.meta.otherSymptoms.filter((symp) => symp != symptom);
			else this.meta.otherSymptoms.push(symptom);
		},
		// @vuese
		// Emptys the other symptoms if hasOtherSymptoms is set to false
		checkOtherSymptoms() {
			if (!this.meta.hasOtherSymptoms) this.meta.otherSymptoms = [];
		},
		// @vuese
		// Resets the meta information of the component
		resetMeta() {
			this.meta = {
				gender: '',
				dateOfBirth: '',
				smoking: false,
				hasLungCondition: false,
				hasHeartCondition: false,
				lungConditions: [],
				heartConditions: [],
				hasCOVIDSymptoms: false,
				covidSymptoms: [],
				hasOtherSymptoms: false,
				otherSymptoms: [],
			};
			this.groundtruthLabel = false;
			this.externalID = '';
			this.packageID = null;
		},
		// @vuese
		// Sets the selected meta
		// @arg meta[Object] - The uploaded meta object
		setMeta(meta) {
			let missingProperties = [];

			if (meta) {
				if ('groundtruthLabel' in meta) this.groundtruthLabel = meta.groundtruthLabel;
				else missingProperties.push('groundtruthLabel');

				if ('externalID' in meta) this.externalID = meta.externalID;
				else missingProperties.push('externalID');

				if (meta.meta) {
					if ('gender' in meta.meta) this.meta.gender = meta.meta.gender;
					else missingProperties.push('gender');

					if ('dateOfBirth' in meta.meta) this.meta.dateOfBirth = meta.meta.dateOfBirth;
					else missingProperties.push('dateOfBirth');

					if ('smoking' in meta.meta) this.meta.smoking = meta.meta.smoking;
					else missingProperties.push('smoking');

					if ('hasLungCondition' in meta.meta) this.meta.hasLungCondition = meta.meta.hasLungCondition;
					else missingProperties.push('hasLungCondition');

					this.$nextTick(() => {
						if ('lungConditions' in meta.meta && meta.meta.hasLungCondition) {
							meta.meta.lungConditions.forEach((cond) => {
								try {
									document.getElementById(this.createCamelCase(cond)).click();
								} catch (error) {}
							});
						} else missingProperties.push('lungConditions');
					});

					if ('hasHeartCondition' in meta.meta) this.meta.hasHeartCondition = meta.meta.hasHeartCondition;
					else missingProperties.push('hasHeartCondition');

					this.$nextTick(() => {
						if ('heartConditions' in meta.meta && meta.meta.hasHeartCondition) {
							meta.meta.heartConditions.forEach((cond) => {
								try {
									document.getElementById(this.createCamelCase(cond)).click();
								} catch (error) {}
							});
						} else missingProperties.push('heartConditions');
					});

					if ('hasCOVIDSymptoms' in meta.meta) this.meta.hasCOVIDSymptoms = meta.meta.hasCOVIDSymptoms;
					else missingProperties.push('hasCOVIDSymptoms');

					this.$nextTick(() => {
						if ('covidSymptoms' in meta.meta && meta.meta.hasCOVIDSymptoms) {
							meta.meta.covidSymptoms.forEach((sym) => {
								try {
									if (sym == 'cough') sym = 'dryCough';
									document.getElementById(this.createCamelCase(sym)).click();
								} catch (error) {}
							});
						} else missingProperties.push('covidSymptoms');
					});

					if ('hasOtherSymptoms' in meta.meta) this.meta.hasOtherSymptoms = meta.meta.hasOtherSymptoms;
					else missingProperties.push('hasOtherSymptoms');

					this.$nextTick(() => {
						if ('otherSymptoms' in meta.meta && meta.meta.hasOtherSymptoms) {
							meta.meta.otherSymptoms.forEach((sym) => {
								try {
									document.getElementById(this.createCamelCase(sym)).click();
								} catch (error) {}
							});
						} else missingProperties.push('otherSymptoms');
					});
				} else {
					missingProperties = [
						...missingProperties,
						...[
							'gender',
							'dateOfBirth',
							'smoking',
							'hasLungCondition',
							'lungConditions',
							'hasHeartCondition',
							'heartConditions',
							'hasCOVIDSymptoms',
							'covidSymptoms',
							'hasOtherSymptoms',
							'otherSymptoms',
						],
					];
				}

				if (missingProperties.length > 0 && !this.viewMode) {
					this.$global.showToast(
						'warn',
						`${this.$t('covcoMissingProperties')} (${missingProperties.length}), ${this.$t('covcoCheckStructure')}`
					);
				}
			}
		},
		// @vuese
		// Converts a string into camel case
		// @arg str[String] - The given string
		// @returns [String] - The given string in camel case
		createCamelCase(str) {
			return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
				if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
				return index === 0 ? match.toLowerCase() : match.toUpperCase();
			});
		},
		// @vuese
		// Checks if some fields are filled and then emits the meta information
		saveMeta() {
			if (this.externalID == '' || this.meta.gender == '' || this.meta.dateOfBirth == '')
				this.$global.showToast('warn', this.$t('covcoMissingInformation'));
			else if (this.meta.hasLungCondition && this.meta.lungConditions.length == 0)
				this.$global.showToast('warn', this.$t('covcoMissingInformation'));
			else if (this.meta.hasHeartCondition && this.meta.heartConditions.length == 0)
				this.$global.showToast('warn', this.$t('covcoMissingInformation'));
			else if (this.meta.hasCOVIDSymptoms && this.meta.covidSymptoms.length == 0)
				this.$global.showToast('warn', this.$t('covcoMissingInformation'));
			else if (this.meta.hasOtherSymptoms && this.meta.otherSymptoms.length == 0)
				this.$global.showToast('warn', this.$t('covcoMissingInformation'));
			else this.$global.showToast('success', this.$t('covcoInformationSaved'));

			this.$emit(
				'saveMeta',
				JSON.parse(
					JSON.stringify({
						packageID: this.packageID,
						meta: { meta: this.meta, groundtruthLabel: this.groundtruthLabel, externalID: this.externalID },
					})
				)
			);
		},
	},
};
</script>

<style scoped>
.covco-wrap-content {
	width: 100%;
	height: fit-content;
	margin-bottom: 10px;
}

.covco-wrap-content p {
	margin: 0px 0px 10px 0px;
	font-size: 18px;
	text-decoration: underline;
}

.covco-wrap-meta {
	width: 100%;
	height: fit-content;
	padding: 5px;
	background-color: var(--main-color-4);
	border: 2px solid var(--main-color-border-dark);
	box-sizing: border-box;
}

.covco-view-mode {
	pointer-events: none;
}

.covco-wrap-top {
	width: 100%;
	height: fit-content;
	border-bottom: 1px solid var(--main-color-5);
}

.covco-wrap-input {
	display: inline-block;
	vertical-align: top;
	padding: 5px;
}

.covco-wrap-input select {
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.covco-wrap-input input {
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.covco-wrap-input label {
	display: block;
}

.covco-wrap-radio {
	display: inline-block;
	vertical-align: top;
	padding: 5px;
}

.covco-wrap-radio p {
	margin: 0px;
	font-size: 15px;
	text-decoration: none;
}

.covco-wrap-bot {
	width: 100%;
	height: fit-content;
	margin: 5px 0px 0px 0px;
}

.covco-wrap-left,
.covco-wrap-right {
	width: 50%;
	height: fit-content;
	padding: 5px;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
}

.covco-lung-conditions,
.covco-heart-conditions,
.covco-covid-symptoms,
.covco-other-symptoms {
	margin-left: 15px;
}

.covco-wrap-lung label,
.covco-wrap-heart label,
.covco-wrap-covid label,
.covco-wrap-other label,
.covco-lung-conditions label,
.covco-heart-conditions label,
.covco-covid-symptoms label,
.covco-other-symptoms label {
	margin-left: 5px;
}

.covco-save-btn {
	width: 100%;
	margin: 10px auto;
	text-align: center;
}

.covco-save-btn button {
	font-size: 17px;
	margin-bottom: 10px;
}
</style>
