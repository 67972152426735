<template>
	<div class="tt-wrap-content">
		<div class="tt-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
		</div>
		<div v-if="applications.length && frameworks.length" class="tt-wrap-table">
			<div v-if="isLoading" class="tt-loading-overlay">
				<p>{{ $t('ttLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="tt-table">
				<div class="tt-wrap-header">
					<div class="tt-header-element app-no-select tt-training-id" :title="$t('ttSortBy')" @click="sortBy('trainingID')">
						{{ $t('ttTrainingID') }}
					</div>
					<div class="tt-header-element app-no-select tt-start" :title="$t('ttSortBy')" @click="sortBy('start')">
						{{ $t('ttStart') }}
					</div>
					<div class="tt-header-element app-no-select tt-end" :title="$t('ttSortBy')" @click="sortBy('end')">
						{{ $t('ttEnd') }}
					</div>
					<div
						class="tt-header-element app-no-select tt-application"
						:title="$t('ttSortBy')"
						@click="sortBy('pipeline', 'applicationID')"
					>
						{{ $t('ttApplication') }}
					</div>
					<div
						class="tt-header-element app-no-select tt-pipeline"
						:title="$t('ttSortBy')"
						@click="sortBy('pipeline', 'name')"
					>
						{{ $t('ttPipeline') }}
					</div>
					<div class="tt-header-element app-no-select tt-bundle" :title="$t('ttSortBy')" @click="sortBy('bundle', 'name')">
						{{ $t('ttBundle') }}
					</div>
					<div
						class="tt-header-element app-no-select tt-verification"
						:title="$t('ttSortBy')"
						@click="sortBy('bundle', 'isVerification')"
					>
						{{ $t('ttBundleVerification') }}
					</div>
					<div class="tt-header-element app-no-select tt-helper" :title="$t('ttSortBy')" @click="sortBy('helper', 'name')">
						{{ $t('ttHelper') }}
					</div>
					<div
						class="tt-header-element app-no-select tt-creator"
						:title="$t('ttSortBy')"
						@click="sortBy('user', 'lastName')"
					>
						{{ $t('ttCreator') }}
					</div>
				</div>
				<div class="tt-wrap-body">
					<div
						v-for="training in trainings"
						:key="training.trainingID"
						:class="['tt-body-hover', 'tt-body-element']"
						@click="$emit('selectTraining', training.trainingID)"
					>
						<div class="tt-training-id">{{ training.trainingID.substring(0, 8) }}</div>
						<div class="tt-start">{{ $global.parseDateShort(training.start) }}</div>
						<div class="tt-end">{{ $global.parseDateShort(training.end) }}</div>
						<div class="tt-application">
							{{
								$t(
									`${
										applications.filter((app) => app.applicationID == training.pipeline.applicationID)[0]
											.abbreviation
									}Name`
								)
							}}
						</div>
						<div class="tt-pipeline">
							{{ training.pipeline.name }} ({{ $global.shortenRevisionID(training.pipeline.revisionID) }})
						</div>
						<div class="tt-bundle">
							{{ training.bundle.name }} ({{ $global.shortenRevisionID(training.bundle.revisionID) }})
						</div>
						<div class="tt-verification">{{ training.bundle.isVerification ? $t('ttYes') : $t('ttNo') }}</div>
						<div class="tt-helper">
							{{ training.helper.name }} ({{ $global.shortenRevisionID(training.helper.revisionID) }})
						</div>
						<div class="tt-creator">
							{{
								training.user.userID == $global.getUser().userID
									? $t('ttYou')
									: `${training.user.firstName} ${training.user.lastName}`
							}}
						</div>
					</div>
					<div v-if="trainings.length == 0" class="tt-wrap-no-trainings">
						<div class="tt-no-trainings">
							<i class="fa-solid fa-folder-open"></i>
							<p>{{ $t('ttNoPipelines') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tt-query-more">
			<p>{{ allTrainings }} {{ $t('ttAllTrainings') }}</p>
			<div class="tt-pages">
				<p v-for="page in $global.getPages(allTrainings, pageSize, currentPage)" :key="page" class="tt-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="tt-no-hover"> . . . </span>
					<span
						v-else
						:class="[
							page == currentPage ? 'tt-highlight-page' : '',
							isLoading || page == currentPage ? 'tt-no-hover' : '',
						]"
						@click="isLoading || page == currentPage ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Tables
 * Displays all created trainings as a table
 */
export default {
	name: 'TrainingTable',
	props: {
		trainings: {
			type: Array,
			required: true,
		},
		allTrainings: {
			type: Number,
			required: false,
		},
		applications: {
			type: Array,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
	},
	watch: {
		trainings: {
			handler: function (newVal) {
				this.isLoading = false;
			},
			deep: true,
		},
	},
	data() {
		return {
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			lastSort: {
				property: null,
				propertyB: null,
				asc: false,
			},
		};
	},
	created() {
		this.sortBy('start');
	},
	methods: {
		// @vuese
		// Sorts the trainings by a given property
		// @arg propertyA[String] - The first property of the sorted object
		// @arg propertyB[String] - The second property of the sorted object
		sortBy(propertyA, propertyB) {
			if (this.lastSort.property == propertyA && this.lastSort.propertyB == propertyB) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: propertyA,
					propertyB: propertyB,
					asc: false,
				};
			}
			this.$emit('sortTrainings', this.lastSort);
		},
		// @vuese
		// Changes the page size and requieries the trainings from page 1
		changePageSize() {
			this.currentPage = 1;
			this.isLoading = true;
			this.$emit('queryTrainings', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Changes the page and querries the trainings
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.isLoading = true;
			this.$emit('queryTrainings', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
	},
};
</script>

<style scoped>
.tt-wrap-content {
	height: fit-content;
	margin: 0px auto 20px auto;
	max-width: 1800px;
}

.tt-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.tt-options select {
	margin-right: 10px;
}

.tt-wrap-table {
	width: 90%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.tt-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.tt-loading-overlay p {
	font-size: 30px;
}

.tt-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.tt-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.tt-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
	/* overflow: hidden; */
}

.tt-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	/* display: inline-flex;
	justify-content: center;
	align-items: center; */
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
}

.tt-header-element i {
	margin-left: 10px;
}

.tt-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.tt-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.tt-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.tt-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.tt-body-element div {
	height: 40px;
	padding: 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.tt-training-id {
	width: 100px;
}

.tt-start {
	width: 130px;
}

.tt-end {
	width: 130px;
}

.tt-application {
	width: 220px;
}

.tt-pipeline {
	min-width: 250px;
	width: calc((100% - 820px) / 3);
}

.tt-bundle {
	min-width: 250px;
	width: calc((100% - 820px) / 3);
}

.tt-verification {
	width: 100px;
}

.tt-helper {
	min-width: 250px;
	width: calc((100% - 820px) / 3);
}

.tt-creator {
	width: 140px;
}

.tt-wrap-no-trainings {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tt-no-trainings {
	text-align: center;
}

.tt-no-trainings i {
	margin-bottom: 10px;
	font-size: 40px;
}

.tt-no-trainings p {
	font-size: 20px;
}

.tt-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.tt-query-more p {
	margin: 5px auto;
}

.tt-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.tt-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.tt-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.tt-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.tt-highlight-page {
	color: var(--main-color-6);
}
</style>
