<template>
	<div class="ln-wrap-content">
		<h1>Impressum</h1>
		<div class="ln-wrap-legal-notice">
			<div>
				<h2>Anbieterkennzeichnung</h2>
				<p>
					Anbieter und somit verantwortlich für dieses Onlineangebot ist die Hochschule Reutlingen, vertreten durch Prof. Dr.
					Hendrik Brumme, Präsident.
				</p>
				<p>
					Hochschule Reutlingen / Reutlingen University <br />
					Alteburgstraße 150 <br />
					72762 Reutlingen <br />
					Deutschland / Germany
				</p>
				<p>
					E-Mail:
					<a href="mailto:info@reutlingen-university.de">info@reutlingen-university.de</a>
					<br />
					Web:
					<a href="https://www.reutlingen-university.de" target="_blank">www.reutlingen-university.de</a>
					<br />
					Tel.: +49 (0)7121 271 – 0 <br />
					Fax: +49 (0)7121 271 – 1101
				</p>
				<p>
					Rechtsform: Körperschaft des öffentlichen Rechts (KdöR) <br />
					Anwendbares Recht: Recht der Bundesrepublik Deutschland (BRD) <br />
					Zuständiges Amtsgericht: Reutlingen
				</p>
				<p>
					Finanzamt Reutlingen <br />
					Umsatzsteuer-Identifikationsnummer: DE 811 323 197 (gemäß § 27a Umsatzsteuergesetz)
				</p>
				<p>Vertretungsberechtigt: Prof. Dr. Hendrik Brumme, Hochschulpräsident</p>
				<h2>Inhaltliche Verantwortung</h2>
				<p>
					Verantwortlicher für journalistisch redaktionelle Beiträge gemäß § 55 Absatz 2 Staatsvertrag für Rundfunk und
					Telemedien (RStV):
				</p>
				<p>
					Prof. Dr. Christian Thies <br />
					Hochschule Reutlingen <br />
					Fakultät Informatik <br />
				</p>
				<p>
					E-Mail:
					<a href="mailto:christian.thies@reutlingen-university.de">christian.thies@reutlingen-university.de</a>
					<br />
					Tel.: +49 (0)7121 271 – 4076
				</p>

				<!-- <h2>Zuständige Aufsichtsbehörde</h2>
				<p>
					Ministerium für Wissenschaft, Forschung und Kunst <br />
					Baden-Württemberg (MWK) <br />
					Königstraße 46 <br />
					70173 Stuttgart
				</p>
				<p>
					E-Mail: <a href="mailto:poststelle@mwk.bwl.de">poststelle@mwk.bwl.de</a> <br />
					Web:
					<a href="https://www.mwk.baden-wuerttemberg.de" target="_blank">www.mwk.baden-wuerttemberg.de</a>
					<br />
					Tel.: +49 (0)711 279 – 0 <br />
					Fax: +49 (0)711 279 – 3080
				</p>
				<h2>Zuständige Aufsichtsbehörde für Datenschutz</h2>
				<p>
					Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit <br />
					Königstraße 10a <br />
					70173 Stuttgart
				</p>
				<p>
					E-Mail: <a href="mailto:poststelle@lfdi.bwl.de">poststelle@lfdi.bwl.de</a>
					<br />
					Web:
					<a href="https://www.baden-wuerttemberg.datenschutz.de/" target="_blank">www.baden-wuerttemberg.datenschutz.de/</a>
					<br />
					Tel.: +49 (0)711 615541 – 0 <br />
					Fax: +49 (0)711 615541 – 15
				</p> -->
				<h2>Informationen zur Online-Streitbeilegung</h2>
				<p>
					Hinweis zur Online-Streitbeilegung gemäß Art. 4 Abs. 1 Verordnung über Online-Streitbeilegung in
					Verbraucherangelegenheiten (ODR-VO):
				</p>
				<p>
					Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter
					<a href="https://ec.europa.eu/consumers/odr" target="_blank">https://ec.europa.eu/consumers/odr</a>
					finden. Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend
					vertraglicher Verpflichtungen. <br />
				</p>
				<p>
					Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder
					verpflichtet noch bereit.
				</p>
				<h2>Datenschutzbeauftragter</h2>
				<p>Externer Datenschutzbeauftragter gemäß Art. 37 Datenschutz-Grundverordnung (DSGVO):</p>
				<p>
					Maximilian Musch <br />
					Deutsche Datenschutzkanzlei
				</p>
				<p>
					E-Mail:
					<a href="mailto:maximilian.musch@reutlingen-university.de">maximilian.musch@reutlingen-university.de</a>
					<br />
					Web:
					<a href="https://www.deutsche-datenschutzkanzlei.de" target="_blank">www.deutsche-datenschutzkanzlei.de</a>
					<br />
					Tel.: +49 (0)7542 94921 – 02
				</p>
				<h2>Technische Betreuung</h2>
				<p>
					Philip Storz <br />
					Wissenschaftlicher Mitarbeiter RRI <br />
					Hochschule Reutlingen
				</p>
				<p>
					E-Mail:
					<a href="mailto:philip.storz@reutlingen-university.de">philip.storz@reutlingen-university.de</a>
				</p>
				<h2>Haftungsausschluss</h2>
				<h3>Haftung für Inhalte</h3>
				<p>
					Als Anbieter dieses Onlineangebotes sind wir gemäß § 7 Telemediengesetz (TMG) Abs. 1 für die von uns
					bereitgestellten eigenen Inhalte verantwortlich. Jedoch sind wir nicht verpflichtet, die von uns übermittelten oder
					gespeicherten Informationen auf eine rechtswidrige Tätigkeit zu überwachen. Sollte eine Rechtsverletzung bekannt
					werden, so werden wir diese Inhalte umgehend entfernen. Eine Haftung für diese Inhalte ist erst ab dem Zeitpunkt
					der Kenntnis einer konkreten Rechtsverletzung möglich.
				</p>
				<h3>Haftung für Links</h3>
				<p>
					Dieses Onlineangebot enthält Links zu externen Websites ("Hyperlinks"). Für den Inhalt der verlinkten Websites sind
					die jeweiligen Anbieter verantwortlich. Zum Zeitpunkt der Linksetzung waren keine Rechtsverletzungen bekannt.
					Sollte ein Verstoß bekannt werden, so werden wir die Links umgehend entfernen.
				</p>
				<h3>Urheberrecht</h3>
				<p>
					Alle Rechte vorbehalten. Texte, Bilder und Grafiken sowie deren Anordnung im Onlineangebot unterliegen dem Schutz
					des Urheberrechts und anderer Schutzgesetze. Die Inhalte dieses Onlineangebots dürfen ohne schriftliche Zustimmung
					des jeweiligen Erstellers nicht zu kommerziellen Zwecken vervielfältigt, verbreitet, verändert oder Dritten
					zugänglich gemacht werden. Nicht von uns erstellte Inhalte Dritter werden als solche gekennzeichnet. Sollte eine
					Urheberrechtsverletzung bekannt werden, so werden wir die entsprechenden Inhalte umgehend entfernen.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group LegalNotice
 * Displays all legal notice information
 */
export default {
	name: 'LegalNotice',
};
</script>

<style scoped>
.ln-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 10px 40px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

h1,
h2,
h3 {
	width: fit-content;
	margin: 5px 0px 10px 0px;
	padding-bottom: 3px;
	color: var(--main-color-6);
}

h1 {
	font-size: 50px;
	margin: 5px auto 10px auto;
}

h2 {
	font-size: 30px;
	border-bottom: 1px solid var(--main-color-border-light);
}

h3 {
	font-size: 20px;
	border-bottom: 1px solid var(--main-color-border-light);
	color: var(--main-color-6-cc);
}

p {
	margin: 15px 0px;
	font-size: 20px;
	color: #ddd;
}

a {
	color: var(--main-color-6-cc);
}

a:visited {
	color: var(--main-color-6-80);
}
</style>
