<template>
	<div class="ds-wrap-content">
		<h2>{{ $t('dsDevelopmentStatus') }}</h2>
		<p>{{ $t('dsLargeDataWarning') }}</p>
		<i class="fas fa-times-circle ds-close-dialog" @click="$emit('closeStatus')"></i>
		<div class="ds-wrap-log">
			<LiveLog :connect="true" />
		</div>
	</div>
</template>

<script>
import LiveLog from '../log/LiveLog.vue';
/**
 * @group Development
 * Displays the development creation status
 */
export default {
	name: 'DevelopmentStatus',
	components: {
		LiveLog,
	},
};
</script>

<style scoped>
.ds-wrap-content {
	width: 90%;
	height: 80%;
	margin-top: 5%;
	padding: 10px 40px;
	/* border: 2px solid var(--main-color-border-dark);
	border-radius: 20px; */
	box-sizing: border-box;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--main-color-2);
	border-radius: 20px;
	border: 1px solid var(--main-color-5);
	animation: slideIn 0.4s linear;
}

.ds-wrap-content > p {
	margin-bottom: 5px;
}

.ds-wrap-content h2 {
	width: fit-content;
	margin: 0px auto 10px auto;
	font-size: 30px;
	color: var(--main-color-6);
}

.ds-close-dialog {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 30px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ds-close-dialog:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.ds-wrap-log {
	width: 100%;
	height: calc(100% - 70px);
	margin: auto;
}
</style>
