<template>
	<div class="duf-wrap-content">
		<div class="duf-wrap-input-upload">
			<div class="app-default-btn duf-file-upload">
				<label>
					<input type="file" webkitdirectory directory multiple @input="handleUpload" />
					<i class="fa fa-cloud-upload"></i> {{ $t('dufUploadFolder') }}
				</label>
			</div>
			<div class="app-default-btn duf-file-upload">
				<label>
					<input type="file" multiple @input="handleUpload" />
					<i class="fa-solid fa-file-arrow-up"></i> {{ $t('dufUploadFiles') }}
				</label>
			</div>
		</div>
		<div class="duf-wrap-upload" @dragenter="checkDragEnter">
			<div class="duf-dnd-background">
				<div>
					<i class="fa-solid fa-folder-open"></i>
					<p>{{ $t('dufUploadFilesDND') }}</p>
				</div>
			</div>
			<div
				v-show="showUpload"
				class="duf-hover-div"
				@dragover="checkDragOver"
				@dragleave="
					$event.preventDefault();
					showUpload = false;
				"
				@drop="handleUpload"
			></div>
			<div class="duf-wrap-uploaded-files">
				<div v-for="dataPackage in sortUploads" :key="dataPackage.id" class="duf-files" v-cloak>
					<div class="duf-file-package" @dragover="checkIfSamePackage($event, dataPackage.id)">
						<div class="duf-package-header" @dragover="checkIfSamePackage($event, dataPackage.id)">
							<p>
								<i
									v-if="dataPackage.files.length == 0"
									class="fa-solid fa-triangle-exclamation duf-warning"
									:title="$t('dufNoFiles')"
								></i>
								<i v-if="!dataPackage.meta" class="fa-solid fa-circle-question duf-info" :title="$t('dufNoMeta')"></i>
								<i
									v-else-if="dataPackage.metaSyntaxStatus == 'content'"
									class="fa-solid fa-triangle-exclamation duf-warning"
									:title="$t('dufIncompleteMeta')"
								></i>
								<i
									v-else-if="dataPackage.metaSyntaxStatus == 'syntax'"
									class="fas fa-times-circle duf-error"
									:title="$t('dufFaultyMeta')"
								></i>
								<i
									v-if="dataPackage.metaSyntaxStatus == 'correct' && dataPackage.files.length > 0"
									class="fa-solid fa-circle-check duf-success"
									:title="$t('dufPackageOK')"
								></i>
								<span>
									{{ $t('dufDataPackage') }} {{ dataPackage.id.substring(0, 8) }} ({{ dataPackage.files.length }}
									{{ dataPackage.files.length == 0 || dataPackage.files.length > 1 ? $t('dufFiles') : $t('dufFile') }})
								</span>
							</p>
							<div class="duf-wrap-package-tools">
								<i
									:id="`${dataPackage.id}-collapse`"
									:class="[
										!expandedBodys.includes(dataPackage.id) ? 'duf-hide-element' : '',
										'fa-solid',
										'fa-circle-chevron-up',
										'duf-collapse',
									]"
									@click="collapseBody(dataPackage.id)"
								></i>
								<i
									:id="`${dataPackage.id}-expand`"
									:class="[
										expandedBodys.includes(dataPackage.id) ? 'duf-hide-element' : '',
										'fa-solid',
										'fa-circle-chevron-down',
										'duf-expand',
									]"
									@click="expandBody(dataPackage.id)"
								></i>
								<i class="fa-solid fa-circle-xmark duf-remove" @click="removePackage(dataPackage.id)"></i>
							</div>
						</div>
						<div
							v-if="expandedBodys.includes(dataPackage.id)"
							:id="`${dataPackage.id}-body`"
							:class="['duf-package-body']"
							@dragover="checkIfSamePackage($event, dataPackage.id)"
						>
							<div class="duf-package-meta" :draggable="dataPackage.meta" @dragstart="dragFile($event, 'meta', dataPackage.id)">
								<div :class="[`duf-meta-status-${dataPackage.metaSyntaxStatus}`, 'duf-action']">
									<i class="fa-solid fa-circle-question duf-check-syntax" @click="checkMetaSyntax(dataPackage.id, true)"></i>
								</div>
								<div class="duf-content" @click="editMeta(dataPackage.id)">
									<p>
										<span :class="[dataPackage.meta ? '' : 'duf-no-meta', 'duf-name']">{{ $t('dufMetaInformation') }}</span>
										<span class="duf-date">{{ $global.parseDateShort(new Date()) }}</span>
									</p>
								</div>
							</div>
							<div
								v-for="file in dataPackage.files"
								:key="file.id"
								class="duf-package-file"
								:draggable="true"
								@dragstart="dragFile($event, file.id, dataPackage.id)"
							>
								<div class="duf-action">
									<i class="fa-solid fa-circle-xmark duf-remove" @click="removeFile(dataPackage.id, file.id)"></i>
								</div>
								<div class="duf-content" @click="selectFile(dataPackage.id, file.id)">
									<p>
										<span class="duf-name">
											{{ file.name }}
										</span>
										<span v-if="application.abbreviation == 'covco'" class="duf-category">
											{{ file.category ? `${$t('dufCategory')}: ${getFileCategory(file.category)}` : `${$t('dufNoCategory')}` }}
										</span>
										<span class="duf-date">
											{{ $global.parseDateShort(file.creation) }}
										</span>
									</p>
								</div>
							</div>
						</div>
						<div
							v-if="packageHover == dataPackage.id"
							:id="dataPackage.id"
							class="duf-package-hover"
							@dragover="packageHover == dataPackage.id"
							@drop="dropFile"
							@dragleave="packageHover = null"
						>
							{{ $t('dufAddToPackage') }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showUploadReport" class="duf-wrap-upload-report">
			<UploadReport
				:noFiles="noFiles"
				:noMeta="noMeta"
				:syntaxError="syntaxError"
				:contentWarning="contentWarning"
				@hideUploadReport="hideUploadReport"
				@showInfo="$emit('showInfo', $event)"
				@uploadFiles="checkAdminUpload"
			/>
		</div>
		<div v-if="showDataVerification" class="duf-wrap-data-verification">
			<div class="duf-data-verification">
				<h2>{{ $t('dufUseDataAsVerification') }}</h2>
				<p>{{ $t('dufUseDataAsVerificationText') }}</p>
				<button class="app-default-btn" @click="verificationData(false)">{{ $t('dufNo') }}</button>
				<button class="app-success-btn" @click="verificationData(true)">{{ $t('dufYes') }}</button>
			</div>
		</div>
		<div class="duf-wrap-save-btn">
			<button
				id="uploadBtn"
				:class="[uploads.length == 0 || remainingMeta > 0 ? 'app-disabled-btn' : 'app-success-btn', uploadPending ? 'duf-disable-click' : '']"
				@click="checkUpload"
			>
				{{ $t('dufSaveFiles') }} ({{ uploads.length }} {{ $t('dufPackages') }}, {{ getFileSize() }})
				<!-- <i v-if="uploadPending" class="fa-solid fa-spinner"></i> -->
			</button>
			<p v-if="remainingFiles > 0 || remainingFolders > 0 || remainingMeta > 0" class="duf-upload-message">
				<span v-if="remainingFiles > 0 || remainingFolders > 0">
					{{ $t('dufParsingUploadedFiles') }} ({{ remainingFiles + remainingFolders }} {{ $t('dufRemaining') }})
					<i class="fa-solid fa-spinner"></i>
				</span>
				<span v-if="remainingMeta > 0">
					{{ $t('dufParsingUploadedMeta') }} ({{ remainingMeta }} {{ $t('dufRemaining') }})
					<i class="fa-solid fa-spinner"></i>
				</span>
			</p>
		</div>
	</div>
</template>

<script>
import UploadReport from './UploadReport';
import * as uuid from 'uuid';
/**
 * @group Upload
 * Contains the data upload components
 */
export default {
	name: 'DataUploadFiles',
	components: {
		UploadReport,
	},
	props: {
		application: {
			type: Object,
			required: true,
		},
		editedMeta: {
			type: Object,
			required: false,
		},
		newPackage: {
			type: Object,
			required: false,
		},
	},
	watch: {
		editedMeta: {
			handler: function (newVal) {
				if (newVal) {
					let packIdx = this.uploads.findIndex((pack) => pack.id == newVal.packageID);
					if (packIdx != -1) {
						let oldMeta = this.uploads[packIdx].meta;
						if (oldMeta) this.uploadSize -= this.getJSONSize(oldMeta);
						this.uploadSize += this.getJSONSize(newVal.meta);
						this.uploads[packIdx].meta = newVal.meta;
						this.uploads[packIdx].metaSyntaxStatus = this.checkMetaSyntax(newVal.meta, false);
					}
				}
			},
			deep: true,
		},
		newPackage: {
			handler: function (newVal) {
				if (newVal) {
					if (this.uploads.filter((pack) => pack.id == newVal.id).length == 0) {
						newVal.files.forEach((file) => {
							this.uploadSize += file.file.size;
						});
						this.uploads.push({ ...newVal, metaSyntaxStatus: null });
						this.$nextTick(() => {
							let element = document.querySelector('.duf-wrap-uploaded-files');
							element.scrollTo({ top: 0, behavior: 'smooth' });
							// element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
						});
					}
				}
			},
			deep: true,
		},
		uploads: {
			handler: function (newVal) {
				if (this.uploads.filter((pack) => pack.id == newVal[newVal.length - 1].id).length > 1) this.uploads.pop();
			},
			deep: true,
		},
	},
	data() {
		return {
			socket: null,
			showUpload: false,
			isLoading: null,
			uploadSize: 0,
			uploads: [],
			sortedUploads: [],
			remainingFiles: 0,
			remainingMeta: 0,
			remainingFolders: 0,
			expandedBodys: [],
			unsupportedMimeTypes: [],
			packageHover: null,
			showUploadReport: false,
			noFiles: [],
			noMeta: [],
			syntaxError: [],
			contentWarning: [],
			showDataVerification: false,
			isVerification: false,
			uploadPending: false,
			fullLength: 0,
		};
	},
	computed: {
		// @vuese
		// Checks if the uploads can be sorted
		// @return [Boolean] - Is true if the uploads can be sorted
		canBeSorted() {
			if (this.remainingMeta > 0 || this.remainingFiles > 0 || this.remainingFolders > 0) return false;
			else return true;
		},
		// @vuese
		// Sorts the uploads depending on the severity of the missing data
		// @return [Array] - Either the sorted array if canBeSorted is true or the unsorted values
		sortUploads() {
			if (!this.canBeSorted) {
				let newEntries = this.uploads.slice(this.sortedUploads.length);
				for (let i = 0; i < newEntries.length; i++) this.sortedUploads.push(newEntries[i]);
				return this.sortedUploads;
			} else {
				this.sortedUploads = this.uploads.sort((a, b) => {
					let err = 'syntax';
					let warn = 'content';
					let ok = 'correct';
					let metaSyntaxA = a.metaSyntaxStatus;
					let metaSyntaxB = b.metaSyntaxStatus;

					// prettier-ignore
					switch (true) {
						// Sort if no file and incorrect meta
						case Boolean(a.files.length == 0 && metaSyntaxA == err && b.files.length == 0 && metaSyntaxB == err): return 0;
						case Boolean(a.files.length == 0 && metaSyntaxA == err && (b.files.length > 0 || metaSyntaxB !== err)): return -1;
						case Boolean((a.files.length > 0 || metaSyntaxA !== err) && b.files.length == 0 && metaSyntaxB == err): return 1;

						// Sort if no file and incomplete meta
						case Boolean(a.files.length == 0 && metaSyntaxA == warn && b.files.length == 0 && metaSyntaxB == warn): return 0;
						case Boolean(a.files.length == 0 && metaSyntaxA == warn && (b.files.length > 0 || metaSyntaxB !== warn)): return -1;
						case Boolean((a.files.length > 0 || metaSyntaxA !== warn) && b.files.length == 0 && metaSyntaxB == warn): return 1;

						// Sort if no file and no meta
						case Boolean(a.files.length == 0 && !metaSyntaxA && b.files.length == 0 && !metaSyntaxB): return 0;
						case Boolean(a.files.length == 0 && !metaSyntaxA && (b.files.length > 0 || metaSyntaxB)): return -1;
						case Boolean((a.files.length > 0 || metaSyntaxA) && b.files.length == 0 && !metaSyntaxB): return 1;

						// Sort if file and incorrect meta
						case Boolean(a.files.length > 0 && metaSyntaxA == err && b.files.length > 0 && metaSyntaxB == err): return 0;
						case Boolean(a.files.length > 0 && metaSyntaxA == err && (b.files.length == 0 || metaSyntaxB !== err)): return -1;
						case Boolean((a.files.length == 0 || metaSyntaxA !== err) && b.files.length > 0 && metaSyntaxB == err): return 1;

						// Sort if file and incomplete meta
						case Boolean(a.files.length > 0 && metaSyntaxA == warn && b.files.length > 0 && metaSyntaxB == warn): return 0;
						case Boolean(a.files.length > 0 && metaSyntaxA == warn && (b.files.length == 0 || metaSyntaxB !== warn)): return -1;
						case Boolean((a.files.length == 0 || metaSyntaxA !== warn) && b.files.length > 0 && metaSyntaxB == warn): return 1;

						// Sort if no file and correct meta
						case Boolean(a.files.length == 0 && metaSyntaxA == ok && b.files.length == 0 && metaSyntaxB == ok): return 0;
						case Boolean(a.files.length == 0 && metaSyntaxA == ok && (b.files.length > 0 || metaSyntaxB !== ok)): return -1;
						case Boolean((a.files.length > 0 || metaSyntaxA !== ok) && b.files.length == 0 && metaSyntaxB == ok): return 1;

						// Sort if file and no meta
						case Boolean(a.files.length > 0 && !metaSyntaxA && b.files.length > 0 && !metaSyntaxB): return 0;
						case Boolean(a.files.length > 0 && !metaSyntaxA && (b.files.length == 0 || metaSyntaxB)): return -1;
						case Boolean((a.files.length == 0 || metaSyntaxA) && b.files.length > 0 && !metaSyntaxB): return 1;

						// Sort if file and correct meta
						case Boolean(a.files.length > 0 && metaSyntaxA && b.files.length > 0 && metaSyntaxB): return 0;
						case Boolean(a.files.length > 0 && metaSyntaxA && (b.files.length == 0 || !metaSyntaxB)): return -1;
						case Boolean((a.files.length == 0 || !metaSyntaxA) && b.files.length > 0 && metaSyntaxB): return 1;

						default: return 0;
					}
				});
				return this.sortedUploads;
			}
		},
	},
	mounted() {
		window.addEventListener('dragend', this.cleanUpDrag);
	},
	beforeDestroy() {
		window.removeEventListener('dragend', this.cleanUpDrag);
		if (this.socket) this.socket.off('upload_progress');
	},
	methods: {
		// @vuese
		// Initiates the socket connection
		initSocket(cb) {
			let that = this;
			this.$socket.getSocket(function (socket) {
				that.socket = socket;
				that.setupSockets(cb);
			});
		},
		// @vuese
		// Sets up the socket listener that retrieves the live log
		setupSockets(cb) {
			let that = this;

			this.socket.on('upload_progress', function (progress) {
				that.updateUploadProgress(progress);
			});
			cb();
		},
		updateUploadProgress(progress) {
			console.log(progress, '/', this.fullLength);
			let elem = document.getElementById('uploadBtn');
			let percentage = Math.round((progress / this.fullLength) * 1000) / 10;
			elem.className = 'duf-progress-btn';
			elem.style.backgroundSize = `${percentage}% 100%`;
		},
		// @vuese
		// Returns the current upload size
		// @returns [String] - The upload size as KB, MB or GB
		getFileSize() {
			let kbSize = this.uploadSize / 1024;
			let mbSize, gbSize;
			if (kbSize >= 1000) mbSize = this.uploadSize / (1024 * 1024);
			if (mbSize >= 1000) gbSize = this.uploadSize / (1024 * 1024 * 1024);
			if (gbSize) return `${Math.round(gbSize * 100) / 100} GB`;
			else if (mbSize) return `${Math.round(mbSize * 100) / 100} MB`;
			else return `${Math.round(kbSize * 100) / 100} KB`;
		},
		// @vuese
		// Handles the upload. Catches events from the input fields or the drag and drop div
		// @arg e[Object] - The event that occured
		handleUpload(e) {
			e.preventDefault();
			this.showUpload = false;
			this.isLoading = performance.now();
			// Upload via input
			if (e.target.files) {
				let files = Array.from(e.target.files);
				e.target.value = null;
				let dataPackageID = uuid.v4();
				files.forEach((file) => {
					if (this.application.mimeTypes.includes(file.type) || this.application.mimeTypes[0] == '*') this.addFile(file, dataPackageID);
					else {
						let mimeType = file.type !== '' ? file.type : this.$t('dufUnknownMimeType');
						if (!this.unsupportedMimeTypes.includes(mimeType)) this.unsupportedMimeTypes.push(mimeType);
					}
				});

				this.isLoading = null;
				if (this.unsupportedMimeTypes.length > 0) {
					this.$global.showToast('warn', `${this.$t('dufUnsupportedMimeType')}: ${this.unsupportedMimeTypes.join(', ')}`);
					this.unsupportedMimeTypes = [];
				}
			}
			// Upload with drag & drop
			else if (e.dataTransfer && e.dataTransfer.items) {
				let items = Array.from(e.dataTransfer.items);
				let dataPackageID = uuid.v4();

				for (let i = 0; i < items.length; i++) {
					let item = items[i].webkitGetAsEntry();
					if (!item.isDirectory) this.checkItemType(item, dataPackageID);
					else this.checkItemType(item, uuid.v4());
				}
			}
			// Handle if upload for folder content isnt possible
			else if (e.dataTransfer.files) {
				try {
					let files = Array.from(e.dataTransfer.files);

					files.forEach((file) => {
						if (this.application.mimeTypes.includes(file.type) || this.application.mimeTypes[0] == '*') this.addFile(file, dataPackageID);
						else {
							let mimeType = file.type !== '' ? file.type : this.$t('dufUnknownMimeType');
							if (!this.unsupportedMimeTypes.includes(mimeType)) this.unsupportedMimeTypes.push(mimeType);
						}
					});
					this.isLoading = null;
				} catch (error) {
					this.isLoading = null;

					this.$global.showToast('warn', this.$t('dufUploadNotSupported'));
				}
			}
		},
		// @vuese
		// Checks if the item is a folder or a file and adds them into different data packages
		// @arg item[Object] - The current item
		// @arg dataPackageID[String] - The packageID
		checkItemType(item, dataPackageID) {
			let that = this;
			if (item.isDirectory) {
				that.remainingFolders += 1;
				let directoryReader = item.createReader();
				directoryReader.readEntries((entries) => {
					for (let i = 0; i < entries.length; i++) {
						that.checkItemType(entries[i], dataPackageID);
						if (entries.length - 1 == i) that.remainingFolders -= 1;
					}
					if (entries.length == 0) {
						let idx = that.uploads.findIndex((pack) => pack.id == dataPackageID);
						if (idx == -1) {
							that.uploads.push({
								id: dataPackageID,
								application: that.application,
								files: [],
								meta: null,
								metaSyntaxStatus: null,
							});
						}
						that.remainingFolders -= 1;
					}
				});
			} else {
				that.remainingFiles += 1;
				item.file((file) => {
					if (that.application.mimeTypes.includes(file.type) || that.application.mimeTypes[0] == '*') {
						window.setTimeout(() => {
							that.addFile(file, dataPackageID);
						}, 1);
					} else {
						let mimeType = file.type !== '' ? file.type : that.$t('dufUnknownMimeType');
						if (!that.unsupportedMimeTypes.includes(mimeType)) that.unsupportedMimeTypes.push(mimeType);
					}
					that.remainingFiles -= 1;
					if (that.remainingFiles == 0 && that.remainingFolders == 0) {
						that.isLoading = null;
						that.$nextTick(() => {
							let element = document.querySelector('.duf-wrap-uploaded-files');
							element.scrollTo({ top: 0, behavior: 'smooth' });
							// element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
						});
						if (that.unsupportedMimeTypes.length > 0) {
							that.$global.showToast('warn', `${that.$t('dufUnsupportedMimeType')}: ${that.unsupportedMimeTypes.join(', ')}`);
							that.unsupportedMimeTypes = [];
						}
					}
				});
			}
			return;
		},
		// @vuese
		// Adds a file to the file upload
		// @arg file[Object] - The added file
		// @arg packageID[String] - The packageID
		addFile(file, packageID) {
			this.uploadSize += file.size;
			if (['covco', 'bwhealthapp'].includes(this.application.abbreviation) && file.type == 'application/json') {
				this.remainingMeta += 1;
				this.addMeta(file, packageID);
			} else {
				let idx = this.uploads.findIndex((pack) => pack.id == packageID);
				if (idx != -1) {
					this.uploads[idx].files.push({
						id: uuid.v4(),
						file: file,
						category: null,
						creation: new Date(file.lastModified),
						name: file.name,
					});
				} else {
					this.uploads.push({
						id: packageID,
						application: this.application,
						files: [
							{
								id: uuid.v4(),
								file: file,
								category: null,
								creation: new Date(file.lastModified),
								name: file.name,
							},
						],
						meta: null,
						metaSyntaxStatus: null,
					});
				}
			}
		},
		// @vuese
		// Adds meta information to a data package by parsing the uploaded json file
		// @arg file[Object] - The added json file
		// @arg packageID[String] - The packageID
		addMeta(file, packageID) {
			this.parseJsonFile(file, (err, meta) => {
				if (!err) {
					let metaSyntaxStatus = this.checkMetaSyntax(meta, false);
					let idx = this.uploads.findIndex((pack) => pack.id == packageID);
					if (idx != -1) {
						this.uploads[idx].meta = meta;
						this.uploads[idx].metaSyntaxStatus = metaSyntaxStatus;
					} else {
						this.uploads.push({
							id: packageID,
							files: [],
							meta: meta,
							metaSyntaxStatus: metaSyntaxStatus,
						});
					}
				}
				this.remainingMeta -= 1;
			});
		},
		// @vuese
		// Parses a uploaded json file
		// @arg file[Object] - The uploaded json file
		parseJsonFile(file, cb) {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				try {
					let result = JSON.parse(e.target.result);
					cb(false, result);
				} catch (error) {
					this.$global.showToast('error', this.$t('dufInvalidJSON'));
					cb(false, {});
				}
			};

			fileReader.onerror = (error) => {
				this.$global.showToast('error', this.$t('dufInvalidJSON'));
				cb(false, {});
			};

			fileReader.readAsText(file);
		},
		// @vuese
		// Catches the start of a drag event and sets the IDs
		// @arg e[Object] - The event that occured
		// @arg fileID[String] - The fileID
		// @arg packageID[String] - The packageID
		dragFile(e, fileID, packageID) {
			// e.preventDefault();
			// e.dataTransfer.setData('fileID', fileID);
			sessionStorage.setItem('fileID', fileID);
			sessionStorage.setItem('packageID', packageID);
		},
		// @vuese
		// Catches the drag enter event and displays the upload div
		// @arg e[Object] - The event that occured
		checkDragEnter(e) {
			// e.preventDefault();
			let fileID = sessionStorage.getItem('fileID');
			if (fileID) this.showUpload = false;
			else this.showUpload = true;
		},
		// @vuese
		// Catches the drag over event and displays the upload div
		// @arg e[Object] - The event that occured
		checkDragOver(e) {
			e.preventDefault();
			let fileID = sessionStorage.getItem('fileID');
			if (fileID) this.showUpload = false;
			else this.showUpload = true;
		},
		// @vuese
		// Checks if the hovered div is the same package or not
		// @arg e[Object] - The event that occured
		// @arg packageID[String] - The packageID
		checkIfSamePackage(e, packageID) {
			e.preventDefault();
			let packID = sessionStorage.getItem('packageID');
			if (packID !== packageID) this.packageHover = packageID;
		},
		// @vuese
		// Catches the drop event and adds the file to the new package
		// @arg e[Object] - The event that occured
		dropFile(e) {
			let fileID = sessionStorage.getItem('fileID');
			let packageID = sessionStorage.getItem('packageID');
			sessionStorage.removeItem('fileID');
			sessionStorage.removeItem('packageID');
			this.packageHover = null;
			let oldPackageIdx = this.uploads.findIndex((pack) => pack.id == packageID);
			let newPackageIdx = this.uploads.findIndex((pack) => pack.id == e.target.id);
			if (fileID !== 'meta') {
				let fileIdx = this.uploads[oldPackageIdx].files.findIndex((file) => file.id == fileID);

				this.uploads[newPackageIdx].files.push(this.uploads[oldPackageIdx].files[fileIdx]);
				this.uploads[oldPackageIdx].files.splice(fileIdx, 1);
			} else {
				this.uploads[newPackageIdx].meta = this.uploads[oldPackageIdx].meta;
				this.uploads[oldPackageIdx].meta = null;
			}
		},
		// @vuese
		// Catches the drag end event and cleans up
		cleanUpDrag() {
			sessionStorage.removeItem('fileID');
			sessionStorage.removeItem('packageID');
			this.packageHover = null;
			this.showUpload = false;
		},
		// @vuese
		// Mapps the translation of a category
		// @arg category[String] - The set category
		// @returns [String] - The translation of the category
		getFileCategory(category) {
			if (category == 'cough') return this.$t('dufCough');
			else if (category == 'breath') return this.$t('dufBreath');
			else if (category == 'speech') return this.$t('dufSpeech');
		},
		// @vuese
		// Collapses the body of a package
		// @arg packageID[String] - The packageID
		collapseBody(packageID) {
			document.getElementById(`${packageID}-collapse`).classList.add('duf-hide-element');
			// document.getElementById(`${packageID}-body`).classList.add('duf-hide-element');
			document.getElementById(`${packageID}-expand`).classList.remove('duf-hide-element');
			this.expandedBodys = this.expandedBodys.filter((packID) => packID !== packageID);
		},
		// @vuese
		// Expands the body of a package
		// @arg packageID[String] - The packageID
		expandBody(packageID) {
			document.getElementById(`${packageID}-collapse`).classList.remove('duf-hide-element');
			// document.getElementById(`${packageID}-body`).classList.remove('duf-hide-element');
			document.getElementById(`${packageID}-expand`).classList.add('duf-hide-element');
			this.expandedBodys.push(packageID);
		},
		// @vuese
		// Deletes a package by a given id
		// @arg id[String] - The packageID
		removePackage(id) {
			let removedPackage = this.uploads.filter((pack) => pack.id == id)[0];
			if (removedPackage) {
				removedPackage.files.forEach((file) => {
					this.uploadSize -= file.file.size;
				});
				this.uploadSize -= this.getJSONSize(removedPackage.meta);
			}
			this.uploads = this.uploads.filter((pack) => pack.id != id);
			if (this.uploads.length == 0) this.uploadSize = 0;
		},
		// @vuese
		// Deletes a file from a package by a given id
		// @arg fileID[String] - The fileID
		// @arg packageID[String] - The packageID
		removeFile(packageID, fileID) {
			let packIdx = this.uploads.findIndex((pack) => pack.id == packageID);
			if (packIdx !== -1) {
				let removedFile = this.uploads[packIdx].files.filter((file) => file.id == fileID)[0];
				if (removedFile) {
					this.uploadSize -= removedFile.file.size;
				}
				this.uploads[packIdx].files = this.uploads[packIdx].files.filter((file) => file.id !== fileID);
			}
		},
		// @vuese
		// Computes the size of a json object in bytes
		// @arg json[Object] - The json object
		// @return [Number] - The amount of bytes
		getJSONSize(json) {
			try {
				return Buffer.from(JSON.stringify(json)).length;
			} catch (error) {
				return new Blob([JSON.stringify(json)]).size;
			}
		},
		// @vuese
		// Selects a file
		// @arg fileID[String] - The fileID
		// @arg packageID[String] - The packageID
		selectFile(packageID, fileID) {
			let packIdx = this.uploads.findIndex((pack) => pack.id == packageID);
			let fileIdx = this.uploads[packIdx].files.findIndex((file) => file.id == fileID);
			this.$emit('selectFile', { packageID: packageID, file: this.uploads[packIdx].files[fileIdx] });
		},
		// @vuese
		// Edits a package meta
		// @arg packageID[String] - The packageID
		editMeta(packageID) {
			this.$emit(
				'editMeta',
				JSON.parse(JSON.stringify({ packageID: packageID, meta: this.uploads.filter((pack) => pack.id == packageID)[0].meta }))
			);
		},
		// @vuese
		// Checks the syntax of the meta information for a package
		// @arg packageID[String] - The packageID
		// @arg showToast[Boolean] - If true the check displays toasts with information about the syntax
		// @returns [String] - Returns a class based on the check result
		checkMetaSyntax(param, showToast) {
			console.log('checkMetaSyntax', param);
			try {
				let meta = typeof param == 'string' ? this.uploads.filter((pack) => pack.id == param)[0].meta : param;
				if (meta) {
					if (this.application.abbreviation == 'covco') return this.checkCOVCOMeta(meta, showToast);
					else if (this.application.abbreviation == 'bwhealthapp') return this.checkBWHEALTHAPPMeta(meta, showToast);
				} else {
					if (showToast) this.$global.showToast('info', this.$t('dufNoMeta'));
					return null;
				}
			} catch (error) {
				console.log(error);
				if (showToast) this.$global.showToast('info', this.$t('dufNoMeta'));
				return null;
			}
		},
		// @vuese
		// Checks the syntax of the covco meta information for a package
		// @arg meta[Object] - The meta
		// @arg showToast[Boolean] - If true the check displays toasts with information about the syntax
		// @returns [String] - Returns a class based on the check result
		checkCOVCOMeta(meta, showToast) {
			try {
				if (!meta) throw null;
				else if (!('groundtruthLabel' in meta)) throw 'syntax';
				else if (!('externalID' in meta)) throw 'syntax';
				else if (!('gender' in meta.meta)) throw 'syntax';
				else if (!('dateOfBirth' in meta.meta)) throw 'syntax';
				else if (!('smoking' in meta.meta)) throw 'syntax';
				else if (!('hasLungCondition' in meta.meta)) throw 'syntax';
				else if (!('lungConditions' in meta.meta)) throw 'syntax';
				else if (!('hasHeartCondition' in meta.meta)) throw 'syntax';
				else if (!('heartConditions' in meta.meta)) throw 'syntax';
				else if (!('hasCOVIDSymptoms' in meta.meta)) throw 'syntax';
				else if (!('covidSymptoms' in meta.meta)) throw 'syntax';
				else if (!('hasOtherSymptoms' in meta.meta)) throw 'syntax';
				else if (!('otherSymptoms' in meta.meta)) throw 'syntax';
				else if (meta.externalID == '' || meta.meta.gender == '' || meta.meta.dateOfBirth == '') throw 'content';
				else if (meta.meta.hasLungCondition && meta.meta.lungConditions.length == 0) throw 'content';
				else if (meta.meta.hasLungCondition && meta.meta.lungConditions.length == 0) throw 'content';
				else if (meta.meta.hasHeartCondition && meta.meta.heartConditions.length == 0) throw 'content';
				else if (meta.meta.hasCOVIDSymptoms && meta.meta.covidSymptoms.length == 0) throw 'content';
				else if (meta.meta.hasOtherSymptoms && meta.meta.otherSymptoms.length == 0) throw 'content';
				else throw 'correct';
			} catch (status) {
				if (status == 'syntax' && showToast) this.$global.showToast('error', this.$t('dufInvalidSyntax'));
				else if (status == 'content' && showToast) this.$global.showToast('warn', this.$t('dufInvalidContent'));
				else if (status == 'correct' && showToast) this.$global.showToast('success', this.$t('dufCorrectSyntaxContent'));
				else if (typeof status !== 'string') {
					if (showToast) this.$global.showToast('error', this.$t('dufUnknownError'));
					status = null;
				}
				return status;
			}
		},
		// @vuese
		// Checks the syntax of the bwhealthapp meta information for a package
		// @arg meta[Object] - The meta
		// @arg showToast[Boolean] - If true the check displays toasts with information about the syntax
		// @returns [String] - Returns a class based on the check result
		checkBWHEALTHAPPMeta(meta, showToast) {
			try {
				console.log(meta);
				if (!meta) throw null;
				else if (!('patient' in meta)) throw 'syntax';
				else if (!('export' in meta)) throw 'syntax';
				else if (!meta.patient) throw 'syntax';
				else if (!meta.export) throw 'syntax';
				else if (!('id' in meta.patient)) throw 'syntax';
				else if (!('name' in meta.patient)) throw 'syntax';
				else if (!('gender' in meta.patient)) throw 'syntax';
				else if (!('dob' in meta.patient)) throw 'syntax';
				else if (!('activity' in meta.patient)) throw 'syntax';
				else if (!('start' in meta.export)) throw 'syntax';
				else if (!('end' in meta.export)) throw 'syntax';
				else if (
					meta.patient.id == null ||
					meta.patient.name == null ||
					meta.patient.dob == null ||
					meta.patient.gender == null ||
					meta.patient.activity == null
				)
					throw 'content';
				else if (meta.export.start == null || meta.export.end == null) throw 'content';
				else throw 'correct';
			} catch (status) {
				console.log(status);
				if (status == 'syntax' && showToast) this.$global.showToast('error', this.$t('dufInvalidSyntax'));
				else if (status == 'content' && showToast) this.$global.showToast('warn', this.$t('dufInvalidContent'));
				else if (status == 'correct' && showToast) this.$global.showToast('success', this.$t('dufCorrectSyntaxContent'));
				else if (typeof status !== 'string') {
					if (showToast) this.$global.showToast('error', this.$t('dufUnknownError'));
					status = null;
				}
				return status;
			}
		},
		// @vuese
		// Checks if the files can be uploaded
		checkUpload() {
			let noFiles = [];
			let noMeta = [];
			let syntaxError = [];
			let contentWarning = [];
			let correctPackage = [];
			this.uploads.forEach((pack) => {
				if (pack.files.length == 0) noFiles.push(pack.id);
				else {
					if (!pack.meta) noMeta.push(pack.id);
					else {
						let metaSyntaxStatus = this.checkMetaSyntax(pack.meta, false);
						if (metaSyntaxStatus == 'syntax') syntaxError.push(pack.id);
						else if (metaSyntaxStatus == 'content') contentWarning.push(pack.id);
						else if (metaSyntaxStatus == 'correct') correctPackage.push(pack.id);
					}
				}
			});
			if (correctPackage.length !== this.uploads.length) {
				this.uploadReport(noFiles, noMeta, syntaxError, contentWarning);
			} else this.checkAdminUpload();
		},
		// @vuese
		// Displays a upload report if the upload check returned warnings or errors
		// @arg noFiles[Array] - Data packages without any files
		// @arg noMeta[Array] - Data packages without a eta object
		// @arg syntaxError[Array] - Data packages with a syntax error
		// @arg contentWarning[Array] - Data packages with missing information
		uploadReport(noFiles, noMeta, syntaxError, contentWarning) {
			this.showUploadReport = true;
			this.noFiles = noFiles;
			this.noMeta = noMeta;
			this.syntaxError = syntaxError;
			this.contentWarning = contentWarning;
		},
		// @vuese
		// Hides the upload report again
		hideUploadReport() {
			this.showUploadReport = false;
			this.noFiles = [];
			this.noMeta = [];
			this.syntaxError = [];
			this.contentWarning = [];
		},
		// @vuese
		// Checks if a admin is uploading the data and asks if the data should be marked as verification data
		checkAdminUpload() {
			if (this.$global.hasRights()) this.showDataVerification = true;
			else this.uploadFiles();
		},
		// @vuese
		// Sets the isVerification var and forwards to the file upload
		// @arg isVerification[Boolean] - Is true if the data is used as verification data
		verificationData(isVerification) {
			this.isVerification = isVerification;
			this.showDataVerification = false;
			this.uploadFiles();
		},
		// @vuese
		// Uploads the files to the server as form data
		uploadFiles() {
			if (this.syntaxError.length > 0) this.$global.showToast('warn', this.$t('dufFilesNotUploaded'));
			else {
				this.initSocket((err) => {
					if (!err) {
						this.uploadPending = true;
						let filteredUploads = this.uploads.filter((pack) => pack.files.length > 0);
						let bodyForm = new FormData();
						this.fullLength = 0;
						filteredUploads.forEach((pack) => {
							let dataPack = JSON.parse(JSON.stringify(pack));
							dataPack.files.map((file) => {
								delete file.src;
								return file;
							});
							dataPack.isVerification = this.isVerification;

							let packIdx = this.uploads.findIndex((pack) => pack.id == dataPack.id);

							bodyForm.append(`${dataPack.id}`, JSON.stringify(dataPack));
							dataPack.files.forEach((file) => {
								this.fullLength += 1;
								let fileIdx = this.uploads[packIdx].files.findIndex((f) => f.id == file.id);
								let f = this.uploads[packIdx].files[fileIdx].file;
								bodyForm.append(`${dataPack.id}_${file.id}`, f, f.name ? f.name : file.name);
							});
						});

						this.$global.postData(
							'data',
							'/packages',
							bodyForm,
							{
								headers: { userid: this.$global.getUser().userID },
								auth: JSON.parse(sessionStorage.getItem('credentials')),
							},
							(err, data) => {
								if (err) {
									let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
									this.$global.showToast('error', this.$t(msg ? msg : 'dufUploadError'));
									this.uploadPending = false;
									this.fullLength = 0;
									let elem = document.getElementById('uploadBtn');
									elem.className = 'app-success-btn';
								} else this.$emit('clearUpload', 'data');
							}
						);
					}
				});
			}
			this.hideUploadReport();
		},
	},
};
</script>

<style scoped>
.duf-wrap-content {
	width: 100%;
	height: 100%;
}

.duf-wrap-input-upload {
	width: 100%;
	height: fit-content;
	margin: 0px auto 10px auto;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	gap: 10px 20px;
}

.duf-file-upload {
	flex: 0 0 220px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 17px;
	background-color: var(--main-color-4);
	border: 2px solid var(--main-color-border-dark);
}

.duf-file-upload:hover {
	background-color: var(--main-color-3);
}

.duf-file-upload label {
	margin: 0px;
	box-sizing: content-box;
	text-align: start;
	padding: 5px 26px;
}

.duf-file-upload:hover label {
	cursor: pointer;
}

.duf-file-upload input[type='file'] {
	width: 100%;
	display: none;
}

.duf-wrap-upload {
	width: 100%;
	height: calc(100% - 110px);
	margin: 0px auto 10px auto;
	position: relative;
	box-sizing: border-box;
	border: 3px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
}

.duf-wrap-save-btn {
	width: 100%;
	height: 40px;
	text-align: center;
}

.duf-wrap-save-btn button {
	font-size: 17px;
}

.duf-upload-message {
	margin: 5px auto;
	font-size: 17px;
}

.duf-upload-message i {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.duf-disable-click {
	pointer-events: none;
}

.duf-disable-click i {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.duf-dnd-background {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.duf-dnd-background div {
	width: fit-content;
	max-width: 90%;
	height: fit-content;
	text-align: center;
	overflow: hidden;
}

.duf-dnd-background div i {
	margin-bottom: 10px;
	font-size: 50px;
	color: var(--main-color-text-light);
}

.duf-dnd-background div p {
	display: block;
	font-size: 30px;
	color: var(--main-color-text-light);
}

.duf-hover-div {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	border: 5px solid var(--main-color-6);
	box-sizing: border-box;
	background-color: var(--main-color-dark-80);
	z-index: 3;
}

.duf-wrap-uploaded-files {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 2;
	box-sizing: border-box;
	overflow: auto;
}

.duf-files {
	background-color: var(--main-color-4);
}

.duf-single-file {
	width: 100%;
	height: fit-content;
	padding: 5px;
	border-bottom: 1px solid var(--main-color-border-light);
	box-sizing: border-box;
}

.duf-file-package {
	width: 100%;
	height: fit-content;
	border-bottom: 2px solid var(--main-color-border-light);
	box-sizing: border-box;
	position: relative;
}

.duf-package-hover {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--main-color-6);
	box-sizing: border-box;
	font-size: 20px;
	background-color: var(--main-color-dark-80);
	color: var(--main-color-text-light);
}

.duf-package-header {
	width: 100%;
	height: fit-content;
	padding: 5px;
	border-bottom: 1px solid var(--main-color-border-dark);
	box-sizing: border-box;
	background-color: var(--main-color-3);
}

.duf-package-header p {
	width: calc(100% - 70px);
	height: 25px;
	margin-right: 10px;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.duf-package-header p span {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.duf-wrap-package-tools {
	width: 60px;
	display: inline-block;
	text-align: center;
}

.duf-wrap-package-tools i {
	margin: 0px 5px;
	font-size: 20px;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.duf-collapse:hover,
.duf-expand:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.duf-hide-element {
	display: none;
}

.duf-package-body {
	width: 100%;
	height: fit-content;
	box-sizing: border-box;
}

.duf-package-file {
	border-bottom: 1px solid var(--main-color-border-dark);
}

.duf-package-file .duf-content:hover {
	background-color: var(--main-color-6-cc);
	cursor: pointer;
}

.duf-package-meta {
	border-bottom: 1px solid var(--main-color-border-dark);
	box-sizing: border-box;
}

.duf-package-meta .duf-content:hover {
	background-color: var(--main-color-6-cc);
	cursor: pointer;
}

.duf-no-meta {
	text-decoration: line-through;
}

.duf-meta-status-correct {
	background-color: var(--main-color-success);
}

.duf-meta-status-syntax {
	background-color: var(--main-color-error);
}

.duf-meta-status-content {
	background-color: var(--main-color-warn);
}

.duf-success {
	margin-right: 5px;
	font-size: 20px !important;
	color: var(--main-color-success);
	cursor: default !important;
}

.duf-info {
	margin-right: 5px;
	font-size: 20px !important;
	color: var(--main-color-info);
	cursor: default !important;
}

.duf-warning {
	margin-right: 5px;
	font-size: 20px !important;
	color: var(--main-color-warn);
	cursor: default !important;
}

.duf-error {
	margin-right: 5px;
	font-size: 20px !important;
	color: var(--main-color-error);
	cursor: default !important;
}

.duf-action {
	width: 30px;
	height: fit-content;
	min-height: 34px;
	display: inline-block;
	padding: 5px;
	vertical-align: top;
	box-sizing: border-box;
	font-size: 20px;
	text-align: center;
}

.duf-content {
	width: calc(100% - 30px);
	height: 100%;
	min-height: 34px;
	display: inline-block;
	padding: 5px;
	vertical-align: top;
	box-sizing: border-box;
	border-left: 1px solid var(--main-color-border-dark);
}

.duf-name {
	float: left;
}

.duf-category {
	float: left;
	margin: 0px 5px;
}

.duf-date {
	min-width: 120px;
	float: right;
	vertical-align: top;
}

.duf-remove {
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.duf-remove:hover {
	color: var(--main-color-error);
	cursor: pointer;
}

.duf-edit {
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.duf-edit:hover {
	color: var(--main-color-6);
	cursor: pointer;
}

.duf-check-syntax:hover {
	cursor: pointer;
	color: var(--main-color-info);
}

.duf-meta-status-correct:hover i,
.duf-meta-status-syntax:hover i,
.duf-meta-status-content:hover i {
	color: var(--main-color-text-dark) !important;
}

.duf-wrap-upload-report,
.duf-wrap-data-verification {
	width: 100vw;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: var(--main-color-dark-80);
}

.duf-data-verification {
	max-width: 80%;
	max-height: 90%;
	position: relative;
	padding: 5px 30px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--main-color-3);
	text-align: center;
	animation: slideIn 0.4s linear;
}

.duf-data-verification h2 {
	width: fit-content;
	margin: 10px auto;
	color: var(--main-color-6);
}

.duf-data-verification p {
	margin: 5px auto 10px auto;
	text-align: start;
}

.duf-data-verification button {
	width: 100px;
	margin: 0px 10px;
}

.duf-loading-spinner {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border-left: 5px solid var(--main-color-6);
}

.duf-progress-btn {
	pointer-events: none;
	background-color: var(--main-color-disabled);
	background-repeat: no-repeat;
	background-image: linear-gradient(to left, var(--main-color-success), var(--main-color-success));
	background-position: 0 0;
	background-size: 0% 100%;
}
</style>
