<template>
	<div class="rt-wrap-content">
		<div class="rt-wrap-tree">
			<div id="revisionTree" class="rt-tree"></div>
		</div>
		<div v-if="dataType !== 'package'" class="rt-compare-revisions">
			<button v-if="!comparisonData" class="app-default-btn" @click="showCompareScreen = true">
				{{ $t('rtCompareRevisions') }} <i class="fa-solid fa-code-compare"></i>
			</button>
			<button v-else class="app-default-btn" @click="closeComparison">
				{{ $t('rtCloseCompareRevisions') }} <i class="fa-solid fa-circle-xmark"></i>
			</button>
			<div v-if="showCompareScreen" class="rt-comparison">
				<div class="rt-comparison-selection">
					<i class="fa-solid fa-circle-xmark" @click="showCompareScreen = false"></i>
					<h3>{{ $t('rtRevisionSelection') }}</h3>
					<select v-model="compareRevision">
						<option :value="null" disabled selected hidden>{{ $t('rtSelectRevisionForComparison') }}</option>
						<option
							v-for="revision in sortedRevisions.filter((rev) => rev.revisionID !== selectedData.revisionID)"
							:key="revision.revisionID"
							:value="revision"
						>
							{{ revision.name }} ({{ `R${revision.revisionID.split('R')[1]}` }})
						</option>
						<option v-if="sortedRevisions.length <= 1" disabled>{{ $t('rtNoRevisionsAvailable') }}</option>
					</select>
					<div class="rt-wrap-revisions">
						<div class="rt-current-revision">
							<p>{{ $t('rtCurrentRevision') }}</p>
							<div class="rt-elements-link">
								<p>{{ selectedData.name }}</p>
								<p>{{ `R${selectedData.revisionID.split('R')[1]}` }}</p>
								<p>{{ $global.parseDateFull(selectedData.creation) }}</p>
							</div>
						</div>

						<div class="rt-compare-revision">
							<p>{{ $t('rtCompareRevision') }}</p>
							<div v-if="compareRevision" class="rt-elements-link">
								<p>{{ compareRevision.name }}</p>
								<p>{{ `R${compareRevision.revisionID.split('R')[1]}` }}</p>
								<p>{{ $global.parseDateFull(compareRevision.creation) }}</p>
							</div>
							<div v-else class="rt-no-revision">{{ $t('rtNoRevisionSelected') }}</div>
						</div>
					</div>
					<div class="rt-compare">
						<button
							:class="[isLoading ? 'app-disabled-btn' : compareRevision ? 'app-success-btn' : 'app-disabled-btn']"
							@click="compareRevisions"
						>
							{{ $t('rtCompare') }} <i v-if="isLoading" class="fa-solid fa-spinner rt-loading"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="selectedData && !comparisonData" class="rt-details">
			<h3>{{ $t('rtDetails') }}</h3>
			<div class="rt-general-information">
				<div class="rt-left-col">
					<RevisionDetails
						:selectedData="selectedData"
						:applications="applications"
						:frameworks="frameworks"
						:dataType="dataType"
					/>
				</div>
				<div v-if="selectedData.files" class="rt-right-col">
					<div class="rt-right-col-header">
						{{ $t('rtMeta') }}
						<div
							v-for="(meta, idx) in selectedData.files.meta"
							:key="idx"
							:class="[meta.name == selectedData.files.meta[metaIdx].name ? 'rt-tag-selected' : '', 'rt-tag']"
							@click="metaIdx = idx"
						>
							{{ idx + 1 }}
						</div>
					</div>
					<COVCOMetaInformation v-if="application == 'covco'" :metaToEdit="metaData" :viewMode="true" />
				</div>
			</div>
			<h4 v-if="selectedData.pipelines" class="rt-wrap-elements-header">{{ $t('rtUsedPipelines') }}</h4>
			<div v-if="selectedData.pipelines" class="rt-wrap-elements">
				<div
					v-for="pipeline in selectedData.pipelines"
					:key="pipeline.pipelineID"
					class="rt-elements-link"
					@click="$emit('loadPipelineContent', pipeline.pipelineID)"
				>
					<p>{{ pipeline.name }}</p>
					<p>{{ `R${pipeline.revisionID.split('R')[1]}` }}</p>
					<p>{{ $global.parseDateFull(pipeline.creation) }}</p>
				</div>
				<div v-if="selectedData.pipelines.length == 0">
					<p>{{ $t('rtNoPipelines') }}</p>
				</div>
			</div>
			<h4 v-if="selectedData.helper" class="rt-wrap-elements-header">{{ $t('rtUsedHelper') }}</h4>
			<div v-if="selectedData.helper" class="rt-wrap-elements">
				<div class="rt-elements-link" @click="$emit('loadHelperContent', selectedData.helper.helperID)">
					<p>{{ selectedData.helper.name }}</p>
					<p>{{ `R${selectedData.helper.revisionID.split('R')[1]}` }}</p>
					<p>{{ $global.parseDateFull(selectedData.helper.creation) }}</p>
				</div>
			</div>
			<h4 v-if="selectedData.bundles && dataType == 'package'" class="rt-wrap-elements-header">
				{{ $t('rtUsedBundlesPackage') }}
			</h4>
			<h4 v-else-if="selectedData.bundles" class="rt-wrap-elements-header">{{ $t('rtUsedBundles') }}</h4>
			<div v-if="selectedData.bundles" class="rt-wrap-elements">
				<div
					v-for="bundle in selectedData.bundles"
					:key="bundle.bundleID"
					class="rt-elements-link"
					@click="$emit('loadBundleContent', bundle.bundleID)"
				>
					<p>{{ bundle.name }}</p>
					<p>{{ `R${bundle.revisionID.split('R')[1]}` }}</p>
					<p>{{ $global.parseDateFull(bundle.creation) }}</p>
				</div>
				<div v-if="selectedData.bundles.length == 0">
					<p>{{ $t('rtNoBundles') }}</p>
				</div>
			</div>
			<h4 v-if="selectedData.files" class="rt-wrap-elements-header">{{ $t('rtDataFiles') }}</h4>
			<div v-if="selectedData.files" class="rt-wrap-elements">
				<div
					v-for="d in selectedData.files.data"
					:key="d.name"
					class="rt-elements-link"
					@click="$emit('loadDataContent', { packageID: selectedData.packageID, name: d.name })"
				>
					<p>{{ d.name }}</p>
					<p>
						{{ $t('rtCategory') }}:
						<span v-if="d.category == 'cough'">{{ $t('dvCough') }}</span>
						<span v-else-if="d.category == 'breath'">{{ $t('dvBreath') }}</span>
						<span v-else-if="d.category == 'speech'">{{ $t('dvSpeech') }}</span>
						<span v-else>{{ $t('dvNoCategory') }}</span>
					</p>
					<p>{{ $global.parseDateFull(d.creation) }}</p>
				</div>
				<div v-if="selectedData.files.data.length == 0">
					<p>{{ $t('rtNoFiles') }}</p>
				</div>
			</div>
			<div class="rt-wrap-files">
				<div v-if="selectedData.b64File" class="rt-text-content">
					<h4>{{ $t('rtHelperFile') }}</h4>
					<pre>{{ getTextContent(selectedData.b64File) }}</pre>
				</div>
				<div v-if="selectedData.paths" class="rt-text-content">
					<h4>{{ $t('rtFolderStructure') }}</h4>
					<div class="rt-wrap-file-tree">
						<div v-for="path in sortPaths(selectedData.paths)" :key="path" class="rt-tree-component">
							<p
								v-if="!path.includes('.')"
								:style="{ paddingLeft: `${20 * (path.split('/').length - 4) + 20}px` }"
								class="rt-folder"
							>
								<i class="fa-solid fa-folder"></i> {{ getFolderName(path) }}
							</p>
							<p
								v-else
								:style="{
									paddingLeft: `${20 * (path.split('/').length - 3) + 20}px`,
								}"
								class="rt-file"
							>
								<i class="fa-solid fa-file"></i> {{ getFileName(path) }}
							</p>
						</div>
					</div>
				</div>
				<div v-if="selectedData.b64Requirements" class="rt-text-content">
					<h4 v-if="dataType == 'helper'">{{ $t('rtHelperRequirements') }}</h4>
					<h4 v-if="dataType == 'pipeline'">{{ $t('rtPipelineRequirements') }}</h4>
					<pre>{{ getTextContent(selectedData.b64Requirements) }}</pre>
				</div>
			</div>
		</div>
		<div v-if="comparisonData">
			<DiffViewer
				:comparisonData="comparisonData"
				:applications="applications"
				:frameworks="frameworks"
				:dataType="dataType"
				@loadPipelineContent="$emit('loadPipelineContent', $event)"
				@loadHelperContent="$emit('loadHelperContent', $event)"
				@loadBundleContent="$emit('loadBundleContent', $event)"
				@loadDataContent="$emit('loadDataContent', $event)"
			/>
		</div>
	</div>
</template>

<script>
import RevisionDetails from './RevisionDetails.vue';
import COVCOMetaInformation from '../upload/COVCOMetaInformation.vue';
import DiffViewer from './DiffViewer.vue';
import axios from 'axios';
/**
 * @group Data
 * Displays a revision tree and details about a selected revision
 */
export default {
	name: 'RevisionTree',
	components: { RevisionDetails, COVCOMetaInformation, DiffViewer },
	props: {
		revisionData: {
			type: Object,
			required: true,
		},
		dataType: {
			type: String,
			required: true,
		},
		application: {
			type: String,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
	},
	emits: ['loadPipelineContent', 'loadHelperContent', 'loadBundleContent', 'loadDataContent'],
	watch: {
		revisionData: {
			handler: function (newVal) {
				this.compareRevision = null;
				this.comparisonData = null;
				this.getRevisionTree();
			},
		},
		deep: true,
	},
	data() {
		return {
			showCompareScreen: false,
			selectedData: null,
			metaIdx: 0,
			chartConfig: {
				chart: {
					container: '#revisionTree',
					callback: {
						onTreeLoaded: this.onTreeLoaded,
					},
					rootOrientation: 'WEST',
					connectors: {
						type: 'step',
					},
					node: {
						HTMLclass: 'rt-revision-tree',
						collapsable: false,
					},
					padding: 0,
				},
				nodeStructure: null,
			},
			revisionTreeHandle: null,
			sortedRevisions: null,
			compareRevision: null,
			comparisonData: null,
			isLoading: false,
		};
	},
	computed: {
		metaData() {
			return {
				packageID: this.selectedData.packageID,
				meta: this.selectedData.files.meta[this.metaIdx].content,
				name: this.selectedData.files.meta[this.metaIdx].name,
			};
		},
	},
	mounted() {
		if (this.revisionData) this.getRevisionTree();
	},
	beforeDestroy() {
		this.revisionTreeHandle = null;
	},
	methods: {
		// @vuese
		// Prepares the revision data and generates the revision tree data structure
		getRevisionTree() {
			let allRevisions = this.revisionData.data.filter((d) =>
				d.revisionID.includes(this.revisionData.revisionID.substring(0, 36))
			);
			allRevisions.sort(this.sortRevisions);

			this.sortedRevisions = sortPaths(
				allRevisions,
				function (item) {
					return item.revisionID;
				},
				'.'
			);

			let parent = {
				text: {
					name: this.sortedRevisions[0].name ? this.sortedRevisions[0].name : this.$t('rtDataPackage'),
					title: `R${this.getRevisionNumber(this.sortedRevisions[0].revisionID)}`,
					desc: this.$global.parseDateFull(this.sortedRevisions[0].creation),
				},
				HTMLid: this.sortedRevisions[0].revisionID,
				collapsable: false,
				collapsed: false,
				stackChildren: false,
				children: [],
			};
			let nodeStructure = parent;
			if (allRevisions.length > 0) nodeStructure = this.generateNodeStructure(allRevisions, parent);

			this.chartConfig.nodeStructure = nodeStructure;
			this.revisionTreeHandle = new Treant(this.chartConfig);
			this.$nextTick(() => {
				document.getElementById(this.revisionData.revisionID).classList.add('rt-revision-tree-selected');
				this.selectedData = this.revisionData.data.filter((d) => d.revisionID == this.revisionData.revisionID)[0];
				this.getSelectedData();
			});
		},
		// @vuese
		// Sorts the revisions by its length and revision numbers
		// @arg a[Object] - The first revision object
		// @arg b[Object] - The second revision object
		// @return [Number] - Can be -1, 0 or 1 depending on the sort result
		sortRevisions(a, b) {
			let partsA = a.revisionID.substring(38).split('.');
			let partsB = b.revisionID.substring(38).split('.');

			let longestPart = partsA.length > partsB.length ? partsA.length : partsB.length;
			let returnVal = 0;
			for (let i = 0; i < longestPart; i++) {
				if (!partsA[i]) {
					returnVal = -1;
					break;
				} else if (!partsB[i]) {
					returnVal = 1;
					break;
				} else if (Number(partsA[i]) > Number(partsB[i])) {
					returnVal = 1;
					break;
				} else if (Number(partsA[i]) < Number(partsB[i])) {
					returnVal = -1;
					break;
				}
			}
			return returnVal;
		},
		// @vuese
		// DFS method to generate the revision tree node structure
		// @arg revisions[Array] - All revisions that need to be added to the node structure
		// @arg parent[Object] - The current parent object to which cild revision nodes should be added
		// @return [Object] - The generated node structure
		generateNodeStructure(revisions, parent) {
			let currentRevisions = revisions.filter((rev) => rev.revisionID.split('.').length == parent.HTMLid.split('.').length + 1);
			let parentRevisionBody = parent.HTMLid.split('.').slice(1).join('.');

			currentRevisions.forEach((rev) => {
				let childRevisionBody = rev.revisionID.split('.').slice(1, -1).join('.');

				if (parentRevisionBody == childRevisionBody) {
					let child = this.generateNode(rev);
					parent.children.push(this.generateNodeStructure(revisions, child));
				}
			});

			return parent;
		},
		// @vuese
		// Generates the json structure of a revision tree node
		// @arg rev[Object] - The revision object
		// @return [Object] - The revision tree node
		generateNode(rev) {
			return {
				text: {
					name: rev.name ? rev.name : this.$t('rtNoName'),
					title: `R${this.getRevisionNumber(rev.revisionID)}`,
					desc: this.$global.parseDateFull(rev.creation),
				},
				HTMLid: rev.revisionID,
				collapsable: false,
				collapsed: false,
				stackChildren: false,
				children: [],
			};
		},
		// @vuese
		// Returns the revisionID number, which comes after the uuid part of the revisionID
		// @arg rev[Object] - The revisionID
		// @return [Object] - The revisionID number
		getRevisionNumber(revisionID) {
			return revisionID.substring(38);
		},
		// @vuese
		// Listener for the revision tree load event to add click listener to every node
		onTreeLoaded() {
			let that = this;
			const nodes = document.querySelectorAll('.Treant > .node');
			nodes.forEach((node) => {
				node.addEventListener('click', function (e) {
					let id = null;
					let nodes = document.querySelectorAll('.Treant > .node');
					nodes.forEach((node) => {
						node.classList.remove('rt-revision-tree-selected');
						node.classList.remove('rt-compare-node');
					});
					if (e.target.classList.contains('node')) {
						e.target.classList.add('rt-revision-tree-selected');
						id = e.target.id;
					} else {
						e.target.closest('.node').classList.add('rt-revision-tree-selected');
						id = e.target.closest('.node').id;
					}

					that.comparisonData = null;
					that.compareRevision = null;

					that.selectedData = that.revisionData.data.filter((d) => d.revisionID == id)[0];
					that.getSelectedData();
				});
			});
		},
		// @vuese
		// Queries the data for the selected revision
		getSelectedData() {
			if (this.dataType == 'helper') this.queryHelper(this.selectedData.helperID);
			else if (this.dataType == 'package') this.queryPackage(this.selectedData.packageID);
			else if (this.dataType == 'bundle') this.queryBundle(this.selectedData.bundleID);
			else if (this.dataType == 'pipeline') this.queryPipeline(this.selectedData.pipelineID);
		},
		// @vuese
		// Queries a single helper by its helperID
		// @arg helperID[String] - The helperID
		queryHelper(helperID) {
			this.$cr.getHelper(helperID, (err, result) => {
				if (!err) this.selectedData = result;
			});
		},
		// @vuese
		// Queries a single package by its packageID
		// @arg packageID[String] - The packageID
		queryPackage(packageID) {
			this.$cr.getPackage(packageID, (err, result) => {
				if (!err) this.selectedData = result;
			});
		},
		// @vuese
		// Queries a single bundle by its bundleID
		// @arg bundleID[String] - The bundleID
		queryBundle(bundleID) {
			this.$cr.getBundle(bundleID, (err, result) => {
				if (!err) this.selectedData = result;
			});
		},
		// @vuese
		// Queries a single pipeline by its pipelineID
		// @arg pipelineID[String] - The pipelineID
		queryPipeline(pipelineID) {
			this.$cr.getPipeline(pipelineID, (err, result) => {
				if (!err) this.selectedData = result;
			});
		},
		// @vuese
		// Gets the text content from a base64 representation of a text file
		// @arg base64[String] - The base64 json
		// @returns [String] The text content
		getTextContent(base64) {
			try {
				return decodeURIComponent(escape(atob(base64.split(', ').pop())));
			} catch (error) {
				return this.$t('rtInvalidContent');
			}
		},
		// @vuese
		// Sorts the given paths by its directory tree structure
		// @arg paths[Array] - All paths to be sorted
		// @return [Array] - The sorted paths
		sortPaths(paths) {
			let sortedPaths = sortPaths(paths, '/');
			return sortedPaths;
		},
		// @vuese
		// Parses the folder name from the given path
		// @arg path[String] - The folder path
		// @returns [String] - The folder name
		getFolderName(path) {
			let parts = path.split('/');
			if (parts.length > 0) return parts[parts.length - 2];
			else return '/';
		},
		// @vuese
		// Parses the file name from the given path
		// @arg path[String] - The file path
		// @returns [String] - The file name
		getFileName(path) {
			return path.split('/').pop();
		},
		// @vuese
		// Closes the comparison view
		closeComparison() {
			document.getElementById(this.compareRevision.revisionID).classList.remove('rt-compare-node');
			this.compareRevision = null;
			this.comparisonData = null;
		},
		// @vuese
		// Queries the data for the revision comparison
		compareRevisions() {
			this.isLoading = true;
			document.getElementById(this.compareRevision.revisionID).classList.add('rt-compare-node');
			let firstID =
				this.dataType == 'bundle'
					? this.selectedData.bundleID
					: this.dataType == 'pipeline'
					? this.selectedData.pipelineID
					: this.selectedData.helperID;
			let secondID =
				this.dataType == 'bundle'
					? this.compareRevision.bundleID
					: this.dataType == 'pipeline'
					? this.compareRevision.pipelineID
					: this.compareRevision.helperID;

			axios
				.get(`/confAPI/data/revision-diff`, {
					headers: { userid: this.$global.getUser().userID },
					auth: JSON.parse(sessionStorage.getItem('credentials')),
					params: {
						firstRevision: { id: firstID, revisionID: this.selectedData.revisionID },
						secondRevision: { id: secondID, revisionID: this.compareRevision.revisionID },
						dataType: this.dataType,
					},
				})
				.then((res) => {
					this.comparisonData = res.data;
					this.showCompareScreen = false;
					this.isLoading = false;
				})
				.catch((err) => {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					this.$global.showToast('error', this.$t(msg ? msg : 'rtDiffQueryError'));
					this.isLoading = false;
				});
		},
	},
};
</script>

<style scoped>
.rt-wrap-content {
	box-sizing: border-box;
}

.rt-wrap-tree {
	max-width: 100%;
	padding: 10px 0px;
	overflow: auto;
}

.rt-tree {
	height: fit-content;
	width: fit-content;
	margin: auto;
}

.rt-details {
	margin-top: 15px;
}

.rt-details h3 {
	font-size: 22px;
}

.rt-general-information {
	width: 100%;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
	box-sizing: border-box;
}

.rt-left-col,
.rt-right-col {
	flex: 1 1 50%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: wrap;
}

.rt-right-col-header {
	margin: 0px 0px 3px 0px;
	padding-bottom: 3px;
	font-weight: bold;
	font-size: 18px;
	border-bottom: 1px solid var(--main-color-border-light);
}

.rt-tag {
	margin: 0px 1px;
	padding: 5px 3px;
	position: relative;
	display: inline-block;
	border: 1px solid var(--main-color-border-dark);
	box-sizing: border-box;
	color: var(--main-color-text-dark);
	background-color: var(--main-color-5);
}

.rt-tag:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-light);
}

.rt-tag-selected {
	font-size: 20px;
	background-color: var(--main-color-6-cc);
}

.rt-tag-selected:hover {
	cursor: default;
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.rt-wrap-elements-header {
	margin-left: 10px;
}

.rt-wrap-elements {
	max-height: 130px;
	margin: 5px;
	padding: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: wrap;
	box-sizing: border-box;
	overflow: auto;
}

.rt-elements-link {
	margin: 5px;
}

.rt-elements-link > p:first-child {
	font-weight: bold;
}

.rt-elements-link:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
}

.rt-wrap-meta-content {
	display: flex;
	flex-flow: wrap;
}

.rt-parsed-json {
	flex: 1 1 150px;
	padding: 5px;
	margin: 5px;
	width: fit-content;
	vertical-align: top;
}

.rt-parsed-json p {
	font-size: 17px;
	font-weight: normal;
	text-decoration: none;
	border: none;
}
.rt-parsed-json p:first-child {
	text-decoration: underline;
}

.rt-wrap-files {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-flow: wrap;
}

.rt-text-content {
	height: 400px;
	flex: 1 1 300px;
	min-width: 300px;
	padding: 5px;
	margin: 5px;
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
	background-color: var(--main-color-4);
	color: var(--main-color-text-dark);
}

.rt-text-content > h4 {
	width: 100%;
	position: sticky;
	top: 0px;
	left: 0px;
	margin-bottom: 10px;
	padding: 5px;
	box-sizing: border-box;
	text-decoration: underline;
	z-index: 2;
	background-color: var(--main-color-5-cc);
	color: var(--main-color-text-dark);
}

.rt-folder,
.rt-file {
	position: relative;
	padding-left: 20px;
	box-sizing: border-box;
}

.rt-folder i {
	font-size: 20px;
	color: var(--main-color-6);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-border-dark);
}

.rt-file i {
	font-size: 20px;
	color: var(--main-color-info);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-border-dark);
}

.rt-compare-revisions {
	width: 100%;
	height: fit-content;
	text-align: center;
}

.rt-compare-revisions button {
	margin: 10px auto;
	font-size: 17px;
}

.rt-compare-revisions button i {
	margin-left: 5px;
}

.rt-comparison {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
	background-color: var(--main-color-dark-80);
}

.rt-comparison-selection {
	width: 500px;
	max-width: 90%;
	padding: 10px 20px;
	position: relative;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	background-color: var(--main-color-4);
	animation: slideIn 0.4s linear;
}

.rt-comparison-selection i {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 30px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.rt-comparison-selection i:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.rt-comparison-selection h3 {
	width: fit-content;
	margin: 5px auto 10px auto;
	font-size: 25px;
	color: var(--main-color-6);
}

.rt-comparison-selection select {
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.rt-wrap-revisions {
	width: 100%;
	margin: 10px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.rt-current-revision,
.rt-compare-revision {
	flex: 1 1 200px;
	vertical-align: top;
}

.rt-current-revision > p:first-child,
.rt-compare-revision > p:first-child {
	margin: 5px auto;
	font-size: 17px;
	text-decoration: underline;
}

.rt-current-revision .rt-elements-link,
.rt-compare-revision .rt-elements-link {
	text-align: start;
	margin: auto;
}

.rt-no-revision {
	height: 55px;
}
</style>
<style>
.Treant svg path {
	stroke: white;
}

.Treant > .node {
}
.Treant > p {
	font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
	font-weight: bold;
	font-size: 12px;
}
.node-name {
	font-weight: bold;
}

.rt-revision-tree,
.rt-elements-link {
	width: fit-content;
	min-width: 150px;
	padding: 3px;
	border-radius: 5px;
	font-size: 13px;
	border: 1px solid var(--main-color-5);
	color: var(--main-color-text-light);
	background-color: var(--main-color-3);
}

.rt-elements-link span {
	display: inline-block;
}

.rt-revision-tree:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.rt-revision-tree-selected {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.rt-revision-tree > p {
	color: var(--main-color-text-light);
}

.rt-wrap-file-tree {
	width: fit-content;
	min-width: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
	background-color: var(--main-color-4);
}

.rt-tree-component {
	background-color: var(--main-color-4);
	white-space: nowrap;
}

.rt-wrap-file-tree p {
	padding: 2px 0px;
	user-select: none;
}

.rt-compare-node {
	background-color: var(--main-color-success);
}

.rt-loading {
	position: static !important;
	font-size: 20px !important;
	color: var(--main-color-text-light) !important;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}
</style>
