<template>
	<div class="hd-wrap-content">
		<h3>{{ $t('hdHelperFileDiff') }}</h3>
		<div class="hd-wrap-helper-diff">
			<div class="hd-wrap-first-file">
				<h4>
					{{ $t('hdFirstRev') }} <i class="fa-solid fa-maximize hd-maximise" @click="maximise('firstFile')"></i>
					<i class="fa-solid fa-minimize hd-minimise" @click="minimise('file')"></i>
				</h4>
				<pre>{{ revisionDiff.firstFile }}</pre>
			</div>
			<div class="hd-wrap-file-diff">
				<h4>
					{{ $t('hdReqDiff') }} <i class="fa-solid fa-maximize hd-maximise" @click="maximise('fileDiff')"></i>
					<i class="fa-solid fa-minimize hd-minimise" @click="minimise('file')"></i>
				</h4>
				<div v-for="(entry, valIdx) in revisionDiff.fileDiff" :key="valIdx">
					<div
						v-for="(line, idx) in getValidLines(entry.value)"
						:key="idx"
						:class="['hd-req-line', entry.added ? 'hd-added' : entry.removed ? 'hd-removed' : 'hd-normal']"
					>
						<pre>{{ line }}</pre>
					</div>
				</div>
			</div>
			<div class="hd-wrap-second-file">
				<h4>
					{{ $t('hdSecondRev') }} <i class="fa-solid fa-maximize hd-maximise" @click="maximise('secondFile')"></i>
					<i class="fa-solid fa-minimize hd-minimise" @click="minimise('file')"></i>
				</h4>
				<pre>{{ revisionDiff.secondFile }}</pre>
			</div>
		</div>
		<h3>{{ $t('hdHelperReqDiff') }}</h3>
		<div class="hd-wrap-requirements-diff">
			<div class="hd-wrap-first-req">
				<h4>
					{{ $t('hdFirstRev') }} <i class="fa-solid fa-maximize hd-maximise" @click="maximise('firstReq')"></i>
					<i class="fa-solid fa-minimize hd-minimise" @click="minimise('req')"></i>
				</h4>
				<pre>{{ revisionDiff.firstReq }}</pre>
			</div>
			<div class="hd-wrap-req-diff">
				<h4>
					{{ $t('hdReqDiff') }}<i class="fa-solid fa-maximize hd-maximise" @click="maximise('reqDiff')"></i>
					<i class="fa-solid fa-minimize hd-minimise" @click="minimise('req')"></i>
				</h4>
				<div v-for="(entry, valIdx) in revisionDiff.reqDiff" :key="valIdx">
					<div
						v-for="(line, idx) in getValidLines(entry.value)"
						:key="idx"
						:class="['hd-req-line', entry.added ? 'hd-added' : entry.removed ? 'hd-removed' : 'hd-normal']"
					>
						<pre>{{ line }}</pre>
					</div>
				</div>
			</div>
			<div class="hd-wrap-second-req">
				<h4>
					{{ $t('hdSecondRev') }}<i class="fa-solid fa-maximize hd-maximise" @click="maximise('secondReq')"></i>
					<i class="fa-solid fa-minimize hd-minimise" @click="minimise('req')"></i>
				</h4>
				<pre>{{ revisionDiff.secondReq }}</pre>
			</div>
		</div>
		<div class="hd-legend">
			<div class="hd-legend-element">
				<div class="hd-circle hd-info"></div>
				<p>{{ $t('hdSame') }}</p>
			</div>
			<div class="hd-legend-element">
				<div class="hd-circle hd-success"></div>
				<p>{{ $t('hdAdded') }}</p>
			</div>
			<div class="hd-legend-element">
				<div class="hd-circle hd-error"></div>
				<p>{{ $t('hdRemoved') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group DiffViewer
 * Displays the differences between the helper requirements and file
 */
export default {
	name: 'HelperDiff',
	props: {
		revisionDiff: {
			type: Object,
			required: true,
		},
	},
	methods: {
		// @vuese
		// Returns the valid lines from a file that can be displayed line by line
		// @arg line[String] - The line
		// @returns [Array] - The valid lines that can be displayed
		getValidLines(line) {
			let lines = line.split('\r\n').filter((x, idx) => {
				if (x !== '') return true;
				else if (x == '' && idx == 0) return true;
				else return false;
			});
			return lines;
		},
		// @vuese
		// Sorts the given paths by its directory tree structure
		// @arg paths[Array] - All paths to be sorted
		// @return [Array] - The sorted paths
		sortPaths(paths) {
			let sortedPaths = sortPaths(paths, '/');
			return sortedPaths;
		},
		// @vuese
		// Maximises the diff display column
		// @arg id[String] - Specifies which colum is maximised
		maximise(id) {
			if (['firstFile', 'fileDiff', 'secondFile'].includes(id)) this.minimise('file');
			else if (['firstReq', 'reqDiff', 'secondReq'].includes(id)) this.minimise('req');

			if (id == 'firstFile') {
				document.querySelector('.hd-wrap-first-file').classList.add('hd-expand-full');
				document.querySelector('.hd-wrap-file-diff').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-second-file').classList.add('hd-shrink');
			} else if (id == 'fileDiff') {
				document.querySelector('.hd-wrap-first-file').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-file-diff').classList.add('hd-expand-full');
				document.querySelector('.hd-wrap-second-file').classList.add('hd-shrink');
			} else if (id == 'secondFile') {
				document.querySelector('.hd-wrap-first-file').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-file-diff').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-second-file').classList.add('hd-expand-full');
			} else if (id == 'firstReq') {
				document.querySelector('.hd-wrap-first-req').classList.add('hd-expand-full');
				document.querySelector('.hd-wrap-req-diff').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-second-req').classList.add('hd-shrink');
			} else if (id == 'reqDiff') {
				document.querySelector('.hd-wrap-first-req').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-req-diff').classList.add('hd-expand-full');
				document.querySelector('.hd-wrap-second-req').classList.add('hd-shrink');
			} else if (id == 'secondReq') {
				document.querySelector('.hd-wrap-first-req').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-req-diff').classList.add('hd-shrink');
				document.querySelector('.hd-wrap-second-req').classList.add('hd-expand-full');
			}
		},
		// @vuese
		// Minimises the diff display column
		// @arg id[String] - Specifies which colum is minimised
		minimise(id) {
			if (id == 'file') {
				document.querySelectorAll('.hd-wrap-helper-diff .hd-shrink').forEach((element) => {
					element.classList.remove('hd-shrink');
				});
				document.querySelectorAll('.hd-wrap-helper-diff .hd-expand-full').forEach((element) => {
					element.classList.remove('hd-expand-full');
				});
			} else if (id == 'req') {
				document.querySelectorAll('.hd-wrap-requirements-diff .hd-shrink').forEach((element) => {
					element.classList.remove('hd-shrink');
				});
				document.querySelectorAll('.hd-wrap-requirements-diff .hd-expand-full').forEach((element) => {
					element.classList.remove('hd-expand-full');
				});
			}
		},
	},
};
</script>

<style scoped>
.hd-wrap-content {
}
.hd-wrap-helper-diff,
.hd-wrap-requirements-diff {
	margin: 5px 0px 10px 0px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
}

.hd-wrap-first-file,
.hd-wrap-file-diff,
.hd-wrap-second-file,
.hd-wrap-first-req,
.hd-wrap-req-diff,
.hd-wrap-second-req {
	height: 400px;
	flex: 1 1 300px;
	min-width: 300px;
	padding: 5px;
	margin: 5px;
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
	background-color: var(--main-color-4);
	color: var(--main-color-text-dark);
}
.hd-wrap-first-file pre,
.hd-wrap-second-file pre,
.hd-wrap-first-req pre,
.hd-wrap-second-req pre {
	line-height: 23px;
}

h4 {
	width: 100%;
	position: sticky;
	top: 0px;
	left: 0px;
	margin-bottom: 10px;
	padding: 5px;
	box-sizing: border-box;
	text-decoration: underline;
	z-index: 2;
	background-color: var(--main-color-5-cc);
	color: var(--main-color-text-dark);
}

h4 i {
	margin: 0px 3px;
	float: right;
	font-size: 20px;
}

h4 i:hover {
	cursor: pointer;
	color: var(--main-color-text-light);
}

.hd-req-line {
	margin: 5px auto;
}
.hd-added {
	background-color: var(--main-color-success-80);
}
.hd-removed {
	background-color: var(--main-color-error-80);
}
.hd-modified {
	background-color: var(--main-color-warn-80);
}
.hd-normal {
	background-color: var(--main-color-info-80);
}

.hd-legend {
	width: fit-content;
	margin: 10px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.hd-legend-element {
	flex: 1 1 fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.hd-legend-element p {
	display: inline-block;
	margin: 0px 20px 0px 5px;
}

.hd-circle {
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 50%;
	border: 1px solid var(--main-color-border-dark);
}

.hd-info {
	background-color: var(--main-color-info);
}

.hd-success {
	background-color: var(--main-color-success);
}

.hd-warn {
	background-color: var(--main-color-warn);
}

.hd-error {
	background-color: var(--main-color-error);
}

.hd-minimise {
	pointer-events: none;
	cursor: default;
	color: var(--main-color-disabled);
}

.hd-expand-full {
	flex: none !important;
	width: calc(100% - 90px);
	height: 413px;
	margin: 5px !important;
	display: inline-block !important;
	vertical-align: top !important;
	box-sizing: border-box !important;
	/* flex: 1 1 calc(100% - 50px);
	max-width: calc(100% - 120px); */
}

.hd-expand-full .hd-maximise {
	pointer-events: none;
	cursor: default;
	color: var(--main-color-disabled);
}

.hd-expand-full .hd-minimise {
	pointer-events: all;
	cursor: pointer;
	color: var(--main-color-text-dark);
}

.hd-expand-full .hd-minimise:hover {
	color: var(--main-color-text-light);
}

.hd-shrink {
	flex: none !important;
	width: 30px !important;
	min-width: 0px !important;
	height: 413px;
	margin: 5px !important;
	padding: 0px;
	display: inline-block !important;
	vertical-align: top !important;
	box-sizing: border-box !important;
	overflow: hidden !important;
}

.hd-shrink h4 {
	height: 30px;
	width: 413px;
	margin: 0px;
	padding-left: 10px;
	position: relative;
	transform: rotate(-90deg) translate(-189px, -193px);
	display: block;
}

.hd-shrink div,
.hd-shrink pre {
	display: none;
}

.hd-shrink .hd-minimise {
	pointer-events: none;
	cursor: default;
	color: var(--main-color-disabled);
}
</style>
