<template>
	<div class="ap-wrap-content">
		<div class="ap-toast-wrapper">
			<div class="ap-toast-animation" @click="$global.abortAnimation">
				<div class="ap-toast ap-info">
					<i class="fas fa-info-circle ap-toast-icon" />
					<p class="ap-info-text"></p>
				</div>
				<div class="ap-toast ap-success">
					<i class="fas fa-check-circle ap-toast-icon" />
					<p class="ap-success-text"></p>
				</div>
				<div class="ap-toast ap-warn">
					<i class="fas fa-exclamation-circle ap-toast-icon" />
					<p class="ap-warn-text"></p>
				</div>
				<div class="ap-toast ap-error">
					<i class="fas fa-times-circle ap-toast-icon" />
					<p class="ap-error-text"></p>
				</div>
				<div class="ap-toast-progress">
					<span style="width: 100%"><span class="ap-progress"></span></span>
				</div>
			</div>
			<div class="ap-sticky-toast-wrapper"></div>
		</div>
		<div v-if="info" class="ap-info-field">
			<i class="fa-solid fa-circle-xmark" @click="info = null"></i>
			<h2 v-html="info.title"></h2>
			<p v-html="info.text"></p>
		</div>
		<Header />
		<transition name="fade" mode="out-in">
			<router-view :key="$route.fullPath" @setTheme="setTheme" @showInfo="showInfo">
				<Login />
			</router-view>
		</transition>
	</div>
</template>

<script>
import Login from './views/Login.vue';
import Header from './components/layout/Header.vue';

/**
 * @group App
 * Wraps all components and provides global styles
 */
export default {
	name: 'App',
	components: {
		Login,
		Header,
	},
	watch: {
		$route: {
			handler: function (newVal) {
				window.setTimeout(() => {
					this.$socket.handleRouteChange(this.$route.name);
				}, 100);
			},
		},
	},
	data() {
		return {
			info: null,
			theme: null,
		};
	},
	created() {
		this.initialTheme();
		this.$global.initializeGlobal(this);
		this.$cr.initializeGlobal(this);
		this.$socket.initializeGlobal(this);
		this.$socket.getConnectionInfo();
		this.setInitialVars();
	},
	beforeDestroy() {
		this.$socket.closeSocketConnection();
	},
	methods: {
		// @vuese
		// Sets the inital theme of the app if available
		initialTheme() {
			if (!localStorage.getItem('theme')) localStorage.setItem('theme', 'dark');
			this.theme = localStorage.getItem('theme')
				? localStorage.getItem('theme')
				: sessionStorage.getItem('theme')
				? sessionStorage.getItem('theme')
				: 'dark';
			let htmlElement = document.documentElement;
			htmlElement.setAttribute('theme', this.theme);
		},
		// @vuese
		// Restores the user on reload if logged in
		setInitialVars() {
			if (sessionStorage.getItem('user')) this.$global.setUser(JSON.parse(sessionStorage.getItem('user')));
		},
		// @vuese
		// Changes the theme of the app
		// @arg theme[String] - The set theme
		setTheme(theme) {
			let htmlElement = document.documentElement;
			this.theme = theme;
			htmlElement.setAttribute('theme', theme);
			localStorage.setItem('theme', theme);
		},
		// @vuese
		// Shows a information text at the top of the page
		// @arg info[String] - The info displayed on the page
		showInfo(info) {
			this.info = info;
		},
	},
};
</script>

<style scoped>
/* Basic style of the app */
.ap-wrap-content {
	height: 100%;
	width: 100%;
	padding-top: 50px;
	position: relative;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: hidden;
}

.ap-info-field {
	width: 100vw;
	height: fit-content;
	min-height: 60px;
	max-height: 50vh;
	padding: 20px 40px 20px 40px;
	position: absolute;
	top: 50px;
	left: 0px;
	box-sizing: border-box;
	z-index: 30;
	background-color: var(--main-color-dark-cc);
	color: var(--main-color-text-light);
	overflow: auto;
}

.ap-info-field i {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 30px;
	-webkit-text-stroke: 1px solid var(--main-color-border-dark);
	color: var(--main-color-error);
}

.ap-info-field i:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.ap-info-field h2 {
	margin-bottom: 10px;
	font-size: 20px;
	text-decoration: underline;
}

.ap-info-field p {
	font-size: 17px;
	margin: 0px;
}

.ap-toast-wrapper {
	width: 100vw;
	height: fit-content;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 101;
}
.ap-toast-animation:hover,
.ap-toast-animation:hover .ap-toast-progress .ap-progress {
	cursor: pointer;
	-webkit-animation-play-state: paused;
	-moz-animation-play-state: paused;
	-ms-animation-play-state: paused;
	-o-animation-play-state: paused;
	animation-play-state: paused;
}
.ap-toast-wrapper .ap-toast {
	width: 100%;
	height: fit-content;
	max-height: 200px;
	padding: 10px 40px;
	display: none;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	color: var(--main-color-text-light);
}
.ap-toast-animation {
	display: none;
	-webkit-animation: fadeout 3s forwards; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeout 3s forwards; /* Firefox < 16 */
	-ms-animation: fadeout 3s forwards; /* Internet Explorer */
	-o-animation: fadeout 3s forwards; /* Opera < 12.1 */
	animation: fadeout 3s forwards;
}
.ap-toast-progress {
	width: 100%;
	display: none;
	height: 5px;
	position: relative;
	background: var(--main-color-1);
	overflow: hidden;
}
.ap-toast-progress span {
	display: block;
	height: 100%;
}
.ap-progress {
	-webkit-animation: progressBar 3s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
	-webkit-animation-fill-mode: both;
	-moz-animation: progressBar 3s ease-in-out; /* Firefox < 16 */
	-moz-animation-fill-mode: both;
	-ms-animation: progressBar 3s ease-in-out; /* Internet Explorer */
	-ms-animation-fill-mode: both;
	-o-animation: progressBar 3s ease-in-out; /* Opera < 12.1 */
	-o-animation-fill-mode: both;
	animation: progressBar 3s ease-in-out;
	animation-fill-mode: both;
}
.show-info .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-info);
}
.show-success .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-success);
}
.show-warn .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-warn);
}
.show-error .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-error);
}
.show-info .ap-toast-animation {
	display: block !important;
}
.show-info .ap-toast-animation .ap-info,
.show-info .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}
.show-success .ap-toast-animation {
	display: block !important;
}
.show-success .ap-toast-animation .ap-success,
.show-success .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}
.show-warn .ap-toast-animation {
	display: block !important;
}
.show-warn .ap-toast-animation .ap-warn,
.show-warn .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}
.show-error .ap-toast-animation {
	display: block !important;
}
.show-error .ap-toast-animation .ap-error,
.show-error .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}

.ap-toast-wrapper .ap-info {
	background-color: var(--main-color-info);
}
.ap-toast-wrapper .ap-success {
	background-color: var(--main-color-success);
}
.ap-toast-wrapper .ap-warn {
	background-color: var(--main-color-warn);
}
.ap-toast-wrapper .ap-error {
	background-color: var(--main-color-error);
}
.ap-toast-icon {
	font-size: 20px;
	margin-right: 10px;
}

.ap-toast p {
	margin: 0px;
}

.ap-sticky-toast-wrapper {
	width: 50px;
	max-height: 330px;
	position: absolute;
	top: 50px;
	right: 5px;
	padding-top: 5px;
	display: flex;
	align-items: flex-end;
	flex-flow: wrap;
	direction: rtl;
	z-index: 40;
	overflow: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Chrome, Safari and Opera */
.ap-sticky-toast-wrapper::-webkit-scrollbar {
	display: none;
}

@keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
/* Firefox < 16 */
@-moz-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
/* Internet Explorer */
@-ms-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
/* Opera < 12.1 */
@-o-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@-moz-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/* Firefox < 16 */
@-moz-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/* Internet Explorer */
@-ms-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/* Opera < 12.1 */
@-o-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* Transition style */
.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.2s;
	transition-property: opacity;
	transition-timing-function: ease-in;
}

/* Transition style */
.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>

<style>
html,
body {
	background-color: var(--main-color-2);
}

* {
	margin: 0;
	padding: 0;
}

*:not(i) {
	color: var(--main-color-text-light);
	font-family: Arial, Helvetica, sans-serif;
}

/* Global color styles */
[theme='dark'] {
	--main-color-1: #0d0d0e;
	--main-color-1-cc: #0d0d0ecc;
	--main-color-1-80: #0d0d0e80;
	--main-color-1-40: #0d0d0e40;

	--main-color-2: #0c1821;
	--main-color-2-cc: #0c1821cc;
	--main-color-2-80: #0c182180;
	--main-color-2-40: #0c182140;

	--main-color-3: #1b2a41;
	--main-color-3-cc: #1b2a41cc;
	--main-color-3-80: #1b2a4180;
	--main-color-3-40: #1b2a4140;

	--main-color-4: #324a5f;
	--main-color-4-cc: #324a5fcc;
	--main-color-4-80: #324a5f80;
	--main-color-4-40: #324a5f40;

	--main-color-5: #878495;
	--main-color-5-cc: #878495cc;
	--main-color-5-80: #87849580;
	--main-color-5-40: #87849540;

	--main-color-6: #e69d4f;
	--main-color-6-cc: #e69d4fcc;
	--main-color-6-80: #e69d4f80;
	--main-color-6-40: #e69d4f40;

	--main-color-text-light: #ffffff;
	--main-color-text-dark: #000000;

	--main-color-border-light: #ffffff;
	--main-color-border-dark: #000000;

	--main-color-dark: #000000;
	--main-color-dark-40: #00000040;
	--main-color-dark-80: #00000080;
	--main-color-dark-cc: #000000cc;

	--main-color-light: #ffffff;
	--main-color-light-40: #ffffff40;
	--main-color-light-80: #ffffff80;
	--main-color-light-cc: #ffffffcc;

	--main-color-info: #0e84be;
	--main-color-info-80: #0e84be80;
	--secondary-color-info: #0c6896;

	--main-color-success: #26c52e;
	--main-color-success-80: #26c52e80;
	--secondary-color-success: #229728;

	--main-color-warn: #fa7901;
	--main-color-warn-80: #fa790180;
	--secondary-color-warn: #cc6816;

	--main-color-error: #c53232;
	--main-color-error-80: #c5323280;
	--secondary-color-error: #b60909;

	--main-color-disabled: #aaaaaa;
	--main-color-disabled-text: #3b3737;

	--main-color-log-timestamp: #0a9443;
	--main-color-log-debug: var(--main-color-disabled);
	--main-color-log-info: var(--main-color-info);
	--main-color-log-warn: var(--main-color-warn);
	--main-color-log-error: var(--main-color-error);
	--main-color-log-logger: var(--main-color-success);
	--main-color-log-caller: #bb12bb;
	--main-color-log-content: var(--main-color-text-light);
	--main-color-highlight: #ffdd1d;
}

[theme='light'] {
	--main-color-1: #f2f2f1;
	--main-color-1-cc: #f2f2f1cc;
	--main-color-1-80: #f2f2f180;
	--main-color-1-40: #f2f2f140;

	--main-color-2: #f3e7de;
	--main-color-2-cc: #f3e7decc;
	--main-color-2-80: #f3e7de80;
	--main-color-2-40: #f3e7de40;

	--main-color-3: #e4d5be;
	--main-color-3-cc: #e4d5becc;
	--main-color-3-80: #e4d5be80;
	--main-color-3-40: #e4d5be40;

	--main-color-4: #cdb5a0;
	--main-color-4-cc: #cdb5a0cc;
	--main-color-4-80: #cdb5a080;
	--main-color-4-40: #cdb5a040;

	--main-color-5: #787b6a;
	--main-color-5-cc: #787b6acc;
	--main-color-5-80: #787b6a80;
	--main-color-5-40: #787b6a40;

	--main-color-6: #1962b0;
	--main-color-6-cc: #1962b0cc;
	--main-color-6-80: #1962b080;
	--main-color-6-40: #1962b040;

	--main-color-text-light: #000000;
	--main-color-text-dark: #ffffff;

	--main-color-border-light: #000000;
	--main-color-border-dark: #ffffff;

	--main-color-dark: #ffffff;
	--main-color-dark-40: #ffffff40;
	--main-color-dark-80: #ffffff80;
	--main-color-dark-cc: #ffffffcc;

	--main-color-light: #000000;
	--main-color-light-40: #00000040;
	--main-color-light-80: #00000080;
	--main-color-light-cc: #000000cc;

	--main-color-info: #0e84be;
	--main-color-info-80: #0e84be80;
	--secondary-color-info: #0c6896;

	--main-color-success: #26c52e;
	--main-color-success-80: #26c52e80;
	--secondary-color-success: #0d9213;

	--main-color-warn: #fa7901;
	--main-color-warn-80: #fa790180;
	--secondary-color-warn: #cc6816;

	--main-color-error: #c53232;
	--main-color-error-80: #c5323280;
	--secondary-color-error: #cc0808;

	--main-color-disabled: #aaaaaa;
	--main-color-disabled-text: #3b3737;

	--main-color-log-timestamp: #0a9443;
	--main-color-log-debug: var(--main-color-disabled);
	--main-color-log-info: var(--main-color-info);
	--main-color-log-warn: var(--main-color-warn);
	--main-color-log-error: var(--main-color-error);
	--main-color-log-logger: var(--main-color-success);
	--main-color-log-caller: #bb12bb;
	--main-color-log-content: var(--main-color-text-light);
	--main-color-highlight: #ffdd1d;
}

/* Global scrollbar style */
::-webkit-scrollbar {
	width: 17px;
	box-sizing: border-box;
}

/* Global scrollbar track style */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px black;
	/* border-radius: 10px; */
	background-color: var(--main-color-4);
}

/* Global scrollbar thumb style */
::-webkit-scrollbar-thumb {
	background: var(--main-color-5);
	/* border-radius: 10px; */
	border: 1px solid black;
}

/* Global scrollbar corner style */
::-webkit-scrollbar-corner {
	background-color: var(--main-color-4);
}

button {
	padding: 5px 10px;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	box-sizing: border-box;
}

button:hover {
	cursor: pointer;
}

button:disabled {
	background-color: var(--main-color-disabled);
	color: var(--main-color-disabled-text);
	pointer-events: none;
}

.app-default-btn {
	border: 1px solid var(--main-color-border-dark);
	color: var(--main-color-text-light);
	background-color: var(--main-color-4);
}

.app-default-btn:hover {
	background-color: var(--main-color-3);
	color: var(--main-color-text-light);
}

.app-success-btn {
	border: 1px solid var(--main-color-border-light);
	color: var(--main-color-text-light);
	background-color: var(--secondary-color-success);
}

.app-success-btn:hover {
	background-color: var(--main-color-success);
	color: var(--main-color-text-light);
}

.app-error-btn {
	border: 1px solid var(--main-color-border-light);
	color: var(--main-color-text-light);
	background-color: var(--secondary-color-error);
}

.app-error-btn:hover {
	background-color: var(--main-color-error);
	color: var(--main-color-text-light);
}

.app-warn-btn {
	border: 1px solid var(--main-color-border-light);
	color: var(--main-color-text-light);
	background-color: var(--secondary-color-warn);
}

.app-warn-btn:hover {
	background-color: var(--main-color-warn);
	color: var(--main-color-text-light);
}

.app-disabled-btn {
	background-color: var(--main-color-disabled);
	pointer-events: none;
	color: var(--main-color-dark-80);
}

/* Global input/textarea/select style */
input,
textarea,
select {
	padding: 5px 10px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	color: var(--main-color-text-light);
	background-color: var(--main-color-3);
	box-shadow: 0 0 0 2pt var(--main-color-2);
}

/* Global input/textarea/select focus style */
input:focus,
textarea:focus,
select:focus {
	outline: none;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0 0 0 2pt var(--main-color-6) !important;
}

input {
	padding-left: 20px !important;
}

input::placeholder,
textarea::placeholder {
	color: var(--main-color-light-cc);
}

/* Chrome */
input[type='range'] {
	height: 20px;
	margin: 0px !important;
	padding: 0px !important;
	vertical-align: top;
	-webkit-appearance: none;
	background: var(--main-color-5);
	border-color: var(--main-color-border-dark);
	border-radius: 5px;
	overflow: hidden;
}

/* Chrome */
input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 2px solid var(--main-color-border-dark);
	height: 30px;
	width: 10px;
	background: var(--main-color-6);
	cursor: pointer;
	margin-top: 0px;
}

input[type='range']:focus {
	outline: none;
	box-shadow: none;
}

/* IE */
input[type='range']::-ms-track {
	width: 100%;
	cursor: pointer;
	background: var(--main-color-4);
	border-color: var(--main-color-border-dark);
	color: transparent;
}

/* Firefox */
input[type='range']::-moz-range-thumb {
	width: 10px;
	height: 30px;
	border: 1px solid var(--main-color-border-dark);
	background: var(--main-color-6);
	cursor: pointer;
}

/* IE */
input[type='range']::-ms-thumb {
	width: 10px;
	height: 30px;
	border: 1px solid var(--main-color-border-dark);
	background: var(--main-color-6);
	cursor: pointer;
}

/* Chrome */
input[type='range']::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	margin-top: -1px;
}

/* Chrome */
input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	box-shadow: -10000px 0 0 10000px var(--main-color-6-cc);
}

/* Firefox */
input[type='range']::-moz-range-progress {
	background-color: var(--main-color-6-cc);
}

/* IE */
input[type='range']::-ms-fill-lower {
	background-color: var(--main-color-6-cc);
}

input[type='radio']:after {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	position: relative;
	background-color: var(--main-color-5);
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid var(--main-color-border-dark);
}

input[type='radio']:checked:after {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	position: relative;
	background-color: var(--main-color-6);
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid var(--main-color-border-dark);
}

input[type='radio'],
input[type='checkbox'] {
	box-shadow: none !important;
	outline: none !important;
	cursor: pointer;
	accent-color: var(--main-color-6);
}

input[type='date'],
input[type='datetime-local'] {
	color-scheme: dark;
}

/* Global input/textarea/select disabled style */
input[disabled],
textarea[disabled],
select:disabled {
	color: var(--main-color-text-dark);
	background-color: var(--main-color-5);
	border: 1px solid var(--main-color-border-dark);
}

/* Global textarea style */
textarea {
	resize: none;
}

/* Global select style */
.select {
	min-height: 40px;
}

/* Global option style */
option {
	padding: 5px;
	font-size: 17px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

/* Heading style */
h1,
h2,
h3,
h4,
h5,
h6 {
	cursor: default;
}

.swal2-container h2 {
	text-decoration: none;
}

.app-no-select {
	-webkit-touch-callout: none !important; /* iOS Safari */
	-webkit-user-select: none !important; /* Safari */
	-khtml-user-select: none !important; /* Konqueror HTML */
	-moz-user-select: none !important; /* Old versions of Firefox */
	-ms-user-select: none !important; /* Internet Explorer/Edge */
	user-select: none !important; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@-ms-keyframes spin {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@-ms-keyframes slideIn {
	from {
		-ms-transform: translateY(-200px);
		opacity: 0;
	}
	to {
		-ms-transform: translateY(0px);
		opacity: 1;
	}
}

@-moz-keyframes slideIn {
	from {
		-moz-transform: translateY(-200px);
		opacity: 0;
	}
	to {
		-moz-transform: translateY(0px);
		opacity: 1;
	}
}

@-webkit-keyframes slideIn {
	from {
		-webkit-transform: translateY(-200px);
		opacity: 0;
	}
	to {
		-webkit-transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes slideIn {
	from {
		transform: translateY(-200px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}
</style>
