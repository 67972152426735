<template>
	<svg width="400">
		<defs>
			<marker
				:id="`${id}-arrow`"
				viewBox="0 0 15 10"
				refX="10"
				refY="5"
				markerWidth="16"
				markerHeight="16"
				orient="auto-start-reverse"
			>
				<path d="M 0 0 L 15 5 L 0 10 L 5 5 z" :fill="arrowColor" :stroke="arrowColor" />
			</marker>
		</defs>
		<foreignObject
			id="connectorText"
			:x="0"
			:y="getElementHeight('.pp-connector') / 2 - 30"
			:width="getElementWidth('.pp-connector')"
			:height="40"
		>
			<div class="ca-wrap-text">{{ text }}</div>
		</foreignObject>
		<line
			id="connectorLine"
			x1="0"
			:y1="getElementHeight('.pp-connector') / 2 + 10"
			:x2="getElementWidth('.pp-connector') - 10"
			:y2="getElementHeight('.pp-connector') / 2 + 10"
			:stroke="arrowColor"
			stroke-width="2"
			:marker-end="`url(#${id}-arrow)`"
		/>
	</svg>
</template>

<script>
/**
 * @group Pipeline
 * A connector arrow used for the pipeline preview
 */
export default {
	name: 'ConnectorArrow',
	props: {
		id: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		arrowColor: {
			type: String,
			required: true,
		},
	},
	mounted() {
		window.addEventListener('resize', this.resizeListener);
		this.$nextTick(() => {
			window.dispatchEvent(new Event('resize'));
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		// @vuese
		// Resize listener for the arrow text field size
		resizeListener() {
			let textModifier = 30;
			if (window.innerWidth <= 600) textModifier = 15;
			else if (window.innerWidth <= 900) textModifier = 30;

			[...document.querySelectorAll('#connectorLine')].forEach((line) => {
				line.y1.baseVal.value = this.getElementHeight('.pp-connector') / 2 + 10;
				line.y2.baseVal.value = this.getElementHeight('.pp-connector') / 2 + 10;
				line.x2.baseVal.value = this.getElementWidth('.pp-connector') - 10;
			});

			[...document.querySelectorAll('#connectorText')].forEach((text) => {
				text.y.baseVal.value = this.getElementHeight('.pp-connector') / 2 - textModifier;
				text.style.width = this.getElementWidth('.pp-connector');
			});
		},
		// @vuese
		// Gets the needed width of a element
		// @returns [Number] - The needed width
		getElementWidth(element) {
			return document.querySelector(element) ? document.querySelector(element).clientWidth : 0;
		},
		// @vuese
		// Gets the needed height of a element
		// @returns [Number] - The needed height
		getElementHeight(element) {
			return document.querySelector(element) ? document.querySelector(element).clientHeight : 0;
		},
	},
};
</script>

<style scoped>
.pp-connector {
	max-width: 20%;
	flex: 1 1 20%;
	text-align: center;
}
.ca-wrap-text {
	height: 40px;
	width: calc(100% - 30px);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

path {
	border: none;
}

@media screen and (max-width: 900px) {
	.ca-wrap-text {
		font-size: 15px;
	}
}

@media screen and (max-width: 600px) {
	.ca-wrap-text {
		height: 20px;
		font-size: 10px;
	}
}
</style>
