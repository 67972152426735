<template>
	<div class="pi-wrap-content">
		<div class="pi-wrap-btns">
			<button
				:class="[$global.hasRights({ route: 'Development' }) ? 'app-default-btn' : 'app-disabled-btn']"
				@click="navigateTo('Development')"
			>
				{{ $t('piDevelopePipeline') }}
			</button>
			<button
				:class="[$global.hasRights({ route: 'Configuration' }) ? 'app-default-btn' : 'app-disabled-btn']"
				@click="navigateTo('Configuration')"
			>
				{{ $t('piTrainPipeline') }}
			</button>
		</div>
		<div class="pi-wrap-top">
			<h1>{{ $t('piTraining') }}</h1>
			<div class="pi-wrap-pipeline-table">
				<TrainingTable
					v-if="trainings"
					:trainings="trainings"
					:allTrainings="allTrainingsAmount"
					:applications="applications"
					:frameworks="frameworks"
					@sortTrainings="sortTrainings"
					@selectTraining="goToTraining"
					@queryTrainings="queryTrainings"
				/>
				<div v-else class="pi-table-placeholder">{{ $t('piLoading') }}<i class="fa-solid fa-spinner"></i></div>
			</div>
		</div>
		<div class="pi-wrap-bot">
			<h1>{{ $t('piLiveSystem') }}</h1>
			<div class="pi-wrap-pipeline-table">
				<PipelineTable
					v-if="pipelines"
					:pipelines="pipelines"
					:allPipelines="allPipelineAmount"
					:applications="applications"
					:frameworks="frameworks"
					:devMode="false"
					@sortPipelines="sortPipelines"
					@queryPipelines="queryPipelines"
				/>
				<div v-else class="pi-table-placeholder">{{ $t('piLoading') }}<i class="fa-solid fa-spinner"></i></div>
			</div>
			<!-- <div class="pi-wrap-btns">
				<button class="app-disabled-btn">{{ $t('piAddPipeline') }}</button>
			</div> -->
		</div>
	</div>
</template>

<script>
import PipelineTable from '../components/tables/PipelineTable.vue';
import TrainingTable from '../components/tables/TrainingTable.vue';
/**
 * @group Pipeline
 * Contains the overview, management, creation, development, execution and evaluation of ai pipelines
 */
export default {
	name: 'Pipeline',
	components: {
		PipelineTable,
		TrainingTable,
	},
	data() {
		return {
			applications: [],
			frameworks: [],
			pipelines: null,
			trainings: null,
			allPipelineAmount: 0,
			allTrainingsAmount: 0,
		};
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			this.user = this.$global.getUser();
			this.$cr.getAllApplications((err, result) => {
				if (!err) this.applications = result;
			});

			this.$cr.getAllFrameworks((err, result) => {
				if (!err) this.frameworks = result;
			});

			this.queryPipelines(0, 50);
			this.queryTrainings(0, 50);
		},
		// @vuese
		// Queries the pipelines by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryPipelines(start, amount) {
			this.$cr.getAllPipelines(start, amount, null, null, true, (err, result) => {
				if (!err) {
					this.pipelines = result.pipelines;
					this.allPipelineAmount = result.amount;
				}
			});
		},
		// @vuese
		// Queries the trainings by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryTrainings(start, amount) {
			this.$cr.getTrainings(start, amount, (err, result) => {
				if (!err) {
					this.trainings = result.trainings;
					this.allTrainingsAmount = result.amount;
				}
			});
		},
		// @vuese
		// Sorts all pipelines by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortPipelines(sort) {
			this.pipelines = this.$global.sortValues(sort, this.pipelines);
		},
		// @vuese
		// Sorts all trainings by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortTrainings(sort) {
			this.trainings = this.$global.sortValues(sort, this.trainings);
		},
		// @vuese
		// Navigates the user to a certain destination
		// @arg dest[String] - The destination
		navigateTo(dest) {
			this.$router.push({ name: dest });
		},
		// @vuese
		// Moves the user to a certain training evaluation
		// @arg trainingID[String] - The ID of the training
		goToTraining(trainingID) {
			this.$router.push({ name: 'Evaluation', params: { trainingID: trainingID } });
		},
	},
};
</script>

<style scoped>
.pi-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

.pi-wrap-top,
.pi-wrap-bot {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	padding: 10px;
	box-sizing: border-box;
}

.pi-wrap-top {
	padding-top: 0px;
}

.pi-wrap-top h1,
.pi-wrap-bot h1 {
	width: fit-content;
	margin: auto;
	color: var(--main-color-6);
}

.pi-wrap-pipeline-table {
	width: 100%;
	height: fit-content;
	min-height: 200px;
	margin-top: 10px;
}

.pi-wrap-btns {
	width: 100%;
	margin: 10px 0px;
	text-align: center;
}

.pi-wrap-btns button {
	margin: 0px 10px;
	font-size: 20px;
}

.pi-table-placeholder {
	height: 240px;
	max-width: 1800px;
	width: 90%;
	margin: 0px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
}

.pi-table-placeholder i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
</style>
