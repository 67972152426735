<template>
	<div class="puf-wrap-content">
		<div class="puf-wrap-input-upload">
			<div class="app-default-btn puf-file-upload">
				<label>
					<input type="file" webkitdirectory directory multiple @input="handleUpload" />
					<i class="fa fa-cloud-upload"></i> {{ $t('pufUploadFolder') }}
				</label>
			</div>
			<div class="app-default-btn puf-file-upload">
				<label>
					<input type="file" multiple @input="handleUpload" />
					<i class="fa-solid fa-file-arrow-up"></i> {{ $t('pufUploadFiles') }}
				</label>
			</div>
		</div>
		<div class="puf-wrap-upload" @dragenter="checkDragEnter">
			<div class="puf-dnd-background">
				<div>
					<i class="fa-solid fa-folder-open"></i>
					<p>{{ $t('pufUploadFilesDND') }}</p>
				</div>
			</div>
			<div
				v-show="showUpload"
				class="puf-hover-div"
				@dragover="checkDragOver"
				@dragleave="
					$event.preventDefault();
					showUpload = false;
				"
				@drop="handleUpload"
			></div>
			<div v-if="showUploadDialog" class="puf-upload-dialog">
				<h2>{{ $t('pufAddItemsToFolder') }}</h2>
				<div
					v-for="folderKey in sortKeys()"
					:key="folderKey"
					class="puf-folder"
					@click="
						basePath = folderKey.replace(new RegExp('/$'), '');
						showUploadDialog = false;
					"
				>
					<i class="fa-solid fa-folder"></i>{{ folderKey }}
				</div>
			</div>
			<div class="puf-wrap-uploaded-files">
				<div v-if="Object.keys(folders).length > 0" class="puf-wrap-file-tree">
					<div v-for="folderKey in sortKeys()" :key="folderKey" class="puf-tree-component">
						<p
							v-if="folderKey !== '/'"
							:style="{ paddingLeft: `${20 * (folderKey.split('/').length - 3) + 20}px` }"
							class="puf-folder"
						>
							<i class="fa-solid fa-folder"></i> {{ getFolderName(folderKey) }}
							<i
								class="fa-solid fa-xmark puf-delete"
								:style="{ marginLeft: `${20 * (folderKey.split('/').length - 3)}px` }"
								@click="deleteFolder(folderKey)"
							></i>
						</p>
						<div v-for="file in folders[folderKey]" :key="file.fileID">
							<p
								:style="{
									paddingLeft: folderKey !== '/' ? `${20 * (folderKey.split('/').length - 2) + 20}px` : `20px`,
								}"
								class="puf-file"
							>
								<span
									:class="[
										possibleEntryPoint(file, folderKey) ? 'puf-file-hover' : '',
										entryPoint == file.file.name && folderKey.split('/').length == 2
											? 'puf-selected-entry-point'
											: '',
									]"
									@click="possibleEntryPoint(file, folderKey) ? $emit('setEntryPoint', file.file.name) : null"
								>
									<i class="fa-solid fa-file"></i> {{ file.file.name }}
								</span>
								<i
									class="fa-solid fa-xmark puf-delete"
									:style="{
										marginLeft: folderKey !== '/' ? `${20 * (folderKey.split('/').length - 2)}px` : `0px`,
									}"
									@click="deleteFile(folderKey, file.fileID)"
								></i>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showRequirementsTest" class="puf-wrap-log">
			<div class="puf-wrapper">
				<h2>{{ $t('pufRequirementsCheck') }}</h2>
				<div class="puf-log">
					<i class="fa-solid fa-circle-xmark" @click="showRequirementsTest = false"></i>
					<LiveLog :connect="true" />
				</div>
			</div>
		</div>
		<div class="puf-wrap-save-btn">
			<button
				:class="[
					Object.keys(folders).length == 0 ? 'app-disabled-btn' : 'app-success-btn',
					uploadPending ? 'puf-disable-click' : '',
				]"
				@click="checkUpload"
			>
				{{ $t('pufSaveFiles') }}
				<i v-if="uploadPending" class="fa-solid fa-spinner"></i>
			</button>
		</div>
	</div>
</template>

<script>
import LiveLog from '../log/LiveLog.vue';
import * as uuid from 'uuid';
/**
 * @group Upload
 * Contains the pipeline upload components
 */
export default {
	name: 'PipelineUploadFiles',
	components: {
		LiveLog,
	},
	props: {
		entryPoint: {
			type: String,
			required: false,
		},
		config: {
			type: Object,
			required: true,
		},
	},
	watch: {
		entryPoint: {
			handler: function (newVal) {
				if (newVal) {
					if (this.folders['/']) {
						let validFiles = this.folders['/'].filter((file) => this.possibleEntryPoint(file, '/'));
						if (validFiles) {
							if (validFiles.filter((file) => file.file.name == newVal).length == 0)
								this.$global.showToast('warn', `${this.$t('pufInvalidEntryPoint')}`);
							else this.$global.showToast('success', `${this.$t('pufSetEntryPointTo')}: ${newVal}`);
						}
					}
				}
			},
		},
	},
	data() {
		return {
			showUpload: false,
			showUploadDialog: false,
			uploadPending: false,
			showRequirementsTest: false,
			basePath: '',
			remainingFiles: 0,
			remainingFolders: 0,
			temp: {},
			folders: {},
		};
	},
	methods: {
		// @vuese
		// Catches the drag enter event and displays the upload div
		// @arg e[Object] - The event that occured
		checkDragEnter(e) {
			// e.preventDefault();
			this.showUpload = true;
		},
		// @vuese
		// Catches the drag over event and displays the upload div
		// @arg e[Object] - The event that occured
		checkDragOver(e) {
			e.preventDefault();
			this.showUpload = true;
		},
		// @vuese
		// Handles the upload. Catches events from the input fields or the drag and drop div
		// @arg e[Object] - The event that occured
		handleUpload(e) {
			e.preventDefault();
			this.showUpload = false;
			this.basePath = '';
			let items = [];
			let type = '';
			if (e.target.files) {
				items = Array.from(e.target.files);
				type = 'up';
			} else if (e.dataTransfer && e.dataTransfer.items) {
				items = Array.from(e.dataTransfer.items).map((i) => i.webkitGetAsEntry());
				type = 'dnd';
			} else if (e.dataTransfer.files) {
				items = Array.from(e.dataTransfer.files);
				type = 'dndold';
			}

			if (Object.keys(this.folders).length > 0) {
				this.showUploadDialog = true;
				let that = this;
				let interval = window.setInterval(() => {
					if (!that.showUploadDialog) {
						clearInterval(interval);
						that.loopUploadedItems(items, type);
					}
				}, 100);
			} else this.loopUploadedItems(items, type);
			e.target.value = null;
		},
		// @vuese
		// Loops through all uploaded items and adds the to a path
		// @arg items[Array] - All uploaded items
		// @arg type[String] - The type of the upload. Can be up (via Input), dnd (via drag & drop) or dndold (drag & drop legacy support)
		loopUploadedItems(items, type) {
			this.showUploadDialog = false;

			if (!this.temp[`${this.basePath}/`]) this.temp[`${this.basePath}/`] = [];

			if (type == 'up') {
				items.forEach((item) => {
					this.inputUpload(item);
				});
				this.folders = this.temp;
				this.$forceUpdate();
			} else if (type == 'dnd') {
				items.forEach((item) => {
					this.dndUpload(item, '/');
				});
			} else if (type == 'dndold') {
				this.$global.showToast('warn', this.$t('pufSubdirectoriesCantBeUploaded'));
				items.forEach((item) => {
					if (this.temp[`${this.basePath}/`].filter((file) => file.file.name == item.name).length == 0)
						this.temp[`${this.basePath}/`].push({ fileID: uuid.v4(), file: item });
				});
				this.folders = this.temp;
				this.$forceUpdate();
			}
		},
		// @vuese
		// Uploads items via the input upload
		// @arg item[Object] - The file that got uploaded
		inputUpload(item) {
			let path = item.webkitRelativePath.split('/');
			path = path.splice(0, path.length - 1).join('/');
			if (!path.includes('__pycache__')) {
				if (path != '') {
					if (!this.temp[`${this.basePath}/${path}/`])
						this.temp[`${this.basePath}/${path}/`] = [{ fileID: uuid.v4(), file: item }];
					else {
						if (this.temp[`${this.basePath}/${path}/`].filter((file) => file.file.name == item.name).length == 0)
							this.temp[`${this.basePath}/${path}/`].push({ fileID: uuid.v4(), file: item });
					}
				} else {
					if (!this.temp[`${this.basePath}/${path}`])
						this.temp[`${this.basePath}/${path}`] = [{ fileID: uuid.v4(), file: item }];
					else {
						if (this.temp[`${this.basePath}/${path}`].filter((file) => file.file.name == item.name).length == 0)
							this.temp[`${this.basePath}/${path}`].push({ fileID: uuid.v4(), file: item });
					}
				}
			}
		},
		// @vuese
		// Checks if the item is a folder or a file and adds the files with the given path
		// @arg item[Object] - The current item
		// @arg path[String] - The current path
		dndUpload(item, path) {
			let that = this;
			// window.requestAnimationFrame(this.loadingAnimation);
			if (item.isDirectory) {
				that.remainingFolders += 1;
				let directoryReader = item.createReader();
				if (!['__pycache__'].includes(item.name)) {
					if (!this.temp[`${this.basePath}${item.fullPath}/`]) this.temp[`${this.basePath}${item.fullPath}/`] = [];
					directoryReader.readEntries((entries) => {
						entries.forEach((entry, idx) => {
							that.dndUpload(entry, item.fullPath);
							if (entries.length - 1 == idx) that.remainingFolders -= 1;
						});
						if (entries.length == 0) that.remainingFolders -= 1;
					});
				} else that.remainingFolders -= 1;
			} else {
				this.remainingFiles += 1;
				let that = this;
				item.file((file) => {
					that.remainingFiles -= 1;
					if (!['Helper.py'].includes(file.name)) {
						if (path !== '/') {
							if (that.temp[`${that.basePath}${path}/`].filter((f) => f.file.name == file.name).length == 0)
								that.temp[`${that.basePath}${path}/`].push({ fileID: uuid.v4(), file: file });
						} else {
							if (that.temp[`${that.basePath}${path}`].filter((f) => f.file.name == file.name).length == 0)
								that.temp[`${that.basePath}${path}`].push({ fileID: uuid.v4(), file: file });
						}
					}
					if (that.remainingFiles == 0 && that.remainingFolders == 0) {
						that.$nextTick(() => {
							that.folders = that.temp;
							that.$forceUpdate();
						});
					}
				});
			}
			return;
		},
		// @vuese
		// Sorts the keys of the uploaded folders with the sort-paths lib (https://github.com/ghornich/sort-paths)
		// @returns [Array] - A sorted array of all paths
		sortKeys() {
			let keys = Object.keys(this.folders);
			let sortedKeys = sortPaths(keys, '/');
			return sortedKeys;
		},
		// @vuese
		// Parses the folder name from the given path
		// @arg path[String] - The folder path
		// @returns [String] - The folder name
		getFolderName(path) {
			let parts = path.split('/');
			if (parts.length > 1) return parts[parts.length - 2];
			else return '/';
		},
		// @vuese
		// Checks if the file is a possible entryPoint
		// @arg file[Object] - The file
		// @arg path[String] - The folder path
		// @returns [Boolean] - Is true if the file can be selected as entryPoint
		possibleEntryPoint(file, path) {
			if (path.split('/').length > 2) return false;
			else if (['__init__.py'].includes(file.file.name)) return false;
			else if (file.file.name.split('.').pop() !== 'py') return false;
			else return true;
		},
		// @vuese
		// Deletes a folder by its path
		// @arg path[String] - The path of the folder
		deleteFolder(path) {
			delete this.folders[path];
			let folders = Object.keys(this.folders);
			let subFolders = folders.filter((folder) => {
				if (folder.match(new RegExp(`^${path}`)) && folder !== path) return true;
				else return false;
			});
			subFolders.forEach((folder) => {
				delete this.folders[folder];
			});
			let parentFolder = path.split('/');
			parentFolder = `${parentFolder.splice(0, parentFolder.length - 2).join('/')}/`;
			subFolders = Object.keys(this.folders).filter((folder) => {
				if (folder.match(new RegExp(`^${parentFolder}`)) && folder !== parentFolder) return true;
				else return false;
			});
			if (this.folders[parentFolder].length == 0 && subFolders.length == 0) delete this.folders[parentFolder];
			this.$forceUpdate();
		},
		// @vuese
		// Deletes a file its fileID and the path of the folder
		// @arg path[String] - The path of the folder
		// @arg fileID[String] - The fileID
		deleteFile(path, fileID) {
			this.folders[path] = this.folders[path].filter((file) => file.fileID !== fileID);
			if (this.folders[path].length == 0) {
				let folders = Object.keys(this.folders);
				let subFolders = folders.filter((folder) => {
					if (folder.match(new RegExp(`^${path}`)) && folder !== path) return true;
					else return false;
				});
				if (subFolders.length == 0) delete this.folders[path];
			}
			this.$forceUpdate();
		},
		// @vuese
		// Tests if the specified requirements can be installed and dont have any conflicts
		testRequirements(cb) {
			this.showRequirementsTest = true;
			let that = this;
			this.$global.postData(
				'pipeline',
				`/requirements`,
				{ requirements: this.config.requirements },
				{
					headers: { userid: this.$global.getUser().userID },
					auth: JSON.parse(sessionStorage.getItem('credentials')),
				},
				function (err, result) {
					if (err) {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						that.$global.showToast('error', that.$t(msg ? msg : 'puiRequirementsError'));
						cb(true);
					} else {
						that.$global.showToast('success', that.$t('puiRequirementsOK'), true);
						cb(false);
					}
				}
			);
		},
		// @vuese
		// Checks if the pipeline can be uploaded and initiates the upload
		checkUpload() {
			this.uploadPending = true;
			let that = this;
			try {
				// Check uploaded files
				if (Object.keys(this.folders).length == 0) throw 'pufNoFiles';
				else if (this.folders['/'].length == 0) throw 'pufMissingExecutable';
				else {
					let files = this.folders['/'].filter((file) => {
						if (file.file.name.includes('.py') && file.file.name !== '__init__.py') return true;
						else return false;
					});
					if (files.length == 0) throw 'pufMissingExecutable';
				}

				// Check config
				if (!this.config.predecessorID) throw 'pufNoPredecessor';
				else if (!this.config.frameworkID) throw 'pufNoFramework';
				else if (!this.config.applicationID) throw 'pufNoApplication';
				else if (!this.config.helperID) throw 'pufNoHelper';
				else if (this.config.input.length == 0) throw 'pufNoInput';
				else if (this.config.output.length == 0) throw 'pufNoOutput';
				else if (this.config.name.length < 5 || this.config.name.length > 30) throw 'pufInvalidName';
				else if (this.config.entryPoint == '') throw 'pufNoExecutable';
				else if (this.folders['/'].filter((file) => file.file.name == this.config.entryPoint).length == 0)
					throw 'pufNoExecutable';

				this.testRequirements((err) => {
					if (!err) {
						that.showRequirementsTest = false;
						let bodyForm = new FormData();
						bodyForm.append(`config`, JSON.stringify(that.config));
						Object.keys(that.folders).forEach((folderKey) => {
							// bodyForm.append(`${folderKey}`, JSON.stringify(that.folders[folderKey].map((file) => file.fileID)));
							that.folders[folderKey].forEach((file) => {
								bodyForm.append(`${folderKey}____${file.fileID}`, file.file, file.file.name);
							});
						});

						that.$global.postData(
							'pipeline',
							'/pipelines',
							bodyForm,
							{
								headers: { userid: that.$global.getUser().userID },
								auth: JSON.parse(sessionStorage.getItem('credentials')),
							},
							(err, data) => {
								if (err) {
									let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
									that.$global.showToast('error', that.$t(msg ? msg : 'pufUploadError'));
									that.uploadPending = false;
								} else that.$emit('clearUpload', 'pipeline');
							}
						);
					} else that.uploadPending = false;
				});
			} catch (error) {
				this.uploadPending = false;
				this.$global.showToast('warn', this.$t(typeof error == 'string' ? error : this.$t('pufUnexpectedUploadError')));
			}
		},
	},
};
</script>

<style scoped>
.puf-wrap-content {
	width: 100%;
	height: 100%;
}

.puf-wrap-input-upload {
	width: 100%;
	height: fit-content;
	margin: 0px auto 10px auto;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	gap: 10px 20px;
}

.puf-file-upload {
	flex: 0 0 220px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 17px;
	background-color: var(--main-color-4);
	border: 2px solid var(--main-color-border-dark);
}

.puf-file-upload:hover {
	background-color: var(--main-color-3);
}

.puf-file-upload label {
	margin: 0px;
	box-sizing: content-box;
	text-align: start;
	padding: 5px 26px;
}

.puf-file-upload:hover label {
	cursor: pointer;
}

.puf-file-upload input[type='file'] {
	width: 100%;
	display: none;
}

.puf-wrap-upload {
	width: 100%;
	height: calc(100% - 100px);
	margin: 0px auto 10px auto;
	position: relative;
	box-sizing: border-box;
	border: 3px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
}

.puf-dnd-background {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.puf-dnd-background div {
	width: fit-content;
	max-width: 90%;
	height: fit-content;
	text-align: center;
	overflow: hidden;
	user-select: none;
}

.puf-dnd-background div i {
	margin-bottom: 10px;
	font-size: 50px;
	color: var(--main-color-text-light);
}

.puf-dnd-background div p {
	display: block;
	font-size: 30px;
	color: var(--main-color-text-light);
}

.puf-hover-div {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	border: 5px solid var(--main-color-6);
	box-sizing: border-box;
	background-color: var(--main-color-dark-80);
	z-index: 3;
}

.puf-upload-dialog {
	width: 100%;
	height: 100%;
	padding: 5px 10px;
	position: absolute;
	top: 0px;
	left: 0px;
	box-sizing: border-box;
	background-color: var(--main-color-4);
	z-index: 4;
	overflow: auto;
}

.puf-upload-dialog h2 {
	width: 100%;
	text-align: center;
}

.puf-upload-dialog div {
	width: fit-content;
	min-width: 100%;
	padding: 5px;
	border-radius: 5px;
	text-align: start;
}

.puf-upload-dialog div:hover {
	color: var(--main-color-text-dark);
	background-color: var(--main-color-6);
	cursor: pointer;
}

.puf-upload-dialog div i {
	margin-right: 10px;
}

.puf-wrap-uploaded-files {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 2;
	box-sizing: border-box;
	overflow: auto;
	white-space: nowrap;
}

.puf-wrap-file-tree {
	width: fit-content;
	min-width: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
	background-color: var(--main-color-4);
}

.puf-tree-component {
	background-color: var(--main-color-4);
	white-space: nowrap;
}

.puf-wrap-file-tree p {
	padding: 2px 0px;
	user-select: none;
}

.puf-folder,
.puf-file {
	position: relative;
	padding-left: 20px;
	box-sizing: border-box;
}

.puf-folder i {
	font-size: 20px;
	color: var(--main-color-6);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-border-dark);
}

.puf-file-hover:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.puf-folder:hover .puf-delete,
.puf-file:hover .puf-delete {
	display: block;
}

.puf-file i {
	font-size: 20px;
	color: var(--main-color-info);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-border-dark);
}

.puf-selected-entry-point {
	color: var(--main-color-6);
}

.puf-delete {
	display: none;
	position: absolute;
	top: 0px;
	left: 0px;
	font-size: 25px !important;
	color: var(--main-color-error) !important;
}

.puf-delete:hover {
	cursor: pointer;
	color: var(--secondary-color-error) !important;
}

.puf-wrap-save-btn {
	width: 100%;
	height: 40px;
	text-align: center;
}

.puf-wrap-save-btn button {
	font-size: 17px;
}

.puf-disable-click {
	pointer-events: none;
}

.puf-disable-click i {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.puf-wrap-log {
	width: 100%;
	height: calc(100% - 50px);
	position: absolute;
	top: 50px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 11;
	background-color: var(--main-color-dark-cc);
}

.puf-wrapper {
	width: 90%;
	height: 90%;
	padding: 10px 30px 10px 30px;
	overflow: auto;
	position: relative;
	box-sizing: border-box;
	background-color: var(--main-color-2);
	border-radius: 20px;
	border: 1px solid var(--main-color-5);
	animation: slideIn 0.4s linear;
}

.puf-wrapper h2 {
	margin: 0px 0px 5px 0px;
	color: var(--main-color-6);
}

.puf-log {
	width: 100%;
	height: calc(100% - 30px);
}

.puf-log i {
	font-size: 25px;
	position: absolute;
	top: 5px;
	right: 5px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.puf-log i:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}
</style>
