<template>
	<div class="pp-wrap-content">
		<div class="pp-wrap-preview">
			<div class="pp-wrap-input">
				<div class="pp-centered-content">
					<strong>
						{{ $t('ppInput') }}
						<i
							v-if="bundle && !bundle.isVerification"
							class="fa-solid fa-triangle-exclamation pp-not-verified"
							@mouseenter="showWarning = true"
							@mouseover="showWarning = true"
							@mouseleave="showWarning = false"
							@mouseout="showWarning = false"
						></i>
						<div v-if="showWarning">
							{{ $t('ppNoBaseline') }}
						</div>
					</strong>
					<i :class="[getIconColor(), 'fa-solid', 'fa-file-import']"></i>
					<p v-if="!bundle">{{ $t('ppSelectInput') }}</p>
					<div v-else>
						<p>{{ bundle.name }}<br />{{ $global.shortenRevisionID(bundle.revisionID) }}</p>
					</div>
					<div v-if="configuration" class="pp-options">
						<div v-if="bundle" :class="[bundle ? 'pp-float-left' : '', 'pp-extra-information']">
							<p>{{ $t('ppAmount') }}:</p>
							<p>{{ bundle.amountData }} {{ $t('ppData') }}</p>
						</div>
						<i
							id="configureBundle"
							:class="[bundle ? 'pp-float-right' : '', 'fa-solid', 'fa-gear', 'pp-highlighted']"
							@click="$emit('configureBundle')"
						></i>
					</div>
				</div>
			</div>
			<div class="pp-connector">
				<ConnectorArrow
					:id="'input'"
					:text="bundle ? bundle.mimeTypes.replace(/,/g, ', ') : $t('ppNoInput')"
					:arrowColor="getArrowColor('input')"
				/>
			</div>
			<div class="pp-wrap-pipeline">
				<div class="pp-centered-content">
					<strong>{{ $t('ppPipeline') }}</strong>
					<i :class="[getIconColor(), 'fa-solid', 'fa-diagram-project']"></i>
					<p>{{ pipeline ? $global.shortenRevisionID(pipeline.revisionID) : $t('ppSelectPipeline') }}</p>
					<div v-if="configuration" class="pp-options">
						<div v-if="pipeline" :class="[pipeline ? 'pp-float-left' : '', 'pp-extra-information']">
							<p>{{ $t('ppAccepts') }}:</p>
							<p>{{ pipeline.input.join(', ') }}</p>
						</div>
						<i
							id="configurePipeline"
							:class="[pipeline ? 'pp-float-right' : '', 'fa-solid', 'fa-gear']"
							@click="$emit('configurePipeline')"
						></i>
					</div>
				</div>
			</div>
			<div class="pp-connector">
				<ConnectorArrow
					:id="'output'"
					:text="pipeline ? pipeline.output.join(', ') : $t('ppNoOutput')"
					:arrowColor="getArrowColor('output')"
				/>
			</div>
			<div class="pp-wrap-output">
				<div class="pp-centered-content">
					<strong>{{ $t('ppOutput') }}</strong>
					<i class="fa-solid fa-file-export"></i>
				</div>
			</div>
		</div>
		<div class="pp-wrap-info">
			<div class="pp-wrap-inputs">
				<div class="pp-wrap-element">
					<label>{{ $t('ppApplication') }}</label>
					<p v-if="pipeline && applications" class="pp-input">
						{{ $t(`${applications.filter((app) => app.applicationID == pipeline.applicationID)[0].abbreviation}Name`) }}
					</p>
					<p v-else class="pp-input"></p>
				</div>
				<div class="pp-wrap-element">
					<label>{{ $t('ppCreation') }}</label>
					<p v-if="pipeline" class="pp-input">{{ $global.parseDateFull(pipeline.creation) }}</p>
					<p v-else class="pp-input"></p>
				</div>
				<div class="pp-wrap-element">
					<label>{{ $t('ppCreator') }}</label>
					<p v-if="pipeline" class="pp-input">{{ pipeline.userID.substring(0, 8) }}</p>
					<p v-else class="pp-input"></p>
				</div>
			</div>
			<div class="pp-wrap-notes">
				<label>{{ $t('ppNotes') }}</label>
				<p v-if="pipeline" class="pp-textarea">{{ pipeline.notes }}</p>
				<p v-else class="pp-textarea"></p>
			</div>
		</div>
	</div>
</template>

<script>
import ConnectorArrow from './ConnectorArrow.vue';
/**
 * @group Pipeline
 * A preview of a selected pipeline and data bundle
 */
export default {
	name: 'PipelinePreview',
	components: {
		ConnectorArrow,
	},
	props: {
		pipeline: {
			type: Object,
			required: false,
		},
		bundle: {
			type: Object,
			required: false,
		},
		applications: {
			type: Array,
			required: true,
		},
		configuration: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			showWarning: false,
		};
	},
	methods: {
		// @vuese
		// Checks if a data bundle is compatible with a pipeline and returns a matching color
		// @arg type[String] - The type of arrow
		// @returns [String] - The color
		getArrowColor(type) {
			if (type == 'input') {
				if (!this.pipeline || !this.bundle) return 'var(--main-color-info)';
				else {
					let bundleInput = this.bundle.mimeTypes.split(',');
					let color = 'var(--main-color-success)';
					bundleInput.forEach((input) => {
						if (!this.pipeline.input.includes(input)) color = 'var(--main-color-error)';
					});
					return color;
				}
			} else if (type == 'output') {
				if (!this.pipeline) return 'var(--main-color-info)';
				else return 'var(--main-color-success)';
			}
		},
		// @vuese
		// Returns the icon color depending on the selected data
		// @returns [String] - The color class
		getIconColor() {
			if (!this.pipeline || !this.bundle) return 'pp-dark';
			else if (this.pipeline.applicationID !== this.bundle.applicationID) return 'pp-error';
			else return 'pp-dark';
		},
	},
};
</script>

<style scoped>
.pp-wrap-content {
	width: 100%;
	height: fit-content;
}

.pp-wrap-preview {
	width: 100%;
	height: fit-content;
	padding: 10px 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
	overflow-x: hidden;
}

.pp-wrap-preview > div {
	display: inline-block;
	margin: 0px 10px;
}

.pp-wrap-input,
.pp-wrap-output,
.pp-wrap-pipeline {
	width: 100px;
	min-height: 100px;
	flex: 1 1 23%;
	padding: 10px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	border: 3px solid var(--main-color-border-dark);
	box-sizing: border-box;
	background-color: var(--main-color-5);
}

.pp-connector {
	max-width: 15%;
	flex: 1 1 15%;
	text-align: center;
}

.pp-centered-content {
	width: 100%;
	text-align: center;
	position: relative;
}

.pp-centered-content strong {
	display: block;
	font-size: 17px;
	font-weight: bold;
	color: var(--main-color-text-dark);
	position: relative;
}

.pp-centered-content strong div {
	width: 100%;
	padding: 5px;
	position: absolute;
	font-weight: normal;
	border: 1px solid var(--main-color-border-dark);
	border-radius: 10px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	animation: fadeIn 0.5s ease-in both;
}

.pp-centered-content p {
	display: block;
	font-size: 17px;
	color: var(--main-color-text-dark);
}

.pp-centered-content i {
	margin: 5px 0px;
	font-size: 40px;
	color: var(--main-color-text-dark);
}

.pp-not-verified {
	/* position: absolute;
	top: 0px;
	right: 0px; */
	font-size: 25px !important;
	color: var(--main-color-warn) !important;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pp-not-verified:hover {
	cursor: pointer;
	color: var(--main-color-warn-80);
}

.pp-extra-information {
	width: calc(100% - 50px);
	display: inline-block;
	text-align: start;
}

.pp-options {
	width: 100%;
	margin-top: 10px;
	padding-top: 10px;
	border-top: 1px solid var(--main-color-border-dark);
	text-align: center;
}

.pp-options i {
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pp-highlighted {
	color: var(--main-color-6-cc) !important;
}

.pp-dark {
	color: var(--main-color-text-dark) !important;
}

.pp-error {
	color: var(--main-color-error) !important;
	-webkit-text-stroke: 1px var(--main-color-text-dark);
}

.pp-options i:hover,
.pp-highlighted:hover {
	cursor: pointer;
	color: var(--main-color-6) !important;
	/* -webkit-text-stroke: 1px var(--main-color-border-dark); */
}

.pp-float-right {
	float: right;
}

.pp-float-left {
	float: left;
}

.pp-wrap-info {
	width: 100%;
	margin: 10px auto;
	box-sizing: border-box;
}

.pp-wrap-inputs {
	width: 250px;
	height: 180px;
	display: inline-block;
	vertical-align: top;
}

.pp-wrap-element {
	width: 100%;
	height: 60px;
	display: block;
	text-align: start;
}

.pp-wrap-element label {
	margin-bottom: 5px;
	display: block;
	font-size: 17px;
}

.pp-wrap-element p {
	width: 100%;
	height: 20px;
	padding: 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
}

.pp-wrap-notes {
	width: calc(100% - 280px);
	height: 180px;
	margin-left: 30px;
	display: inline-block;
	vertical-align: top;
}

.pp-wrap-notes label {
	margin-bottom: 5px;
	display: block;
	font-size: 17px;
}

.pp-wrap-notes p {
	width: 100%;
	height: calc(100% - 25px);
	padding: 5px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}

@media screen and (max-width: 900px) {
	.pp-centered-content strong {
		font-size: 15px;
	}

	.pp-centered-content p {
		font-size: 10px;
	}

	.pp-centered-content i {
		font-size: 25px;
	}
}

@media screen and (max-width: 600px) {
	.pp-wrap-inputs {
		width: calc(100% - 15px);
		height: 180px;
		display: block;
		box-sizing: border-box;
	}

	.pp-wrap-notes {
		width: 100%;
		height: 180px;
		margin: 5px 0px;
		display: block;
	}

	.pp-centered-content strong {
		font-size: 15px;
	}

	.pp-centered-content p {
		font-size: 10px;
	}

	.pp-centered-content i {
		font-size: 25px;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
</style>
