<template>
	<div class="ht-wrap-content">
		<div class="ht-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
		</div>
		<div v-if="applications.length && frameworks.length" class="ht-wrap-table">
			<div v-if="isLoading" class="ht-loading-overlay">
				<p>{{ $t('htLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="ht-table">
				<div class="ht-wrap-header">
					<div class="ht-header-element app-no-select ht-helper-id" :title="$t('htSortBy')" @click="sortBy('helperID')">
						{{ $t('htHelperID') }}
					</div>
					<div class="ht-header-element app-no-select ht-revision-id" :title="$t('htSortBy')" @click="sortBy('revisionID')">
						{{ $t('htRevisionID') }}
					</div>
					<div class="ht-header-element app-no-select ht-application" @click="sortBy('applicationID')" :title="$t('htSortBy')">
						{{ $t('htApplication') }}
					</div>
					<div class="ht-header-element app-no-select ht-framework" :title="$t('htSortBy')" @click="sortBy('frameworkID')">
						{{ $t('htFramework') }}
					</div>
					<div class="ht-header-element app-no-select ht-name" :title="$t('htSortBy')" @click="sortBy('name')">
						{{ $t('htName') }}
					</div>
					<div class="ht-header-element app-no-select ht-creator" :title="$t('htSortBy')" @click="sortBy('user', 'lastName')">
						{{ $t('htCreator') }}
					</div>
					<div class="ht-header-element app-no-select ht-creation" :title="$t('htSortBy')" @click="sortBy('creation')">
						{{ $t('htCreation') }}
					</div>
					<div class="ht-header-element app-no-select ht-revision-visualization" :title="$t('htShowRevision')"></div>
				</div>
				<div class="ht-wrap-body">
					<div
						v-for="helper in helpers"
						:key="helper.helperID"
						:class="[devMode ? 'ht-body-hover' : '', helper.selected ? 'ht-body-selected' : '', 'ht-body-element']"
						@click="devMode ? $emit('selectHelper', helper.helperID) : null"
					>
						<div class="ht-helper-id">{{ helper.helperID.substring(0, 8) }}</div>
						<div class="ht-revision-id">{{ $global.shortenRevisionID(helper.revisionID) }}</div>
						<div class="ht-application">
							{{ $t(`${applications.filter((app) => app.applicationID == helper.applicationID)[0].abbreviation}Name`) }}
						</div>
						<div class="ht-framework">
							{{ $t(`${frameworks.filter((frame) => frame.frameworkID == helper.frameworkID)[0].abbreviation}Name`) }}
						</div>
						<div class="ht-name">{{ helper.name }}</div>
						<div class="ht-creator">
							{{ helper.user.userID == $global.getUser().userID ? $t('htYou') : `${helper.user.firstName} ${helper.user.lastName}` }}
						</div>
						<div class="ht-creation">{{ $global.parseDateShort(helper.creation) }}</div>
						<div class="ht-revision-visualization" :title="$t('htShowRevision')">
							<i class="fa-solid fa-diagram-predecessor" @click="showRevision($event, helper)"></i>
						</div>
					</div>
					<div v-if="helpers.length == 0" class="ht-wrap-no-helpers">
						<div class="ht-no-helpers">
							<i class="fa-solid fa-folder-open"></i>
							<p>{{ $t('htNoHelpers') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ht-query-more">
			<p>{{ allHelpers }} {{ $t('htAllHelpers') }}</p>
			<div class="ht-pages">
				<p v-for="page in $global.getPages(allHelpers, pageSize, currentPage)" :key="page" class="ht-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="ht-no-hover"> . . . </span>
					<span
						v-else
						:class="[page == currentPage ? 'ht-highlight-page' : '', isLoading || page == currentPage ? 'ht-no-hover' : '']"
						@click="isLoading || page == currentPage ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
		<button v-if="!devMode" class="app-default-btn ht-upload-btn" @click="showUpload = true">
			{{ $t('htManageHelper') }}
		</button>
		<div v-if="showUpload && !devMode" class="ht-wrap-helper-upload">
			<HelperUpload :applications="applications" :frameworks="frameworks" :helpers="helpers" @hideHelperUpload="hideHelperUpload" />
		</div>
	</div>
</template>

<script>
import HelperUpload from '../upload/HelperUpload.vue';
/**
 * @group Tables
 * Displays all uploaded helpers as a table
 */
export default {
	name: 'HelperTable',
	components: {
		HelperUpload,
	},
	props: {
		helpers: {
			type: Array,
			required: true,
		},
		allHelpers: {
			type: Number,
			required: false,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		devMode: {
			type: Boolean,
			required: false,
		},
		selectedHelper: {
			type: String,
			required: false,
		},
	},
	watch: {
		helpers: {
			handler: function (newVal) {
				this.isLoading = false;
			},
			deep: true,
		},
	},
	data() {
		return {
			showUpload: false,
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			lastSort: {
				property: null,
				propertyB: null,
				asc: false,
			},
		};
	},
	created() {
		this.sortBy('creation');
	},

	methods: {
		// @vuese
		// Sorts the helpers by a given property
		// @arg propertyA[String] - The first property of the sorted object
		// @arg propertyB[String] - The second property of the sorted object
		sortBy(propertyA, propertyB) {
			if (this.lastSort.property == propertyA && this.lastSort.propertyB == propertyB) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: propertyA,
					propertyB: propertyB,
					asc: false,
				};
			}
			this.$emit('sortHelpers', this.lastSort);
		},

		// @vuese
		// Changes the page size and requieries the helpers from page 1
		changePageSize() {
			this.currentPage = 1;
			this.isLoading = true;
			this.$emit('queryHelpers', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Changes the page and querries the helpers
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.isLoading = true;
			this.$emit('queryHelpers', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Hides the helper upload
		hideHelperUpload() {
			this.showUpload = false;
			this.isLoading = true;
			this.$emit('queryHelpers', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Shows the revision of the helper class
		// @arg e[Object] - The event that occured
		// @arg helper[Object] - The selected helper
		showRevision(e, helper) {
			e.stopPropagation();
			if (window.location.href.includes('/configuration/'))
				window.location.href = `/configuration/data/revision/helper/${helper.helperID}?applicationID=${helper.applicationID}`;
			else window.location.href = `/data/revision/helper/${helper.helperID}?applicationID=${helper.applicationID}`;
		},
	},
};
</script>

<style scoped>
.ht-wrap-content {
	height: fit-content;
	margin: 0px auto 20px auto;
	max-width: 1800px;
}

.ht-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ht-options select {
	margin-right: 10px;
}

.ht-wrap-table {
	width: 90%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.ht-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.ht-loading-overlay p {
	font-size: 30px;
}

.ht-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.ht-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.ht-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
}

.ht-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
	vertical-align: top;
}

.ht-header-element i {
	margin-left: 10px;
}

.ht-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.ht-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.ht-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.ht-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.ht-body-element div {
	height: 40px;
	padding: 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.ht-helper-id {
	width: 80px;
}
.ht-revision-id {
	min-width: 160px;
	width: calc(100% - 1080px);
}
.ht-application {
	min-width: 240px;
}
.ht-framework {
	min-width: 140px;
}
.ht-name {
	width: 300px;
}
.ht-creator {
	width: 160px;
}
.ht-creation {
	width: 130px;
}

.ht-revision-visualization {
	width: 30px;
}

.ht-revision-visualization i {
	margin: 0px 2px;
	font-size: 20px;
}

.ht-revision-visualization i:hover {
	cursor: pointer;
	color: var(--main-color-text-light);
}

.ht-wrap-no-helpers {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ht-no-helpers {
	text-align: center;
}

.ht-no-helpers i {
	margin-bottom: 10px;
	font-size: 40px;
}

.ht-no-helpers p {
	font-size: 20px;
}

.ht-wrap-helper-upload {
	width: 100vw;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: var(--main-color-dark-80);
}

.ht-upload-btn {
	height: 40px;
	width: 250px;
	margin-top: 10px;
	padding: 5px 10px;
	font-size: 20px;
	box-sizing: border-box;
}

.ht-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.ht-query-more p {
	margin: 5px auto;
}

.ht-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.ht-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.ht-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.ht-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.ht-highlight-page {
	color: var(--main-color-6);
}
</style>
