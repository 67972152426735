<template>
	<div class="pui-wrap-content">
		<div v-if="applicationID" class="pui-wrap-information">
			<div class="pui-wrap-top">
				<button :class="['app-default-btn', showConfig ? 'pui-selected' : '']" @click="toggleConfig(true)">
					{{ $t('puiConfig') }}
				</button>
				<button :class="['app-default-btn', !showConfig ? 'pui-selected' : '']" @click="toggleConfig(false)">
					{{ $t('puiRequirements') }}
				</button>
			</div>
			<div v-if="showConfig">
				<h2>{{ $t('puiPipelineConfig') }}</h2>
				<div class="pui-wrap-upload">
					<label class="app-default-btn pui-file-upload">
						<input id="configUpload" type="file" multiple @input="uploadConfig" />
						<i class="fa-solid fa-file-arrow-up"></i> {{ $t('puiConfigFile') }}
					</label>
				</div>
				<div class="pui-informations">
					<div class="pui-wrap-select">
						<p>{{ $t('puiPredecessor') }}</p>
						<select v-model="config.predecessorID">
							<option :value="null" disabled selected hidden>{{ $t('puiSelectPipeline') }}</option>
							<option value="NEW">{{ $t('puiNewPipeline') }}</option>
							<option v-for="pipeline in pipelines" :key="pipeline.pipelineID" :value="pipeline.pipelineID">
								{{ `${pipeline.name} (${$global.shortenRevisionID(pipeline.revisionID)})` }}
							</option>
						</select>
					</div>
					<div class="pui-wrap-select">
						<p>{{ $t('puiFrameworks') }}</p>
						<select v-model="config.frameworkID">
							<option :value="null" disabled selected hidden>{{ $t('puiSelectFramework') }}</option>
							<option v-if="frameworks.length == 0" disabled>{{ $t('puiNoFrameworks') }}</option>
							<option v-for="framework in frameworks" :key="framework.frameworkID" :value="framework.frameworkID">
								{{ $t(`${framework.abbreviation}Name`) }}
							</option>
						</select>
					</div>
					<div class="pui-wrap-select">
						<p>{{ $t('puiOS') }}</p>
						<select v-model="config.os">
							<option :value="null" disabled selected hidden>{{ $t('puiSelectOS') }}</option>
							<option value="win32">{{ $t('devWindows') }}</option>
							<option value="linux">{{ $t('devLinux') }}</option>
							<option value="darwin">{{ $t('devMac') }}</option>
						</select>
					</div>
					<div class="pui-wrap-select">
						<p>{{ $t('puiHelpers') }}</p>
						<select v-model="config.helperID">
							<option :value="null" disabled selected hidden>{{ $t('puiSelectHelper') }}</option>
							<option v-if="helpers.length == 0" disabled>{{ $t('puiNoHelpers') }}</option>
							<option v-for="helper in helpers" :key="helper.helperID" :value="helper.helperID">
								{{ `${helper.name} (${$global.shortenRevisionID(helper.revisionID)})` }}
							</option>
						</select>
					</div>
					<div class="pui-wrap-select">
						<p>{{ $t('puiDataBundles') }}</p>
						<select v-model="config.bundleID">
							<option :value="null" disabled selected hidden>{{ $t('puiSelectDataBundle') }}</option>
							<option v-if="bundles.length == 0" disabled>{{ $t('puiNoDataBundles') }}</option>
							<option v-for="bundle in bundles" :key="bundle.bundleID" :value="bundle.bundleID">
								{{ `${bundle.name} (${$global.shortenRevisionID(bundle.revisionID)})` }}
							</option>
						</select>
					</div>
					<div class="pui-wrap-input">
						<p>{{ $t('puiName') }}</p>
						<input v-model="config.name" type="text" :placeholder="$t('puiEnterName')" />
					</div>

					<div class="pui-wrap-input">
						<p>{{ $t('puiEntryPoint') }}</p>
						<input
							v-model="config.entryPoint"
							type="text"
							:placeholder="$t('puiEnterEntryPoint')"
							@change="$emit('setEntryPoint', config.entryPoint)"
						/>
					</div>
					<div class="pui-wrap-io">
						<div class="pui-wrap-input">
							<p>{{ $t('puiInput') }}</p>
							<input id="pipelineInput" type="text" :placeholder="$t('puiEnterInput')" @keyup.enter="setInput" />
							<div class="pui-wrap-pipeline-io">
								<p v-for="input in config.input" :key="input">
									{{ input }}<i class="fa-solid fa-xmark" @click="removeInput(input)"></i>
								</p>
								<p v-if="config.input.length == 0" class="pui-no-element">{{ $t('puiNoInput') }}</p>
							</div>
						</div>
						<div class="pui-wrap-input">
							<p>{{ $t('puiOutput') }}</p>
							<input id="pipelineOutput" type="text" :placeholder="$t('puiEnterOutput')" @keyup.enter="setOutput" />
							<div class="pui-wrap-pipeline-io">
								<p v-for="output in config.output" :key="output">
									{{ output }}<i class="fa-solid fa-xmark" @click="removeOutput(output)"></i>
								</p>
								<p v-if="config.output.length == 0" class="pui-no-element">{{ $t('puiNoOutput') }}</p>
							</div>
						</div>
					</div>
					<div class="pui-wrap-textarea">
						<p>{{ $t('puiNotes') }}</p>
						<textarea v-model="config.notes" type="text" :placeholder="$t('puiEnterNotes')" />
					</div>
				</div>
			</div>
			<div v-else class="pui-wrap-requirements-upload">
				<h2>{{ $t('puiPipelineRequirements') }}</h2>
				<div class="pui-wrap-upload">
					<label class="app-default-btn pui-requirements-upload">
						<input id="requirementsUpload" type="file" multiple @input="uploadRequirements" />
						<i class="fa-solid fa-file-arrow-up"></i> {{ $t('puiRequirementsFile') }}
					</label>
				</div>
				<div class="pui-wrap-requirements">
					<div v-if="config.requirements.length > 0" class="pui-requirements">
						<pre v-for="(req, idx) in config.requirements" :key="idx"><span>{{ idx+1 }}.</span> {{ req }}</pre>
					</div>
					<p v-else>{{ $t('puiNoRequirements') }}</p>
				</div>
				<div class="pui-wrap-btn">
					<button
						:class="[checkRequirementsCondition() ? 'app-success-btn' : 'app-disabled-btn']"
						@click="checkRequirementsCondition() ? testRequirements() : null"
					>
						{{ $t('puiTestRequirements') }}
					</button>
				</div>
				<div v-if="showRequirementsTest" class="pui-wrap-log">
					<div class="pui-wrapper">
						<h2>{{ $t('puiRequirementsCheck') }}</h2>
						<div class="pui-log">
							<i class="fa-solid fa-circle-xmark" @click="showRequirementsTest = false"></i>
							<LiveLog :connect="true" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pui-spacer"></div>
	</div>
</template>

<script>
import LiveLog from '../log/LiveLog.vue';
/**
 * @group Upload
 * Contains the pipeline information components
 */
export default {
	name: 'PipelineUploadInformation',
	components: {
		LiveLog,
	},
	props: {
		applicationID: {
			type: String,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		entryPoint: {
			type: String,
			required: false,
		},
	},
	watch: {
		entryPoint: {
			handler: function (newVal) {
				if (newVal && newVal != this.config.entryPoint) {
					this.config.entryPoint = newVal;
				}
			},
		},
		config: {
			handler: function (newVal) {
				this.$emit('newConfig', newVal);
			},
		},
		applicationID: {
			handler: function (newVal) {
				this.config.applicationID = newVal;
			},
		},
	},
	data() {
		return {
			showConfig: true,
			showRequirementsTest: false,
			activeRequirementsCheck: false,
			os: ['win32', 'linux', 'darwin'],
			pipelines: [],
			helpers: [],
			bundles: [],
			config: {
				applicationID: null,
				bundleID: null,
				frameworkID: null,
				helperID: null,
				os: null,
				predecessorID: null,
				input: [],
				output: [],
				entryPoint: '',
				name: '',
				notes: '',
				requirements: [],
			},
		};
	},
	created() {
		this.config.applicationID = this.applicationID;

		this.setupComponent();
		this.$emit('newConfig', this.config);
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			this.$cr.getAllPipelines(0, -1, this.config.applicationID, null, null, (err, result) => {
				if (!err) this.pipelines = result.pipelines;
			});

			this.$cr.getAllHelper(0, -1, this.config.applicationID, null, (err, result) => {
				if (!err) this.helpers = result.helpers;
			});

			this.$cr.getDataBundleMeta(this.applicationID, (err, result) => {
				if (!err) this.bundles = result;
			});
		},
		// @vuese
		// Toggles the view between the config and requirements upload
		toggleConfig(showConfig) {
			this.showConfig = showConfig;
		},
		// @vuese
		// Parses a text file as requirements content
		// @arg e[Object] - The event that occured
		async uploadRequirements(e) {
			e.preventDefault();
			if (e.target.files) {
				try {
					let that = this;
					let files = Array.from(e.target.files);
					let reader = new FileReader();
					reader.onload = function () {
						let text = reader.result;
						that.config.requirements = text.split('\n');

						e.target.value = null;
					};
					reader.readAsText(files[0]);
				} catch (error) {
					console.log(error);
					this.$global.showToast('error', this.$t('puiCantReadRequirements'));
				}
			} else this.$global.showToast('warn', this.$t('puiCantReadRequirements'));
		},
		// @vuese
		// Uploads and parses a json file with config informations
		// @arg e[Object] - The event that occured
		async uploadConfig(e) {
			e.preventDefault();
			if (e.target.files) {
				let files = Array.from(e.target.files);
				let conf = await this.parseJsonFile(files[0]);
				let missingProperties = [];
				// Check the application in the config file
				if (conf.application) {
					if (this.config.applicationID != conf.application) this.$global.showToast('warn', this.$t('puiWrongApplication'));
				} else missingProperties.push(this.$t('puiApplication'));

				// Check the framework in the config file
				if (conf.framework) {
					if (this.frameworks.filter((frame) => frame.frameworkID == conf.framework).length == 0)
						this.$global.showToast('warn', this.$t('puiWrongFramework'));
					else this.config.frameworkID = conf.framework;
				} else missingProperties.push(this.$t('puiFramework'));

				// Check the os in the config file
				if (conf.os) {
					if (this.os.filter((os) => os == conf.os).length == 0) this.$global.showToast('warn', this.$t('puiWrongOS'));
					else this.config.os = conf.os;
				} else missingProperties.push(this.$t('puiOS'));

				// Check the bundle in the config file
				if (conf.bundleID) {
					if (this.bundles.filter((bundle) => bundle.bundleID == conf.bundleID).length == 0)
						this.$global.showToast('warn', this.$t('puiWrongBundle'));
					else this.config.bundleID = conf.bundleID;
				} else missingProperties.push(this.$t('puiBundle'));

				// Check the bundle in the config file
				if (conf.helperID) {
					if (this.helpers.filter((helper) => helper.helperID == conf.helperID).length == 0)
						this.$global.showToast('warn', this.$t('puiWrongHelper'));
					else this.config.helperID = conf.helperID;
				} else missingProperties.push(this.$t('puiHelper'));

				// Check the predecessor pipeline in the config file
				if ('predecessorID' in conf) {
					if (
						this.pipelines.filter((pipeline) => pipeline.pipelineID == conf.predecessorID).length == 0 &&
						conf.predecessorID &&
						conf.predecessorID !== 'NEW'
					)
						this.$global.showToast('warn', this.$t('puiWrongPipeline'));
					else this.config.predecessorID = conf.predecessorID;
				} else missingProperties.push(this.$t('puiPredecessor'));

				// Check the input property in the config file
				if (conf.input) {
					if (conf.input.length !== 0) this.config.input = conf.input;
					else missingProperties.push(this.$t('puiInput'));
				} else missingProperties.push(this.$t('puiInput'));

				// Check the output property in the config file
				if (conf.output) {
					if (conf.output.length !== 0) this.config.output = conf.output;
					else missingProperties.push(this.$t('puiOutput'));
				} else missingProperties.push(this.$t('puiOutput'));

				// Check the name property in the config file
				if (conf.entryPoint) {
					this.config.entryPoint = conf.entryPoint;
					this.$emit('setEntryPoint', conf.entryPoint);
				} else missingProperties.push(this.$t('puiEntryPoint'));

				// Check the name property in the config file
				if (conf.name) this.config.name = conf.name;
				else missingProperties.push(this.$t('puiName'));

				// Check the notes property in the config file
				if (conf.notes) this.config.notes = conf.notes;
				else missingProperties.push(this.$t('puiNotes'));

				if (missingProperties.length > 0) {
					this.$global.showToast('warn', `${this.$t('puiMissingProperties')}: ${missingProperties.join(', ')}`);
				}
			} else this.$global.showToast('warn', this.$t('puiCantUploadFile'));
			e.target.value = null;
		},
		// @vuese
		// Parses a uploaded json file
		// @arg file[Object] - The uploaded json file
		async parseJsonFile(file) {
			return new Promise((resolve, reject) => {
				const fileReader = new FileReader();
				fileReader.onload = (e) => {
					try {
						let result = JSON.parse(e.target.result);
						resolve(result);
					} catch (error) {
						document.getElementById('configUpload').value = null;
						this.$global.showToast('error', this.$t('puiInvalidJSON'));
						reject(error);
					}
				};

				fileReader.onerror = (error) => {
					document.getElementById('configUpload').value = null;
					this.$global.showToast('error', this.$t('puiInvalidJSON'));
					reject(error);
				};

				fileReader.readAsText(file);
			});
		},
		// @vuese
		// Checks ans sets the input of the input field
		setInput() {
			let element = document.getElementById('pipelineInput');
			let value = element.value.replace('.', '');
			if (value !== '') {
				if (this.config.input.includes(value)) this.$global.showToast('warn', this.$t('puiInputAlreadyExists'));
				else this.config.input.push(value);
				element.value = '';
			}
		},
		// @vuese
		// Removes a input element
		// @arg input[String] - The input that gets removed
		removeInput(input) {
			this.config.input = this.config.input.filter((inp) => inp !== input);
		},
		// @vuese
		// Checks ans sets the input of the output field
		setOutput() {
			let element = document.getElementById('pipelineOutput');
			let value = element.value.replace('.', '');

			if (value !== '') {
				if (this.config.output.includes(value)) this.$global.showToast('warn', this.$t('puiOutputAlreadyExists'));
				else this.config.output.push(value);
				element.value = '';
			}
		},
		// @vuese
		// Removes a output element
		// @arg output[String] - The output that gets removed
		removeOutput(output) {
			this.config.output = this.config.output.filter((out) => out !== output);
		},
		// @vuese
		// Checks if the requirements can be tested
		// @returns [Boolean] - Is true if requirements can be tested
		checkRequirementsCondition() {
			if (this.config.requirements.length > 0) {
				// Filter empty lines and comments
				if (this.config.requirements.filter((req) => req !== '' && req.charAt(0) !== '#').length > 0) return true;
				else return false;
			} else return false;
		},
		// @vuese
		// Tests if the specified requirements can be installed and dont have any conflicts
		testRequirements() {
			this.showRequirementsTest = true;
			if (!this.activeRequirementsCheck) {
				this.activeRequirementsCheck = true;
				let that = this;
				this.$global.postData(
					'pipeline',
					`/requirements`,
					{ requirements: this.config.requirements },
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
					},
					function (err, result) {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							that.$global.showToast('error', that.$t(msg ? msg : 'puiRequirementsError'));
						} else that.$global.showToast('success', that.$t('puiRequirementsOK'), true);

						that.activeRequirementsCheck = false;
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.pui-wrap-content {
	width: 100%;
	height: fit-content;
	/* padding-bottom: 20px; */
	/* position: relative; */
}

.pui-wrap-top {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pui-wrap-top button {
	flex: 1 1;
	margin: 0px 5px 10px 5px;
	padding: 5px 5px;
	background-color: var(--main-color-3);
	color: var(--main-color-text-light);
}

.pui-wrap-top button:hover {
	background-color: var(--main-color-2);
}

.pui-selected {
	background-color: var(--main-color-6) !important;
	color: var(--main-color-text-dark) !important;
}

.pui-wrap-upload {
	width: fit-content;
	display: inline-block;
	overflow: hidden;
}

.pui-file-upload,
.pui-requirements-upload {
	width: fit-content;
	padding: 5px 10px;
	display: inline-block;
	border-radius: 5px;
	font-size: 17px;
	background-color: var(--main-color-3);
}

.pui-file-upload:hover,
.pui-requirements-upload:hover {
	background-color: var(--main-color-2);
}

.pui-file-upload:hover,
.pui-requirements-upload:hover {
	cursor: pointer;
}

.pui-file-upload input[type='file'],
.pui-requirements-upload input[type='file'] {
	width: fit-content;
	display: none;
}

.pui-wrap-information {
	width: 100%;
	height: fit-content;
	padding: 5px 10px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
}

.pui-wrap-information h2 {
	width: fit-content;
	margin: 5px 10px 10px 0px;
	display: inline-block;
	vertical-align: top;
	font-size: 18px;
	font-weight: normal;
	text-decoration: underline;
}

.pui-informations {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
}

.pui-wrap-input {
	display: inline-block;
	padding: 5px 10px;
	flex: 1 1 200px;
	vertical-align: top;
}

.pui-wrap-input p,
.pui-wrap-select p,
.pui-wrap-textarea p {
	margin: 0px 0px 5px 0px;
}

.pui-wrap-input input {
	width: 100%;
	box-sizing: border-box;
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.pui-wrap-pipeline-io {
	height: 100px;
	margin-top: 5px;
	border: 2px solid var(--main-color-border-dark);
	box-sizing: border-box;
	overflow: auto;
	background-color: var(--main-color-3);
}

.pui-wrap-pipeline-io p {
	width: 90%;
	margin: 0px auto;
	padding: 5px 10px;
	position: relative;
	box-sizing: border-box;
	border-bottom: 1px solid var(--main-color-border-light);
}

.pui-wrap-pipeline-io p i {
	position: absolute;
	top: 5px;
	right: 5px;
	display: none;
	font-size: 22px;
	text-shadow: 1px 1px var(--main-color-text-dark);
	color: var(--main-color-error);
	cursor: pointer;
}

.pui-wrap-pipeline-io p:hover i {
	display: block;
}

.pui-no-element {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: none !important;
}

.pui-wrap-select {
	display: inline-block;
	padding: 5px 10px;
	flex: 1 1 200px;
	vertical-align: top;
}

.pui-wrap-select select {
	width: 100%;
	box-sizing: border-box;
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.pui-wrap-io {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1 100%;
}

.pui-wrap-io div {
	flex: 1 1 50%;
}

.pui-wrap-textarea {
	display: inline-block;
	padding: 5px 10px;
	flex: 1 1 100%;
	vertical-align: top;
}

.pui-wrap-textarea textarea {
	width: 100%;
	height: 100px;
	box-sizing: border-box;
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.pui-spacer {
	height: 10px;
}

.pui-wrap-requirements {
	width: 100%;
	height: calc(100vh - 340px);
	margin-bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
}

.pui-requirements {
	width: 100%;
	height: 100%;
	padding: 5px;
	text-align: start;
	box-sizing: border-box;
	overflow: auto;
}

.pui-requirements > pre > span {
	min-width: 35px;
	display: inline-block;
	border-right: 1px solid var(--main-color-border-light);
}

.pui-wrap-btn {
	width: 100%;
	margin: 10px 0px 5px 0px;
	text-align: center;
}

.pui-wrap-btn button {
	font-size: 17px;
}

.pui-wrap-log {
	width: 100%;
	height: calc(100% - 50px);
	position: absolute;
	top: 50px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 11;
	background-color: var(--main-color-dark-cc);
}

.pui-wrapper {
	width: 90%;
	height: 90%;
	padding: 10px 30px 10px 30px;
	overflow: auto;
	position: relative;
	box-sizing: border-box;
	background-color: var(--main-color-2);
	border-radius: 20px;
	border: 1px solid var(--main-color-5);
	animation: slideIn 0.4s linear;
}

.pui-wrapper h2 {
	margin: 0px 0px 5px 0px;
	color: var(--main-color-6);
}

.pui-log {
	width: 100%;
	height: calc(100% - 30px);
}

.pui-log i {
	font-size: 25px;
	position: absolute;
	top: 5px;
	right: 5px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pui-log i:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}
</style>
