var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"puf-wrap-content"},[_c('div',{staticClass:"puf-wrap-input-upload"},[_c('div',{staticClass:"app-default-btn puf-file-upload"},[_c('label',[_c('input',{attrs:{"type":"file","webkitdirectory":"","directory":"","multiple":""},on:{"input":_vm.handleUpload}}),_c('i',{staticClass:"fa fa-cloud-upload"}),_vm._v(" "+_vm._s(_vm.$t('pufUploadFolder'))+" ")])]),_c('div',{staticClass:"app-default-btn puf-file-upload"},[_c('label',[_c('input',{attrs:{"type":"file","multiple":""},on:{"input":_vm.handleUpload}}),_c('i',{staticClass:"fa-solid fa-file-arrow-up"}),_vm._v(" "+_vm._s(_vm.$t('pufUploadFiles'))+" ")])])]),_c('div',{staticClass:"puf-wrap-upload",on:{"dragenter":_vm.checkDragEnter}},[_c('div',{staticClass:"puf-dnd-background"},[_c('div',[_c('i',{staticClass:"fa-solid fa-folder-open"}),_c('p',[_vm._v(_vm._s(_vm.$t('pufUploadFilesDND')))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUpload),expression:"showUpload"}],staticClass:"puf-hover-div",on:{"dragover":_vm.checkDragOver,"dragleave":function($event){$event.preventDefault();
				_vm.showUpload = false;},"drop":_vm.handleUpload}}),(_vm.showUploadDialog)?_c('div',{staticClass:"puf-upload-dialog"},[_c('h2',[_vm._v(_vm._s(_vm.$t('pufAddItemsToFolder')))]),_vm._l((_vm.sortKeys()),function(folderKey){return _c('div',{key:folderKey,staticClass:"puf-folder",on:{"click":function($event){_vm.basePath = folderKey.replace(new RegExp('/$'), '');
					_vm.showUploadDialog = false;}}},[_c('i',{staticClass:"fa-solid fa-folder"}),_vm._v(_vm._s(folderKey)+" ")])})],2):_vm._e(),_c('div',{staticClass:"puf-wrap-uploaded-files"},[(Object.keys(_vm.folders).length > 0)?_c('div',{staticClass:"puf-wrap-file-tree"},_vm._l((_vm.sortKeys()),function(folderKey){return _c('div',{key:folderKey,staticClass:"puf-tree-component"},[(folderKey !== '/')?_c('p',{staticClass:"puf-folder",style:({ paddingLeft: `${20 * (folderKey.split('/').length - 3) + 20}px` })},[_c('i',{staticClass:"fa-solid fa-folder"}),_vm._v(" "+_vm._s(_vm.getFolderName(folderKey))+" "),_c('i',{staticClass:"fa-solid fa-xmark puf-delete",style:({ marginLeft: `${20 * (folderKey.split('/').length - 3)}px` }),on:{"click":function($event){return _vm.deleteFolder(folderKey)}}})]):_vm._e(),_vm._l((_vm.folders[folderKey]),function(file){return _c('div',{key:file.fileID},[_c('p',{staticClass:"puf-file",style:({
								paddingLeft: folderKey !== '/' ? `${20 * (folderKey.split('/').length - 2) + 20}px` : `20px`,
							})},[_c('span',{class:[
									_vm.possibleEntryPoint(file, folderKey) ? 'puf-file-hover' : '',
									_vm.entryPoint == file.file.name && folderKey.split('/').length == 2
										? 'puf-selected-entry-point'
										: '',
								],on:{"click":function($event){_vm.possibleEntryPoint(file, folderKey) ? _vm.$emit('setEntryPoint', file.file.name) : null}}},[_c('i',{staticClass:"fa-solid fa-file"}),_vm._v(" "+_vm._s(file.file.name)+" ")]),_c('i',{staticClass:"fa-solid fa-xmark puf-delete",style:({
									marginLeft: folderKey !== '/' ? `${20 * (folderKey.split('/').length - 2)}px` : `0px`,
								}),on:{"click":function($event){return _vm.deleteFile(folderKey, file.fileID)}}})])])})],2)}),0):_vm._e()])]),(_vm.showRequirementsTest)?_c('div',{staticClass:"puf-wrap-log"},[_c('div',{staticClass:"puf-wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t('pufRequirementsCheck')))]),_c('div',{staticClass:"puf-log"},[_c('i',{staticClass:"fa-solid fa-circle-xmark",on:{"click":function($event){_vm.showRequirementsTest = false}}}),_c('LiveLog',{attrs:{"connect":true}})],1)])]):_vm._e(),_c('div',{staticClass:"puf-wrap-save-btn"},[_c('button',{class:[
				Object.keys(_vm.folders).length == 0 ? 'app-disabled-btn' : 'app-success-btn',
				_vm.uploadPending ? 'puf-disable-click' : '',
			],on:{"click":_vm.checkUpload}},[_vm._v(" "+_vm._s(_vm.$t('pufSaveFiles'))+" "),(_vm.uploadPending)?_c('i',{staticClass:"fa-solid fa-spinner"}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }