<template>
	<div class="dev-wrap-content">
		<div :key="refreshToggle">
			<div class="dev-wrap-top">
				<div class="dev-wrap-select">
					<label>{{ $t('devApplications') }}</label>
					<select v-model="config.application" @change="queryElements">
						<option :value="null" disabled selected hidden>{{ $t('devSelectApplication') }}</option>
						<option v-for="application in applications" :key="application.applicationID" :value="application.applicationID">
							{{ $t(`${application.abbreviation}Name`) }}
						</option>
					</select>
				</div>
				<div class="dev-wrap-select">
					<label>{{ $t('devFrameworks') }}</label>
					<select v-model="config.framework" @change="queryElements">
						<option :value="null" disabled selected hidden>{{ $t('devSelectFramework') }}</option>
						<option v-for="framework in frameworks" :key="framework.frameworkID" :value="framework.frameworkID">
							{{ $t(`${framework.abbreviation}Name`) }}
						</option>
					</select>
				</div>
				<div class="dev-wrap-select">
					<label>{{ $t('devOS') }}</label>
					<select v-model="config.os">
						<option :value="null" disabled selected hidden>{{ $t('devSelectOS') }}</option>
						<option value="win32">{{ $t('devWindows') }}</option>
						<option value="linux">{{ $t('devLinux') }}</option>
						<option value="darwin">{{ $t('devMac') }}</option>
					</select>
				</div>
			</div>
			<div v-if="config.application && config.framework" class="dev-wrap-mid">
				<h1>{{ $t('devPipelineHelper') }}</h1>
				<HelperTable
					v-if="helpers"
					:helpers="helpers"
					:allHelpers="allHelperAmount"
					:applications="applications"
					:frameworks="frameworks"
					:devMode="true"
					:selectedHelper="config.helper"
					@sortHelpers="sortHelpers"
					@selectHelper="selectHelper"
					@queryHelpers="queryHelpers"
				/>
				<div v-else class="dev-table-placeholder">{{ $t('devLoading') }}<i class="fa-solid fa-spinner"></i></div>
				<h1>{{ $t('devPipelines') }}</h1>
				<PipelineTable
					v-if="pipelines"
					:pipelines="pipelines"
					:allPipelines="allPipelineAmount"
					:applications="applications"
					:frameworks="frameworks"
					:devMode="true"
					:selectedPipeline="config.pipeline"
					@sortPipelines="sortPipelines"
					@selectPipeline="selectPipeline"
					@queryPipelines="queryPipelines"
				/>
				<div v-else class="dev-table-placeholder">{{ $t('devLoading') }}<i class="fa-solid fa-spinner"></i></div>
				<h1>{{ $t('devBundles') }}</h1>
				<BundleTable
					v-if="bundles"
					:bundles="bundles"
					:allBundles="allBundleAmount"
					:applications="applications"
					:devMode="true"
					:selectedBundles="config.bundles"
					:multiSelect="true"
					@sortBundles="sortBundles"
					@selectBundle="selectBundle"
					@selectAll="selectAllBundles"
					@queryBundles="queryBundles"
				/>
				<div v-else class="dev-table-placeholder">{{ $t('devLoading') }}<i class="fa-solid fa-spinner"></i></div>
				<h1>{{ $t('devPackages') }}</h1>
				<PackageTable
					v-if="packages"
					:packages="packages"
					:allPackages="allPackagesAmount"
					:applications="applications"
					:devMode="true"
					:selectedPackages="config.packages"
					:multiSelect="true"
					@sortPackages="sortPackages"
					@selectPackage="selectPackage"
					@selectAll="selectAllPackages"
					@selectAllGroundtruth="selectAllGroundtruthPackages"
					@queryPackages="queryPackages"
				/>
				<div v-else class="dev-table-placeholder">{{ $t('devLoading') }}<i class="fa-solid fa-spinner"></i></div>
			</div>
			<div v-if="config.application && config.framework" class="dev-wrap-bot">
				<!-- <div class="dev-wrap-bot"> -->
				<div class="dev-wrap-select">
					<label>{{ $t('devSelectBundle') }}</label>
					<select v-model="config.previousRevision">
						<option :value="null" disabled selected hidden>{{ $t('devChooseBundle') }}</option>
						<option value="NEW">{{ $t('devNewBundle') }}</option>
						<option v-for="bundle in bundleRevisions" :key="bundle.revisionID" :value="bundle.revisionID">
							{{ bundle.name }} ({{ $global.shortenRevisionID(bundle.revisionID) }})
						</option>
					</select>
				</div>
				<div class="dev-wrap-input">
					<label>{{ $t('devNewBundleName') }}</label>
					<input type="text" :placeholder="$t('devNewBundleName')" v-model="config.newBundleName" />
				</div>
			</div>
		</div>
		<div class="dev-wrap-create-btn">
			<button :class="[saveCondition() && !preventClick ? 'app-success-btn' : 'app-disabled-btn']" @click="createPipeline">
				{{ $t('devGeneratePipeline') }}
			</button>
		</div>
		<div v-if="showStatus" class="dev-wrap-status">
			<DevelopmentStatus @closeStatus="closeStatus" />
		</div>
	</div>
</template>

<script>
import HelperTable from '../components/tables/HelperTable.vue';
import PipelineTable from '../components/tables/PipelineTable.vue';
import BundleTable from '../components/tables/BundleTable.vue';
import PackageTable from '../components/tables/PackageTable.vue';
import DevelopmentStatus from '../components/development/DevelopmentStatus.vue';
import axios from 'axios';
/**
 * @group Development
 * Lets the user configure and create a pipeline environment
 */
export default {
	name: 'Development',
	components: {
		HelperTable,
		PipelineTable,
		BundleTable,
		PackageTable,
		DevelopmentStatus,
	},
	data() {
		return {
			refreshToggle: false,
			user: null,
			applications: [],
			frameworks: [],
			helpers: null,
			pipelines: null,
			bundles: null,
			bundleRevisions: null,
			packages: null,
			allHelperAmount: 0,
			allPipelineAmount: 0,
			allPackagesAmount: 0,
			allBundleAmount: 0,
			config: {
				application: null,
				framework: null,
				os: null,
				helper: null,
				pipeline: null,
				bundles: [],
				packages: [],
				previousRevision: null,
				newBundleName: null,
			},
			preventClick: false,
			showStatus: false,
		};
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			this.user = this.$global.getUser();
			this.$cr.getAllApplications((err, result) => {
				if (!err) this.applications = result;
			});
			this.$cr.getAllFrameworks((err, result) => {
				if (!err) this.frameworks = result;
			});
		},
		// @vuese
		// Resets the vars of the component
		resetComponent() {
			this.config = {
				application: null,
				framework: null,
				os: null,
				helper: null,
				pipeline: null,
				bundles: [],
				packages: [],
				previousRevision: null,
				newBundleName: null,
			};
			this.preventClick = false;
			this.refreshToggle = !this.refreshToggle;
		},
		// @vuese
		// Querries all data (helper, pipelines data packages and data bundles)
		queryElements() {
			if (this.config.application) {
				this.$cr.getDataBundleMeta(this.config.application, (err, result) => {
					if (!err) {
						this.bundleRevisions = result.sort((a, b) => a.name.localeCompare(b.name));
					}
					if (this.config.framework) {
						this.queryHelpers(0, 50);
						this.queryPipelines(0, 50);
					}
					this.queryPackages(0, 50);
					this.queryBundles(0, 5);
				});
			}
		},
		// @vuese
		// Selects a helper
		// @arg helperID[String] - The ID of the helper
		selectHelper(helperID) {
			if (this.config.helper == helperID) {
				this.config.helper = null;
				this.helpers = this.helpers.map((helper) => {
					helper.selected = false;
					return helper;
				});
			} else {
				this.config.helper = helperID;
				this.helpers = this.helpers.map((helper) => {
					if (helper.helperID == helperID) helper.selected = true;
					else helper.selected = false;
					return helper;
				});
			}
			this.$forceUpdate();
		},
		// @vuese
		// Selects a pipeline
		// @arg pipelineID[String] - The ID of the pipeline
		selectPipeline(pipelineID) {
			if (this.config.pipeline == pipelineID) {
				this.config.pipeline = null;
				this.pipelines = this.pipelines.map((pipeline) => {
					pipeline.selected = false;
					return pipeline;
				});
			} else {
				this.config.pipeline = pipelineID;
				this.pipelines = this.pipelines.map((pipeline) => {
					if (pipeline.pipelineID == pipelineID) pipeline.selected = true;
					else pipeline.selected = false;
					return pipeline;
				});
			}
			this.$forceUpdate();
		},
		// @vuese
		// Selects a data bundle
		// @arg bundleID[String] - The ID of the data bundle
		selectBundle(bundleID) {
			let updateBundle = this.bundles.findIndex((bundle) => bundle.bundleID == bundleID);
			if (updateBundle !== -1) this.bundles[updateBundle].selected = !this.bundles[updateBundle].selected;

			if (this.config.bundles.includes(bundleID)) this.config.bundles = this.config.bundles.filter((bundle) => bundle != bundleID);
			else this.config.bundles.push(bundleID);
		},
		// @vuese
		// Selects a data package
		// @arg packageID[String] - The ID of the data package
		selectPackage(packageID) {
			let updatePackage = this.packages.findIndex((pack) => pack.packageID == packageID);
			if (updatePackage !== -1) this.packages[updatePackage].selected = !this.packages[updatePackage].selected;

			if (this.config.packages.includes(packageID)) this.config.packages = this.config.packages.filter((pack) => pack != packageID);
			else this.config.packages.push(packageID);
		},
		// @vuese
		// Selects all data packages
		// @arg select[Boolean] - Is true if everything should be selected
		selectAllPackages(select) {
			if (select) {
				this.packages.map((pack) => {
					if (!this.config.packages.includes(pack.packageID)) {
						this.config.packages.push(pack.packageID);
						pack.selected = true;
					}
					return pack;
				});
			} else {
				let removedPackages = [];
				this.packages.map((pack) => {
					pack.selected = false;
					removedPackages.push(pack.packageID);
					return pack;
				});

				this.config.packages = this.config.packages.filter((packageID) => !removedPackages.includes(packageID));
			}
		},
		// @vuese
		// Selects all data bundles
		// @arg select[Boolean] - Is true if everything should be selected
		selectAllBundles(select) {
			if (select) {
				this.bundles.map((bundle) => {
					if (!this.config.bundles.includes(bundle.bundleID)) {
						this.config.bundles.push(bundle.bundleID);
						bundle.selected = true;
					}
					return bundle;
				});
			} else {
				let removedBundles = [];
				this.bundles.map((bundle) => {
					bundle.selected = false;
					removedBundles.push(bundle.bundleID);
					return bundle;
				});

				this.config.bundles = this.config.bundles.filter((bundleID) => !removedBundles.includes(bundleID));
			}
		},
		// @vuese
		// Selects all data packages that are verified
		// @arg select[Boolean] - Is true if everything should be selected
		selectAllGroundtruthPackages(select) {
			if (!select) {
				let removedPackages = [];
				this.packages.map((pack) => {
					if (pack.isGroundtruth && this.config.packages.includes(pack.packageID)) {
						removedPackages.push(pack.packageID);
						pack.selected = false;
					}
					return pack;
				});
				this.config.packages = this.config.packages.filter((packageID) => !removedPackages.includes(packageID));
			} else {
				this.packages.map((pack) => {
					if (pack.isGroundtruth && !this.config.packages.includes(pack.packageID)) {
						this.config.packages.push(pack.packageID);
						pack.selected = true;
					}
					return pack;
				});
			}
		},
		// @vuese
		// Sorts all helpers by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortHelpers(sort) {
			this.helpers = this.$global.sortValues(sort, this.helpers);
		},
		// @vuese
		// Sorts all pipelines by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortPipelines(sort) {
			this.pipelines = this.$global.sortValues(sort, this.pipelines);
		},
		// @vuese
		// Sorts all packages by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortPackages(sort) {
			this.packages = this.$global.sortValues(sort, this.packages);
		},
		// @vuese
		// Sorts all bundles by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortBundles(sort) {
			this.bundles = this.$global.sortValues(sort, this.bundles);
		},
		// @vuese
		// Queries the helpers by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryHelpers(offset, amount) {
			this.$cr.getAllHelper(offset, amount, this.config.application, this.config.framework, (err, result) => {
				if (!err) {
					this.helpers = result.helpers;
					this.allHelperAmount = result.amount;
					this.helpers.map((helper) => {
						if (this.config.helper == helper.helperID) helper.selected = true;
						return helper;
					});
				}
			});
		},
		// @vuese
		// Queries the pipelines by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryPipelines(offset, amount) {
			this.$cr.getAllPipelines(offset, amount, this.config.application, this.config.framework, null, (err, result) => {
				if (!err) {
					this.pipelines = result.pipelines;
					this.allPipelineAmount = result.amount;
					this.pipelines.map((pipeline) => {
						if (this.config.pipeline == pipeline.pipelineID) pipeline.selected = true;
						return pipeline;
					});
				}
			});
		},
		// @vuese
		// Queries the data bundles by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryBundles(offset, amount) {
			this.$cr.getDataBundles(offset, amount, this.config.application, (err, result) => {
				if (!err) {
					this.bundles = result.bundles;
					this.allBundleAmount = result.amount;
					this.bundles.map((bundle) => {
						if (this.config.bundles.includes(bundle.bundleID)) bundle.selected = true;
						return bundle;
					});
				}
			});
		},
		// @vuese
		// Queries the data packages by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryPackages(offset, amount) {
			this.$cr.getDataPackages(offset, amount, this.config.application, 'unverified', null, (err, result) => {
				if (!err) {
					this.packages = result.packages;
					this.allPackagesAmount = result.amount;
					this.packages.map((pack) => {
						if (this.config.packages.includes(pack.packageID)) pack.selected = true;
						return pack;
					});
				}
			});
		},
		// Checks if the pipeline can be created
		// @return [Boolean] - Is true if the pipeline can be created
		saveCondition() {
			if (!this.config.application) return false;
			else if (!this.config.framework) return false;
			else if (!this.config.os) return false;
			else if (!this.config.helper) return false;
			else if (this.config.packages.length == 0 && this.config.bundles.length == 0) return false;
			else if (!this.config.previousRevision) return false;
			else if (!this.config.newBundleName) return false;
			else if (this.config.newBundleName.length < 5) return false;
			else if (this.config.newBundleName.length > 30) return false;
			else return true;
		},
		// @vuese
		// Creates the pipeline environment and adds a new data bundle
		createPipeline() {
			this.preventClick = true;
			try {
				if (!this.config.application) throw 'devNoApplication';
				if (!this.config.framework) throw 'devNoFramework';
				if (!this.config.os) throw 'devNoOS';
				if (!this.config.helper) throw 'devNoHelper';
				if (this.config.packages.length == 0 && this.config.bundles.length == 0) throw 'devNoData';
				if (!this.config.previousRevision) throw 'devNoOS';
				if (this.config.newBundleName.length < 5 || this.config.newBundleName.length > 30) throw 'devNameLength';
				this.showStatus = true;

				this.$global.postData(
					'data',
					'/bundles',
					{
						application: this.config.application,
						packages: this.config.packages,
						bundles: this.config.bundles,
						previousRevision: this.config.previousRevision,
						name: this.config.newBundleName,
						isVerification: false,
					},
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
					},
					(err, bundleID) => {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							this.$global.showToast('error', this.$t(msg ? msg : 'devCreateQuery'));
							this.preventClick = false;
						} else {
							let input = [];
							this.config.packages.forEach((pack) => {
								let types = this.packages.filter((p) => p.packageID == pack)[0];
								if (types) {
									types = types.mimeTypes.split(',');
									types.forEach((mime) => {
										if (!input.includes(mime)) input.push(mime);
									});
								}
							});

							this.config.bundles.forEach((bID) => {
								let types = this.bundles.filter((b) => b.bundleID == bID)[0];
								if (types) {
									types = types.mimeTypes.split(',');
									types.forEach((mime) => {
										if (!input.includes(mime)) input.push(mime);
									});
								}
							});
							let that = this;

							axios
								.post(
									`/confAPI/pipeline/environment`,
									{
										applicationID: this.config.application,
										frameworkID: this.config.framework,
										os: this.config.os,
										helperID: this.config.helper,
										pipelineID: this.config.pipeline,
										bundleID: bundleID,
										input: input,
									},
									{
										headers: { userid: this.$global.getUser().userID },
										auth: JSON.parse(sessionStorage.getItem('credentials')),
									}
								)
								.then((res) => {
									console.log(res.data.link);
									const link = document.createElement('a');
									link.href = res.data.link;
									link.setAttribute('download', res.data.link);
									document.body.appendChild(link);
									link.click();
								})
								.catch((err) => {
									console.log(err);
									let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
									that.$global.showToast('error', that.$t(msg ? msg : 'devCreateQuery'));
									that.preventClick = false;
								});
						}
					}
				);
			} catch (error) {
				this.$global.showToast('warn', this.$t(error));
				this.preventClick = false;
			}
		},
		// @vuese
		// Reloads the page after the export is finished
		closeStatus() {
			location.reload();
		},
	},
};
</script>

<style scoped>
.dev-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

.dev-wrap-top,
.dev-wrap-bot {
	width: 90%;
	max-width: 1800px;
	margin: auto;
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: wrap;
}

.dev-wrap-select {
	min-width: fit-content;
	margin: 0px 20px 10px 0px;
}

.dev-wrap-select label {
	display: block;
	margin: 5px;
}

.dev-wrap-select select {
	width: fit-content;
	font-size: 17px;
}

.dev-wrap-mid h1 {
	width: fit-content;
	color: var(--main-color-6);
	margin: 5px auto 10px auto;
}

.dev-table-placeholder {
	height: 240px;
	max-width: 1800px;
	width: 90%;
	margin: 0px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
}

.dev-table-placeholder i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.dev-wrap-input {
	min-width: fit-content;
	margin: 0px 20px 10px 0px;
}

.dev-wrap-input label {
	display: block;
	margin: 5px;
}

.dev-wrap-input input {
	width: fit-content;
	font-size: 17px;
	box-sizing: border-box;
}

.dev-wrap-create-btn {
	width: 100%;
	margin: 10px 0px;
	text-align: center;
}

.dev-wrap-create-btn button {
	font-size: 20px;
}

.dev-wrap-status {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
}
</style>
