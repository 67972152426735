import axios from 'axios';

let queue = [];
let currentToast = null;
let toastWrapper = null;
let toastType = null;
let toastMsg = null;
let toastSticky = false;
let timeoutInit = false;
let user = null;
let context = null;
export const global = {
	// Initializes the global functions with the vue context
	// @arg ctx[Object] - The context of the vue components
	initializeGlobal: function (ctx) {
		context = ctx;
	},
	// Shows a toast width a certain type and message
	// @arg type[String] - The type of the toast
	// @arg msg[String] - The displayed message
	showToast: function (type, msg, sticky) {
		if (!currentToast) {
			currentToast = `${type}${msg}${sticky}`;
			window.setTimeout(() => {
				toastType = type;
				toastMsg = msg;
				toastSticky = sticky;
				toastWrapper = document.querySelector('.ap-toast-wrapper');
				if (type.toLowerCase() == 'info') {
					toastWrapper.classList.add('show-info');
					document.querySelector('.ap-info-text').innerText = msg;
				} else if (type.toLowerCase() == 'success') {
					toastWrapper.classList.add('show-success');
					document.querySelector('.ap-success-text').innerText = msg;
				} else if (type.toLowerCase() == 'warn') {
					toastWrapper.classList.add('show-warn');
					document.querySelector('.ap-warn-text').innerText = msg;
				} else if (type.toLowerCase() == 'error') {
					toastWrapper.classList.add('show-error');
					document.querySelector('.ap-error-text').innerText = msg;
				}

				toastWrapper.addEventListener('animationend', this.animationListener);
			}, 100);
		} else {
			let msgExists =
				queue.filter((pair) => pair[0] == type && pair[1] == msg && pair[2] == sticky)[0] ||
				currentToast == `${type}${msg}${sticky}`;
			if (!msgExists) queue.push([type, msg, sticky]);

			if (!timeoutInit) {
				timeoutInit = true;

				window.setInterval(() => {
					if (!currentToast && queue.length > 0) {
						let toast = queue.shift();
						if (toast) this.showToast(toast[0], toast[1], toast[2]);
					}
				}, 100);
			}
		}
	},
	// Listens to a toast animation end and removes the animation
	animationListener: function () {
		let icon = document.createElement('i');
		if (toastType.toLowerCase() == 'info') {
			toastWrapper.classList.remove('show-info');
			icon.classList.add('fas', 'fa-info-circle');
		} else if (toastType.toLowerCase() == 'success') {
			toastWrapper.classList.remove('show-success');
			icon.classList.add('fas', 'fa-check-circle');
		} else if (toastType.toLowerCase() == 'warn') {
			toastWrapper.classList.remove('show-warn');
			icon.classList.add('fas', 'fa-exclamation-circle');
		} else if (toastType.toLowerCase() == 'error') {
			toastWrapper.classList.remove('show-error');
			icon.classList.add('fas', 'fa-times-circle');
		}

		if (toastSticky || (typeof toastSticky == 'undefined' && ['warn', 'error'].includes(toastType))) {
			let stickyWrapper = document.querySelector('.ap-sticky-toast-wrapper');
			let stickyNote = document.createElement('div');
			let text = document.createElement('p');
			text.style.cssText = 'margin: 5px 10px; display: block; font-size: 17px; direction: ltr;';
			text.innerText = `${new Date().toLocaleTimeString()}: ${toastMsg}`;
			icon.style.fontSize = '25px';
			stickyNote.appendChild(icon);
			let normalCss = `
				flex: 1 1 100%;
				width: 50px;
				height: 50px;
				max-width: 50px;
				max-height: 50px;
				margin-bottom: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				cursor: pointer;
				box-sizing: border-box;
				border: 1px solid var(--main-color-border-dark);
				border-radius: 50%;
				transition: width 1s ease-in;
				user-select: none;
			`;

			let expandedCss = `
				width: fit-content;
				height: fit-content;
				min-width: 50px;
				max-width: 50vw;
				min-height: 50px;
				max-height: 50vh;
				margin-bottom: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				cursor: pointer;
				box-sizing: border-box;
				border: 1px solid var(--main-color-border-dark);
				border-radius: 20px;
				transition: width 1s ease-in;
				user-select: none;
			`;

			if (toastType.toLowerCase() == 'info') {
				expandedCss += 'background-color: var(--main-color-info);';
				normalCss += 'background-color: var(--main-color-info);';
			} else if (toastType.toLowerCase() == 'success') {
				expandedCss += 'background-color: var(--main-color-success);';
				normalCss += 'background-color: var(--main-color-success);';
			} else if (toastType.toLowerCase() == 'warn') {
				expandedCss += 'background-color: var(--main-color-warn);';
				normalCss += 'background-color: var(--main-color-warn);';
			} else if (toastType.toLowerCase() == 'error') {
				expandedCss += 'background-color: var(--main-color-error);';
				normalCss += 'background-color: var(--main-color-error);';
			}
			stickyNote.style.cssText = normalCss;

			stickyNote.addEventListener('mouseenter', (e) => {
				stickyWrapper.style.display = 'block';
				stickyWrapper.style.width = 'fit-content';
				stickyNote.style.cssText = expandedCss;
				stickyNote.innerHTML = '';
				stickyNote.appendChild(text);
			});

			stickyNote.addEventListener('mouseleave', (e) => {
				stickyWrapper.style.display = 'flex';
				stickyWrapper.style.width = '50px';
				stickyNote.style.cssText = normalCss;
				stickyNote.innerHTML = '';
				stickyNote.appendChild(icon);
			});

			stickyNote.addEventListener('click', (e) => {
				stickyNote.remove();
			});

			stickyWrapper.appendChild(stickyNote);
		}
		currentToast = null;
		toastSticky = null;
		// toastMsg = null;
		toastWrapper.removeEventListener('animationend', this.animationListener);
	},
	// Cancels the current animation
	abortAnimation() {
		if (toastType.toLowerCase() == 'info') toastWrapper.classList.remove('show-info');
		else if (toastType.toLowerCase() == 'success') toastWrapper.classList.remove('show-success');
		else if (toastType.toLowerCase() == 'warn') toastWrapper.classList.remove('show-warn');
		else if (toastType.toLowerCase() == 'error') toastWrapper.classList.remove('show-error');

		currentToast = null;
	},
	// GET request to the backend
	// @arg endpoint[String] - The targeted endpoint
	// @arg route[String] - The called route
	// @arg data[String] - The data that is send
	// @arg options[String] - The header options
	// @arg cb[String] - A callback to the calling function
	getData: async function (endpoint, route, data, options, cb) {
		axios
			.get(`/confAPI/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				console.log(err);
				cb(err, null);
			});
	},
	// POST request to the backend
	// @arg endpoint[String] - The targeted endpoint
	// @arg route[String] - The called route
	// @arg data[String] - The data that is send
	// @arg options[String] - The header options
	// @arg cb[String] - A callback to the calling function
	postData: async function (endpoint, route, data, options, cb) {
		axios
			.post(`/confAPI/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				console.log(err);
				cb(err, null);
			});
	},
	// PUT request to the backend
	// @arg endpoint[String] - The targeted endpoint
	// @arg route[String] - The called route
	// @arg data[String] - The data that is send
	// @arg options[String] - The header options
	// @arg cb[String] - A callback to the calling function
	putData: async function (endpoint, route, data, options, cb) {
		axios
			.put(`/confAPI/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				console.log(err);
				cb(err, null);
			});
	},
	// PATCH request to the backend
	// @arg endpoint[String] - The targeted endpoint
	// @arg route[String] - The called route
	// @arg data[String] - The data that is send
	// @arg options[String] - The header options
	// @arg cb[String] - A callback to the calling function
	patchData: async function (endpoint, route, data, options, cb) {
		axios
			.patch(`/confAPI/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				console.log(err);
				cb(err, null);
			});
	},
	// DELETE request to the backend
	// @arg endpoint[String] - The targeted endpoint
	// @arg route[String] - The called route
	// @arg data[String] - The data that is send
	// @arg options[String] - The header options
	// @arg cb[String] - A callback to the calling function
	deleteData: async function (endpoint, route, data, options, cb) {
		axios
			.delete(`/confAPI/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				console.log(err);
				cb(err, null);
			});
	},
	// Checks if the user can access the current page
	// @arg [Function] cb - Callback to the parent function
	checkAccess: function (cb) {
		let cred = JSON.parse(sessionStorage.getItem('credentials'));
		if (!cred) {
			this.showToast('warn', context.$t('apUnauthorizedWarning'));
			context.$router.push({ name: 'Login' });
		} else {
			this.postData(
				'access',
				'/check-credentials',
				{ credentials: { email: cred.username, password: cred.password } },
				null,
				(err, data) => {
					if (err) {
						this.showToast('warn', context.$t('apUnauthorizedWarning'));
						context.$router.push({ name: 'Login' });
					} else {
						sessionStorage.setItem('credentials', JSON.stringify(data.credentials));
						sessionStorage.setItem('user', JSON.stringify(data.user));
						this.setUser(data.user);
						if (cb) cb();
					}
				}
			);
		}
	},
	// Validates if the provided email matches a certain pattern
	// https://stackoverflow.com/a/201378
	// @arg email[String] - The email
	// @return [Boolean] - Is true if the email is valid
	validEmail: function (email) {
		const re =
			/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
		return re.test(email);
	},
	// Sets the current logged in user
	// @arg user[Object] - The current user
	setUser: function (newUser) {
		user = newUser;
	},
	// Returns the current logged in user
	// @returns [Object] - The current user
	getUser: function () {
		return user;
	},
	// Parses a date that can be displayed to the user with all fields
	// @arg date[String] - The provided date
	// @returns [String] - The parsed date
	parseDateFull: function (date) {
		let d = new Date(date);

		let month = d.getMonth() + 1;
		let day = d.getDate();
		let hours = d.getHours();
		let minutes = d.getMinutes();
		let seconds = d.getSeconds();

		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		let timestamp = `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}:${seconds}`;
		return timestamp;
	},
	// Parses a date that can be displayed to the user with some fields
	// @arg date[String] - The provided date
	// @returns [String] - The parsed date
	parseDateShort: function (date) {
		let d = new Date(date);

		let month = d.getMonth() + 1;
		let day = d.getDate();
		let hours = d.getHours();
		let minutes = d.getMinutes();

		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;

		let timestamp = `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}`;
		return timestamp;
	},
	// Sets the colors for a specific element
	// @arg color[String] - Identifier for global color style
	// @return [String] - Return the parsed global color style
	setColor: function (color) {
		// .trim() is required, otherwise the color would have a space before it
		return `${getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue(color)}`.trim();
	},
	// Checks if the current user can access the given route or element
	// @arg data[Object] - Contains the checked element or route
	// @returns [Boolean] - Is true if the route can be accessed
	hasRights: function (data) {
		let user = this.getUser();
		if (data) {
			if (data.route) {
				if (!user) {
					if (['Login', 'Registration', 'PasswordReset', 'LegalNotice', 'NotFound'].includes(data.route)) return true;
					else return false;
				} else {
					if (user.role == 'admin' || user.role == 'super') return true;
					else if (user.role == 'developer') {
						// prettier-ignore
						if (['Home', 'Upload', 'Pipeline', 'Development','Configuration','Evaluation', 'Data', 'Documentation', 'Profile', 'LegalNotice'].includes(data.route))
							return true;
						else return false;
					} else if (user.role == 'medical') {
						if (['Home', 'Upload', 'Profile', 'LegalNotice'].includes(data.route)) return true;
						else return false;
					} else if (user.role == 'auditor') {
						if (['Home', 'Pipeline', 'Evaluation', 'Data', 'Profile', 'LegalNotice'].includes(data.route)) return true;
						else return false;
					} else if (user.role == 'default') {
						if (['Home', 'Profile', 'LegalNotice'].includes(data.route)) return true;
						else return false;
					} else return false;
				}
			} else if (data.element) {
				if (!user) return false;
				else if (user.role == 'admin' || user.role == 'super') return true;
				else if (user.role == 'developer') {
					if (['PipelineUpload'].includes(data.element)) return true;
					else return false;
				} else if (user.role == 'medical') {
					if ([].includes(data.element)) return true;
					else return false;
				} else if (user.role == 'auditor') {
					if ([].includes(data.element)) return true;
					else return false;
				} else if (user.role == 'default') {
					if ([].includes(data.element)) return true;
					else return false;
				}
			}
		} else {
			if (!user) return false;
			else if (user.role == 'admin' || user.role == 'super') return true;
			else return false;
		}
	},
	// Returns the label description for the applications
	// @arg [String] abbreviation - The abbreviation of the application
	// @arg [String] label - The label of the data
	// @return [String] - The label description
	getLabelByApplication(abbreviation, label) {
		if (abbreviation == 'covco') {
			if (Number(label)) return context.$t('glCOVID19');
			else return context.$t('glHealthy');
		}
	},
	// Shortens a revisionID
	// @arg [String] revisionID - The revisionID
	// @returns [String] The shortend revisionID
	shortenRevisionID(revisionID) {
		return revisionID.split('-').slice(-2).join('-');
	},
	// Checks the type of the var and returns the type as a String
	// @arg [*] variable - The var which can be any type
	// @returns [String] The type of the var as String
	getTypeOfVar(variable) {
		let type = typeof variable;
		if (type == 'number') {
			if (isNaN(variable)) return 'NaN';
			else if (variable % 1 === 0) return 'Integer';
			else return 'Float';
		} else if (type == 'object') {
			if (variable instanceof Array) return 'Array';
			else if (variable == null) return 'Null';
			else return 'Object';
		} else if (type == 'string') {
			try {
				let number = Number(variable);
				let date = new Date(variable);
				if (!isNaN(number)) return 'String';
				else if (date instanceof Date && !isNaN(date)) return 'Date';
				else return 'String';
			} catch (error) {
				return 'String';
			}
		} else return type.charAt(0).toUpperCase() + type.slice(1);
	},
	// Sorts the values of a array by given properties and sort direction
	// @arg sort[Object] - The sort direction and sort proprties
	// @arg values[Array] - The values that are sorted
	// @returns [Array] The sorted values
	sortValues(sort, values) {
		values.sort((a, b) => {
			// Date properties
			if (['start', 'end', 'creation'].includes(sort.property)) {
				if (sort.asc) return new Date(a[sort.property]) - new Date(b[sort.property]);
				else return new Date(b[sort.property]) - new Date(a[sort.property]);
			}
			// RevisionID requires split of revision count
			else if (sort.property == 'revisionID') {
				let aID = a[sort.property].split('-').slice(-2).join('-');
				let bID = b[sort.property].split('-').slice(-2).join('-');
				if (sort.asc) return aID.localeCompare(bID);
				else return bID.localeCompare(aID);
			}
			// Array properties
			else if (['input', 'output'].includes(sort.property)) {
				let valueA = a[sort.property].join(', ');
				let valueB = b[sort.property].join(', ');
				if (sort.asc) return valueA.localeCompare(valueB);
				else return valueB.localeCompare(valueA);
			}
			// Number properties
			else if (['isGroundtruth', 'amountPackages', 'amountData', 'amountMeta', 'verified', 'members'].includes(sort.property)) {
				if (sort.asc) return Number(a[sort.property]) - Number(b[sort.property]);
				else return Number(b[sort.property]) - Number(a[sort.property]);
			}
			// Multiple properties
			else if (sort.propertyB) {
				// Boolean properties
				if (sort.propertyB == 'isVerification') {
					if (sort.asc) return a[sort.property][sort.propertyB] - b[sort.property][sort.propertyB];
					else return b[sort.property][sort.propertyB] - a[sort.property][sort.propertyB];
				}
				// String properties
				else {
					if (sort.asc) return a[sort.property][sort.propertyB].localeCompare(b[sort.property][sort.propertyB]);
					else return b[sort.property][sort.propertyB].localeCompare(a[sort.property][sort.propertyB]);
				}
			}
			// String properties
			else {
				if (sort.asc) return a[sort.property].localeCompare(b[sort.property]);
				else return b[sort.property].localeCompare(a[sort.property]);
			}
		});

		return values;
	},
	// Computes the amount of pages for the table pagination
	// @arg allValues[Number] - The values of the table
	// @arg pageSize[Number] - The size of the page
	// @arg currentPage[Number] - The current page
	// @returns [Array] The pages with or without added dividers
	getPages(allValues, pageSize, currentPage) {
		let pages = [];
		let maxPages = Math.ceil(allValues / pageSize);
		// First page is selected
		if (currentPage == 1) {
			pages.push(currentPage);
			for (let i = 2; i < 5; i++) if (i <= maxPages) pages.push(i);

			// Add upper divider and last page if more than 5 pages are available
			if (pages.length == 4 && maxPages > 5) {
				pages.push('pageUpperDivider');
				pages.push(maxPages);
			} else if (pages.length == 4 && maxPages == 5) pages.push(maxPages);
		}
		// Last page is selected
		else if (currentPage == maxPages) {
			// Add first page and lower divider if more than 5 pages are available
			if (maxPages > 5) {
				pages.push(1);
				pages.push('pageLowerDivider');
				for (let i = maxPages - 3; i < maxPages; i++) pages.push(i);
				pages.push(maxPages);
			} else for (let i = 1; i < maxPages + 1; i++) pages.push(i);
		}
		// A page between first and last is selected
		else {
			// The page is to close to the first page to insert a lower divider
			if (currentPage - 3 <= 1) {
				for (let i = 1; i < currentPage; i++) pages.push(i);
				pages.push(currentPage);

				// The page is to close to the last page to insert a upper divider
				if (currentPage + 3 > maxPages) for (let i = currentPage + 1; i < maxPages + 1; i++) pages.push(i);
				else {
					for (let i = currentPage + 1; i < currentPage + 4; i++) pages.push(i);
					if (maxPages - 1 == pages[pages.length - 1]) pages.push(maxPages);
					else if (maxPages !== pages[pages.length - 1]) {
						pages.push('pageUpperDivider');
						pages.push(maxPages);
					}
				}
			}
			// The page is to close to the last page to insert a upper divider
			else if (currentPage + 3 >= maxPages) {
				pages.push(1);
				pages.push('pageLowerDivider');
				for (let i = currentPage - 3; i < currentPage; i++) pages.push(i);
				pages.push(currentPage);
				for (let i = currentPage + 1; i < maxPages + 1; i++) pages.push(i);
			}
			// The page is somewhere in the middle and has both dividers
			else {
				pages.push(1);
				if (currentPage - 3 !== 2) pages.push('pageLowerDivider');
				for (let i = currentPage - 3; i < currentPage + 4; i++) pages.push(i);
				if (maxPages - 1 !== pages[pages.length - 1]) pages.push('pageUpperDivider');
				pages.push(maxPages);
			}
		}

		return pages;
	},
	// Returns the translation for the user role
	// @arg role[String] - The role flag of the user
	// @return [String] - The role translation
	getUserRole(role) {
		if (role == 'super') return context.$t('glSuperAdmin');
		else if (role == 'admin') return context.$t('glAdmin');
		else if (role == 'developer') return context.$t('glSuperDeveloper');
		else if (role == 'medical') return context.$t('glMedical');
		else if (role == 'auditor') return context.$t('glAuditor');
		else if (role == 'default') return context.$t('glDefault');
	},
};
