<template>
	<div class="ll-wrap-content">
		<!-- LiveLog options bar -->
		<div class="ll-log-options">
			<div class="ll-wrap-input">
				<input :id="`${logID}-logScroll`" type="checkbox" v-model="scroll" />
				<label :for="`${logID}-logScroll`">{{ $t('llScrollOnUpdate') }}</label>
			</div>
			<div class="ll-wrap-input">
				<input :id="`${logID}-logWordWrap`" type="checkbox" v-model="wrap" @change="wrapLog" />
				<label :for="`${logID}-logWordWrap`">{{ $t('llWrapLog') }}</label>
			</div>
			<div class="ll-wrap-input">
				<input :id="`${logID}-logHideName`" type="checkbox" v-model="hide" />
				<label :for="`${logID}-logHideName`">{{ $t('llHideName') }}</label>
			</div>
			<div class="ll-wrap-select">
				<label>{{ $t('llLogLevel') }}</label>
				<div class="ll-multi-select">
					<div class="ll-multi-option">{{ $t('llSelectLogLevel') }}</div>
					<div class="ll-multi-option ll-logger">
						<input :id="`${logID}-logLevelLogger`" type="checkbox" v-model="logLevel.logger" @change="filterLogs" />
						<label :for="`${logID}-logLevelLogger`">[LOGGER]</label>
					</div>
					<div class="ll-multi-option ll-debug">
						<input :id="`${logID}-logLevelDebug`" type="checkbox" v-model="logLevel.debug" @change="filterLogs" />
						<label :for="`${logID}-logLevelDebug`">[DEBUG]</label>
					</div>
					<div class="ll-multi-option ll-info">
						<input :id="`${logID}-logLevelInfo`" type="checkbox" v-model="logLevel.info" @change="filterLogs" />
						<label :for="`${logID}-logLevelInfo`">[INFO]</label>
					</div>
					<div class="ll-multi-option ll-warn">
						<input :id="`${logID}-logLevelWarn`" type="checkbox" v-model="logLevel.warn" @change="filterLogs" />
						<label :for="`${logID}-logLevelWarn`">[WARN]</label>
					</div>
					<div class="ll-multi-option ll-error">
						<input :id="`${logID}-logLevelError`" type="checkbox" v-model="logLevel.error" @change="filterLogs" />
						<label :for="`${logID}-logLevelError`">[ERROR]</label>
					</div>
				</div>
			</div>
			<div class="ll-download-log" @click="downloadLog">
				<i v-if="!logDownloadLoading" class="fa-solid fa-file-arrow-down"></i>
				<i v-else class="fa-solid fa-spinner ll-loading"></i>
				<p>{{ $t('llDownloadLog') }}</p>
			</div>
		</div>
		<!-- LiveLog field -->
		<div :id="`ll-wrap-log-${logID}`" class="ll-wrap-log ll-words-wrapper">
			<div :id="`ll-log-${logID}`" class="ll-logs ll-wrap-words-log">
				<!-- Single log component -->
				<div v-for="(log, idx) in currentLogs" :key="`${logID}-${idx}`" :id="`${logID}-${idx}`">
					<div v-if="log.content.trim().length > 0" class="ll-log-content">
						<div class="ll-line-number" :style="{ minWidth: lineNumberWidth, flex: `1 1 ${lineNumberWidth}` }">
							{{ log.lineNumber }}
						</div>
						<div :style="{ flex: `1 1 calc(100% - ${lineNumberWidth})` }">
							<!-- Single log component part-->
							<div v-for="part in getLogParts(log)" :key="part.logID" class="ll-log">
								<span class="ll-created">{{ parseDate(part.creation) }}</span>
								<span :class="`ll-level-${part.level}`">[{{ part.level.toUpperCase() }}]</span>
								<span v-if="!hide" class="ll-file">{{ part.file }}</span>
								<span v-if="!hide" class="ll-function">{{ part.function }}():</span>
								<span v-if="part.log.length <= 20000" class="ll-content">{{ part.log }}</span>
								<span v-else class="ll-content-large"
									>{{ $t('llLogToLarge') }}
									<button class="app-default-btn" @click="showLargeContent = part.log">
										{{ $t('llShowLogContent') }}
									</button>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="showLargeContent" class="ll-large-content">
					<i @click="showLargeContent = null" class="fa-solid fa-circle-xmark ll-close"></i>
					<div>{{ showLargeContent }}</div>
				</div>
				<p v-if="allLogs.length == 0" class="ll-no-log">{{ $t('llWaitingForLog') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
		</div>
		<!-- Pagination of the displayed logs -->
		<div class="ll-log-pagination">
			<p>
				{{ pageSize > filteredLogs.length ? filteredLogs.length : pageSize }} / {{ filteredLogs.length }}
				{{ $t('llLogsShowed') }} ({{ logViewport }})
			</p>
			<div class="ll-options">
				<select v-model="pageSize" @change="currentPage = 1">
					<option :value="100">100</option>
					<option :value="200">200</option>
					<option :value="500">500</option>
					<option :value="1000">1000</option>
					<option :value="999999999999999">{{ $t('llAllLogs') }}</option>
				</select>
			</div>
			<div class="ll-pages">
				<p v-for="page in pages" :key="page" class="ll-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="ll-no-hover"> . . . </span>
					<span v-else :class="[page == currentPage ? 'll-highlight-page' : '']" @click="currentPage = page">{{
						page
					}}</span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import * as uuid from 'uuid';
/**
 * @group Log
 * Displays a pipeline log that gets generated on creation or execution
 */
export default {
	name: 'LiveLog',
	props: {
		logs: {
			type: Array,
			required: false,
		},
		connect: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			logID: uuid.v4().substr(0, 8),
			wrapper: null,
			log: null,
			worker: null,
			socket: null,
			allLogs: [],
			existingEntries: [],
			filteredLogs: [],
			requestedFullLog: false,
			acceptNextLog: true,
			scroll: false,
			wrap: false,
			hide: false,
			lineNumbers: [],
			logLevel: {
				logger: true,
				debug: true,
				info: true,
				warn: true,
				error: true,
			},
			pageSize: 500,
			currentPage: 1,
			logQueue: [],
			showLargeContent: null,
			logDownloadLoading: false,
		};
	},
	computed: {
		// @vuese
		// Computes the current logs on the fly if the filtered logs update
		// @returns [Array] - The currently displayed logs
		currentLogs() {
			return this.filteredLogs.slice(
				(this.currentPage - 1) * this.pageSize,
				(this.currentPage - 1) * this.pageSize + this.pageSize
			);
		},
		// @vuese
		// Computes the log viewport which is displayed as a string under the livelog
		// @returns [String] - The log viewport string with start and end linenumber
		logViewport() {
			let currentLogs = this.currentLogs;
			let start = currentLogs[0] ? currentLogs[0].lineNumber : 0;
			let end = currentLogs[currentLogs.length - 1] ? currentLogs[currentLogs.length - 1].lineNumber : 0;
			return `${start} - ${end}`;
		},
		// @vuese
		// Computes the pages needed to display all logs depending on the length of filteredLogs, the pageSize and the currentPage
		// @returns [Array] - Array full of page numbers and dividers
		pages() {
			return this.$global.getPages(this.filteredLogs.length, this.pageSize, this.currentPage);
		},
		// @vuese
		// Computes the width of the lineNumber part of the log depending on the longest lineNumber
		// @returns [String] - The width in px
		lineNumberWidth() {
			return `${10 * this.currentLogs[this.currentLogs.length - 1].lineNumber.toString().length}px`;
		},
	},
	created() {
		this.createWorker();
		this.initLogs();
		if (this.connect) this.initSocket();
	},
	mounted() {
		this.wrapper = document.querySelector(`#ll-wrap-log-${this.logID}`);
		this.log = document.querySelector(`#ll-log-${this.logID}`);
	},
	beforeDestroy() {
		if (this.socket) {
			this.socket.off('live_log');
			this.socket.off('log_progress');
			this.socket.off('training_finished');
			this.socket.off('training_rollback');
		}
		this.worker = null;
		this.wrapper = null;
		this.log = null;
	},
	methods: {
		// @vuese
		// Creates a worker handle with different functions that can be called to parse the log content
		createWorker() {
			const actions = [
				{
					message: 'parseLiveLog',
					// @vuese
					// Checks if the incoming logs are new, sorts them, adds lineNumbers to them, filters them according to the logLevel and returns them
					// @arg allLogs[Array] - All exisiting logs
					// @arg existingEntries[Array] - The IDs of all the existing logs
					// @arg logs[Array] - The incoming logs
					// @arg logLevel[Object] - The logLevel
					// @returns [Object] - The parsed newEntries and the updated existingEntries and allLogs
					func: (allLogs, existingEntries, logs, logLevel) => {
						let newEntries = [];
						if (existingEntries.length > 0) newEntries = logs.filter((l) => !existingEntries.includes(l.id));
						else newEntries = logs;
						if (newEntries.length > 0) {
							newEntries.sort((a, b) => {
								return new Date(a.creation) - new Date(b.creation);
							});

							let lastLog = allLogs[allLogs.length - 1];
							let currentLineNumber = 1;
							if (lastLog && lastLog.lineNumber) currentLineNumber = lastLog.lineNumber + 1;

							newEntries = newEntries.map((entry, idx) => {
								entry.lineNumber = currentLineNumber;
								currentLineNumber += 1;
								allLogs.push(entry);
								existingEntries.push(entry.id);
								return entry;
							});

							newEntries = newEntries.filter((log) => logLevel[log.level]);

							return { existingEntries: existingEntries, allLogs: allLogs, newEntries: newEntries };
						} else return null;
					},
				},
				{
					message: 'parsePropLogs',
					// @vuese
					// Sorts the logs that got passed as a prop and adds a lineNumber to it
					// @arg logs[Array] - The prop logs
					// @returns [Array] - The parsed logs
					func: (logs) => {
						let newEntries = logs.sort((a, b) => {
							return new Date(a.creation) - new Date(b.creation);
						});

						let currentLineNumber = 1;
						newEntries = newEntries.map((entry, idx) => {
							entry.lineNumber = currentLineNumber;
							currentLineNumber += 1;
							return entry;
						});

						return newEntries;
					},
				},
				{
					message: 'parseRequestedLogs',
					// @vuese
					// Sorts the requested logs, creates the existingEntries array, adds the lineNumbers and filters the logs
					// @arg logs[Array] - The prop logs
					// @arg logLevel[Object] - The logLevel
					// @returns [Object] - The filteredLogs, existingEntries and allLogs
					func: (logs, logLevel) => {
						let allLogs = logs.sort((a, b) => {
							return new Date(a.creation) - new Date(b.creation);
						});

						let existingEntries = allLogs.map((l) => l.id);
						let currentLineNumber = 1;
						allLogs = allLogs.map((entry, idx) => {
							entry.lineNumber = currentLineNumber;
							currentLineNumber += 1;
							return entry;
						});

						let filteredLogs = allLogs.filter((log) => logLevel[log.level]);

						return { existingEntries: existingEntries, allLogs: allLogs, filteredLogs: filteredLogs };
					},
				},
			];
			this.worker = this.$worker.create(actions);
		},
		// @vuese
		// Initiates the logs if they are passed as a prop to the component
		initLogs() {
			if (this.logs && this.logs.length) {
				this.worker
					.postMessage('parsePropLogs', [this.logs])
					.then((data) => {
						this.allLogs = data;
						this.filteredLogs = this.allLogs;
						this.$nextTick(() => {
							this.scroll = true;
						});
					})
					.catch(console.error);
			} else this.scroll = true;
		},
		// @vuese
		// Initiates the socket connection
		initSocket() {
			let that = this;
			this.$socket.getSocket(function (socket) {
				that.socket = socket;
				that.setupSockets();
			});
		},
		// @vuese
		// Sets up the socket listener that retrieves the live log
		setupSockets() {
			if (this.$route.params.trainingID) this.socket.emit('join_live_log', this.$route.params.trainingID);
			let that = this;

			this.socket.on('connect', function () {
				if (that.$route.params.trainingID) that.socket.emit('join_live_log', that.$route.params.trainingID);
			});

			this.socket.on('live_log', this.handleLiveLog);

			this.socket.on('log_progress', this.handleLogProgress);

			this.socket.on('training_finished', function () {
				that.$emit('loadResults');
			});

			this.socket.on('training_rollback', function () {
				that.$emit('trainingRollback');
			});
		},
		// @vuese
		// Handles the live_log socket event. Parses the logs and adds it to the log arrays
		// @arg logs[Object] - The newest 50 logs, the length of all logs and the refID if the full log needs to be requested
		handleLiveLog(logs) {
			// New logs arrived and need to be parsed
			if (this.allLogs.length !== logs.len) {
				if (this.allLogs.length + 50 < logs.len && !this.requestedFullLog) this.requestFullLog(logs.refID);
				else if (!this.requestedFullLog && this.acceptNextLog) {
					this.acceptNextLog = false;
					let newLogs = [...this.logQueue, ...logs.logs];
					newLogs = newLogs.filter((value, idx, self) => idx === self.findIndex((t) => t.id === value.id));
					this.logQueue = [];
					this.worker
						.postMessage('parseLiveLog', [this.allLogs, this.existingEntries, newLogs, this.logLevel])
						.then((getNewEntriesData) => {
							if (getNewEntriesData) {
								this.allLogs = getNewEntriesData.allLogs;
								this.existingEntries = getNewEntriesData.existingEntries;
								getNewEntriesData.newEntries.forEach((entry) => this.filteredLogs.push(entry));
								this.acceptNextLog = true;
								if (this.currentLogs.length == this.pageSize && this.scroll)
									this.currentPage = this.pages[this.pages.length - 1];
								this.scrollToBottom();
							} else this.acceptNextLog = true;
						})
						.catch((err) => {
							console.log(err);
							that.acceptNextLog = true;
						});
				} else if (!this.requestedFullLog && this.acceptNextLog) {
					this.logQueue = [...this.logQueue, ...logs.logs];
					this.logQueue = this.logQueue.filter((value, idx, self) => idx === self.findIndex((t) => t.id === value.id));
				}
			}
			// log_progress fallback for live_log. See handleLogProgress below
			else if (this.allLogs[this.allLogs.length - 1].id !== logs.logs[logs.logs.length - 1].id) {
				let newestLog = logs.logs[logs.logs.length - 1];
				newestLog.lineNumber = this.allLogs[this.allLogs.length - 1].lineNumber;
				this.$set(this.allLogs, this.allLogs.length - 1, newestLog);
				this.existingEntries[this.existingEntries.length - 1] = newestLog.id;

				if (this.logLevel[newestLog.level]) {
					this.$set(this.filteredLogs, this.filteredLogs.length - 1, newestLog);
					if (this.currentLogs.length == this.pageSize && this.scroll) this.currentPage = this.pages[this.pages.length - 1];
					this.scrollToBottom();
				}
			}
		},
		// @vuese
		// Handles the log_progress socket event. Overwrites the last log with the new log msg
		// @arg log[Object] - The newest log
		handleLogProgress(log) {
			log.lineNumber = this.allLogs[this.allLogs.length - 1].lineNumber;
			this.$set(this.allLogs, this.allLogs.length - 1, log);
			this.existingEntries[this.existingEntries.length - 1] = log.id;

			if (this.logLevel[log.level]) {
				this.$set(this.filteredLogs, this.filteredLogs.length - 1, log);
				if (this.currentLogs.length == this.pageSize && this.scroll) this.currentPage = this.pages[this.pages.length - 1];
				this.scrollToBottom();
			}
		},
		// @vuese
		// Requests the full log
		// @arg refID[String] - The refID of the pipeline training
		requestFullLog(refID) {
			this.requestedFullLog = true;
			this.socket.emit('request_live_log', { trainID: this.$route.params.trainingID, refID: refID }, this.handleRequestLiveLog);
		},
		// @vuese
		// Handles the request_live_log callback. Parses the full requested log
		// @arg err[Object] - The error that occured
		// @arg logs[Array] - The full log
		handleRequestLiveLog(err, logs) {
			if (err) console.log(err);
			if (logs.length > 0) {
				this.worker
					.postMessage('parseRequestedLogs', [logs, this.logLevel])
					.then((data) => {
						this.filteredLogs = data.filteredLogs;
						this.existingEntries = data.existingEntries;
						this.allLogs = data.allLogs;
						if (this.currentLogs.length == this.pageSize && this.scroll)
							this.currentPage = this.pages[this.pages.length - 1];
						this.$nextTick(() => {
							this.requestedFullLog = false;
							this.scrollToBottom();
						});
					})
					.catch((err) => {
						console.log(err);
						this.requestedFullLog = false;
					});
			} else this.requestedFullLog = false;
		},
		// @vuese
		// Wraps each line of the log or not
		wrapLog() {
			let wrapper = document.querySelector(`#ll-wrap-log-${this.logID}`);
			let log = document.querySelector(`#ll-log-${this.logID}`);
			if (!this.wrap) {
				wrapper.classList.add('ll-words-wrapper');
				log.classList.add('ll-wrap-words-log');
			} else {
				wrapper.classList.remove('ll-words-wrapper');
				log.classList.remove('ll-wrap-words-log');
			}
		},
		// @vuese
		// Scrolls to the bottom of the logs
		scrollToBottom() {
			if (this.scroll) {
				this.$nextTick(() => {
					this.wrapper.scrollTo({ top: this.wrapper.scrollHeight, behavior: 'smooth' });
					this.log.scrollTo({ top: this.log.scrollHeight, behavior: 'smooth' });
				});
			}
		},
		// @vuese
		// Filters all logs by the set log level, sort them by creation date and compute the line numbers
		filterLogs() {
			this.filteredLogs = this.allLogs.filter((log) => this.logLevel[log.level]);
			let maxPages = Math.ceil(this.filteredLogs.length / this.pageSize);
			maxPages = maxPages <= 0 ? 1 : maxPages;

			if (this.currentPage > maxPages) this.currentPage = maxPages;
			this.scrollToBottom();
		},
		// @vuese
		// Parses a date and adjusts the time zone
		// @arg date[String] - The date string
		// @returns [String] - The parsed date
		parseDate(date) {
			let d = new Date(date);
			if (this.isDST(d)) d.setTime(d.getTime() + 7200000);
			else d.setTime(d.getTime() + 3600000);

			return d.toISOString().split('.')[0];
		},
		// @vuese
		// Checks if a date is in the daytime saving zone
		// @arg date[Object] - The date
		// @returns [Boolean] - Is true if the date is in the dst
		isDST(date) {
			let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
			let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
			return Math.max(jan, jul) !== date.getTimezoneOffset();
		},
		// @vuese
		// Splits the log content by lines and displays the each in a seperate line
		// @arg log[String] - The full log
		// @return [Array] - The array of all log parts
		getLogParts(log) {
			let logEntries = [];
			try {
				`${log.content}`.split(/\r\n|\r|\n/).forEach((line) => {
					if (line.trim() !== '') {
						line = line.replace(/(?:\r\n|\r|\n)/g, ' ');

						logEntries.push({ logID: uuid.v4(), log: line, ...log });
					}
				});
			} catch (error) {
				logEntries.push({ logID: uuid.v4(), log: `${log.content}`, ...log });
			}
			if (logEntries.length == 0) logEntries.push({ logID: uuid.v4(), log: `${log.content}`, ...log });
			return logEntries;
		},
		// @vuese
		// Downloads all logs currently available
		downloadLog() {
			if (!this.logDownloadLoading) {
				this.logDownloadLoading = true;
				try {
					let a = document.createElement('a');
					let logContent = '';
					this.allLogs.forEach((log) => {
						let logParts = this.getLogParts(log);
						logParts.forEach((l) => {
							if (l.log.trim().length > 0) {
								logContent += `${new Date(l.creation).toISOString().split('.')[0]} `;
								logContent += `[${l.level.toUpperCase()}] `;
								logContent += `${l.file} `;
								logContent += `${l.function}(): `;
								logContent += `${l.log}\n`;
							}
						});
					});
					a.href = `data:text/plain;base64, ${btoa(unescape(encodeURIComponent(logContent)))}`;
					a.download = `log_${new Date().toLocaleString().replace(/\./g, '_').replace(/, /g, '_')}.log`;
					a.click();
					a.remove();
				} catch (error) {
					console.log(error);
					this.$global.showToast('error', this.$t('llDownloadError'));
				} finally {
					this.logDownloadLoading = false;
				}
			}
		},
	},
};
</script>

<style scoped>
.ll-wrap-content {
	width: 100%;
	height: 100%;
}

.ll-log-options {
	width: 100%;
	height: fit-content;
	min-height: 30px;
	padding: 5px 0px 5px 5px;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	flex-flow: wrap;
	row-gap: 5px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
}

.ll-wrap-input {
	width: fit-content;
	height: fit-content;
	margin-right: 10px;
	vertical-align: top;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
}

.ll-wrap-input input {
	margin-right: 5px;
	cursor: pointer;
}
.ll-wrap-input label {
	cursor: pointer;
}

.ll-wrap-select {
	width: 220px;
	height: fit-content;
	min-height: 30px;
	position: relative;
	margin-right: 10px;
	vertical-align: top;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
}

.ll-multi-select {
	width: 140px;
	height: 30px;
	margin-left: 5px;
	padding: 4px 0px 4px 10px;
	position: absolute;
	top: 0px;
	right: 0px;
	display: inline-flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
	overflow: hidden;
	z-index: 11;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	background-color: var(--main-color-4);
	cursor: pointer;
}

.ll-multi-select:hover {
	height: fit-content;
}

.ll-multi-option {
	flex: 1 1 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 15px;
	line-height: 20px;
}

.ll-multi-option input {
	margin-right: 5px;
}

.ll-info label {
	color: var(--main-color-log-info);
}

.ll-debug label {
	color: var(--main-color-log-debug);
}

.ll-warn label {
	color: var(--main-color-log-warn);
}

.ll-error label {
	color: var(--main-color-log-error);
}

.ll-logger label {
	color: var(--main-color-log-logger);
}

.ll-wrap-log {
	width: 100%;
	height: calc(100% - 140px);
	display: block;
	border: 2px solid var(--main-color-5);
	box-sizing: border-box;
	overflow: auto;
	position: relative;
	background-color: var(--main-color-1);
}

.ll-logs {
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
}

.ll-words-wrapper {
	overflow: hidden;
}

.ll-wrap-words-log {
	overflow-y: scroll;
	overflow-x: auto;
	white-space: nowrap;
}

.ll-no-log {
	width: 100%;
	height: fit-content;
	text-align: center;
	font-size: 20px;
}

.ll-no-log i {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.ll-log-pagination {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.ll-log-pagination p {
	margin: 5px auto;
}

.ll-log-pagination button {
	font-size: 17px;
}

.ll-options select {
	cursor: pointer;
}

.ll-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.ll-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.ll-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.ll-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.ll-highlight-page {
	color: var(--main-color-6);
	text-decoration: none !important;
	cursor: default !important;
}

.ll-log-content {
	width: 100%;
	height: fit-content;
	margin: 2px 0px;
	display: flex;
}

.ll-line-number {
	border-right: 1px solid var(--main-color-border-light);
	margin-right: 5px;
	padding-right: 5px;
}

.ll-log {
	flex: 1 1;
	/* display: inline-block; */
	text-align: start;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
}

.ll-log pre {
	display: inline-block;
}

.ll-log-content div span {
	margin-right: 5px;
}
.ll-created {
	white-space: nowrap;
	color: var(--main-color-log-timestamp);
}

.ll-level-info {
	color: var(--main-color-log-info);
	font-weight: bold;
}

.ll-level-debug {
	color: var(--main-color-log-debug);
	font-weight: bold;
}

.ll-level-warn {
	color: var(--main-color-log-warn);
	font-weight: bold;
}

.ll-level-error {
	color: var(--main-color-log-error);
	font-weight: bold;
}

.ll-level-logger {
	color: var(--main-color-log-logger);
	font-weight: bold;
}

.ll-file {
	color: var(--main-color-log-caller);
}

.ll-function {
	color: var(--main-color-log-caller);
}

.ll-content {
	color: var(--main-color-log-content);
}

.ll-content-large {
	color: var(--main-color-log-warn);
}

.ll-large-content {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 15;
	background-color: var(--main-color-dark-cc);
}

.ll-close {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 25px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px solid var(--main-color-border-dark);
}

.ll-close:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.ll-large-content div {
	width: 90%;
	height: 90%;
	padding: 20px;
	box-sizing: border-box;
	white-space: normal;
	overflow: auto;
}

.ll-download-log {
	text-align: center;
}

.ll-download-log:hover {
	cursor: pointer;
	text-decoration: underline;
}

.ll-loading {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}
</style>
