<template>
	<div class="bwhealthapp-wrap-content">
		<p v-if="!viewMode">{{ $t('bwhealthappMeta') }}</p>
		<div :class="[viewMode ? 'bwhealthapp-view-mode' : '', 'bwhealthapp-wrap-meta']">
			<div class="bwhealthapp-wrap-top">
				<h3>{{ $t('bwhealthappPatient') }}</h3>
				<div class="bwhealthapp-flexbox">
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappExternalReference') }}</label>
						<input type="text" :placeholder="$t('bwhealthappExternalReferencePlaceholder')" v-model="meta.patient.id" />
					</div>
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappPatientName') }}</label>
						<input type="text" :placeholder="$t('bwhealthappPatientName')" v-model="meta.patient.name" />
					</div>
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappPatientGender') }}</label>
						<select v-model="meta.patient.gender">
							<option :value="null" disabled selected hidden>{{ $t('bwhealthappSelectPatientGender') }}</option>
							<option value="MALE">{{ $t('bwhealthappMaleGender') }}</option>
							<option value="FEMALE">{{ $t('bwhealthappFemaleGender') }}</option>
							<option value="DIVERSE">{{ $t('bwhealthappDiversGender') }}</option>
						</select>
					</div>
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappDateOfBirth') }}</label>
						<input type="datetime-local" v-model="meta.patient.dob" />
					</div>
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappPatientActivity') }}</label>
						<select v-model="meta.patient.activity">
							<option :value="null" disabled selected hidden>{{ $t('bwhealthappSelectPatientActivity') }}</option>
							<option value="SEDENTARY">{{ $t('bwhealthappActivitySedentary') }}</option>
							<option value="VIGOROUSLY">{{ $t('bwhealthappActivityVigorously') }}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="bwhealthapp-wrap-bot">
				<h3>{{ $t('bwhealthappExport') }}</h3>
				<div class="bwhealthapp-flexbox">
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappExportStart') }}</label>
						<input type="datetime-local" v-model="meta.export.start" />
					</div>
					<div class="bwhealthapp-wrap-input">
						<label>{{ $t('bwhealthappExportEnd') }}</label>
						<input type="datetime-local" v-model="meta.export.end" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="!viewMode" class="bwhealthapp-save-btn">
			<button class="app-success-btn" @click="saveMeta">{{ $t('bwhealthappSaveMeta') }}</button>
		</div>
	</div>
</template>

<script>
/**
 * @group Upload
 * Contains the bwHealthApp Meta information structure
 */
export default {
	name: 'BWHEALTHAPPMetaInformation',
	props: {
		metaToEdit: {
			type: Object,
			required: false,
		},
		viewMode: {
			type: Boolean,
			required: false,
		},
	},
	watch: {
		metaToEdit: {
			handler: function (newVal) {
				if (newVal) {
					this.resetMeta();
					this.$nextTick(() => {
						this.setMeta(newVal.meta);
						this.packageID = newVal.packageID;
					});
				} else {
					this.packageID = null;
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			meta: {
				patient: { id: null, name: null, gender: null, dob: null, activity: null },
				export: { start: null, end: null },
			},
			packageID: null,
		};
	},
	created() {
		if (this.metaToEdit) {
			this.setMeta(this.metaToEdit.meta);
			this.packageID = this.metaToEdit.packageID;
		}
	},
	methods: {
		// @vuese
		// Resets the meta information of the component
		resetMeta() {
			this.meta = {
				patient: { id: null, name: null, gender: null, dob: null, activity: null },
				export: { start: null, end: null },
			};
			this.packageID = null;
		},
		// @vuese
		// Sets the selected meta
		// @arg meta[Object] - The uploaded meta object
		setMeta(meta) {
			let missingProperties = [];

			if (meta) {
				if ('patient' in meta && meta.patient) this.meta.patient = meta.patient;
				else missingProperties.push('patient');

				if ('export' in meta && meta.export) this.meta.export = meta.export;
				else missingProperties.push('export');

				if (meta.patient) {
					console.log(meta.patient);
					if ('id' in meta.patient) this.meta.patient.id = meta.patient.id;
					else missingProperties.push('id');

					if ('name' in meta.patient) this.meta.patient.name = meta.patient.name;
					else missingProperties.push('name');

					if ('gender' in meta.patient) this.meta.patient.gender = meta.patient.gender;
					else missingProperties.push('gender');

					if ('dob' in meta.patient) this.meta.patient.dob = meta.patient.dob;
					else missingProperties.push('dob');

					if ('activity' in meta.patient) this.meta.patient.activity = meta.patient.activity;
					else missingProperties.push('activity');
				}

				if (meta.export) {
					console.log(meta.export);
					if ('start' in meta.export) this.meta.export.start = meta.export.start;
					else missingProperties.push('start');

					if ('end' in meta.export) this.meta.export.end = meta.export.end;
					else missingProperties.push('end');
				}

				if (missingProperties.length > 0 && !this.viewMode) {
					this.$global.showToast(
						'warn',
						`${this.$t('bwhealthappMissingProperties')} (${missingProperties.length}), ${this.$t('bwhealthappCheckStructure')}`
					);
				}
			}
		},
		// @vuese
		// Checks if some fields are filled and then emits the meta information
		saveMeta() {
			if (
				this.meta.patient.id == '' ||
				this.meta.patient.name == '' ||
				this.meta.patient.gender == '' ||
				this.meta.patient.dob == '' ||
				this.meta.patient.activity == ''
			)
				this.$global.showToast('warn', this.$t('bwhealthappMissingInformation'));
			else if (this.meta.export.start == '' || this.meta.export.end == '')
				this.$global.showToast('warn', this.$t('bwhealthappMissingInformation'));
			else this.$global.showToast('success', this.$t('bwhealthappInformationSaved'));

			this.$emit(
				'saveMeta',
				JSON.parse(
					JSON.stringify({
						packageID: this.packageID,
						meta: this.meta,
					})
				)
			);
		},
	},
};
</script>

<style scoped>
.bwhealthapp-wrap-content {
	width: 100%;
	height: fit-content;
	margin-bottom: 10px;
}

.bwhealthapp-wrap-content p {
	margin: 0px 0px 10px 0px;
	font-size: 18px;
	text-decoration: underline;
}

.bwhealthapp-wrap-meta {
	width: 100%;
	height: fit-content;
	padding: 5px;
	background-color: var(--main-color-4);
	border: 2px solid var(--main-color-border-dark);
	box-sizing: border-box;
}

.bwhealthapp-view-mode {
	pointer-events: none;
}

.bwhealthapp-wrap-top {
	width: 100%;
	height: fit-content;
	border-bottom: 1px solid var(--main-color-5);
}

.bwhealthapp-flexbox {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-flow: wrap;
}

.bwhealthapp-wrap-input {
	flex: 1 1 auto;
	flex-flow: column;
	vertical-align: top;
	padding: 5px;
	box-sizing: border-box !important;
}

.bwhealthapp-wrap-input select {
	width: 100%;
	min-width: 200px;
	max-width: 400px;
	box-shadow: 0 0 0 2pt var(--main-color-4);
	box-sizing: border-box !important;
}

.bwhealthapp-wrap-input input {
	width: 100%;
	min-width: 200px;
	max-width: 400px;
	box-shadow: 0 0 0 2pt var(--main-color-4);
	box-sizing: border-box !important;
}

.bwhealthapp-wrap-input label {
	display: block;
	margin-bottom: 5px;
}

.bwhealthapp-wrap-bot {
	width: 100%;
	height: fit-content;
	margin: 5px 0px 0px 0px;
}

.bwhealthapp-save-btn {
	width: 100%;
	margin: 10px auto;
	text-align: center;
}

.bwhealthapp-save-btn button {
	font-size: 17px;
	margin-bottom: 10px;
}
</style>
