<template>
	<div class="pr-wrap-content">
		<div class="pr-top-bar">
			<i v-if="result.category == 'metrics'" class="fa-solid fa-calculator pr-result-icon pr-metrics"></i>
			<i v-else-if="result.category == 'interim'" class="fa-solid fa-file pr-result-icon pr-interim"></i>
			<i v-else-if="result.category == 'model'" class="fa-solid fa-cube pr-result-icon pr-model"></i>
			<p class="pr-result-name">{{ result.name }}</p>
			<i v-if="!isLoading" class="fa-regular fa-floppy-disk pr-result-save" @click="loadFileContent"></i>
			<i v-else class="fa-solid fa-spinner pr-loading"></i>
		</div>
		<div class="pr-wrap-result">
			<div v-if="result.category == 'metrics'" class="pr-wrap-metrics">
				<div
					v-for="key in Object.keys(result.result)"
					:key="key"
					:class="['pr-wrap-input', result.result[key] ? (result.result[key].length > 400 ? 'pr-expand-full' : '') : '']"
				>
					<p class="pr-label">{{ key }} ({{ $global.getTypeOfVar(result.result[key]) }})</p>
					<p class="pr-content">{{ result.result[key] == null ? 'null' : result.result[key] }}</p>
				</div>
			</div>
			<div v-else-if="result.category == 'interim'" class="pr-wrap-interim">
				<div v-if="isImage(result.extension)" class="pr-interim-image">
					<p class="pr-file-name pr-file-hover" @click="toggleMedia">
						<i v-if="!showResult" class="fa-solid fa-eye-slash"></i><i v-if="showResult" class="fa-solid fa-eye"></i>
						{{ result.path.split('/').pop() }}
					</p>
					<div v-if="resultLoadedContent && showResult" class="pr-image-content">
						<img :src="resultLoadedContent" :alt="result.name" @click="addLargeImage" />
					</div>
				</div>
				<div v-else-if="isText(result.extension)" class="pr-interim-text">
					<p class="pr-file-name pr-file-hover" @click="toggleMedia">
						<i v-if="!showResult" class="fa-solid fa-eye-slash"></i><i v-if="showResult" class="fa-solid fa-eye"></i>
						{{ result.path.split('/').pop() }}
					</p>
					<div v-if="resultLoadedContent && showResult">
						<div v-if="result.extension == 'json' && jsonContent">
							<div v-if="Array.isArray(jsonContent)" class="pr-wrap-metrics">
								<div v-for="(element, idx) in jsonContent" :key="idx" class="pr-wrap-input">
									<div v-if="isJSONObject(element)">
										<p>{{ $t('prArrayEntry') }} {{ idx + 1 }}</p>
										<div v-for="key in Object.keys(element)" :key="key" class="pr-wrap-input-sub">
											<p class="pr-label">{{ key }} ({{ $global.getTypeOfVar(element[key]) }})</p>
											<p class="pr-content">
												{{ element[key] == null ? 'null' : element[key] }}
											</p>
										</div>
									</div>
									<p v-else class="pr-array-element"><pre>{{ idx + 1 }}.</pre><span>{{ element }}</span></p>
								</div>
							</div>
							<div v-else class="pr-wrap-metrics">
								<div v-for="key in Object.keys(jsonContent)" :key="key" class="pr-wrap-input">
									<p class="pr-label">{{ key }} ({{ $global.getTypeOfVar(jsonContent[key]) }})</p>
									<p class="pr-content">
										{{ jsonContent[key] == null ? 'null' : jsonContent[key] }}
									</p>
								</div>
							</div>
						</div>
						<p v-else-if="result.extension !== 'json'" class="pr-text-content">
							{{ getTextContent(resultLoadedContent) }}
						</p>
					</div>
				</div>
				<div v-else class="pr-interim-other">
					<p class="pr-file-name">{{ result.path.split('/').pop() }}</p>
				</div>
			</div>
			<div v-else-if="result.category == 'model'" class="pr-wrap-model-component">
				<p class="pr-file-name">{{ result.path.split('/').pop() }}</p>
			</div>
		</div>
		<p class="pr-result-creation">{{ $global.parseDateFull(result.creation) }}</p>
	</div>
</template>

<script>
/**
 * @group Evaluation
 * Displays a pipeline result
 */
export default {
	name: 'PipelineResult',
	props: {
		result: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			// prettier-ignore
			supportedImgExtensions: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'bmp', 'ico', 'cur', 'tif', 'tiff'],
			supportedTextExtensions: ['txt', 'json', 'csv', 'log'],
			showResult: false,
			resultLoadedContent: null,
			jsonContent: null,
			preventClick: false,
			displayLargeImage: false,
			isLoading: false,
		};
	},
	methods: {
		// @vuese
		// Checks if a extension matches a image
		// @arg extension[String] - The extension
		// @returns [Boolean] Is true if the extension matches a image
		isImage(extension) {
			if (this.supportedImgExtensions.includes(extension.replace('.', ''))) return true;
			else return false;
		},
		// @vuese
		// Checks if a extension matches a text
		// @arg extension[String] - The extension
		// @returns [Boolean] Is true if the extension matches a text
		isText(extension) {
			if (this.supportedTextExtensions.includes(extension.replace('.', ''))) return true;
			else return false;
		},
		// @vuese
		// Toggles the media element and queries it if not already queried
		toggleMedia() {
			if (!this.preventClick) {
				this.preventClick = true;
				this.showResult = !this.showResult;
				if (!this.resultLoadedContent) {
					let that = this;
					this.getMediaElement(function (res) {
						that.resultLoadedContent = res;
						if (that.result.extension == 'json') that.jsonContent = that.getJSONContent(res);
						that.preventClick = false;
					});
				} else this.preventClick = false;
			}
		},
		// @vuese
		// Queries the media element
		// @arg cb[Function] - Callback to the parent function
		getMediaElement(cb) {
			let that = this;
			this.$global.getData(
				'training',
				`/trainings/${this.$route.params.trainingID}/media/${this.result.resultID}`,
				{
					headers: { userid: this.$global.getUser().userID },
					auth: JSON.parse(sessionStorage.getItem('credentials')),
				},
				null,
				function (err, result) {
					if (err) {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						that.$global.showToast('error', that.$t(msg ? msg : 'prMediaQueryError'));
						cb(null);
					} else cb(result);
				}
			);
		},
		// @vuese
		// Gets the text content from a base64 representation of a text file
		// @arg base64[String] - The base64 json
		// @returns [String] The text content
		getTextContent(base64) {
			try {
				return decodeURIComponent(escape(atob(base64.split(', ').pop())));
			} catch (error) {
				return this.$t('prInvalidContent');
			}
		},
		// @vuese
		// Gets the json content from a base64 representation of a json file
		// @arg base64[String] - The base64 json
		// @returns [String] The json content
		getJSONContent(base64) {
			try {
				let text = atob(base64.split(', ').pop());
				text = text.replace(/'/g, '"');
				return JSON.parse(text);
			} catch (error) {
				this.$global.showToast('error', this.$t('prCantReadJSON'), false)
				return {};
			}
		},
		// @vuese
		// Checks if a element is a json object
		// @arg element[Object] - A element
		// @returns [Boolean] - Is true if the element is a json object
		isJSONObject(element) {
			if (element === null) return false;
			if (element === undefined) return false;
			if (Array.isArray(element)) return false;
			if (typeof element !== 'object') return false;
			return true;
		},
		// @vuese
		// Adds a lareg image preview to the document
		addLargeImage() {
			let wrapper = document.createElement('div');
			let css = {
				width: '100%',
				height: '100%',
				position: 'absolute',
				top: '0px',
				left: '0px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 10,
				backgroundColor: 'var(--main-color-dark-cc)',
				cursor: 'pointer',
			};
			Object.assign(wrapper.style, css);

			let img = document.createElement('img');
			img.src = this.resultLoadedContent;
			img.alt = this.result.name;
			img.style.maxWidth = '90%';
			img.style.maxHeight = '90%';

			wrapper.addEventListener('click', this.imageClickListener);
			img.addEventListener('click', this.imageClickListener);

			wrapper.appendChild(img);
			document.querySelector('.ev-image-wrapper').appendChild(wrapper);
		},
		// @vuese
		// Large image click listener to close the image
		imageClickListener(e) {
			if (e.target.tagName == 'DIV') e.target.remove();
			else e.target.parentElement.remove();
		},
		// @vuese
		// Loads the file content and downloads it
		loadFileContent() {
			this.isLoading = true;
			if (this.resultLoadedContent) this.downloadContent();
			else {
				let that = this;
				this.getMediaElement(function (res) {
					if (res) {
						that.resultLoadedContent = res;
						that.downloadContent();
					} else this.isLoading = false;
				});
			}
		},
		// @vuese
		// Downloads the result content
		downloadContent() {
			try {
				let a = document.createElement('a');
				a.href = this.resultLoadedContent;
				a.download = `${this.result.name}.${this.result.extension}`;
				a.click();
				a.remove();
				this.isLoading = false;
			} catch (error) {
				console.log(error);
				this.$global.showToast('error', this.$t('prMediaDownloadError'));
				this.isLoading = false;
			}
		},
	},
};
</script>

<style scoped>
.pr-wrap-content {
	flex: 1 1 400px;
	max-width: 100%;
	padding: 10px 20px;
	margin: 10px 20px;
	border: 1px solid var(--main-color-border-light);
	border-radius: 20px;
	background-color: var(--main-color-3);
	box-sizing: border-box;
}

.pr-top-bar {
	width: 100%;
	height: 30px;
}

.pr-result-icon {
	float: left;
	font-size: 25px;
	margin-right: 10px;
	vertical-align: top;
}

.pr-metrics {
	color: var(--main-color-info);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pr-interim {
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pr-model {
	color: var(--main-color-success);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pr-result-name {
	width: calc(100% - 80px);
	float: left;
	font-size: 25px;
	margin-right: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: top;
}

.pr-result-save,
.pr-loading {
	float: right;
	font-size: 25px;
	margin-left: 10px;
	vertical-align: top;
}

.pr-result-save:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.pr-loading {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.pr-wrap-metrics {
	width: 100%;
	height: fit-content;
	max-height: 300px;
	padding: 5px;
	margin-top: 5px;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	overflow-wrap: break-word;
}

.pr-wrap-input {
	flex: 1 1 200px;
	display: inline-block;
	margin: 10px;
	box-sizing: border-box;
}

.pr-wrap-input:has(p.pr-array-element) {
	flex: 1 1 100%;
	margin: 0px;
	box-sizing: border-box;
}

.pr-wrap-input-sub {
	margin: 5px 0px;
	box-sizing: border-box;
}

.pr-expand-full {
	flex: 1 1 100%;
}

.pr-label {
	width: fit-content;
	display: block;
	margin-bottom: 5px;
}

.pr-content {
	width: 100%;
	height: calc(100% - 20px);
	max-height: 300px;
	display: block;
	padding: 5px;
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	overflow-wrap: break-word;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
}

.pr-array-element {
	width: 100%;
	height: fit-content;
	max-height: 100px;
	/* display: flex;
	justify-content: center;
	align-items: center; */
	margin: 0px;
	overflow: hidden;
	/* overflow-y: auto;
	overflow-x: hidden; */
	box-sizing: border-box;
	overflow-wrap: break-word;
	
}

.pr-array-element pre{
	width: 50px;
	display: inline-block;
	vertical-align: top;

}

.pr-array-element span{
	width: calc(100% - 55px);
	max-height: 100px;
	padding: 3px;
	display: inline-block;
	box-sizing: border-box;
	overflow: auto;
	border: 1px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
}

.pr-file-name {
	margin-top: 5px;
}

.pr-file-name:hover {
	cursor: default;
}

.pr-file-hover {
	text-decoration: underline;
}

.pr-file-hover i {
	width: 20px;
	margin-right: 10px;
	font-size: 20px;
	vertical-align: bottom;
}

.pr-file-hover:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.pr-image-content {
	width: 100%;
	max-height: 300px;
	margin: 10px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pr-image-content > img {
	max-width: 100%;
	max-height: 300px;
}

.pr-image-content > img:hover {
	cursor: pointer;
	box-shadow: 0px 0px 2px 6px var(--main-color-dark);
}

.pr-text-content {
	width: 100%;
	max-height: 300px;
	padding: 10px;
	margin: 10px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	overflow-wrap: break-word;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
}

.pr-result-creation {
	width: 100%;
	margin-top: 10px;
	text-align: end;
}
</style>
