<template>
	<div class="ho-wrap-content">
		<div v-if="authorizationDone" class="ho-center-content">
			<div
				:class="[$global.hasRights({ route: 'Upload' }) ? '' : 'ho-disable-navigation', 'ho-nav-element']"
				@click="$router.push({ name: 'Upload' })"
			>
				<p>{{ $t('hoUpload') }}</p>
				<i class="fa-solid fa-cloud-arrow-up"></i>
			</div>
			<div
				:class="[$global.hasRights({ route: 'Pipeline' }) ? '' : 'ho-disable-navigation', 'ho-nav-element']"
				@click="$router.push({ name: 'Pipeline' })"
			>
				<p>{{ $t('hoPipeline') }}</p>
				<i class="fa-solid fa-diagram-project"></i>
			</div>
			<div
				:class="[$global.hasRights({ route: 'Data' }) ? '' : 'ho-disable-navigation', 'ho-nav-element']"
				@click="$router.push({ name: 'Data' })"
			>
				<p>{{ $t('hoData') }}</p>
				<i class="fa-solid fa-database"></i>
			</div>
			<div
				:class="[$global.hasRights({ route: 'Documentation' }) ? '' : 'ho-disable-navigation', 'ho-nav-element']"
				@click="toDocumentation"
			>
				<p>{{ $t('hoDocumentation') }}</p>
				<i class="fa-solid fa-book"></i>
			</div>
			<div
				:class="[$global.hasRights({ route: 'Profile' }) ? '' : 'ho-disable-navigation', 'ho-nav-element']"
				@click="$router.push({ name: 'Profile' })"
			>
				<p>{{ $t('hoProfile') }}</p>
				<i class="fa-solid fa-circle-user"></i>
			</div>
			<div
				:class="[$global.hasRights({ route: 'Admin' }) ? '' : 'ho-disable-navigation', 'ho-nav-element']"
				@click="$router.push({ name: 'Admin' })"
			>
				<p>{{ $t('hoAdmin') }}</p>
				<i class="fa-solid fa-gear"></i>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Home
 * Provides navigation and overview of the app
 */
export default {
	name: 'Home',
	data() {
		return {
			authorizationDone: false,
		};
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.authorizationDone = true});
	},
	methods: {
		// @vuese
		// Navigates the user to a external code documentation
		toDocumentation() {
			if (['super', 'admin', 'developer'].includes(this.$global.getUser().role))
				window.open('https://ai4health.reutlingen-university.de/landing/', '_blank');
			else this.$global.showToast('warn', this.$t('apUnauthorizedWarning'));
		},
	},
};
</script>

<style scoped>
.ho-wrap-content {
	max-width: 100vw;
	height: 100%;
	padding-top: 20px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
	box-sizing: border-box;
}

.ho-center-content {
	width: 900px;
	max-width: 90%;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.ho-nav-element {
	height: 200px;
	min-width: 220px;
	margin: 1.5%;
	padding: 20px 10px;
	flex: 0 0 30%;
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--main-color-dark);
	border-radius: 10px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	cursor: pointer;
}

.ho-nav-element:hover {
	background-color: var(--main-color-4);
}

.ho-nav-element:hover i {
	color: var(--main-color-6) !important;
}

.ho-nav-element p {
	flex: 0 0 100%;
	font-size: 30px;
	color: var(--main-color-text-light);
}

.ho-nav-element i {
	flex: 0 0 100%;
	font-size: 90px;
	-webkit-text-stroke: 2px var(--main-color-border-dark);
	color: var(--main-color-5);
}

.ho-disable-navigation {
	pointer-events: none;
	background-color: var(--main-color-disabled);
}

.ho-disable-navigation p {
	color: var(--main-color-1);
}

.ho-disable-navigation i {
	color: var(--main-color-5);
}
</style>
