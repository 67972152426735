<template>
	<div class="pc-wrap-content">
		<div class="pc-top-bar">
			<i class="fa-solid fa-gear pc-config-icon"></i>
			<p class="pc-config-name">{{ config.name }}</p>
			<i class="fa-regular fa-floppy-disk pc-config-save" @click="downloadContent"></i>
		</div>
		<div class="pc-wrap-config-fields">
			<div v-for="key in Object.keys(config.config)" :key="key" class="pc-wrap-input">
				<p class="pc-label">{{ key }} ({{ $global.getTypeOfVar(config.config[key]) }})</p>
				<p class="pc-content">{{ config.config[key] == null ? 'null' : config.config[key] }}</p>
			</div>
		</div>
		<p class="pc-config-creation">{{ $global.parseDateFull(config.creation) }}</p>
	</div>
</template>

<script>
/**
 * @group Evaluation
 * Displays a configuration of a pipeline training
 */
export default {
	name: 'PipelineConfiguration',
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
		};
	},
	methods: {
		// @vuese
		// Downloads the configuration content
		downloadContent() {
			this.isLoading = true;
			try {
				let a = document.createElement('a');
				a.href = `data:application/json;base64, ${btoa(JSON.stringify(this.config.config))}`;
				a.download = `${this.config.name}.json`;
				a.click();
				a.remove();
				this.isLoading = false;
			} catch (error) {
				console.log(error);
				this.$global.showToast('error', this.$t('pcMediaDownloadError'));
				this.isLoading = false;
			}
		},
	},
};
</script>

<style scoped>
.pc-wrap-content {
	flex: 1 1 400px;
	padding: 10px 20px;
	margin: 10px 20px;
	border: 1px solid var(--main-color-border-light);
	border-radius: 20px;
	background-color: var(--main-color-3);
	box-sizing: border-box;
}

.pc-top-bar {
	width: 100%;
	height: 30px;
}

.pc-config-icon {
	float: left;
	font-size: 25px;
	margin-right: 10px;
	vertical-align: top;
	color: var(--main-color-highlight);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pc-config-name {
	width: calc(100% - 80px);
	float: left;
	font-size: 25px;
	margin-right: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: top;
}

.pc-config-save {
	float: right;
	font-size: 25px;
	margin-left: 10px;
	vertical-align: top;
}

.pc-config-save:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.pc-wrap-config-fields {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	box-sizing: border-box;
}

.pc-wrap-input {
	flex: 1 1 200px;
	display: inline-block;
	margin: 10px;
	box-sizing: border-box;
}

.pc-label {
	width: fit-content;
	display: block;
	margin-bottom: 5px;
}

.pc-content {
	width: 100%;
	height: calc(100% - 20px);
	display: block;
	padding: 5px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
}

.pc-config-creation {
	width: 100%;
	margin-top: 10px;
	text-align: end;
}
</style>
