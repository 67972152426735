<template>
	<div class="nf-wrap-content">
		<div class="nf-center-content">
			<h1>{{ $t('nfPageNotFound') }}</h1>
			<h2>{{ $t('nfPageNotFoundText') }}</h2>
			<button class="app-default-btn" @click="backToPage">{{ $t('nfBackToPage') }}</button>
		</div>
	</div>
</template>

<script>
/**
 * @group NotFound
 * Catches all routes that are unknown to the server and redirects the user
 */
export default {
	name: 'NotFound',
	methods: {
		// @vuese
		// Forwards the user to a certain page depending of the login status
		backToPage() {
			if (this.$global.getUser()) this.$router.push({ name: 'Home' });
			else this.$router.push({ name: 'Login' });
		},
	},
};
</script>

<style scoped>
.nf-wrap-content {
	max-width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
}

.nf-center-content {
	width: 600px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px;
}

.nf-wrap-content h1 {
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	color: var(--main-color-6);
}

.nf-wrap-content h2 {
	font-size: 30px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	font-weight: normal;
	color: var(--main-color-text-light);
}

button {
	font-size: 25px;
	width: 300px;
	height: 40px;
}
</style>
