<template>
	<div class="re-wrap-content">
		<div class="re-center-content">
			<h1>{{ $t('reRegistration') }}</h1>
			<div class="re-wrap-input">
				<label>{{ $t('reEmail') }}</label>
				<input type="email" autocomplete="on" :placeholder="$t('reEmail')" v-model.trim="user.email" />
			</div>
			<br />
			<div class="re-wrap-input">
				<label>{{ $t('reFirstName') }}</label>
				<input type="text" autocomplete="on" :placeholder="$t('reFirstName')" v-model.trim="user.firstName" />
			</div>
			<div class="re-wrap-input">
				<label>{{ $t('reLastName') }}</label>
				<input type="text" autocomplete="on" :placeholder="$t('reLastName')" v-model.trim="user.lastName" />
			</div>
			<br />
			<div class="re-wrap-input">
				<label>{{ $t('rePassword') }}</label>
				<input
					:type="[showPassword ? 'text' : 'password']"
					autocomplete="on"
					:placeholder="$t('rePassword')"
					v-model="user.password"
				/>
				<i v-if="!showPassword" class="fas fa-eye re-smaller-eye" @mouseenter="showPassword = true"></i>
				<i v-if="showPassword" class="fas fa-eye-slash" @mouseleave="showPassword = false"></i>
			</div>
			<div class="re-wrap-input">
				<label>{{ $t('rePasswordRepeat') }}</label>
				<input
					:type="[showPasswordRepeat ? 'text' : 'password']"
					autocomplete="on"
					:placeholder="$t('rePasswordRepeat')"
					v-model="user.passwordRepeat"
				/>
				<i v-if="!showPasswordRepeat" class="fas fa-eye re-smaller-eye" @mouseenter="showPasswordRepeat = true"></i>
				<i v-if="showPasswordRepeat" class="fas fa-eye-slash" @mouseleave="showPasswordRepeat = false"></i>
			</div>
			<button class="app-success-btn" @click="register">
				{{ $t('reRegister') }}
				<i v-if="registrationMailPending" class="fa-solid fa-spinner re-spin"></i>
			</button>
			<button class="app-default-btn" @click="showRequestMail = true">
				{{ $t('reRequestCode') }}

				<i v-if="verificationMailPending" class="fa-solid fa-spinner re-spin"></i>
			</button>
			<div v-if="showRequestMail" class="re-wrap-code-reset">
				<h2>{{ $t('reRequestNewCode') }}</h2>
				<div class="re-wrap-input">
					<label>{{ $t('reEmail') }}</label>
					<input type="text" autocomplete="on" :placeholder="$t('reEmail')" v-model.trim="resetEmail" />
				</div>
				<button class="app-success-btn" @click="requestNewCode">{{ $t('reRequest') }}</button>
				<i class="fas fa-times-circle" @click="showRequestMail = false"></i>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Access
 * Provides inputs for the registration process
 */
export default {
	name: 'Registration',
	data() {
		return {
			registrationMailPending: false,
			verificationMailPending: false,
			showPassword: false,
			showPasswordRepeat: false,
			showRequestMail: false,
			user: {
				email: '',
				firstName: '',
				lastName: '',
				password: '',
				passwordRepeat: '',
				profilePicture: null,
			},
			resetEmail: '',
		};
	},
	methods: {
		// @vuese
		// Starts the registration process of a user by checking the inputs and sending a request to the backend
		register() {
			this.user.email = this.user.email.toLowerCase();
			if (!this.$global.validEmail(this.user.email)) this.$global.showToast('warn', this.$t('reInvalidEmail'));
			else if (this.user.firstName.length < 2 || this.user.firstName.length > 20)
				this.$global.showToast('warn', this.$t('reFirstNameLength'));
			else if (this.user.lastName.length < 2 || this.user.lastName.length > 20)
				this.$global.showToast('warn', this.$t('reLastNameLength'));
			else if (this.user.password.length < 9) this.$global.showToast('warn', this.$t('reShortPasswordLength'));
			else if (this.user.password !== this.user.passwordRepeat) this.$global.showToast('warn', this.$t('rePasswordDontMatch'));
			else {
				this.registrationMailPending = true;
				this.$global.postData('access', '/registration', { user: this.user }, null, (err, data) => {
					if (err) {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						this.$global.showToast('error', this.$t(msg ? msg : 'reRegistrationError'));
						this.registrationMailPending = false;
					} else {
						this.$global.showToast('success', this.$t('reRegistrationSuccess'));
						this.$router.push({ name: 'Login' });
						this.registrationMailPending = false;
					}
				});
			}
		},
		// @vuese
		// Requests a new verification code if the user needs a new one
		requestNewCode() {
			this.verificationMailPending = true;
			this.$global.postData('access', '/resend-verification', { email: this.resetEmail }, null, (err, data) => {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					this.$global.showToast('error', this.$t(msg ? msg : 'reMailError'));
					this.verificationMailPending = false;
				} else {
					this.$global.showToast('success', this.$t('reMailSend'));
					this.resetEmail = '';
					this.showRequestMail = false;
					this.verificationMailPending = false;
				}
			});
		},
	},
};
</script>

<style scoped>
.re-wrap-content {
	max-width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
}

.re-wrap-content h1 {
	width: fit-content;
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	color: var(--main-color-6);
}

.re-center-content {
	width: 800px;
	padding: 10px;
	text-align: start;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}

.re-wrap-input {
	width: fit-content;
	height: fit-content;
	margin: 5px;
	display: inline-block;
	position: relative;
	text-align: start;
}

.re-wrap-input label {
	font-size: 20px;
}

.re-wrap-input input {
	width: 350px;
	max-width: calc(100% - 25px);
	height: 30px;
	padding: 5px;
	display: block;
	margin: 10px auto 20px auto;
	font-size: 20px;
}

.re-wrap-input i {
	font-size: 25px;
	position: absolute;
	bottom: 30px;
	right: 10px;
	-webkit-text-stroke: 1px var(--main-color-text-dark);
	color: var(--main-color-text-light);
}

.re-smaller-eye {
	right: 12px !important;
}

button {
	width: 350px;
	height: 40px;
	margin: 10px auto;
	padding: 0px 15px;
	display: block;
	font-size: 25px;
	box-sizing: content-box;
}

.re-wrap-code-reset {
	width: 80vw;
	height: fit-content;
	max-height: 60vh;
	position: absolute;
	top: 20vh;
	left: 10vw;
	border-radius: 20px;
	padding: 3vh 2vw 2vh 2vw;
	overflow: auto;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: -10vw -20vh 0px 10000px var(--main-color-dark-80);
	box-sizing: border-box;
	text-align: center;
	background-color: var(--main-color-4);
	animation: slideIn 0.4s linear;
}

.re-wrap-code-reset h2 {
	font-size: 30px;
	margin: 0px auto 10px auto;
}

.re-wrap-code-reset i {
	font-size: 40px;
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-text-dark);
}

.re-wrap-code-reset i:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.re-wrap-code-reset .re-wrap-input input {
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.re-spin {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}
</style>
