<template>
	<div :id="state.moduleID" class="ts-wrap-content">
		<div class="ts-collapsable">
			<h2 :id="`toc${idx}${state.name.replace(/ /g, '')}`">
				{{ idx + 1 }}. {{ state.name
				}}<i v-if="!stateReportLoading" class="fa-regular fa-floppy-disk" @click="generateStateReport"> </i
				><i v-else class="fa-solid fa-spinner ts-loading"></i>
			</h2>
			<div class="ts-toggle-div">
				<i class="fa-solid fa-chevron-up ts-hidden" @click="collapseState"></i>
				<i class="fa-solid fa-chevron-down" @click="expandState"></i>
			</div>
			<div class="ts-header">
				<div class="ts-information-bar">
					<div class="ts-duration">
						<p>{{ $t('tsDuration') }}<i class="fa-regular fa-clock"></i></p>
						<div class="ts-content">
							{{ getDuration() }}
						</div>
					</div>
					<div class="ts-category">
						<p>{{ $t('tsCategory') }}<i class="fa-solid fa-cubes"></i></p>
						<div class="ts-content">
							{{ $t(`ts${state.category}`) }}
						</div>
					</div>
					<div class="ts-files">
						<p>{{ $t('tsFiles') }}<i class="fa-solid fa-file-code"></i></p>
						<div class="ts-content">
							{{ state.files.join(', ') }}
							<p v-if="state.files.length == 0">{{ $t('tsNoFiles') }}</p>
						</div>
					</div>
					<div class="ts-functions">
						<p>{{ $t('tsFunctions') }}<i class="fa-solid fa-code"></i></p>
						<div class="ts-content">
							{{ getFunctionNames() }}
							<p v-if="state.functions.length == 0">{{ $t('tsNoFunctions') }}</p>
						</div>
					</div>
				</div>
			</div>
			<h3 :id="`toc${idx}1conf`">{{ idx + 1 }}.1 {{ $t('tsConfigurations') }}</h3>
			<div class="ts-wrap-configs">
				<PipelineConfiguration
					v-for="config in state.configs.slice(
						(configCurrentPage - 1) * configPageSize,
						(configCurrentPage - 1) * configPageSize + configPageSize
					)"
					:key="config.configurationID"
					:config="config"
				/>

				<div v-if="state.configs.length == 0" class="ts-no-configurations">
					<p>{{ $t('tsNoConfigurations') }}</p>
				</div>
			</div>
			<div v-if="state.configs.length !== 0" class="ts-show-more">
				<p>{{ state.configs.length }} {{ $t('tsAllConfigs') }}</p>
				<div class="ts-options">
					<select v-model="configPageSize" @change="configCurrentPage = 1">
						<option :value="5">5</option>
						<option :value="10">10</option>
						<option :value="25">25</option>
						<option :value="100">100</option>
					</select>
				</div>
				<div class="ts-pages">
					<p
						v-for="page in $global.getPages(state.configs.length, configPageSize, configCurrentPage)"
						:key="page"
						class="ts-page"
					>
						<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="ts-no-hover"> . . . </span>
						<span v-else :class="[page == configCurrentPage ? 'ts-highlight-page' : '']" @click="setConfigPage(page)">{{
							page
						}}</span>
					</p>
				</div>
			</div>
			<h3 :id="`toc${idx}2res`">{{ idx + 1 }}.2 {{ $t('tsResults') }}</h3>
			<div class="ts-wrap-results">
				<PipelineResult
					v-for="result in state.results.slice(
						(resultCurrentPage - 1) * resultPageSize,
						(resultCurrentPage - 1) * resultPageSize + resultPageSize
					)"
					:key="result.resultID"
					:result="result"
				/>
				<div v-if="state.results.length == 0" class="ts-no-results">
					<p>{{ $t('tsNoResults') }}</p>
				</div>
			</div>
			<div v-if="state.results.length !== 0" class="ts-show-more">
				<p>{{ state.results.length }} {{ $t('tsAllResults') }}</p>
				<div class="ts-options">
					<select v-model="resultPageSize" @change="resultCurrentPage = 1">
						<option :value="5">5</option>
						<option :value="10">10</option>
						<option :value="25">25</option>
						<option :value="100">100</option>
					</select>
				</div>
				<div class="ts-pages">
					<p
						v-for="page in $global.getPages(state.results.length, resultPageSize, resultCurrentPage)"
						:key="page"
						class="ts-page"
					>
						<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="ts-no-hover"> . . . </span>
						<span v-else :class="[page == resultCurrentPage ? 'ts-highlight-page' : '']" @click="setResultPage(page)">{{
							page
						}}</span>
					</p>
				</div>
			</div>
			<h3 :id="`toc${idx}3logs`">
				{{ idx + 1 }}.3 {{ $t('tsLogs') }}
				<i v-if="!logDownloadLoading" class="fa-regular fa-floppy-disk" @click="downloadLogs"> </i
				><i v-else class="fa-solid fa-spinner ts-loading"></i>
			</h3>
			<div :class="logs.length > 0 ? 'ts-wrap-logs' : 'ts-no-logs'">
				<LiveLog v-if="logs.length > 0" :logs="logs" :connect="false" />
				<p v-else>{{ $t('tsNoLogs') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import PipelineConfiguration from './PipelineConfiguration.vue';
import PipelineResult from './PipelineResult.vue';
import LiveLog from '../log/LiveLog.vue';
import i18n from '@/translations/i18n';
/**
 * @group Evaluation
 * Displays all logs, results and configurations for a training state
 */
export default {
	name: 'TrainingState',
	components: {
		PipelineConfiguration,
		PipelineResult,
		LiveLog,
	},
	props: {
		state: {
			type: Object,
			required: true,
		},
		idx: {
			type: Number,
			required: true,
		},
		logs: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			preventClick: false,
			logDownloadLoading: false,
			stateReportLoading: false,
			configPageSize: 5,
			configCurrentPage: 1,
			resultPageSize: 5,
			resultCurrentPage: 1,
		};
	},
	methods: {
		// @vuese
		// Gets the duration between two dates
		// @return [String] The duration as HH:mm:ss
		getDuration() {
			let seconds = (new Date(this.state.finished) - new Date(this.state.started)) / 1000;
			seconds = seconds < 1 ? 1 : seconds;
			let minutes = Math.round(Math.floor(seconds / 60));
			let hours = Math.round(Math.floor(minutes / 60));
			let days = Math.round(Math.floor(hours / 24));

			hours = String(hours - days * 24);
			minutes = String(minutes - days * 24 * 60 - hours * 60);
			seconds = String(Math.round(seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60));

			hours = hours.length == 1 ? `0${hours}` : hours;
			minutes = minutes.length == 1 ? `0${minutes}` : minutes;
			seconds = seconds.length == 1 ? `0${seconds}` : seconds;

			return `${hours}:${minutes}:${seconds}`;
		},
		// @vuese
		// Returns a function call string
		// @return [String] The functions string
		getFunctionNames() {
			return this.state.functions
				.map((func) => {
					return `${func}()`;
				})
				.join(', ');
		},
		// @vuese
		// Sets the page of the configs
		// @arg page[Number] - The page number
		setConfigPage(page) {
			this.configCurrentPage = page;
		},
		// @vuese
		// Sets the page of the results
		// @arg page[Number] - The page number
		setResultPage(page) {
			this.resultCurrentPage = page;
		},
		// @vuese
		// Expands the state content
		expandState() {
			let elem = document.getElementById(this.state.moduleID).querySelector(':scope > .ts-collapsable');
			elem.style.height = 'fit-content';
			elem.style.overflow = 'auto';

			[...elem.querySelectorAll(':scope > .ts-toggle-div > i')].forEach((i) => {
				if (i.classList.contains('ts-hidden')) i.classList.remove('ts-hidden');
				else i.classList.add('ts-hidden');
			});
		},
		// @vuese
		// Collapses the state content
		collapseState() {
			let elem = document.getElementById(this.state.moduleID).querySelector(':scope > .ts-collapsable');
			elem.style.height = '30px';
			elem.style.overflow = 'hidden';

			[...elem.querySelectorAll(':scope > .ts-toggle-div > i')].forEach((i) => {
				if (i.classList.contains('ts-hidden')) i.classList.remove('ts-hidden');
				else i.classList.add('ts-hidden');
			});
		},
		// @vuese
		// Downloads the logs for the state
		downloadLogs() {
			this.logDownloadLoading = true;
			try {
				let a = document.createElement('a');
				let logContent = '';
				this.logs.forEach((log) => {
					logContent += `${new Date(log.creation).toISOString().split('.')[0]} `;
					logContent += `[${log.level.toUpperCase()}] `;
					logContent += `${log.file} `;
					logContent += `${log.function}(): `;
					logContent += `${log.content}\n`;
				});
				a.href = `data:text/plain;base64, ${btoa(logContent)}`;
				a.download = `${this.state.name}_log.log`;
				a.click();
				a.remove();
				this.logDownloadLoading = false;
			} catch (error) {
				console.log(error);
				this.$global.showToast('error', this.$t('tsMediaDownloadError'));
				this.logDownloadLoading = false;
			}
		},
		// @vuese
		// Generates the state report for the selected state
		generateStateReport() {
			if (!this.preventClick) {
				this.preventClick = true;
				this.stateReportLoading = true;
				this.$global.showToast('info', this.$t('tsPartialGenerationStarted'), true);
				let that = this;
				this.$global.getData(
					'training',
					`/trainings/${this.$route.params.trainingID}/report/${this.state.moduleID}`,
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
						params: { locale: i18n.locale },
					},
					null,
					function (err, result) {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							that.$global.showToast('error', that.$t(msg ? msg : 'tsReportCreationError'));
							that.stateReportLoading = false;
							that.preventClick = false;
						} else {
							try {
								let a = document.createElement('a');
								a.href = `data:application/zip;base64, ${result}`;
								a.download = `${that.$t('evEvaluationReport')}.zip`;
								a.click();
								a.remove();
								that.$global.showToast('success', that.$t('tsPartialGenerationFinished'), true);
							} catch (error) {
								console.log(error);
								that.$global.showToast('error', that.$t('tsReportDownloadError'));
							} finally {
								that.stateReportLoading = false;
								that.preventClick = false;
							}
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.ts-wrap-content {
	width: 100%;
	height: fit-content;
	padding: 10px;
	margin-bottom: 20px;
	box-sizing: border-box;
	border-radius: 20px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-dark-80);
	overflow: hidden;
}

.ts-collapsable {
	height: 30px;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.ts-toggle-div {
	position: absolute;
	top: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ts-toggle-div i {
	font-size: 25px;
}

.ts-toggle-div i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.ts-hidden {
	display: none;
}

.ts-header {
	width: 100%;
	height: fit-content;
	margin-bottom: 10px;
	text-align: center;
}

h2,
h3 {
	width: 100%;
	margin-bottom: 5px;
	text-align: center;
	color: var(--main-color-6);
}

h3 {
	margin: 5px 0px;
	font-size: 20px;
	text-decoration: underline;
}

h2 > i,
h3 > i {
	margin-left: 10px;
	color: var(--main-color-text-light);
}

h2 > i:hover,
h3 > i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.ts-loading {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.ts-information-bar {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
}

.ts-information-bar > div {
	padding: 5px 10px;
}

.ts-duration {
	flex: 1 1 120px;
}

.ts-category {
	flex: 1 1 130px;
}

.ts-files {
	flex: 1 1 150px;
}

.ts-functions {
	flex: 1 1 150px;
}

.ts-information-bar > div > p {
	margin-bottom: 5px;
	font-size: 20px;
	font-style: italic;
	text-decoration: underline;
}

.ts-information-bar > div > p > i {
	margin-left: 10px;
}

.ts-information-bar > div > .ts-content {
	max-height: 100px;
	overflow: auto;
}

.ts-wrap-configs {
	width: 100%;
	height: fit-content;
	display: inline-flex;
	justify-content: center;
	flex-flow: wrap;
}

.ts-wrap-results {
	width: 100%;
	height: fit-content;
	display: inline-flex;
	justify-content: center;
	flex-flow: wrap;
}

.ts-wrap-logs {
	height: 400px;
}

.ts-no-configurations,
.ts-no-results,
.ts-no-logs {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	display: inline-flex;
	justify-content: center;
	flex-flow: wrap;
}

.ts-wrap-button {
	width: 100%;
	height: fit-content;
	padding: 5px 0px;
	text-align: center;
}

.ts-show-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.ts-show-more p {
	margin: 5px auto;
}

.ts-show-more button {
	font-size: 17px;
}

.ts-options select {
	cursor: pointer;
}

.ts-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.ts-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.ts-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.ts-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.ts-highlight-page {
	color: var(--main-color-6);
	text-decoration: none !important;
	cursor: default !important;
}
</style>
