<template>
	<div class="up-wrap-content">
		<div class="up-wrap-top">
			<button :class="[dataUpload ? 'app-warn-btn' : 'app-default-btn']" @click="showDataUpload">
				{{ $t('upDataUpload') }}
				<i class="fa-solid fa-file"></i>
			</button>
			<button
				:class="[
					$global.hasRights({ element: 'PipelineUpload' })
						? pipelineUpload
							? 'app-warn-btn'
							: 'app-default-btn'
						: 'app-disabled-btn',
				]"
				@click="$global.hasRights({ element: 'PipelineUpload' }) ? showPipelineUpload() : null"
			>
				{{ $t('upPipelineUpload') }}
				<i class="fa-solid fa-code"></i>
			</button>
		</div>
		<div class="up-wrap-information">
			<div class="up-wrap-applications">
				<p>{{ $t('upApplications') }}</p>
				<select v-model="selectedApplication">
					<option :value="null" disabled selected hidden>{{ $t('upChooseApplication') }}</option>
					<option v-for="app in applications" :key="app.applicationID" :value="app">
						{{ $t(`${app.abbreviation}Name`) }}
					</option>
				</select>
				<i
					v-if="selectedApplication"
					class="fa-solid fa-circle-info up-info"
					@click="
						$emit('showInfo', {
							title: $t(`${selectedApplication.abbreviation}InfoTitle`),
							text: $t(`${selectedApplication.abbreviation}Info`),
						})
					"
				></i>
			</div>
			<DataUploadInformation
				v-if="dataUpload && selectedApplication"
				:application="selectedApplication"
				:selectedApplication="selectedApplication"
				:file="selectedFile"
				:metaToEdit="metaToEdit"
				@showInfo="$emit('showInfo', $event)"
				@clearFile="clearFile"
				@newPackage="addPackage"
				@saveMeta="saveMeta"
			/>
			<PipelineUploadInformation
				v-if="pipelineUpload && selectedApplication"
				:applicationID="selectedApplication.applicationID"
				:frameworks="frameworks"
				:entryPoint="entryPoint"
				@showInfo="$emit('showInfo', $event)"
				@setEntryPoint="entryPoint = $event"
				@newConfig="config = $event"
			/>
		</div>
		<div v-if="selectedApplication" class="up-wrap-files">
			<DataUploadFiles
				v-if="dataUpload"
				:application="selectedApplication"
				:editedMeta="editedMeta"
				:newPackage="newPackage"
				@showInfo="$emit('showInfo', $event)"
				@selectFile="selectFile"
				@editMeta="editMeta"
				@clearUpload="clearUpload"
			/>
			<PipelineUploadFiles
				v-if="pipelineUpload"
				:entryPoint="entryPoint"
				:config="config"
				@setEntryPoint="entryPoint = $event"
				@clearUpload="clearUpload"
			/>
		</div>
	</div>
</template>

<script>
import DataUploadInformation from '../components/upload/DataUploadInformation.vue';
import DataUploadFiles from '../components/upload/DataUploadFiles.vue';
import PipelineUploadInformation from '../components/upload/PipelineUploadInformation.vue';
import PipelineUploadFiles from '../components/upload/PipelineUploadFiles.vue';
/**
 * @group Upload
 * Contains the upload of groundtruth data and ai pipelines
 */
export default {
	name: 'Upload',
	components: {
		DataUploadInformation,
		DataUploadFiles,
		PipelineUploadInformation,
		PipelineUploadFiles,
	},
	data() {
		return {
			dataUpload: true,
			pipelineUpload: false,
			user: null,
			frameworks: [],
			applications: [],
			selectedApplication: null,
			selectedFile: null,
			metaToEdit: null,
			editedMeta: null,
			newPackage: null,
			entryPoint: null,
			config: {},
		};
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			if (sessionStorage.getItem('uploadSuccessfull')) {
				let from = sessionStorage.getItem('from');
				if (from == 'pipeline') this.showPipelineUpload();
				sessionStorage.removeItem('from');

				this.$global.showToast('success', this.$t('upFilesUploaded'), true);
				this.$nextTick(() => {
					this.selectedApplication = JSON.parse(sessionStorage.getItem('uploadSuccessfull'));
					sessionStorage.removeItem('uploadSuccessfull');
				});
			}

			this.user = this.$global.getUser();
			this.$cr.getAllApplications((err, result) => {
				if (!err) this.applications = result;
			});
			this.$cr.getAllFrameworks((err, result) => {
				if (!err) this.frameworks = result;
			});
		},
		// @vuese
		// Shows the data upload and hides the pipeline upload
		showDataUpload() {
			if (!this.dataUpload) {
				this.selectedApplication = null;
				this.pipelineUpload = false;
				this.dataUpload = true;
			}
		},
		// @vuese
		// Shows the pipeline upload and hides the data upload
		showPipelineUpload() {
			if (!this.pipelineUpload) {
				this.selectedApplication = null;
				this.pipelineUpload = true;
				this.dataUpload = false;
			}
		},
		// @vuese
		// Selects a file
		// @arg file[Object] - The selected file
		selectFile(file) {
			this.selectedFile = null;
			this.newPackage = null;
			this.$nextTick(() => {
				this.selectedFile = file;
			});
		},
		// @vuese
		// Clears the selected file
		clearFile() {
			this.selectedFile = null;
		},
		// @vuese
		// Saves the meta data
		// @arg meta[Object] - The edited meta
		saveMeta(meta) {
			this.metaToEdit = null;
			this.editedMeta = meta;
		},
		// @vuese
		// Edits a meta object
		// @arg meta[Object] - The meta object
		editMeta(meta) {
			this.editedMeta = null;
			this.metaToEdit = meta;
		},
		// @vuese
		// Adds a new package with recorded data
		// @arg pack[Object] - The new package
		addPackage(pack) {
			this.newPackage = null;
			this.$nextTick(() => {
				this.newPackage = pack;
			});
		},
		// @vuese
		// Clears the upload data after a successfull upload
		// @arg from[String] - Is either pipline or data
		clearUpload(from) {
			sessionStorage.setItem('uploadSuccessfull', JSON.stringify(this.selectedApplication));
			sessionStorage.setItem('from', from);
			location.reload();
		},
	},
};
</script>

<style scoped>
.up-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 0px 10px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
}
.up-wrap-top {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
	margin: 10px auto;
}

.up-wrap-top button {
	flex: 1 1 250px;
	margin: 0px 2.5%;
	font-size: 25px;
}

.app-warn-btn {
	background-color: var(--main-color-6);
	border: 2px solid var(--main-color-border-dark);
	color: var(--main-color-text-dark);
}

.app-default-btn {
	border: 2px solid var(--main-color-border-dark);
}

.up-wrap-top button i {
	margin-left: 5px;
}

.up-wrap-applications {
	width: 100%;
	height: fit-content;
	display: block;
	margin-bottom: 20px;
}

.up-wrap-applications p {
	margin: 0px 0px 10px 5px;
	font-size: 18px;
	text-decoration: underline;
}

.up-wrap-applications select {
	width: 100%;
	max-width: 300px;
	min-width: 150px;
	height: fit-content;
	font-size: 17px;
}

.up-info {
	margin-left: 10px;
	font-size: 30px;
	vertical-align: top;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.up-info:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.up-wrap-information,
.up-wrap-files {
	width: 50%;
	height: calc(100% - 70px);
	padding: 0px 10px;
	display: inline-block;
	box-sizing: border-box;
	vertical-align: top;
}
</style>
