<template>
	<div class="lo-wrap-content">
		<div class="lo-center-content">
			<img :src="require(`@/assets/images/logo_transparent.png`)" alt="Logo" class="lo-logo" />
			<h1>{{ $t('loPageTitle') }}</h1>
			<div class="lo-wrap-login">
				<div class="lo-wrap-input">
					<label>{{ $t('loEmail') }}</label>
					<input
						type="text"
						autocomplete="on"
						:placeholder="$t('loEmail')"
						v-model.trim="credentials.email"
						@keyup.enter="login"
					/>
				</div>
				<div class="lo-wrap-input">
					<label>{{ $t('loPassword') }}</label>
					<input
						:type="[showPassword ? 'text' : 'password']"
						autocomplete="on"
						:placeholder="$t('loPassword')"
						v-model="credentials.password"
						@keyup.enter="login"
					/>
					<i v-if="!showPassword" class="lo-smaller-eye fas fa-eye" @mouseenter="showPassword = true"></i>
					<i v-if="showPassword" class="fas fa-eye-slash" @mouseleave="showPassword = false"></i>
				</div>
				<button class="app-default-btn" @click="login">{{ $t('loLogin') }}</button>
				<div class="lo-wrap-links">
					<p @click="showPasswordReset = true">{{ $t('loForgotPassword') }}</p>
					<p @click="$router.push({ name: 'Registration' })">{{ $t('loRegister') }}</p>
				</div>
			</div>
			<div v-if="showPasswordReset" class="lo-wrap-password-reset">
				<h2>{{ $t('loForgotPasswordTitle') }}</h2>
				<div class="lo-wrap-input">
					<label>{{ $t('loEmail') }}</label>
					<input
						type="email"
						autocomplete="on"
						:placeholder="$t('loEmail')"
						v-model.trim="resetEmail"
						@keyup.enter="resetPassword"
					/>
				</div>
				<button class="app-success-btn" @click="resetPassword">
					{{ $t('loResetPassword') }}
					<i v-if="resetMailPending" class="fa-solid fa-spinner lo-spin"></i>
				</button>
				<i class="fas fa-times-circle lo-close" @click="showPasswordReset = false"></i>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Access
 * Provides inputs for the login process
 */
export default {
	name: 'Login',
	data() {
		return {
			resetMailPending: false,
			showPassword: false,
			showPasswordReset: false,
			credentials: {
				email: '',
				password: '',
			},
			resetEmail: '',
		};
	},
	created() {
		sessionStorage.removeItem('credentials');
		sessionStorage.removeItem('user');
	},
	mounted() {
		if (['loVerified'].includes(this.$route.query.msg)) this.$global.showToast('success', this.$t(this.$route.query.msg));
		else if (this.$route.query.msg !== '' && this.$route.query.msg)
			this.$global.showToast('error', this.$t(this.$route.query.msg));
	},
	methods: {
		// @vuese
		// Starts the login process of a user by checking the inputs and sending a request to the backend
		login() {
			if (this.credentials.email.length === 0) this.$global.showToast('error', this.$t('loEmptyEmail'));
			else if (this.credentials.password.length === 0) this.$global.showToast('error', this.$t('loEmptyPassword'));
			else {
				this.$global.postData('access', '/check-credentials', { credentials: this.credentials }, null, (err, data) => {
					if (err) {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						this.$global.showToast('error', this.$t(msg ? msg : 'loLoginError'));
					} else {
						sessionStorage.setItem('credentials', JSON.stringify(data.credentials));
						sessionStorage.setItem('user', JSON.stringify(data.user));
						this.$global.setUser(data.user);
						this.$router.push({ name: 'Home' });
					}
				});
			}
		},
		// @vuese
		// Starts the password reset process of a user by sending a request to the backend
		resetPassword() {
			this.resetMailPending = true;
			this.$global.postData('access', '/initiate-password-reset', { email: this.resetEmail }, null, (err, data) => {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					this.$global.showToast('error', this.$t(msg ? msg : 'loMailError'));
					this.resetMailPending = false;
				} else {
					this.$global.showToast('success', this.$t('loMailSend'));
					this.resetEmail = '';
					this.showPasswordReset = false;
					this.resetMailPending = false;
				}
			});
		},
	},
};
</script>

<style scoped>
.lo-wrap-content {
	max-width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
}

.lo-center-content {
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px;
}

.lo-logo {
	max-width: 200px;
	max-height: 200px;
}

.lo-wrap-content h1 {
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	color: var(--main-color-6);
}
.lo-wrap-input {
	width: fit-content;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
}
.lo-wrap-input label {
	font-size: 20px;
}

.lo-wrap-input input {
	min-width: 350px;
	max-width: calc(100% - 25px);
	height: 30px;
	padding: 5px;
	display: block;
	margin: 10px auto 20px auto;
	font-size: 20px;
}
.lo-wrap-input i {
	font-size: 25px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	-webkit-text-stroke: 1px var(--main-color-text-dark);
	color: var(--main-color-text-light);
}
.lo-smaller-eye {
	right: 12px !important;
}

button {
	font-size: 25px;
	width: 300px;
	height: 40px;
	padding: 0px 15px;
	box-sizing: content-box;
}

.lo-wrap-links {
	margin: 20px auto 0px auto;
}
.lo-wrap-links p {
	width: fit-content;
	font-size: 20px;
	font-style: italic;
	margin: 10px auto;
}
.lo-wrap-links p:hover {
	cursor: pointer;
	text-decoration: underline;
}
.lo-wrap-password-reset {
	width: 80vw;
	height: fit-content;
	max-height: 60vh;
	position: absolute;
	top: 20vh;
	left: 10vw;
	border-radius: 20px;
	padding: 3vh 2vw 2vh 2vw;
	overflow: auto;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: -10vw -20vh 0px 10000px var(--main-color-dark-80);
	box-sizing: border-box;
	background-color: var(--main-color-4);
	animation: slideIn 0.4s linear;
}
.lo-wrap-password-reset h2 {
	font-size: 30px;
	margin: 0px auto 10px auto;
}
.lo-close {
	font-size: 40px;
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-text-dark);
}
.lo-close:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.lo-wrap-password-reset .lo-wrap-input input {
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.lo-wrap-password-reset button {
	width: 350px;
}

.lo-spin {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}
</style>
