<template>
	<div class="ap-wrap-content">
		<div class="ap-player">
			<i v-if="!isPlaying" class="fa-solid fa-circle-play ap-play-pause" @click="playAudio"></i>
			<i v-else class="fa-solid fa-circle-pause ap-play-pause" @click="pauseAudio"></i>
			<div class="ap-player-progress">
				<p>{{ currentTimeParsed }}</p>
				<input
					type="range"
					:class="[isPlaying ? 'ap-disable-change' : '', 'ap-progress-bar']"
					:min="0"
					:max="fullDuration"
					:step="0.0001"
					v-model="currentTime"
					@input="setTime"
				/>
				<p>{{ fullDurationParsed }}</p>
				<i v-if="currentVolume == 0" class="fa-solid fa-volume-xmark ap-volume" @click="setVolume($event, 0.5)"></i>
				<i v-else-if="currentVolume < 0.5" class="fa-solid fa-volume-low ap-volume" @click="setVolume($event, 0)"></i>
				<i v-else class="fa-solid fa-volume-high ap-volume" @click="setVolume($event, 0)"></i>
				<input type="range" class="ap-volume-bar" :min="0" :max="1" :step="0.01" v-model="currentVolume" @change="setVolume" />
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Audio
 * Plays a given audio file
 */
export default {
	name: 'AudioPlayer',
	props: {
		application: {
			type: String,
			required: true,
		},
		file: {
			type: Object,
			required: false,
		},
	},
	watch: {
		file: {
			handler: function (newVal) {
				this.resetVars();
				this.setupPlayer();
			},
			deep: true,
		},
	},
	data() {
		return {
			audioFile: null,
			// audioFile: new Audio(require('@/assets/sounds/test.wav')),
			currentTime: 0,
			currentTimeParsed: '00:00',
			fullDuration: 0,
			fullDurationParsed: '00:00',
			currentVolume: 0.5,
			audioTimeInterval: null,
			isPlaying: false,
		};
	},
	created() {
		this.setupPlayer();
	},
	beforeDestroy() {
		clearInterval(this.audioTimeInterval);
	},
	methods: {
		// @vuese
		// Setup for the player if a audio file is available
		setupPlayer() {
			if (this.file) {
				this.audioFile = new Audio(this.file.src);
				let that = this;
				this.audioFile.addEventListener('loadedmetadata', function () {
					if (that.audioFile.duration == Infinity) {
						that.audioFile.currentTime = 1e101;
						that.audioFile.ontimeupdate = function () {
							this.ontimeupdate = () => {
								return;
							};
							that.audioFile.currentTime = 0;
							that.canPlayAudio = true;
							that.fullDuration = that.audioFile.duration;
							that.fullDurationParsed = that.parseTime(that.fullDuration);
							return;
						};
					} else {
						that.audioFile.currentTime = 0;
						that.canPlayAudio = true;
						that.fullDuration = that.audioFile.duration;
						that.fullDurationParsed = that.parseTime(that.fullDuration);
					}
				});
			}
		},
		// @vuese
		// Creates the audio interval and plays the audio file
		playAudio() {
			if (this.audioFile && this.canPlayAudio) {
				this.isPlaying = true;
				this.createAudioInterval();
				this.audioFile.play();
			}
		},
		// @vuese
		// Destroys the audio interval and stops the audio file
		pauseAudio() {
			if (this.audioFile && this.canPlayAudio) {
				this.isPlaying = false;
				this.destroyAudioInterval();
				this.audioFile.pause();
			}
		},
		// @vuese
		// Creates the audio interval and plays the audio file
		// @arg e[Object] - The event that occured
		// @arg volume[Number] - The volume that was set
		setVolume(e, volume) {
			if (this.audioFile && this.canPlayAudio) {
				if (typeof volume == 'number') this.currentVolume = volume;
				this.audioFile.volume = this.currentVolume;
			}
		},
		// @vuese
		// Sets the current time of the audio file
		setTime() {
			if (this.audioFile && this.canPlayAudio) {
				if (!this.isPlaying) {
					this.audioFile.currentTime = this.currentTime;
					this.currentTimeParsed = this.parseTime(this.currentTime);
				}
			}
		},
		// @vuese
		// Creates a audio interval that is listening for the audio file time
		createAudioInterval() {
			this.audioTimeInterval = window.setInterval(() => {
				this.currentTime = this.audioFile.currentTime;
				this.currentTimeParsed = this.parseTime(this.currentTime);
				if (this.audioFile.ended) this.destroyAudioInterval();
			}, 10);
		},
		// @vuese
		// Destroys the audio interval
		destroyAudioInterval() {
			clearInterval(this.audioTimeInterval);
			this.isPlaying = false;
		},
		// @vuese
		// Parses the given time for better readability
		// @arg time[Number] - The time
		// @returns [String] - The parsed time as mm:ss
		parseTime(time) {
			let minutes = Math.floor(time / 60);
			let seconds = Math.round(time - 60 * minutes).toFixed(0);
			minutes = minutes.toFixed(0);
			minutes = minutes.length === 1 ? `0${minutes}` : minutes;
			seconds = seconds.length === 1 ? `0${seconds}` : seconds;
			return `${minutes}:${seconds}`;
		},
		// @vuese
		// Resets the audio player vars
		resetVars() {
			if (this.audioFile) this.pauseAudio();
			this.canPlayAudio = false;
			this.currentTime = 0;
			this.currentTimeParsed = '00:00';
			this.fullDuration = 0;
			this.fullDurationParsed = '00:00';
			this.currentVolume = 0.5;
			this.audioFile = null;
		},
	},
};
</script>

<style scoped>
.ap-wrap-content {
	width: 100%;
	max-width: 500px;
	min-width: 300px;
	height: fit-content;
}

.ap-player {
	width: 100%;
	height: 30px;
}

.ap-play-pause {
	font-size: 25px;
	width: 30px;
	margin-right: 5px;
	vertical-align: top;
	cursor: pointer;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ap-play-pause:hover {
	color: var(--main-color-6);
}

.ap-player-progress {
	width: calc(100% - 35px);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
}

.ap-player-progress p {
	width: 40px;
	margin: 0px 5px;
	display: inline-block;
	vertical-align: top;
}

.ap-progress-bar {
	width: calc(100% - 180px);
	margin: 0px !important;
	padding: 0px !important;
	vertical-align: top;
	box-shadow: none !important;
}

.ap-progress-bar:focus {
	box-shadow: none;
}

.ap-volume-bar {
	width: 40px;
	box-shadow: none !important;
}

.ap-volume-bar:focus {
	box-shadow: none;
}

.ap-volume {
	font-size: 25px;
	width: 35px;
	margin: 0px 10px 0px 5px;
	vertical-align: top;
	cursor: pointer;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ap-volume:hover {
	color: var(--main-color-6);
}

.ap-disable-change {
	pointer-events: none;
	cursor: default;
}
</style>
