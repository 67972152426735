import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { global } from './global.js';
import { socket } from './socket.js';
import { cr } from './commonRequests';
import i18n from '@/translations/i18n';
import VueWorker from 'vue-worker';

Vue.prototype.$global = global;
Vue.prototype.$socket = socket;
Vue.prototype.$cr = cr;
Vue.config.productionTip = false;
Vue.use(VueWorker);

new Vue({
	router,
	i18n,
	render: function (h) {
		return h(App);
	},
}).$mount('#app');
