<template>
	<div class="rd-wrap-content">
		<div v-if="selectedData.applicationID" class="rd-wrap-information">
			<div>{{ $t('rdApplication') }}</div>
			<p>{{ getApplicationName(selectedData.applicationID) }}</p>
		</div>
		<div v-if="selectedData.frameworkID" class="rd-wrap-information">
			<div>{{ $t('rdFramework') }}</div>
			<p>{{ getFrameworkName(selectedData.frameworkID) }}</p>
		</div>
		<div v-if="selectedData.name" class="rd-wrap-information">
			<div>{{ $t('rdName') }}</div>
			<p>{{ selectedData.name }}</p>
		</div>
		<div v-if="selectedData.creation" class="rd-wrap-information">
			<div>{{ $t('rdCreation') }}</div>
			<p>{{ $global.parseDateFull(selectedData.creation) }}</p>
		</div>
		<div v-if="selectedData.revisionID" class="rd-wrap-information">
			<div>{{ $t('rdRevisionID') }}</div>
			<p>{{ $global.shortenRevisionID(selectedData.revisionID) }}</p>
		</div>
		<div v-if="selectedData.trainings || typeof selectedData.trainings == 'number'" class="rd-wrap-information">
			<div>{{ $t('rdTrainings') }}</div>
			<p>{{ selectedData.trainings }}</p>
		</div>
		<div v-if="selectedData.amountPackages || typeof selectedData.amountPackages == 'number'" class="rd-wrap-information">
			<div>{{ $t('rdAmountPackages') }}</div>
			<p>{{ selectedData.amountPackages }}</p>
		</div>
		<div v-if="selectedData.amountData || typeof selectedData.amountData == 'number'" class="rd-wrap-information">
			<div>{{ $t('rdAmountData') }}</div>
			<p>{{ selectedData.amountData }}</p>
		</div>
		<div v-if="selectedData.amountMeta || typeof selectedData.amountMeta == 'number'" class="rd-wrap-information">
			<div>{{ $t('rdAmountMeta') }}</div>
			<p>{{ selectedData.amountMeta }}</p>
		</div>
		<div v-if="selectedData.mimeTypes" class="rd-wrap-information">
			<div>{{ $t('rdMimeTypes') }}</div>
			<p>{{ selectedData.mimeTypes }}</p>
		</div>
		<div v-if="dataType == 'pipeline'" class="rd-wrap-information">
			<div>{{ $t('rdActivated') }}</div>
			<p>{{ selectedData.activated ? selectedData.activated : $t('rdNeverActivated') }}</p>
		</div>
		<div v-if="selectedData.config" class="rd-wrap-information">
			<div>{{ $t('rdInput') }}</div>
			<p>{{ selectedData.config.input.join(', ') }}</p>
		</div>
		<div v-if="selectedData.config" class="rd-wrap-information">
			<div>{{ $t('rdOutput') }}</div>
			<p>{{ selectedData.config.output.join(', ') }}</p>
		</div>
		<div v-if="selectedData.config" class="rd-wrap-information">
			<div>{{ $t('rdOS') }}</div>
			<p>{{ getOSName(selectedData.config.os) }}</p>
		</div>
		<div v-if="selectedData.config" class="rd-wrap-information rd-expand-50">
			<div>{{ $t('rdNotes') }}</div>
			<p>{{ selectedData.config.notes }}</p>
		</div>
		<div v-if="selectedData.label" class="rd-wrap-information">
			<div>{{ $t('rdLabel') }}</div>
			<p>{{ selectedData.label }}</p>
		</div>
		<div v-if="selectedData.externalID" class="rd-wrap-information">
			<div>{{ $t('rdExternalID') }}</div>
			<p>{{ selectedData.externalID }}</p>
		</div>
		<div v-if="selectedData.user" class="rd-wrap-information">
			<div>{{ $t('rdUser') }}</div>
			<p>
				{{ selectedData.user.firstName }} {{ selectedData.user.lastName }} ({{ $global.getUserRole(selectedData.user.role) }})
			</p>
		</div>
	</div>
</template>

<script>
/**
 * @group Data
 * Contains details about the displayed revision
 */
export default {
	name: 'RevisionDetails',
	props: {
		selectedData: {
			type: Object,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		dataType: {
			type: String,
			required: true,
		},
	},
	methods: {
		// @vuese
		// Returns the application name translation for a applicationID
		// @arg applicationID[String] - The applicationID
		// @return [String] - The name of the application
		getApplicationName(applicationID) {
			let application = this.applications.filter((app) => app.applicationID == applicationID)[0];
			return this.$t(`${application.abbreviation}Name`);
		},
		// @vuese
		// Returns the framework name translation for a frameworkID
		// @arg frameworkID[String] - The frameworkID
		// @return [String] - The name of the framework
		getFrameworkName(frameworkID) {
			let framework = this.frameworks.filter((frame) => frame.frameworkID == frameworkID)[0];
			return this.$t(`${framework.abbreviation}Name`);
		},
		// @vuese
		// Returns the translation for the os
		// @arg os[String] - The os flag
		// @return [String] - The os translation
		getOSName(os) {
			if (os == 'win32') return this.$t('rdWin32');
			else if (os == 'linux') return this.$t('rdLinux');
			else if (os == 'darwin') return this.$t('rdDarwin');
		},
	},
};
</script>

<style scoped>
.rd-wrap-content {
	flex: 1 1 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: wrap;
}
.rd-wrap-information {
	flex: 1 1 200px;
	max-height: 150px;
	padding: 5px;
	overflow: auto;
}

.rd-expand-50 {
	flex: 1 1 50%;
}

.rd-expand-100 {
	flex: 1 1 100%;
	max-height: fit-content;
}

.rd-wrap-information > div {
	margin-bottom: 3px;
	padding-bottom: 3px;
	font-weight: bold;
	font-size: 18px;
	border-bottom: 1px solid var(--main-color-border-light);
}
</style>
