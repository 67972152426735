<template>
	<div class="pt-wrap-content">
		<div class="pt-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
		</div>
		<div v-if="applications.length && frameworks.length" class="pt-wrap-table">
			<div v-if="isLoading" class="pt-loading-overlay">
				<p>{{ $t('ptLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="pt-table">
				<div class="pt-wrap-header">
					<div class="pt-header-element app-no-select pt-pipeline-id" :title="$t('ptSortBy')" @click="sortBy('pipelineID')">
						{{ $t('ptPipelineID') }}
					</div>
					<div class="pt-header-element app-no-select pt-revision-id" :title="$t('ptSortBy')" @click="sortBy('revisionID')">
						{{ $t('ptRevisionID') }}
					</div>
					<div class="pt-header-element app-no-select pt-application" @click="sortBy('applicationID')" :title="$t('ptSortBy')">
						{{ $t('ptApplication') }}
					</div>
					<div class="pt-header-element app-no-select pt-framework" :title="$t('ptSortBy')" @click="sortBy('frameworkID')">
						{{ $t('ptFramework') }}
					</div>
					<div class="pt-header-element app-no-select pt-name" :title="$t('ptSortBy')" @click="sortBy('name')">
						{{ $t('ptName') }}
					</div>
					<div class="pt-header-element app-no-select pt-input" :title="$t('ptSortBy')" @click="sortBy('input')">
						{{ $t('ptInput') }}
					</div>
					<div class="pt-header-element app-no-select pt-output" :title="$t('ptSortBy')" @click="sortBy('output')">
						{{ $t('ptOutput') }}
					</div>
					<div class="pt-header-element app-no-select pt-creator" :title="$t('ptSortBy')" @click="sortBy('user', 'lastName')">
						{{ $t('ptCreator') }}
					</div>
					<div class="pt-header-element app-no-select pt-creation" :title="$t('ptSortBy')" @click="sortBy('creation')">
						{{ $t('ptCreation') }}
					</div>
					<div class="pt-header-element app-no-select pt-revision-visualization" :title="$t('ptShowRevision')"></div>
				</div>
				<div class="pt-wrap-body">
					<div
						v-for="pipeline in pipelines"
						:key="pipeline.pipelineID"
						:class="[devMode ? 'pt-body-hover' : '', pipeline.selected ? 'pt-body-selected' : '', 'pt-body-element']"
						@click="devMode ? $emit('selectPipeline', pipeline.pipelineID) : null"
					>
						<div class="pt-pipeline-id">{{ pipeline.pipelineID.substring(0, 8) }}</div>
						<div class="pt-revision-id">{{ $global.shortenRevisionID(pipeline.revisionID) }}</div>
						<div class="pt-application">
							{{ $t(`${applications.filter((app) => app.applicationID == pipeline.applicationID)[0].abbreviation}Name`) }}
						</div>
						<div class="pt-framework">
							{{ $t(`${frameworks.filter((frame) => frame.frameworkID == pipeline.frameworkID)[0].abbreviation}Name`) }}
						</div>
						<div class="pt-name">{{ pipeline.name }}</div>
						<div class="pt-input">{{ pipeline.input.join(', ') }}</div>
						<div class="pt-output">{{ pipeline.output.join(', ') }}</div>
						<div class="pt-creator">
							{{
								pipeline.user.userID == $global.getUser().userID
									? $t('htYou')
									: `${pipeline.user.firstName} ${pipeline.user.lastName}`
							}}
						</div>
						<div class="pt-creation">{{ $global.parseDateShort(pipeline.creation) }}</div>
						<div class="pt-revision-visualization" :title="$t('ptShowRevision')">
							<i class="fa-solid fa-diagram-predecessor" @click="showRevision($event, pipeline)"></i>
						</div>
					</div>
					<div v-if="pipelines.length == 0" class="pt-wrap-no-pipelines">
						<div class="pt-no-pipelines">
							<i class="fa-solid fa-folder-open"></i>
							<p>{{ $t('ptNoPipelines') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pt-query-more">
			<p>{{ allPipelines }} {{ $t('ptAllPipelines') }}</p>
			<div class="pt-pages">
				<p v-for="page in $global.getPages(allPipelines, pageSize, currentPage)" :key="page" class="pt-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="pt-no-hover"> . . . </span>
					<span
						v-else
						:class="[page == currentPage ? 'pt-highlight-page' : '', isLoading || page == currentPage ? 'pt-no-hover' : '']"
						@click="isLoading || page == currentPage ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Tables
 * Displays all uploaded pipelines as a table
 */
export default {
	name: 'PipelineTable',
	props: {
		pipelines: {
			type: Array,
			required: true,
		},
		allPipelines: {
			type: Number,
			required: false,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		devMode: {
			type: Boolean,
			required: false,
		},
		selectedPipeline: {
			type: String,
			required: false,
		},
	},
	watch: {
		pipelines: {
			handler: function (newVal) {
				this.isLoading = false;
			},
			deep: true,
		},
	},
	data() {
		return {
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			lastSort: {
				property: null,
				propertyB: null,
				asc: false,
			},
		};
	},
	created() {
		this.sortBy('creation');
	},
	methods: {
		// @vuese
		// Sorts the pipelines by a given property
		// @arg propertyA[String] - The first property of the sorted object
		// @arg propertyB[String] - The second property of the sorted object
		sortBy(propertyA, propertyB) {
			if (this.lastSort.property == propertyA && this.lastSort.propertyB == propertyB) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: propertyA,
					propertyB: propertyB,
					asc: false,
				};
			}
			this.$emit('sortPipelines', this.lastSort);
		},
		// @vuese
		// Changes the page size and requieries the pipelines from page 1
		changePageSize() {
			this.currentPage = 1;
			this.isLoading = true;
			this.$emit('queryPipelines', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Changes the page and querries the pipelines
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.isLoading = true;
			this.$emit('queryPipelines', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Shows the revision of the pipeline
		// @arg e[Object] - The event that occured
		// @arg pipeline[Object] - The selected pipeline
		showRevision(e, pipeline) {
			e.stopPropagation();
			if (window.location.href.includes('/configuration/'))
				window.location.href = `/configuration/data/revision/pipeline/${pipeline.pipelineID}?applicationID=${pipeline.applicationID}`;
			else window.location.href = `/data/revision/pipeline/${pipeline.pipelineID}?applicationID=${pipeline.applicationID}`;
		},
	},
};
</script>

<style scoped>
.pt-wrap-content {
	height: fit-content;
	margin: 0px auto 20px auto;
	max-width: 1800px;
}

.pt-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.pt-options select {
	margin-right: 10px;
}

.pt-wrap-table {
	width: 90%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.pt-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.pt-loading-overlay p {
	font-size: 30px;
}

.pt-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.pt-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.pt-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
	/* overflow: hidden; */
}

.pt-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	/* display: inline-flex;
	justify-content: center;
	align-items: center; */
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
	vertical-align: top;
}

.pt-header-element i {
	margin-left: 10px;
}

.pt-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.pt-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.pt-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.pt-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.pt-body-element div {
	height: 40px;
	padding: 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.pt-pipeline-id {
	width: 80px;
}
.pt-revision-id {
	min-width: 160px;
	width: calc(100% - 1340px);
}
.pt-application {
	min-width: 240px;
}
.pt-framework {
	min-width: 140px;
}
.pt-name {
	width: 260px;
}

.pt-input {
	width: 150px;
}

.pt-output {
	width: 150px;
}

.pt-creator {
	width: 160px;
}
.pt-creation {
	width: 130px;
}

.pt-revision-visualization {
	width: 30px;
}

.pt-revision-visualization i {
	margin: 0px 2px;
	font-size: 20px;
}

.pt-revision-visualization i:hover {
	cursor: pointer;
	color: var(--main-color-text-light);
}

.pt-wrap-no-pipelines {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pt-no-pipelines {
	text-align: center;
}

.pt-no-pipelines i {
	margin-bottom: 10px;
	font-size: 40px;
}

.pt-no-pipelines p {
	font-size: 20px;
}

.pt-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.pt-query-more p {
	margin: 5px auto;
}

.pt-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.pt-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.pt-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.pt-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.pt-highlight-page {
	color: var(--main-color-6);
}
</style>
