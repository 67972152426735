<template>
	<div class="dv-wrap-content">
		<h3>{{ $t('dvDetailsDiff') }}</h3>
		<div class="dv-wrap-details-comparison">
			<div v-for="key in revisionKeys" :key="key" :class="['dv-details-row', getDiffForKey(key)]">
				<div class="dv-details-name">{{ getDetailsName(key) }}</div>
				<RevisionDiffColumn
					:revisionKey="key"
					:revisionData="comparisonData.firstRevision"
					:dataType="dataType"
					:applications="applications"
					:frameworks="frameworks"
					@loadPipelineContent="$emit('loadPipelineContent', $event)"
					@loadHelperContent="$emit('loadHelperContent', $event)"
					@loadBundleContent="$emit('loadBundleContent', $event)"
				/>
				<RevisionDiffColumn
					:revisionKey="key"
					:revisionData="comparisonData.secondRevision"
					:dataType="dataType"
					:applications="applications"
					:frameworks="frameworks"
					@loadPipelineContent="$emit('loadPipelineContent', $event)"
					@loadHelperContent="$emit('loadHelperContent', $event)"
					@loadBundleContent="$emit('loadBundleContent', $event)"
				/>
			</div>
			<div v-if="comparisonData.firstRevision.config" class="dv-wrap-config-details">
				<div v-for="key in configRevisionKeys" :key="key" :class="['dv-details-row', getDiffForKey(key)]">
					<div class="dv-details-name">{{ getDetailsName(key) }}</div>
					<RevisionDiffColumn
						:revisionKey="key"
						:revisionData="comparisonData.firstRevision.config"
						:dataType="dataType"
						:applications="applications"
						:frameworks="frameworks"
					/>
					<RevisionDiffColumn
						:revisionKey="key"
						:revisionData="comparisonData.secondRevision.config"
						:dataType="dataType"
						:applications="applications"
						:frameworks="frameworks"
					/>
				</div>
			</div>
			<div class="dv-legend">
				<div class="dv-legend-element">
					<div class="dv-circle dv-info"></div>
					<p>{{ $t('dvNotCompared') }}</p>
				</div>
				<div class="dv-legend-element">
					<div class="dv-circle dv-success"></div>
					<p>{{ $t('dvNoDifference') }}</p>
				</div>
				<div class="dv-legend-element">
					<div class="dv-circle dv-warn"></div>
					<p>{{ $t('dvDifference') }}</p>
				</div>
			</div>
		</div>
		<h3 v-if="dataType == 'bundle'">{{ $t('dvBundlePackageDiff') }}</h3>
		<BundleDiff
			v-if="dataType == 'bundle'"
			:revisionDiff="comparisonData.revisionDiff"
			@loadDataContent="$emit('loadDataContent', $event)"
		/>
		<PipelineDiff v-if="dataType == 'pipeline'" :revisionDiff="comparisonData.revisionDiff" />
		<HelperDiff v-if="dataType == 'helper'" :revisionDiff="comparisonData.revisionDiff" />
	</div>
</template>

<script>
import RevisionDiffColumn from './RevisionDiffColumn.vue';
import BundleDiff from './BundleDiff.vue';
import PipelineDiff from './PipelineDiff.vue';
import HelperDiff from './HelperDiff.vue';
/**
 * @group DiffViewer
 * Displays the differences between two revisions for bundles, pipelines or helper
 */
export default {
	name: 'DiffViewer',
	components: { RevisionDiffColumn, BundleDiff, PipelineDiff, HelperDiff },
	props: {
		comparisonData: {
			type: Object,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		frameworks: {
			type: Array,
			required: true,
		},
		dataType: {
			type: String,
			required: true,
		},
	},
	emits: ['loadPipelineContent', 'loadHelperContent', 'loadBundleContent', 'loadDataContent'],
	computed: {
		// @vuese
		// Filters the revision keys to only display certain fields
		// @returns [Array] - The filtered revision keys
		revisionKeys() {
			let keys = Object.keys(this.comparisonData.firstRevision);
			keys = keys.filter(
				(key) => !['path', 'configPath', 'reqPath', 'paths', 'config', 'b64Requirements', 'b64File', 'userID'].includes(key)
			);
			return keys;
		},
		// @vuese
		// Filters the config revision keys to only display certain fields
		// @returns [Array] - The filtered config revision keys
		configRevisionKeys() {
			let keys = Object.keys(this.comparisonData.firstRevision.config);
			keys = keys.filter(
				(key) =>
					!['applicationID', 'frameworkID', 'bundleID', 'helperID', 'predecessorID', 'name', 'userID', 'newID'].includes(key)
			);
			return keys;
		},
	},
	methods: {
		// @vuese
		// Returns a translation for a object key
		// @arg key[String] - The key
		// @returns [Sttring] - The key translation
		getDetailsName(key) {
			if (key == 'bundleID') return this.$t('dvBundleID');
			else if (key == 'pipelineID') return this.$t('dvPipelineID');
			else if (key == 'helperID') return this.$t('dvHelperID');
			else if (key == 'applicationID') return this.$t('dvApplication');
			else if (key == 'frameworkID') return this.$t('dvFramework');
			else if (key == 'name') return this.$t('dvName');
			else if (key == 'user') return this.$t('dvCreator');
			else if (key == 'creation') return this.$t('dvCreation');
			else if (key == 'activated') return this.$t('dvActivated');
			else if (key == 'revisionID') return this.$t('dvRevisionID');
			else if (key == 'amountMeta') return this.$t('dvAmountMeta');
			else if (key == 'amountData') return this.$t('dvAmountData');
			else if (key == 'amountPackages') return this.$t('dvAmountPackages');
			else if (key == 'mimeTypes') return this.$t('dvMimeTypes');
			else if (key == 'trainings') return this.$t('dvTrainings');
			else if (key == 'os') return this.$t('dvOS');
			else if (key == 'input') return this.$t('dvInput');
			else if (key == 'output') return this.$t('dvOutput');
			else if (key == 'entryPoint') return this.$t('dvEntryPoint');
			else if (key == 'notes') return this.$t('dvNotes');
			else if (key == 'pipelines') return this.$t('dvPipelines');
			else if (key == 'bundles') return this.$t('dvBundles');
			else if (key == 'helper') return this.$t('dvHelper');
			else return this.$t(key);
		},
		// @vuese
		// Returns a css class depending on the difference of the current key property of both revisions
		// @arg key[String] - The key
		// @returns [Sttring] - The css class
		getDiffForKey(key) {
			if (this.comparisonData.firstRevision.hasOwnProperty(key)) {
				if (
					['bundleID', 'pipelineID', 'helperID', 'creation', 'revisionID', 'pipelines', 'bundles', 'trainings'].includes(key)
				)
					return 'dv-dont-compare';
				else if (typeof this.comparisonData.firstRevision[key] !== 'object') {
					if (this.comparisonData.firstRevision[key] !== this.comparisonData.secondRevision[key])
						return 'dv-change-detected';
					else return 'dv-no-change-detected';
				} else if (typeof this.comparisonData.firstRevision[key] == 'object') {
					if (
						JSON.stringify(this.comparisonData.firstRevision[key]) !==
						JSON.stringify(this.comparisonData.secondRevision[key])
					)
						return 'dv-change-detected';
					else return 'dv-no-change-detected';
				} else return 'dv-no-change-detected';
			} else if (this.comparisonData.firstRevision.config[key]) {
				if (typeof this.comparisonData.firstRevision.config[key] !== 'object') {
					if (this.comparisonData.firstRevision.config[key] !== this.comparisonData.secondRevision.config[key])
						return 'dv-change-detected';
					else return 'dv-no-change-detected';
				} else if (typeof this.comparisonData.firstRevision.config[key] == 'object') {
					if (
						JSON.stringify(this.comparisonData.firstRevision.config[key]) !==
						JSON.stringify(this.comparisonData.secondRevision.config[key])
					)
						return 'dv-change-detected';
					else return 'dv-no-change-detected';
				} else return 'dv-no-change-detected';
			}
		},
	},
};
</script>

<style scoped>
.dv-wrap-content {
	width: 100%;
	height: fit-content;
}

.dv-wrap-details-comparison {
	width: 100%;
	height: fit-content;
}

.dv-details-row {
	margin: 10px 0px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
	box-sizing: border-box;
}

.dv-details-name {
	flex: 1 1 20%;
	max-width: 20%;
}

.dv-dont-compare {
	background-color: var(--main-color-info-80);
}

.dv-change-detected {
	background-color: var(--main-color-warn-80);
}

.dv-no-change-detected {
	background-color: var(--main-color-success-80);
}

.dv-legend {
	width: fit-content;
	margin: 10px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.dv-legend-element {
	flex: 1 1 fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.dv-legend-element p {
	display: inline-block;
	margin: 0px 20px 0px 5px;
}

.dv-circle {
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 50%;
	border: 1px solid var(--main-color-border-dark);
}

.dv-info {
	background-color: var(--main-color-info);
}

.dv-success {
	background-color: var(--main-color-success);
}

.dv-warn {
	background-color: var(--main-color-warn);
}

.dv-error {
	background-color: var(--main-color-error);
}
</style>
