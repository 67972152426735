<template>
	<div class="pd-wrap-content">
		<h3>{{ $t('pdPipelineFileDiff') }}</h3>
		<div class="pd-wrap-file-trees">
			<div class="pd-wrap-first-file-tree">
				<h4>
					{{ $t('pdFirstRev') }} <i class="fa-solid fa-maximize pd-maximise" @click="maximise('firstFile')"></i>
					<i class="fa-solid fa-minimize pd-minimise" @click="minimise('file')"></i>
				</h4>
				<div v-for="path in sortPaths(revisionDiff.firstPaths)" :key="path" class="pd-tree-component">
					<p
						v-if="!path.includes('.')"
						:style="{ paddingLeft: `${20 * (path.split('/').length - 4) + 20}px` }"
						class="pd-folder"
					>
						<i class="fa-solid fa-folder"></i> {{ getFolderName(path) }}
					</p>
					<p
						v-else
						:style="{
							paddingLeft: `${20 * (path.split('/').length - 3) + 20}px`,
						}"
						class="pd-file"
					>
						<i class="fa-solid fa-file"></i> {{ getFileName(path) }}
					</p>
				</div>
			</div>
			<div class="pd-wrap-file-diff">
				<h4>
					{{ $t('pdReqDiff') }} <i class="fa-solid fa-maximize pd-maximise" @click="maximise('fileDiff')"></i>
					<i class="fa-solid fa-minimize pd-minimise" @click="minimise('file')"></i>
				</h4>
				<div v-for="(pathParts, idx) in revisionDiff.pathDiff" :key="idx">
					<div
						v-for="path in pathParts.value"
						:key="path"
						:class="[
							'pd-tree-component',
							pathParts.added
								? 'pd-added'
								: pathParts.removed
								? 'pd-removed'
								: pathParts.modified.includes(path)
								? 'pd-modified'
								: 'pd-normal',
						]"
					>
						<p
							v-if="!path.includes('.')"
							:style="{ paddingLeft: `${20 * (path.split('/').length - 4) + 20}px` }"
							class="pd-folder"
						>
							<i class="fa-solid fa-folder"></i> {{ getFolderName(path) }}
						</p>
						<p
							v-else
							:style="{
								paddingLeft: `${20 * (path.split('/').length - 3) + 20}px`,
							}"
							class="pd-file"
						>
							<i class="fa-solid fa-file"></i> {{ getFileName(path) }}
						</p>
					</div>
				</div>
			</div>
			<div class="pd-wrap-second-file-tree">
				<h4>
					{{ $t('pdSecondRev') }} <i class="fa-solid fa-maximize pd-maximise" @click="maximise('secondFile')"></i>
					<i class="fa-solid fa-minimize pd-minimise" @click="minimise('file')"></i>
				</h4>
				<div v-for="path in sortPaths(revisionDiff.secondPaths)" :key="path" class="pd-tree-component">
					<p
						v-if="!path.includes('.')"
						:style="{ paddingLeft: `${20 * (path.split('/').length - 4) + 20}px` }"
						class="pd-folder"
					>
						<i class="fa-solid fa-folder"></i> {{ getFolderName(path) }}
					</p>
					<p
						v-else
						:style="{
							paddingLeft: `${20 * (path.split('/').length - 3) + 20}px`,
						}"
						class="pd-file"
					>
						<i class="fa-solid fa-file"></i> {{ getFileName(path) }}
					</p>
				</div>
			</div>
		</div>
		<div class="pd-legend">
			<div class="pd-legend-element">
				<div class="pd-circle pd-info"></div>
				<p>{{ $t('pdSame') }}</p>
			</div>
			<div class="pd-legend-element">
				<div class="pd-circle pd-success"></div>
				<p>{{ $t('pdAdded') }}</p>
			</div>
			<div class="pd-legend-element">
				<div class="pd-circle pd-warn"></div>
				<p>{{ $t('pdModified') }}</p>
			</div>
			<div class="pd-legend-element">
				<div class="pd-circle pd-error"></div>
				<p>{{ $t('pdRemoved') }}</p>
			</div>
		</div>
		<h3>{{ $t('pdPipelineReqDiff') }}</h3>
		<div class="pd-wrap-requirements-diff">
			<div class="pd-wrap-first-req">
				<h4>
					{{ $t('pdFirstRev') }} <i class="fa-solid fa-maximize pd-maximise" @click="maximise('firstReq')"></i>
					<i class="fa-solid fa-minimize pd-minimise" @click="minimise('req')"></i>
				</h4>
				<pre>{{ revisionDiff.firstReq }}</pre>
			</div>
			<div class="pd-wrap-req-diff">
				<h4>
					{{ $t('pdReqDiff') }} <i class="fa-solid fa-maximize pd-maximise" @click="maximise('reqDiff')"></i>
					<i class="fa-solid fa-minimize pd-minimise" @click="minimise('req')"></i>
				</h4>
				<div v-for="(entry, valIdx) in revisionDiff.reqDiff" :key="valIdx">
					<div
						v-for="(line, idx) in getValidLines(entry.value)"
						:key="idx"
						:class="['pd-req-line', entry.added ? 'pd-added' : entry.removed ? 'pd-removed' : 'pd-normal']"
					>
						<pre>{{ line }}</pre>
					</div>
				</div>
			</div>
			<div class="pd-wrap-second-req">
				<h4>
					{{ $t('pdSecondRev') }} <i class="fa-solid fa-maximize pd-maximise" @click="maximise('secondReq')"></i>
					<i class="fa-solid fa-minimize pd-minimise" @click="minimise('req')"></i>
				</h4>
				<pre>{{ revisionDiff.secondReq }}</pre>
			</div>
		</div>
		<div class="pd-legend">
			<div class="pd-legend-element">
				<div class="pd-circle pd-info"></div>
				<p>{{ $t('pdSame') }}</p>
			</div>
			<div class="pd-legend-element">
				<div class="pd-circle pd-success"></div>
				<p>{{ $t('pdAdded') }}</p>
			</div>
			<div class="pd-legend-element">
				<div class="pd-circle pd-error"></div>
				<p>{{ $t('pdRemoved') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group DiffViewer
 * Displays the differences between the pipeline requirements and files
 */
export default {
	name: 'PipelineDiff',
	props: {
		revisionDiff: {
			type: Object,
			required: true,
		},
	},
	methods: {
		// @vuese
		// Returns the valid lines from a file that can be displayed line by line
		// @arg line[String] - The line
		// @returns [Array] - The valid lines that can be displayed
		getValidLines(line) {
			let lines = line.split('\r\n').filter((x, idx) => {
				if (x !== '') return true;
				else if (x == '' && idx == 0) return true;
				else return false;
			});
			return lines;
		},
		// @vuese
		// Sorts the given paths by its directory tree structure
		// @arg paths[Array] - All paths to be sorted
		// @return [Array] - The sorted paths
		sortPaths(paths) {
			let sortedPaths = sortPaths(paths, '/');
			return sortedPaths;
		},
		// @vuese
		// Parses the folder name from the given path
		// @arg path[String] - The folder path
		// @returns [String] - The folder name
		getFolderName(path) {
			let parts = path.split('/');
			if (parts.length > 0) return parts[parts.length - 2];
			else return '/';
		},
		// @vuese
		// Parses the file name from the given path
		// @arg path[String] - The file path
		// @returns [String] - The file name
		getFileName(path) {
			return path.split('/').pop();
		},
		// @vuese
		// Maximises the diff display column
		// @arg id[String] - Specifies which colum is maximised
		maximise(id) {
			if (['firstFile', 'fileDiff', 'secondFile'].includes(id)) this.minimise('file');
			else if (['firstReq', 'reqDiff', 'secondReq'].includes(id)) this.minimise('req');

			if (id == 'firstFile') {
				document.querySelector('.pd-wrap-first-file-tree').classList.add('pd-expand-full');
				document.querySelector('.pd-wrap-file-diff').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-second-file-tree').classList.add('pd-shrink');
			} else if (id == 'fileDiff') {
				document.querySelector('.pd-wrap-first-file-tree').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-file-diff').classList.add('pd-expand-full');
				document.querySelector('.pd-wrap-second-file-tree').classList.add('pd-shrink');
			} else if (id == 'secondFile') {
				document.querySelector('.pd-wrap-first-file-tree').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-file-diff').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-second-file-tree').classList.add('pd-expand-full');
			} else if (id == 'firstReq') {
				document.querySelector('.pd-wrap-first-req').classList.add('pd-expand-full');
				document.querySelector('.pd-wrap-req-diff').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-second-req').classList.add('pd-shrink');
			} else if (id == 'reqDiff') {
				document.querySelector('.pd-wrap-first-req').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-req-diff').classList.add('pd-expand-full');
				document.querySelector('.pd-wrap-second-req').classList.add('pd-shrink');
			} else if (id == 'secondReq') {
				document.querySelector('.pd-wrap-first-req').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-req-diff').classList.add('pd-shrink');
				document.querySelector('.pd-wrap-second-req').classList.add('pd-expand-full');
			}
		},
		// @vuese
		// Minimises the diff display column
		// @arg id[String] - Specifies which colum is minimised
		minimise(id) {
			if (id == 'file') {
				document.querySelectorAll('.pd-wrap-file-trees .pd-shrink').forEach((element) => {
					element.classList.remove('pd-shrink');
				});
				document.querySelectorAll('.pd-wrap-file-trees .pd-expand-full').forEach((element) => {
					element.classList.remove('pd-expand-full');
				});
			} else if (id == 'req') {
				document.querySelectorAll('.pd-wrap-requirements-diff .pd-shrink').forEach((element) => {
					element.classList.remove('pd-shrink');
				});
				document.querySelectorAll('.pd-wrap-requirements-diff .pd-expand-full').forEach((element) => {
					element.classList.remove('pd-expand-full');
				});
			}
		},
	},
};
</script>

<style scoped>
.pd-wrap-content {
}

.pd-wrap-file-trees,
.pd-wrap-requirements-diff {
	margin: 5px 0px 10px 0px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: wrap;
}

.pd-wrap-first-file-tree,
.pd-wrap-file-diff,
.pd-wrap-second-file-tree {
	flex: 1 1 300px;
	height: 400px;
	padding: 5px;
	margin: 5px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
	background-color: var(--main-color-4);
}

.pd-tree-component {
	margin: 5px 0px;
	white-space: nowrap;
	background-color: var(--main-color-4);
}

.pd-folder,
.pd-file {
	position: relative;
	padding-left: 20px;
	box-sizing: border-box;
}

.pd-folder i {
	font-size: 20px;
	color: var(--main-color-6);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-border-dark);
}

.pd-file i {
	font-size: 20px;
	color: var(--main-color-info);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	text-shadow: 2px 2px var(--main-color-border-dark);
}

.pd-wrap-first-req,
.pd-wrap-req-diff,
.pd-wrap-second-req {
	height: 400px;
	flex: 1 1 300px;
	min-width: 300px;
	padding: 5px;
	margin: 5px;
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
	background-color: var(--main-color-4);
	color: var(--main-color-text-dark);
}

.pd-wrap-first-req pre,
.pd-wrap-second-req pre {
	line-height: 23px;
}

h4 {
	width: 100%;
	position: sticky;
	top: 0px;
	left: 0px;
	margin-bottom: 10px;
	padding: 5px;
	box-sizing: border-box;
	text-decoration: underline;
	z-index: 2;
	background-color: var(--main-color-5-cc);
	color: var(--main-color-text-dark);
}

h4 i {
	margin: 0px 3px;
	float: right;
	font-size: 20px;
}

h4 i:hover {
	cursor: pointer;
	color: var(--main-color-text-light);
}

.pd-req-line {
	margin: 5px auto;
}
.pd-added {
	background-color: var(--main-color-success-80);
}
.pd-removed {
	background-color: var(--main-color-error-80);
}
.pd-modified {
	background-color: var(--main-color-warn-80);
}
.pd-normal {
	background-color: var(--main-color-info-80);
}

.pd-legend {
	width: fit-content;
	margin: 10px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.pd-legend-element {
	flex: 1 1 fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.pd-legend-element p {
	display: inline-block;
	margin: 0px 20px 0px 5px;
}

.pd-circle {
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 50%;
	border: 1px solid var(--main-color-border-dark);
}

.pd-info {
	background-color: var(--main-color-info);
}

.pd-success {
	background-color: var(--main-color-success);
}

.pd-warn {
	background-color: var(--main-color-warn);
}

.pd-error {
	background-color: var(--main-color-error);
}

.pd-minimise {
	pointer-events: none;
	cursor: default;
	color: var(--main-color-disabled);
}

.pd-expand-full {
	flex: none !important;
	width: calc(100% - 90px);
	height: 413px;
	margin: 5px !important;
	display: inline-block !important;
	vertical-align: top !important;
	box-sizing: border-box !important;
	/* flex: 1 1 calc(100% - 50px);
	max-width: calc(100% - 120px); */
}

.pd-expand-full .pd-maximise {
	pointer-events: none;
	cursor: default;
	color: var(--main-color-disabled);
}

.pd-expand-full .pd-minimise {
	pointer-events: all;
	cursor: pointer;
	color: var(--main-color-text-dark);
}

.pd-expand-full .pd-minimise:hover {
	color: var(--main-color-text-light);
}

.pd-shrink {
	flex: none !important;
	width: 30px !important;
	min-width: 0px !important;
	height: 413px;
	margin: 5px !important;
	padding: 0px;
	display: inline-block !important;
	vertical-align: top !important;
	box-sizing: border-box !important;
	overflow: hidden !important;
}

.pd-shrink h4 {
	height: 30px;
	width: 413px;
	margin: 0px;
	padding-left: 10px;
	position: relative;
	transform: rotate(-90deg) translate(-189px, -193px);
	display: block;
}

.pd-shrink div,
.pd-shrink pre {
	display: none;
}

.pd-shrink .pd-minimise {
	pointer-events: none;
	cursor: default;
	color: var(--main-color-disabled);
}
</style>
