<template>
	<div class="pr-wrap-content">
		<div class="pr-page-layout">
			<h2>{{ $t('prProfileInformation') }}</h2>
			<div v-if="user" class="pr-profile-information">
				<div class="pr-wrap-input">
					<label>{{ $t('prFirstName') }}</label>
					<input type="text" :value="user.firstName" />
				</div>
				<div class="pr-wrap-input">
					<label>{{ $t('prLastName') }}</label>
					<input type="text" :value="user.lastName" />
				</div>
				<div class="pr-wrap-input">
					<label>{{ $t('prEmail') }}</label>
					<input type="text" :value="user.email" />
				</div>
				<div class="pr-wrap-input">
					<label>{{ $t('prRole') }}</label>
					<input type="text" :value="$global.getUserRole(user.role)" />
				</div>
				<div class="pr-wrap-password-change">
					<button class="app-default-btn" @click="showPasswordChange = true">
						{{ $t('prChangePassword') }} <i class="fa-solid fa-key"></i>
					</button>
					<div v-if="showPasswordChange" class="pr-popup-window">
						<div>
							<h3>{{ $t('prChangePassword') }}?</h3>
							<div class="pr-wrap-buttons">
								<button class="app-default-btn" @click="showPasswordChange = false">{{ $t('prNo') }}</button>
								<button class="app-success-btn" @click="changePassword">
									{{ $t('prYes') }} <i v-if="resetMailPending" class="fa-solid fa-spinner pr-spin"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="pr-institutions">
					<h2>{{ $t('prYourInstitutions') }}</h2>
					<div class="pr-wrap-institutions">
						<div
							v-for="institution in user.institutions"
							:key="institution.institutionID"
							:class="['pr-institution', getInstitutionClass(institution)]"
							:title="getInstitutionTitle(institution)"
						>
							<i
								class="fa-solid fa-door-open"
								:title="$t('prLeaveInstitution')"
								@click="showLeave = institution.institutionID"
							></i>
							<p>{{ institution.name }} ({{ institution.abbreviation }})</p>
							<p>{{ $t('prOwner') }}: {{ institution.owner.firstName }} {{ institution.owner.lastName }}</p>
							<p>{{ $t('prMembers') }}: {{ institution.members }}</p>
							<p>{{ $global.parseDateFull(institution.creation) }}</p>
						</div>
					</div>
					<div v-if="user.institutions.length == 0" class="pr-no-institutions">
						<p class="pr-no-institutions-warning">{{ $t('prNoInstitutions') }}</p>
					</div>
					<div class="pr-institution-btns">
						<button class="app-default-btn" @click="showInstitutionJoin">{{ $t('prJoinInstitution') }}</button>
						<button class="app-default-btn" @click="showInstitutionCreation">{{ $t('prCreateInstitution') }}</button>
					</div>
					<div v-if="ownedInstitutions.length > 0" class="pr-institution-requests">
						<h3>{{ $t('prAccessRequests') }}</h3>
						<div class="pr-wrap-requests">
							<div v-for="request in requests" :key="`${request.institutionID}-${request.userID}`" class="pr-requests">
								<p>{{ request.firstName }} {{ request.lastName }} ({{ request.role }})</p>
								<p>
									<i class="fa-solid fa-arrow-right"></i> {{ request.institution.name }} ({{
										request.institution.abbreviation
									}})
								</p>
								<div class="pr-decline" @click="updateRequest(request.institutionID, request.userID, false)">
									<i class="fa-solid fa-circle-xmark"></i>
									<p>{{ $t('prDecline') }}</p>
								</div>
								<div class="pr-accept" @click="updateRequest(request.institutionID, request.userID, true)">
									<p>{{ $t('prAccept') }}</p>
									<i class="fa-solid fa-circle-check"></i>
								</div>
							</div>
						</div>
						<div v-if="requests.length == 0" class="pr-no-requests">
							<p class="pr-no-requests-warning">{{ $t('prNoRequests') }}</p>
						</div>
					</div>
					<div v-if="showJoin" class="pr-popup-window">
						<div class="pr-wrap-table">
							<i class="fa-solid fa-circle-xmark" @click="showJoin = false"></i>
							<h3>{{ $t('prJoinInstitution') }}</h3>
							<div class="pr-wrap-institution-table">
								<InstitutionTable
									v-if="institutions"
									:institutions="institutions"
									:allInstitutions="allInstitutionAmount"
									:selectedInstitution="selectedInstitution"
									@sortInstitutions="sortInstitutions"
									@selectInstitution="selectInstitution"
									@queryInstitutions="queryInstitutions"
								/>
							</div>
							<div v-if="selectedInstitution" class="pr-wrap-input">
								<button class="app-success-btn pr-join-btn" @click="joinInstitution">
									{{ $t('prRequestAccess') }}
								</button>
								<label>{{ $t('prDescription') }}</label>
								<pre v-if="selectedInstitutionDescription.length > 0">{{ selectedInstitutionDescription }}</pre>
								<p v-else>{{ $t('prNoDescription') }}</p>
							</div>
						</div>
					</div>
					<div v-if="showCreate" class="pr-popup-window">
						<div class="pr-wrap-institution-creation">
							<h3>{{ $t('prCreateInstitution') }}</h3>
							<i class="fa-solid fa-circle-xmark" @click="showCreate = false"></i>
							<div class="pr-institution-creation">
								<div class="pr-wrap-input">
									<label>{{ $t('prName') }}</label>
									<input type="text" :placeholder="$t('prName')" v-model="createdInstitution.name" />
								</div>
								<div class="pr-wrap-input">
									<label>{{ $t('prAbbreviation') }}</label>
									<input type="text" :placeholder="$t('prAbbreviation')" v-model="createdInstitution.abbreviation" />
								</div>
								<div class="pr-wrap-input">
									<label>{{ $t('prDescription') }}</label>
									<textarea :placeholder="$t('prDescription')" v-model="createdInstitution.description" />
								</div>
								<button class="app-success-btn" @click="preventClick ? null : createInstitution()">
									{{ $t('prCreateInstitution') }}
								</button>
							</div>
						</div>
					</div>
					<div v-if="showLeave" class="pr-popup-window">
						<div>
							<h3>{{ $t('prLeaveInstitution') }}?</h3>
							<div class="pr-wrap-buttons">
								<button class="app-default-btn" @click="showLeave = false">{{ $t('prNo') }}</button>
								<button class="app-warn-btn" @click="leaveInstitution(showLeave)">{{ $t('prYes') }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h2>{{ $t('prMoreSettings') }}</h2>
			<div class="pr-wrap-other-settings">
				<div class="pr-create-institution"></div>
				<div class="pr-dark-mode">
					<div class="pr-wrap-switch">
						<div class="pr-wrap-theme">
							<p>{{ $t('prDarkTheme') }}</p>
							<label class="pr-switch">
								<input type="checkbox" v-model="darkTheme" @change="$emit('prtTheme', darkTheme ? 'dark' : 'light')" />
								<span class="pr-slider"></span>
							</label>
						</div>
					</div>
				</div>
				<div class="pr-delete-cookies">
					<button class="app-warn-btn" @click="showCookieDeletion = true">{{ $t('prDeleteCookies') }}</button>
					<div v-if="showCookieDeletion" class="pr-popup-window">
						<div>
							<h3>{{ $t('prDeleteCookies') }}?</h3>
							<div class="pr-wrap-buttons">
								<button class="app-default-btn" @click="showCookieDeletion = false">{{ $t('prNo') }}</button>
								<button class="app-warn-btn" @click="deleteCookies">{{ $t('prYes') }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InstitutionTable from '../components/tables/InstitutionTable';
import axios from 'axios';
/**
 * @group Profile
 * Lets the user update and view their account information
 */
export default {
	name: 'Profile',
	components: { InstitutionTable },
	data() {
		return {
			showPasswordChange: false,
			showCookieDeletion: false,
			resetMailPending: false,
			showJoin: false,
			showCreate: false,
			showLeave: false,
			darkTheme: true,
			preventClick: false,
			institutions: null,
			requests: [],
			selectedInstitution: null,
			user: null,
			createdInstitution: {
				name: '',
				abbreviation: '',
				description: '',
			},
		};
	},
	computed: {
		// @vuese
		// Gets the description of the selected institution
		// @return [String] - The description
		selectedInstitutionDescription() {
			return this.institutions.filter((inst) => inst.institutionID == this.selectedInstitution)[0].description;
		},
		// @vuese
		// Gets the owned institutions
		// @return [Array] - The owned institutions
		ownedInstitutions() {
			if (this.institutions) return this.institutions.filter((inst) => inst.owner.userID == this.$global.getUser().userID);
			else return [];
		},
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => { if (access) this.setupComponent() });
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			let theme = localStorage.getItem('theme');
			this.darkTheme = theme ? (theme == 'dark' ? true : false) : true;
			this.$cr.getUser(this.$global.getUser().userID, (err, result) => {
				if (!err) {
					this.user = result;
					let interval = window.setInterval(() => {
						if (this.institutions) {
							clearInterval(interval);
							this.getOpenRequests();
						}
					}, 100);
				}
			});
			this.queryInstitutions(0, 50);
		},
		// @vuese
		// Decides the institution class which can be owner, accepted and not accepted
		// @arg institution[Object] - The institution
		// @return [String] - The class name
		getInstitutionClass(institution) {
			return institution.owner.userID == this.$global.getUser().userID
				? 'pr-owner'
				: institution.accepted
				? 'pr-accepted'
				: 'pr-not-accepted';
		},
		// @vuese
		// Decides the institution title which can be owner, member and not accepted
		// @arg institution[Object] - The institution
		// @return [String] - The title
		getInstitutionTitle(institution) {
			return institution.owner.userID == this.$global.getUser().userID
				? this.$t('prOwner')
				: institution.accepted
				? this.$t('prMember')
				: this.$t('prNotAcceptedYet');
		},
		// @vuese
		// Shows the institution join popup
		showInstitutionJoin() {
			this.showJoin = true;
			this.showCreate = false;
		},
		// @vuese
		// Shows the institution create popup
		showInstitutionCreation() {
			this.showJoin = false;
			this.showCreate = true;
		},
		// @vuese
		// Starts the password reset process of a user by sending a request to the backend
		changePassword() {
			this.resetMailPending = true;
			this.$global.postData('access', '/initiate-password-reset', { email: this.user.email }, null, (err, data) => {
				if (err) {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					this.$global.showToast('error', this.$t(msg ? msg : 'prMailError'));
					this.showPasswordChange = false;
					this.resetMailPending = false;
				} else {
					this.$global.showToast('success', this.$t('prMailSend'));
					this.showPasswordChange = false;
					this.resetMailPending = false;
				}
			});
		},
		// @vuese
		// Deletes the cookies set in the localStorage
		deleteCookies() {
			let theme = localStorage.getItem('theme');
			sessionStorage.setItem('theme', theme ? theme : this.darkTheme ? 'dark' : 'light');
			localStorage.clear();
			this.showCookieDeletion = false;
		},
		// @vuese
		// Sorts all institutions by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortInstitutions(sort) {
			this.institutions = this.$global.sortValues(sort, this.institutions);
		},
		// @vuese
		// Selects a institution
		// @arg institutionID[String] - The ID of the institution
		selectInstitution(institutionID) {
			if (this.selectedInstitution == institutionID) {
				this.selectedInstitution = null;
				this.institutions = this.institutions.map((institution) => {
					institution.selected = false;
					return institution;
				});
			} else {
				this.selectedInstitution = institutionID;
				this.institutions = this.institutions.map((institution) => {
					if (institution.institutionID == institutionID) institution.selected = true;
					else institution.selected = false;
					return institution;
				});
			}
			this.$forceUpdate();
		},
		// @vuese
		// Querries the open join requests for the owned institutions
		getOpenRequests() {
			if (this.ownedInstitutions.length > 0) {
				axios
					.get(`/confAPI/user/institutions/requests`, {
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
						params: {
							userID: this.$global.getUser().userID,
							institutions: this.ownedInstitutions.map((inst) => inst.institutionID),
						},
					})
					.then((res) => {
						this.requests = res.data.map((req) => {
							req.institution = this.institutions.filter((inst) => inst.institutionID == req.institutionID)[0];
							return req;
						});
					})
					.catch((err) => {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						this.$global.showToast('error', this.$t(msg ? msg : 'prInstitutionRequestError'));
					});
			}
		},

		// @vuese
		// Queries the institutions by a amount and offset
		// @arg offset[Number] - The offset of the queried data
		// @arg amount[Number] - The amount of the queried data
		queryInstitutions(offset, amount) {
			this.$cr.getAllInstitutions(offset, amount, (err, result) => {
				if (!err) {
					this.institutions = result.institutions;
					this.allInstitutionAmount = result.amount;
					this.institutions.map((institution) => {
						if (this.selectedInstitution == institution.institutionID) institution.selected = true;
						return institution;
					});
				}
			});
		},
		// @vuese
		// Creates a institution if the entered parameters are correct
		createInstitution() {
			if (this.createdInstitution.name.length < 2 || this.createdInstitution.name.length > 50)
				this.$global.showToast('warn', this.$t('prInstitutionNameLength'));
			else if (this.createdInstitution.abbreviation.length < 2 || this.createdInstitution.abbreviation.length > 5)
				this.$global.showToast('warn', this.$t('prInstitutionAbbreviationLength'));
			else {
				this.preventClick = true;
				axios
					.post(
						`/confAPI/user/institutions`,
						{
							...this.createdInstitution,
							owner: this.$global.getUser().userID,
						},
						{
							headers: { userid: this.$global.getUser().userID },
							auth: JSON.parse(sessionStorage.getItem('credentials')),
						}
					)
					.then((res) => {
						this.createdInstitution.name = '';
						this.createdInstitution.abbreviation = '';
						this.createdInstitution.description = '';
						this.showCreate = false;
						this.$global.showToast('success', this.$t('prInstitutionCreateSuccess'));
						this.queryInstitutions(0, 50);
						this.$cr.getUser(this.$global.getUser().userID, (err, result) => {
							if (!err) this.user = result;
						});
					})
					.catch((err) => {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						this.$global.showToast('error', this.$t(msg ? msg : 'prInstitutionCreateError'));
						this.preventClick = false;
					});
			}
		},
		// @vuese
		// Joins a institution
		joinInstitution() {
			let institution = this.institutions.filter((inst) => inst.institutionID == this.selectedInstitution)[0];
			if (institution && institution.owner.userID == this.$global.getUser().userID)
				this.$global.showToast('info', this.$t('prOwnerOfInstitution'));
			else {
				axios
					.post(
						`/confAPI/user/institutions/${this.selectedInstitution}`,
						{
							user: this.$global.getUser().userID,
						},
						{
							headers: { userid: this.$global.getUser().userID },
							auth: JSON.parse(sessionStorage.getItem('credentials')),
						}
					)
					.then((res) => {
						this.showJoin = false;
						this.selectedInstitution = null;
						this.$global.showToast('success', this.$t('prJoinedInstitution'));
						this.queryInstitutions(0, 50);
						this.$cr.getUser(this.$global.getUser().userID, (err, result) => {
							if (!err) this.user = result;
						});
					})
					.catch((err) => {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						this.$global.showToast('error', this.$t(msg ? msg : 'prInstitutionJoinError'));
						this.preventClick = false;
					});
			}
		},
		// @vuese
		// Leaves a institution or deletes it if the owner is the only member
		// @arg institutionID[String] - The ID of the institution
		leaveInstitution(institutionID) {
			this.showLeave = false;
			axios
				.delete(
					`/confAPI/user/institutions/${institutionID}`,
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
						params: { userID: this.$global.getUser().userID },
					},
					null
				)
				.then((res) => {
					this.$global.showToast('success', this.$t('prInstitutionLeaveSuccess'));
					this.$cr.getUser(this.$global.getUser().userID, (err, result) => {
						if (!err) {
							this.user = result;
							this.queryInstitutions(0, 50);
							this.getOpenRequests();
						}
					});
				})
				.catch((err) => {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					this.$global.showToast('error', this.$t(msg ? msg : 'prInstitutionLeaveError'));
					this.preventClick = false;
				});
		},
		// @vuese
		// Updates a join request (accept or decline)
		// @arg institutionID[String] - The ID of the institution
		// @arg userID[String] - The ID of the user
		// @arg accepted[Boolean] - Is true if the request got accepted
		updateRequest(institutionID, userID, accepted) {
			axios
				.patch(
					`/confAPI/user/institutions/requests`,
					{
						institutionID: institutionID,
						userID: userID,
						accepted: accepted,
					},
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
					}
				)
				.then((res) => {
					this.$global.showToast('success', this.$t(accepted ? 'prRequestAccepted' : 'prRequestDeclined'));
					this.$cr.getUser(this.$global.getUser().userID, (err, result) => {
						if (!err) {
							this.user = result;
							this.getOpenRequests();
						}
					});
				})
				.catch((err) => {
					let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
					this.$global.showToast('error', this.$t(msg ? msg : 'prRequestUpdateError'));
					this.preventClick = false;
				});
		},
	},
};
</script>

<style scoped>
.pr-wrap-content {
	width: 100vw;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
}

.pr-page-layout {
	width: 900px;
	max-width: 100%;
	margin: auto;
}

.pr-page-layout h2,
.pr-page-layout h3 {
	width: fit-content;
	margin: 5px 0px 10px 0px;
	font-size: 25px;
	color: var(--main-color-6);
}

.pr-page-layout h3 {
	font-size: 20px;
}

.pr-profile-information {
	margin: 10px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.pr-wrap-input {
	flex: 1 1 450px;
	padding: 5px 10px 5px 0px;
	box-sizing: border-box;
}

.pr-wrap-input label {
	margin-bottom: 5px;
	display: block;
	font-size: 17px;
}

.pr-wrap-input input {
	width: 100%;
	padding-left: 10px !important;
	box-sizing: border-box;
	pointer-events: none;
	font-size: 17px;
}

.pr-wrap-input pre {
	max-height: 200px;
	max-width: 800px;
	margin: 0px 0px 0px 20px;
	overflow: auto;
	box-sizing: border-box;
	white-space: pre-wrap;
	text-align: start;
}

.pr-join-btn {
	margin-bottom: 10px !important;
	font-size: 17px !important;
}

.pr-institutions {
	flex: 1 1 100%;
}

.pr-wrap-institutions {
	width: 100%;
	max-height: 300px;
	display: flex;
	flex-flow: wrap;
	box-sizing: border-box;
	overflow: auto;
}

.pr-institution {
	flex: 1 1 250px;
	min-width: 200px;
	margin: 5px;
	padding: 10px;
	display: inline-block;
	position: relative;
	border-radius: 20px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
}

.pr-institution i {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.pr-institution i:hover {
	cursor: pointer;
	color: var(--main-color-error);
}

.pr-institution p:last-child {
	margin-top: 5px;
	float: right;
}

.pr-owner {
	border: 2px solid var(--main-color-6);
}

.pr-accepted {
	border: 2px solid var(--main-color-success);
}

.pr-not-accepted {
	border: 2px solid var(--main-color-error);
}

.pr-no-institutions {
	width: 100%;
	height: fit-content;
	text-align: center;
}

.pr-no-institutions-warning {
	margin-bottom: 10px;
}

.pr-institution-btns {
	width: 100%;
	text-align: center;
	box-sizing: border-box;
}

.pr-institution-btns button {
	margin: 5px;
	font-size: 17px;
}

.pr-institution-requests {
	width: 100%;
	height: fit-content;
}

.pr-wrap-requests {
	width: 100%;
	max-height: 300px;
	display: flex;
	flex-flow: wrap;
	box-sizing: border-box;
	overflow: auto;
}

.pr-requests {
	flex: 1 1 250px;
	min-width: 200px;
	margin: 5px;
	padding: 10px;
	display: inline-block;
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3);
}

.pr-decline {
	left: 0px;
	background: linear-gradient(to left, #00000000 50%, var(--main-color-error-80) 50%) right;
}

.pr-accept {
	right: 0px;
	background: linear-gradient(to right, #00000000 50%, var(--main-color-success-80) 50%) left;
}

.pr-accept,
.pr-decline {
	height: 100%;
	width: 50%;
	position: absolute;
	top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: 200% 100%;
	transition: all 0.25s linear;
	cursor: pointer;
}

.pr-decline p,
.pr-accept p {
	opacity: 0;
	font-size: 30px;
	transition: opacity 0.25s linear;
}

.pr-decline p {
	margin-left: 10px;
}

.pr-accept p {
	margin-right: 10px;
}

.pr-decline i,
.pr-accept i {
	opacity: 0;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	font-size: 35px;
	transition: opacity 0.25s linear;
}

.pr-decline:hover {
	background-position: left;
}

.pr-accept:hover {
	background-position: right;
}

.pr-decline:hover i,
.pr-accept:hover i,
.pr-decline:hover p,
.pr-accept:hover p {
	opacity: 1;
}

.pr-wrap-password-change {
	width: 100%;
	margin: 10px 0px;
	text-align: center;
}

.pr-wrap-password-change > button {
	font-size: 17px;
}

.pr-popup-window {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: var(--main-color-dark-80);
}

.pr-popup-window > div {
	max-width: 90%;
	max-height: calc(100% - 50px);
	padding: 10px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	text-align: center;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--main-color-3);
	animation: slideIn 0.4s linear;
}

.pr-popup-window > div button {
	margin: 0px 10px;
	font-size: 15px;
}

.pr-wrap-institution-creation {
	width: 500px !important;
}

.pr-wrap-institution-creation,
.pr-wrap-table {
	width: fit-content;
	position: relative;
}
.pr-wrap-institution-creation h3,
.pr-wrap-table h3 {
	margin: auto;
	font-size: 25px;
}

.pr-wrap-institution-creation i,
.pr-wrap-table i {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 30px;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	color: var(--main-color-error);
}

.pr-wrap-institution-creation i:hover,
.pr-wrap-table i:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.pr-wrap-institution-table {
	width: 800px;
	max-width: 100%;
	margin: auto;
}

.pr-institution-creation {
	box-sizing: border-box;
}
.pr-institution-creation input,
.pr-institution-creation textarea {
	width: 100%;
	pointer-events: all;
	font-size: 17px;
	box-shadow: 0 0 0 2pt var(--main-color-3);
	background-color: var(--main-color-4);
}

.pr-institution-creation textarea {
	height: 200px;
	box-sizing: border-box;
}

.pr-institution-creation button {
	margin-top: 10px !important;
	font-size: 17px !important;
}

.pr-spin {
	margin-left: 5px;
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.pr-wrap-switch {
	text-align: start;
}
.pr-wrap-theme {
	height: fit-content;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.pr-wrap-theme p {
	margin-right: 10px;
}
.pr-switch {
	position: relative;
	width: 50px;
	height: 30px;
}
.pr-switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
.pr-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-color-2);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 30px;
	border: 3px solid var(--main-color-border-dark);
	box-sizing: border-box;
}
.pr-slider:before {
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	left: 3px;
	bottom: 4px;
	background-color: var(--main-color-1);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
	border: 3px solid var(--main-color-border-dark);
	box-sizing: border-box;
}
input:checked + .pr-slider {
	background-color: var(--main-color-6);
}
input:focus + .pr-slider {
	box-shadow: 0 0 1px var(--main-color-6);
}
input:checked + .pr-slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

.pr-delete-cookies button {
	margin: 10px 0px;
	font-size: 17px;
}
</style>
