<template>
	<div class="ut-wrap-content">
		<div class="ut-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
		</div>
		<div class="ut-wrap-table">
			<div v-if="isLoading" class="ut-loading-overlay">
				<p>{{ $t('utLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="ut-table">
				<div class="ut-wrap-header">
					<div class="ut-header-element app-no-select ut-user-id" :title="$t('utSortBy')" @click="sortBy('userID')">
						{{ $t('utUserID') }}
					</div>
					<div class="ut-header-element app-no-select ut-first-name" :title="$t('utSortBy')" @click="sortBy('firstName')">
						{{ $t('utFirstName') }}
					</div>
					<div class="ut-header-element app-no-select ut-last-name" :title="$t('utSortBy')" @click="sortBy('lastName')">
						{{ $t('utLastName') }}
					</div>
					<div class="ut-header-element app-no-select ut-email" :title="$t('utSortBy')" @click="sortBy('email')">
						{{ $t('utEmail') }} <i class="fa-solid fa-envelope"></i>
					</div>
					<div class="ut-header-element app-no-select ut-rights" :title="$t('utSortBy')" @click="sortBy('role')">
						{{ $t('utRights') }} <i class="fa-solid fa-shield-halved"></i>
					</div>
					<div class="ut-header-element app-no-select ut-verified" :title="$t('utSortBy')" @click="sortBy('verified')">
						{{ $t('utVerified') }}
					</div>
				</div>
				<div class="ut-wrap-body">
					<div v-for="user in users" :key="user.userID" class="ut-body-element">
						<div class="ut-user-id">{{ user.userID.substring(0, 8) }}</div>
						<div class="ut-first-name">{{ user.firstName }}</div>
						<div class="ut-last-name">{{ user.lastName }}</div>
						<div class="ut-email">{{ user.email }}</div>
						<div class="ut-rights">
							<select
								:class="[disabledCondition(user) ? 'ut-disable-select' : '', 'ut-role-select']"
								@change="setUserRole($event, user.userID)"
								v-model="user.changedRole"
							>
								<option v-if="user.role == 'super' || $global.getUser().role == 'super'" value="super">
									{{ $t('utSuperAdmin') }}
								</option>
								<option value="admin">{{ $t('utAdmin') }}</option>
								<option value="developer">{{ $t('utDeveloper') }}</option>
								<option value="medical">{{ $t('utMedical') }}</option>
								<option value="auditor">{{ $t('utAuditor') }}</option>
								<option value="default">{{ $t('utDefault') }}</option>
							</select>
						</div>
						<div class="ut-verified">
							<i
								v-if="!user.changedVerification"
								:class="[
									disabledCondition(user) ? 'ut-disable-verified' : '',
									'fa-solid',
									'fa-circle-xmark',
									'ut-not-verified',
								]"
								@click="setUserVerification(user.userID, 1)"
							>
							</i>
							<i
								v-else
								:class="[
									disabledCondition(user) ? 'ut-disable-verified' : '',
									'fa-solid',
									'fa-circle-check',
									'ut-is-verified',
								]"
								@click="setUserVerification(user.userID, 0)"
							></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ut-query-more">
			<p>{{ allUsers }} {{ $t('utAllUsers') }}</p>
			<div class="ut-pages">
				<p v-for="page in $global.getPages(allUsers, pageSize, currentPage)" :key="page" class="ut-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="ut-no-hover"> . . . </span>
					<span
						v-else
						:class="[
							page == currentPage ? 'ut-highlight-page' : '',
							isLoading || page == currentPage ? 'ut-no-hover' : '',
						]"
						@click="isLoading || page == currentPage ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
		<button :class="[canSaveChanges() ? 'app-success-btn' : 'app-disabled-btn', 'ut-save-btn']" @click="saveChanges">
			{{ $t('utSaveChanges') }}
		</button>
	</div>
</template>

<script>
/**
 * @group Tables
 * Displays all registered users as a table and provides update functionality
 */
export default {
	name: 'UserTable',
	props: {
		users: {
			type: Array,
			required: true,
		},
		allUsers: {
			type: Number,
			required: false,
		},
	},
	watch: {
		users: {
			handler: function (newVal) {
				this.users.map((user) => {
					let change = this.changes.filter((change) => change.userID == user.userID)[0];
					if (change) {
						user.changedRole = change.role ? change.role : user.role;
						user.changedVerification = change.verified ? change.verified : user.verified;
					} else {
						user.changedRole = user.role;
						user.changedVerification = user.verified;
						this.changes.push({
							userID: user.userID,
							role: null,
							verified: null,
						});
					}
					return user;
				});
				this.isLoading = false;
			},
		},
	},
	data() {
		return {
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			changes: [],
			lastSort: {
				property: null,
				asc: false,
			},
		};
	},
	created() {
		this.users.map((user) => {
			user.changedRole = user.role;
			user.changedVerification = user.verified;
			this.changes.push({
				userID: user.userID,
				role: null,
				verified: null,
			});
			return user;
		});
		this.sortBy('email');
	},
	methods: {
		// @vuese
		// Checks if a condition to display/enable options for a user is met
		// @arg user[Object] - The user element
		// @returns [Boolean] - Is true if the option should be disabled
		disabledCondition(user) {
			if (user.userID == this.$global.getUser().userID) return true;
			if (
				user.role == 'super' &&
				this.changes.filter((change) => change.userID == user.userID)[0].role == null &&
				this.$global.getUser().role !== 'super'
			)
				return true;
		},
		// @vuese
		// Sorts the users by a given property
		// @arg property[String] - The property of the user object
		sortBy(property) {
			if (this.lastSort.property == property) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: property,
					asc: false,
				};
			}
			this.$emit('sortUsers', this.lastSort);
		},
		// @vuese
		// Changes the page size and requieries the users from page 1
		changePageSize() {
			this.currentPage = 1;
			this.isLoading = true;
			this.$emit('queryUsers', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Changes the page and querries the users
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.isLoading = true;
			this.$emit('queryUsers', (this.currentPage - 1) * this.pageSize, this.pageSize);
		},
		// @vuese
		// Sets the new user role for a given userID
		// @arg e[Object] - The event that occured
		// @arg userID[String] - The ID of the user
		setUserRole(e, userID) {
			let newRole = e.target.options[e.target.selectedIndex].value;
			let user = this.users.filter((user) => user.userID == userID)[0];
			let changeIdx = this.changes.findIndex((change) => change.userID == userID);

			if (user.role == newRole) this.changes[changeIdx].role = null;
			else this.changes[changeIdx].role = newRole;
		},
		// @vuese
		// Sets the verified status for a given userID
		// @arg userID[String] - The ID of the user
		// @arg verified[Boolean] - The verification status
		setUserVerification(userID, verified) {
			let changeIdx = this.changes.findIndex((change) => change.userID == userID);
			let user = this.users.filter((user) => user.userID == userID)[0];

			if (user.verified == verified) this.changes[changeIdx].verified = null;
			else this.changes[changeIdx].verified = verified;

			this.users.map((user) => {
				if (user.userID == userID) user.changedVerification = verified;
				return user;
			});
			this.$forceUpdate();
		},
		// @vuese
		// Checks if the changes can be saved
		// @returns [Boolean] - Is true if the changes can be saved
		canSaveChanges() {
			// https://stackoverflow.com/questions/18808226/why-is-typeof-null-object
			return this.changes.filter((change) => typeof change.role !== 'object' || typeof change.verified !== 'object').length > 0;
		},
		// @vuese
		// Saves the changed users
		saveChanges() {
			let updatedUsers = [];
			this.users.forEach((user) => {
				if (user.changedRole !== user.role || user.changedVerification !== user.verified) {
					user.role = user.changedRole;
					user.verified = user.changedVerification;
					updatedUsers.push(user);
				}
			});
			console.log(updatedUsers);

			this.$global.patchData(
				'admin',
				'/users',
				{ users: updatedUsers },
				{
					headers: { userid: this.$global.getUser().userID },
					auth: JSON.parse(sessionStorage.getItem('credentials')),
				},
				(err, data) => {
					if (err) {
						let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
						this.$global.showToast('error', this.$t(msg ? msg : 'utSaveError'));
					} else {
						this.changes = [];
						this.$global.showToast('success', this.$t('utSaveSuccess'));
						this.pageSize = 50;
						this.currentPage = 1;
						this.$emit('queryUsers', 0, 50);
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.ut-wrap-content {
	height: fit-content;
	margin: 0px auto 20px auto;
	max-width: 1800px;
}

.ut-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ut-options select {
	margin-right: 10px;
}

.ut-wrap-table {
	width: 90%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.ut-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.ut-loading-overlay p {
	font-size: 30px;
}

.ut-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.ut-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.ut-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
}

.ut-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
}

.ut-header-element i {
	margin-left: 10px;
}

.ut-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.ut-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.ut-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.ut-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.ut-body-element div {
	height: 40px;
	padding: 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.ut-user-id {
	width: 90px;
}

.ut-first-name {
	width: 150px;
}

.ut-last-name {
	width: 150px;
}

.ut-email {
	min-width: 320px;
	width: calc(100% - 640px);
}

.ut-rights {
	width: 150px;
}

.ut-role-select {
	min-width: 140px;
	box-shadow: 0 0 0 2pt var(--main-color-4);
}

.ut-disable-select {
	pointer-events: none;
	background-color: var(--main-color-5);
	color: var(--main-color-text-dark);
}

.ut-verified {
	width: 100px;
}

.ut-body-element .ut-verified {
	display: inline-block;
	text-align: center !important;
}

.ut-verified i {
	font-size: 28px;
	cursor: pointer;
	margin: auto;
}

.ut-not-verified {
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ut-not-verified:hover {
	color: var(--secondary-color-error);
}

.ut-is-verified {
	color: var(--main-color-success);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.ut-is-verified:hover {
	color: var(--secondary-color-success);
}

.ut-disable-verified {
	pointer-events: none;
	cursor: default;
}

.ut-save-btn {
	height: 40px;
	width: 250px;
	margin-top: 10px;
	padding: 5px 10px;
	font-size: 20px;
	box-sizing: border-box;
}

.ut-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.ut-query-more p {
	margin: 5px auto;
}

.ut-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.ut-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.ut-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.ut-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.ut-highlight-page {
	color: var(--main-color-6);
}
</style>
