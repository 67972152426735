<template>
	<div class="rt-wrap-content">
		<div class="rt-options">
			<select v-model="pageSize" @change="changePageSize">
				<option :value="50">50</option>
				<option :value="100">100</option>
				<option :value="250">250</option>
				<option :value="500">500</option>
			</select>
		</div>
		<div v-if="applications.length" class="rt-wrap-table">
			<div v-if="isLoading" class="rt-loading-overlay">
				<p>{{ $t('rtLoading') }} <i class="fa-solid fa-spinner"></i></p>
			</div>
			<div class="rt-table">
				<div class="rt-wrap-header">
					<div class="rt-header-element app-no-select rt-id" :title="$t('rtSortBy')" @click="sortBy('ID')">
						{{ $t('rtID') }}
					</div>
					<div class="rt-header-element app-no-select rt-revision-id" :title="$t('rtSortBy')" @click="sortBy('revisionID')">
						{{ $t('rtRevisionID') }}
					</div>
					<div class="rt-header-element app-no-select rt-name" :title="$t('rtSortBy')" @click="sortBy('name')">
						{{ $t('rtName') }}
					</div>
					<div class="rt-header-element app-no-select rt-creation" :title="$t('rtSortBy')" @click="sortBy('creation')">
						{{ $t('rtCreation') }}
					</div>
				</div>
				<div class="rt-wrap-body">
					<div
						v-for="d in data"
						:key="getDataID(d)"
						:id="getDataID(d)"
						:class="['rt-body-hover', d.selected ? 'rt-body-selected' : '', 'rt-body-element']"
						@click="$emit('selectRevision', getDataID(d))"
					>
						<div class="rt-id">{{ getDataID(d).substring(0, 8) }}</div>
						<div class="rt-revision-id">{{ $global.shortenRevisionID(d.revisionID) }}</div>
						<div class="rt-name">{{ d.name ? d.name : '-' }}</div>
						<div class="rt-creation">{{ $global.parseDateShort(d.creation) }}</div>
					</div>
					<div v-if="data.length == 0" class="rt-wrap-no-data">
						<div class="rt-no-data">
							<i class="fa-solid fa-folder-open"></i>
							<p>{{ $t('rtNoData') }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rt-query-more">
			<p>{{ allData }} {{ $t('rtRevisionsAvailable') }}</p>

			<div class="rt-pages">
				<p v-for="page in $global.getPages(allData, pageSize, currentPage)" :key="page" class="rt-page">
					<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="rt-no-hover"> . . . </span>
					<span
						v-else
						:class="[page == currentPage ? 'rt-highlight-page' : '', isLoading ? 'rt-no-hover' : '']"
						@click="isLoading ? null : setPage(page)"
						>{{ page }}</span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Tables
 * Displays all revisions of given data as a table
 */
export default {
	name: 'RevisionTable',
	props: {
		data: {
			type: Array,
			required: true,
		},
		allData: {
			type: Number,
			required: true,
		},
		dataType: {
			type: String,
			required: true,
		},
		applications: {
			type: Array,
			required: true,
		},
		setSizeAndPage: {
			type: Object,
			required: false,
		},
	},
	watch: {
		data: {
			handler: function () {
				this.isLoading = false;
			},
			deep: true,
		},
		setSizeAndPage: {
			handler: function (newVal) {
				if (newVal && (this.pageSize != newVal.pageSize || this.currentPage != newVal.currentPage)) {
					this.pageSize = newVal.pageSize;
					this.setPage(newVal.currentPage);
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			pageSize: 50,
			currentPage: 1,
			isLoading: false,
			lastSort: {
				property: null,
				asc: false,
			},
		};
	},
	created() {
		this.sortBy('creation');
		this.$emit('setState', { pageSize: this.pageSize, currentPage: this.currentPage });
	},
	methods: {
		// @vuese
		// Retruns the ID of a given data object
		// @arg data[Object] - The data, that can be a package, a bundle, a pipeline or a helper
		// @return [String] - The ID, that can be a packageID, a bundleID, a pipelineID or a helperID
		getDataID(data) {
			if (this.dataType == 'helper') return data.helperID;
			else if (this.dataType == 'package') return data.packageID;
			else if (this.dataType == 'bundle') return data.bundleID;
			else if (this.dataType == 'pipeline') return data.pipelineID;
		},
		// @vuese
		// Sorts the users by a given property
		// @arg property[String] - The property of the user object
		sortBy(property) {
			if (property == 'ID') {
				if (this.dataType == 'helper') property = 'helperID';
				else if (this.dataType == 'package') property = 'packageID';
				else if (this.dataType == 'bundle') property = 'bundleID';
				else if (this.dataType == 'pipeline') property = 'pipelineID';
			}
			if (this.lastSort.property == property) {
				this.lastSort.asc = !this.lastSort.asc;
			} else {
				this.lastSort = {
					property: property,
					asc: false,
				};
			}
			this.$emit('sortRevisions', this.lastSort);
		},
		// @vuese
		// Changes the page size and requieries the packages from page 1
		changePageSize() {
			this.currentPage = 1;
			this.$emit('setState', { pageSize: this.pageSize, currentPage: this.currentPage });
			this.isLoading = true;
			this.$emit(
				'setViewedData',
				(this.currentPage - 1) * this.pageSize,
				(this.currentPage - 1) * this.pageSize + this.pageSize
			);
		},
		// @vuese
		// Changes the page and querries the packages
		// @arg page[Number] - The new page
		setPage(page) {
			this.currentPage = page;
			this.$emit('setState', { pageSize: this.pageSize, currentPage: this.currentPage });
			this.isLoading = true;
			this.$emit(
				'setViewedData',
				(this.currentPage - 1) * this.pageSize,
				(this.currentPage - 1) * this.pageSize + this.pageSize
			);
		},
	},
};
</script>

<style scoped>
.rt-wrap-content {
	height: fit-content;
	margin: 0px auto 20px auto;
	max-width: 1800px;
}

.rt-options {
	width: 90%;
	margin: 5px auto;
	text-align: start;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.rt-options select {
	margin-right: 10px;
}

.rt-options div {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.rt-options div input {
	width: 15px;
	height: 15px;
	margin-right: 5px;
}

.rt-options div label {
	font-size: 17px;
	cursor: pointer;
}

.rt-wrap-table {
	width: 90%;
	margin: auto;
	overflow: hidden;
	position: relative;
}

.rt-loading-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-80);
	z-index: 1;
}

.rt-loading-overlay p {
	font-size: 30px;
}

.rt-loading-overlay p i {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}

.rt-table {
	width: 100%;
	height: 240px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
}

.rt-wrap-header {
	width: 100%;
	height: 40px;
	text-align: start;
	box-sizing: border-box;
	position: sticky;
	top: 0;
}

.rt-header-element {
	height: 100%;
	padding: 10px 5px 5px 5px;
	display: inline-block;
	text-align: start;
	font-size: 18px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border-bottom: 1px solid var(--main-color-5);
}

.rt-header-element i {
	margin-left: 10px;
}

.rt-header-element:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.rt-wrap-body {
	width: 100%;
	height: calc(100% - 40px);
	text-align: start;
	background-color: var(--main-color-4);
}

.rt-body-hover:hover div {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-text-dark);
}

.rt-body-selected div {
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-dark);
}

.rt-body-element div {
	height: 45px;
	padding: 3px 5px 2px 5px;
	box-sizing: border-box;
	font-size: 15px;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-inline-box-orient: vertical;
	white-space: normal;
}

.rt-id {
	width: 80px;
}
.rt-revision-id {
	min-width: 160px;
	width: calc(100% - 410px);
}

.rt-name {
	width: 200px;
}

.rt-creation {
	width: 130px;
}

.rt-wrap-no-data {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rt-no-data {
	text-align: center;
}

.rt-no-data i {
	margin-bottom: 10px;
	font-size: 40px;
}

.rt-no-data p {
	font-size: 20px;
}

.rt-query-more {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.rt-query-more p {
	margin: 5px auto;
}

.rt-query-more button {
	font-size: 17px;
}

.rt-pages {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	overflow-x: auto;
}

.rt-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.rt-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.rt-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.rt-highlight-page {
	color: var(--main-color-6);
}
</style>
