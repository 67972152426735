<template>
	<div class="bbc-wrap-content">
		<h2>{{ $t('bbcBaselineBundleCreation') }}</h2>
		<i class="fas fa-times-circle bbc-close-dialog" @click="$emit('hideBundleCreation', false)"></i>
		<div class="bbc-wrap-selects">
			<div class="bbc-wrap-select">
				<label>{{ $t('bbcApplications') }}</label>
				<select v-model="selectedApplication" @change="queryData">
					<option :value="null" disabled selected hidden>{{ $t('bbcSelectApplication') }}</option>
					<option v-for="application in applications" :key="application.applicationID" :value="application.applicationID">
						{{ $t(`${application.abbreviation}Name`) }}
					</option>
				</select>
			</div>
			<div v-if="selectedApplication" class="bbc-wrap-select">
				<label>{{ $t('bbcBundles') }}</label>
				<select v-model="previousRevision">
					<option :value="null" disabled selected hidden>{{ $t('bbcChooseBundle') }}</option>
					<option value="NEW">{{ $t('bbcNewBundle') }}</option>
					<option v-for="bundle in bundles" :key="bundle.revisionID" :value="bundle.revisionID">
						{{ bundle.name }} ({{ $global.shortenRevisionID(bundle.revisionID) }})
					</option>
				</select>
			</div>
			<div v-if="selectedApplication" class="bbc-wrap-input">
				<label>{{ $t('bbcNewBundleName') }}</label>
				<input type="text" :placeholder="$t('bbcNewBundleName')" v-model="newBundleName" />
			</div>
		</div>
		<PackageTable
			v-if="selectedApplication && packages"
			:packages="packages"
			:allPackages="allPackagesAmount"
			:applications="applications"
			:devMode="true"
			:multiSelect="true"
			:selectedPackages="selectedPackages"
			@sortPackages="sortPackages"
			@selectPackage="selectPackage"
			@selectAll="selectAllPackages"
			@queryPackages="queryPackages"
		/>
		<div v-else-if="selectedApplication && !packages" class="bbc-table-placeholder">
			{{ $t('bbcLoading') }}<i class="fa-solid fa-spinner"></i>
		</div>
		<div class="bbc-wrap-create-btn">
			<button :class="[canBeCreated() && !preventClick ? 'app-success-btn' : 'app-disabled-btn']" @click="createNewBundle">
				{{ $t('bbcCreateBundle') }}
			</button>
		</div>
	</div>
</template>

<script>
import PackageTable from '../tables/PackageTable.vue';
/**
 * @group Admin
 * Creation of a baseline bundle
 */
export default {
	name: 'BaselineBundleCreation',
	components: {
		PackageTable,
	},
	props: {
		applications: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selectedApplication: null,
			packages: null,
			allPackagesAmount: 0,
			bundles: [],
			selectedPackages: [],
			previousRevision: null,
			newBundleName: '',
			preventClick: false,
		};
	},
	methods: {
		// @vuese
		// Queries all baseline packages and meta
		queryData() {
			this.queryPackages(0, 50);
			this.queryBaselineBundleMeta();
		},
		// @vuese
		// Queries all baseline package meta
		queryBaselineBundleMeta() {
			this.$cr.getBaselineBundleMeta(this.selectedApplication, (err, result) => {
				if (!err) this.bundles = result;
			});
		},
		// @vuese
		// Queries all baseline packages
		// @arg offset[Number] - The query offset
		// @arg amount[Number] - The query amount
		queryPackages(offset, amount) {
			this.$cr.getDataPackages(offset, amount, this.selectedApplication, 'verified', null, (err, result) => {
				if (!err) {
					this.packages = result.packages;
					this.allPackagesAmount = result.amount;
					this.packages.map((pack) => {
						if (this.selectedPackages.includes(pack.packageID)) pack.selected = true;
						return pack;
					});
				}
			});
		},
		// @vuese
		// Sorts all data packages by a sort property and sort direction
		// @arg sort[Object] - Contains the sort property and sort direction
		sortPackages(sort) {
			this.packages = this.$global.sortValues(sort, this.packages);
		},
		// @vuese
		// Selects a data package
		// @arg packageID[String] - The ID of the data package
		selectPackage(packageID) {
			let updatePackage = this.packages.findIndex((pack) => pack.packageID == packageID);
			if (updatePackage !== -1) this.packages[updatePackage].selected = !this.packages[updatePackage].selected;

			if (this.selectedPackages.includes(packageID))
				this.selectedPackages = this.selectedPackages.filter((pack) => pack != packageID);
			else this.selectedPackages.push(packageID);
		},
		// @vuese
		// Selects all data packages
		// @arg select[Boolean] - Is true if everything should be selected
		selectAllPackages(select) {
			if (select) {
				this.packages.map((pack) => {
					if (!this.selectedPackages.includes(pack.packageID)) {
						this.selectedPackages.push(pack.packageID);
						pack.selected = true;
					}
					return pack;
				});
			} else {
				let removedPackages = [];
				this.packages.map((pack) => {
					pack.selected = false;
					removedPackages.push(pack.packageID);
					return pack;
				});

				this.selectedPackages = this.selectedPackages.filter((packageID) => !removedPackages.includes(packageID));
			}
		},
		// @vuese
		// Checks if the baseline bundle can be created
		// @returns [Boolean] - Is true if the bundle can be created
		canBeCreated() {
			if (
				this.selectedApplication &&
				this.previousRevision &&
				this.newBundleName.length >= 5 &&
				this.newBundleName.length <= 30 &&
				this.selectedPackages.length > 0
			)
				return true;
			else return false;
		},
		// @vuese
		// Creates a new baseline bundle
		createNewBundle() {
			this.preventClick = true;
			if (!this.selectedApplication) this.$global.showToast('warn', this.$t('bbcNoApplication'));
			else if (!this.previousRevision) this.$global.showToast('warn', this.$t('bbcNoPreviousBundle'));
			else if (this.newBundleName.length < 5 || this.newBundleName.length > 30)
				this.$global.showToast('warn', this.$t('bbcNameLength'));
			else if (this.selectedPackages.length == 0) this.$global.showToast('warn', this.$t('bbcNoPackagesSelected'));
			else {
				this.$global.postData(
					'data',
					'/bundles',
					{
						application: this.selectedApplication,
						packages: this.selectedPackages,
						bundles: [],
						previousRevision: this.previousRevision,
						name: this.newBundleName,
						isVerification: true,
					},
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
					},
					(err, bundleID) => {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							this.$global.showToast('error', this.$t(msg ? msg : 'bbcCreateQuery'));
							this.preventClick = false;
						} else {
							this.$global.showToast('success', this.$t('bbcBundleCreated'));
							this.$emit('hideBundleCreation', true);
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.bbc-wrap-content {
	min-width: 90%;
	max-height: 90%;
	width: 700px;
	position: relative;
	padding: 10px 0px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: start;
	background-color: var(--main-color-2);
	animation: slideIn 0.4s linear;
}

.bbc-wrap-content h2 {
	width: 100%;
	text-align: center;
	font-size: 30px;
	color: var(--main-color-6);
}

.bbc-close-dialog {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 30px;
	color: var(--main-color-error);
	-webkit-text-stroke: 1px var(--main-color-border-dark);
}

.bbc-close-dialog:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.bbc-wrap-selects {
	margin: 0px 5%;
}

.bbc-wrap-select {
	min-width: fit-content;
	margin: 5px 20px 5px 0px;
	display: inline-block;
	text-align: start;
}

.bbc-wrap-select label {
	display: block;
	margin: 5px;
}

.bbc-wrap-select select {
	width: fit-content;
	font-size: 17px;
	box-shadow: 0 0 0 2pt var(--main-color-2);
	background-color: var(--main-color-4);
}

.bbc-wrap-input {
	min-width: fit-content;
	margin: 5px 20px 5px 0px;
	display: inline-block;
	text-align: start;
}

.bbc-wrap-input label {
	display: block;
	margin: 5px;
}

.bbc-wrap-input input {
	width: fit-content;
	font-size: 17px;
	box-sizing: border-box;
	box-shadow: 0 0 0 2pt var(--main-color-2);
	background-color: var(--main-color-4);
}

.bbc-wrap-create-btn {
	width: 100%;
	margin: 10px 0px;
	text-align: center;
}

.bbc-wrap-create-btn button {
	height: 40px;
	font-size: 20px;
	box-sizing: border-box;
}

.bbc-table-placeholder {
	height: 240px;
	max-width: 1800px;
	width: 90%;
	margin: 0px auto 20px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	border: 1px solid var(--main-color-5);
	box-shadow: 0px 0px 1px 1px var(--main-color-5);
	background-color: var(--main-color-4);
}

.bbc-table-placeholder i {
	margin-left: 10px;
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
</style>
