<template>
	<div class="toc-wrap-content">
		<div class="toc-collapsable">
			<h2 id="toc">
				{{ $t('tocTableOfContents') }}
			</h2>
			<div class="toc-toggle-div">
				<i class="fa-solid fa-chevron-up" @click="collapseTOC"></i>
				<i class="fa-solid fa-chevron-down toc-hidden" @click="expandTOC"></i>
			</div>
			<div v-for="(state, idx) in states" :key="`${idx}-${state.moduleID}`" class="toc-wrap-element">
				<p class="toc-element" @click="scrollToChapter(`toc${idx}${state.name.replace(/ /g, '')}`, state.moduleID)">
					<span>{{ `${idx + 1}. ${state.name}` }}</span>
				</p>
				<p v-if="!isListView" class="toc-sub-element" @click="scrollToChapter(`toc${idx}1conf`, state.moduleID)">
					<span>{{ `${idx + 1}.1 ${$t('tocConfigurations')}` }} </span>
				</p>
				<p v-if="!isListView" class="toc-sub-element" @click="scrollToChapter(`toc${idx}2res`, state.moduleID)">
					<span>{{ `${idx + 1}.2 ${$t('tocResults')}` }} </span>
				</p>
				<p v-if="!isListView" class="toc-sub-element" @click="scrollToChapter(`toc${idx}3logs`, state.moduleID)">
					<span>{{ `${idx + 1}.3 ${$t('tocLogs')}` }} </span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Evaluation
 * Displays the table of contents for the training report
 */
export default {
	name: 'TableOfContents',
	props: {
		states: {
			type: Array,
			required: true,
		},
		isListView: {
			type: Boolean,
			required: false,
		},
	},
	methods: {
		// @vuese
		// Expands the table of contents
		expandTOC() {
			let elem = document.querySelector('.toc-collapsable');
			elem.style.height = 'fit-content';
			elem.style.overflow = 'auto';

			[...elem.querySelectorAll(':scope > .toc-toggle-div > i')].forEach((i) => {
				if (i.classList.contains('toc-hidden')) i.classList.remove('toc-hidden');
				else i.classList.add('toc-hidden');
			});
		},
		// @vuese
		// Collapses the table of contents
		collapseTOC() {
			let elem = document.querySelector('.toc-collapsable');
			elem.style.height = '30px';
			elem.style.overflow = 'hidden';

			[...elem.querySelectorAll(':scope > .toc-toggle-div > i')].forEach((i) => {
				if (i.classList.contains('toc-hidden')) i.classList.remove('toc-hidden');
				else i.classList.add('toc-hidden');
			});
		},
		// @vuese
		// Scrolls to a certain chapter
		// @arg chapter[String] - The name of the chapter
		// @arg stateID[String] - The ID of the state
		scrollToChapter(chapter, stateID) {
			if (!this.isListView) {
				let elem = document.getElementById(stateID).querySelector(':scope > .ts-collapsable');
				if (elem.clientHeight == 30) {
					elem.style.height = 'fit-content';
					elem.style.overflow = 'auto';

					[...elem.querySelectorAll(':scope > .ts-toggle-div > i')].forEach((i) => {
						if (i.classList.contains('ts-hidden')) i.classList.remove('ts-hidden');
						else i.classList.add('ts-hidden');
					});
				}

				this.$nextTick(() => {
					let chap = document.querySelector(`#${chapter}`);
					let wind = document.querySelector(`.ev-wrap-training-states`);
					let wrap = document.querySelector(`.ev-wrap-content`);
					let fullHeight = wrap.scrollHeight - wrap.clientHeight;

					wind.scrollTo({
						top: chap.getBoundingClientRect().top - 150 + wind.scrollTop - (fullHeight - wrap.scrollTop),
						behavior: 'smooth',
					});
				});
			} else {
				this.$emit('jumpToState', stateID);
				this.$nextTick(() => {
					let chap = document.querySelector(`#${chapter}`);
					let wind = document.querySelector(`.ev-wrap-training-states`);
					let wrap = document.querySelector(`.ev-wrap-content`);
					let fullHeight = wrap.scrollHeight - wrap.clientHeight;

					wind.scrollTo({
						top: chap.getBoundingClientRect().top - 150 + wind.scrollTop - (fullHeight - wrap.scrollTop),
						behavior: 'smooth',
					});
				});
			}
		},
	},
};
</script>

<style scoped>
.toc-wrap-content {
	width: 100%;
	height: fit-content;
	max-height: calc(100vh - 160px);
	padding: 10px;
	margin-bottom: 20px;
	box-sizing: border-box;
	border-radius: 20px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-dark-80);
	overflow: auto;
}

.toc-collapsable {
	height: fit-content;
	width: 100%;
	position: relative;
}

.toc-toggle-div {
	position: absolute;
	top: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toc-toggle-div i {
	font-size: 25px;
}

.toc-toggle-div i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.toc-hidden {
	display: none;
}

h2 {
	width: 100%;
	margin-bottom: 5px;
	text-align: center;
	color: var(--main-color-6);
}

.toc-wrap-element {
	width: 100%;
	margin: 5px auto;
	padding: 0px 10px;
	box-sizing: border-box;
}

.toc-element {
	font-size: 17px;
	padding-bottom: 5px;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
}

.toc-sub-element {
	font-size: 16px;
	padding-left: 20px;
	padding-bottom: 3px;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
}

.toc-element span:hover,
.toc-sub-element span:hover {
	cursor: pointer;
	text-decoration: underline;
}

.toc-element:after,
.toc-sub-element:after {
	content: '..........................................................................................................................................................................................................................................................................................................................................................................................................................';
	text-decoration: none !important;
}
</style>
