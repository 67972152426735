<template>
	<div class="ev-wrap-content">
		<div v-if="training.pipeline && training.bundle" class="ev-wrap-pipeline-preview">
			<PipelinePreview
				:pipeline="training.pipeline"
				:bundle="training.bundle"
				:applications="applications"
				:configuration="false"
			/>
		</div>
		<div v-else class="ev-loading-evaluation">
			<p>{{ $t('evLoadingEvaluation') }} <i class="fa-solid fa-spinner ev-loading"></i></p>
		</div>
		<div v-if="training.logs" class="ev-wrap-live-log">
			<LiveLog :logs="training.logs" :connect="true" @loadResults="loadResults" @trainingRollback="trainingRollback" />
		</div>
		<h1 v-if="training.states">
			{{ $t('evTrainingResults') }}
			<i v-if="!reportLoading && training.states.length > 0" class="fa-regular fa-floppy-disk" @click="generateFullReport"></i
			><i v-else-if="reportLoading && training.states.length > 0" class="fa-solid fa-spinner ev-loading"></i>
		</h1>
		<div v-if="training.states" class="ev-options-bar">
			<div v-if="training.states.length > 0">
				<button v-if="!showListView" class="app-default-btn" @click="expandAll">{{ $t('evExpandAll') }}</button>
				<button v-else class="app-default-btn" @click="expandAllList = true">{{ $t('evExpandAll') }}</button>
				<button v-if="!showListView" class="app-default-btn" @click="collapseAll">{{ $t('evCollapseAll') }}</button>
				<button v-else class="app-default-btn" @click="expandAllList = false">{{ $t('evCollapseAll') }}</button>
				<button v-if="!showListView" class="app-default-btn" @click="showListView = true">{{ $t('evListView') }}</button>
				<button v-else class="app-default-btn" @click="showListView = false">{{ $t('evStateView') }}</button>
			</div>
		</div>
		<div class="ev-image-wrapper">
			<div v-if="training.states" class="ev-wrap-training-states">
				<div v-if="!showListView">
					<TableOfContents v-if="training.states.length > 0" :states="training.states" :isListView="false" />
					<TrainingState
						v-for="(state, idx) in training.states"
						:key="state.moduleID"
						:state="state"
						:idx="idx"
						:logs="getStateLogs(state)"
					/>
				</div>
				<div v-else>
					<ListView :sortedElements="sortedElements" :stateOrder="stateOrder" :expandAllList="expandAllList" />
				</div>
				<div v-if="evaluationFailed" class="ev-no-evaluation ev-error">
					{{ $t('evEvaluationFailed') }}
				</div>
				<div v-else-if="training.states.length == 0 && training.status !== 'finished'" class="ev-no-evaluation">
					{{ $t('evEvaluationStillInProgress') }}
				</div>
				<div v-else-if="training.states.length == 0 && training.status == 'finished'" class="ev-no-evaluation">
					{{ $t('evNoEvaluation') }}
				</div>
				<div v-if="training.states.length > 0" class="ev-back-to-top" @click="backUp">
					<i class="fa-solid fa-arrow-up"></i>
					<p>{{ $t('evBackUp') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LiveLog from '../components/log/LiveLog.vue';
import PipelinePreview from '../components/pipeline/PipelinePreview.vue';
import TrainingState from '../components/pipeline/TrainingState.vue';
import TableOfContents from '../components/pipeline/TableOfContents.vue';
import ListView from '../components/pipeline/ListView.vue';
import i18n from '@/translations/i18n';
import * as uuid from 'uuid';
/**
 * @group Evaluation
 * Evaluation of a pipeline training
 */
export default {
	name: 'Evaluation',
	components: {
		LiveLog,
		PipelinePreview,
		TrainingState,
		TableOfContents,
		ListView,
	},
	data() {
		return {
			applications: [],
			training: {},
			showListView: false,
			reportLoading: false,
			preventClick: false,
			evaluationFailed: false,
			stateOrder: null,
			expandAllList: false,
		};
	},
	computed: {
		// @vuese
		// Sorts all states, logs, configs and results
		// @returns [Array] - The flat array with all elements
		sortedElements() {
			if (this.showListView) {
				let elements = [];
				let creationID = uuid.v4();
				let executionID = uuid.v4();

				this.training.states.forEach((state) => {
					state.configs.forEach((config) =>
						elements.push({ ...config, state: state.name, type: 'config', moduleID: state.moduleID })
					);
					state.results.forEach((result) =>
						elements.push({ ...result, state: state.name, type: 'result', moduleID: state.moduleID })
					);
				});
				this.training.logs.forEach((log) => {
					let state = this.training.states.filter((state) => state.name == log.state)[0];
					if (log.state == 'creation') elements.push({ ...log, type: 'log', moduleID: creationID });
					else if (log.state == 'execution') elements.push({ ...log, type: 'log', moduleID: executionID });
					else elements.push({ ...log, type: 'log', moduleID: state.moduleID });
				});

				elements = elements.sort((a, b) => {
					return new Date(a.creation) - new Date(b.creation);
				});

				let i = 0;
				let stateOrder = [];
				while (i < elements.length) {
					let elemState = null;
					if (elements[i].state == 'creation' && stateOrder.length == 0) {
						let creationStateElems = elements.filter((elem) => elem.state == 'creation');
						let files = [...new Set(creationStateElems.map((elem) => elem.file))];
						let functions = [...new Set(creationStateElems.map((elem) => elem.function))];

						elemState = {
							category: 'Creation',
							creation: elements[i].creation,
							files: files,
							functions: functions,
							finished: creationStateElems[creationStateElems.length - 1].creation,
							moduleID: creationID,
							name: this.$t('evCreationState'),
							started: creationStateElems[0].creation,
							type: 'state',
						};

						stateOrder.push(elemState);
						elements.splice(i, 0, elemState);
					} else if (elements[i].state == 'execution' && stateOrder[stateOrder.length - 1].category !== 'Execution') {
						let executionStateElems = elements.filter((elem) => elem.state == 'execution');
						let files = [...new Set(executionStateElems.map((elem) => elem.file))];
						let functions = [...new Set(executionStateElems.map((elem) => elem.function))];

						elemState = {
							category: 'Execution',
							creation: elements[i].creation,
							files: files,
							functions: functions,
							finished: executionStateElems[executionStateElems.length - 1].creation,
							moduleID: stateOrder.length !== 1 ? uuid.v4() : executionID,
							name: this.$t('evExecutionState'),
							started: executionStateElems[0].creation,
							type: 'state',
						};

						stateOrder.push(elemState);
						elements.splice(i, 0, elemState);
					} else if (!['creation', 'execution'].includes(elements[i].state)) {
						elemState = this.training.states.filter((state) => state.name == elements[i].state)[0];
						let state = {
							category: elemState.category,
							creation: elemState.creation,
							files: elemState.files,
							functions: elemState.functions,
							finished: elemState.finished,
							moduleID: elemState.moduleID,
							name: elemState.name,
							started: elemState.started,
							type: 'state',
						};
						if (stateOrder.length == 0 || stateOrder[stateOrder.length - 1].moduleID !== elemState.moduleID) {
							stateOrder.push(elemState);
							elements.splice(i, 0, state);
						}
					}
					i += 1;
				}
				this.stateOrder = stateOrder;
				return elements;
			} else return null;
		},
	},
	created() {
		// prettier-ignore
		this.$cr.accessCheck((access) => {if(access) this.setupComponent()});
	},
	methods: {
		// @vuese
		// Setup function for the component
		setupComponent() {
			this.$cr.getAllApplications((err, result) => {
				if (!err) this.applications = result;
			});

			this.$cr.getTraining(this.$route.params.trainingID, (err, result) => {
				console.log(err);
				if (!err) {
					this.training = result;
					this.training.states = this.training.states.sort((a, b) => {
						return new Date(a.creation) - new Date(b.creation);
					});
				} else if (['erRessourceDoesntExist', 'erForbidden'].includes(err)) this.$router.push({ name: 'Pipeline' });
			});
		},
		// @vuese
		// Loads the training data if available
		loadResults() {
			this.$cr.getTraining(this.$route.params.trainingID, (err, result) => {
				if (!err) {
					this.training = result;
					this.$nextTick(() => {
						let elem = document.querySelector('.ev-wrap-content');
						elem.scrollTo({ top: elem.scrollHeight, behavior: 'smooth' });
					});
				} else if (err == 'erRessourceDoesntExist') this.$router.push({ name: 'Pipeline' });
			});
		},
		// @vuese
		// Expands all training states
		expandAll() {
			[...document.querySelectorAll('.ts-collapsable')].forEach((elem) => {
				if (elem.style.height !== 'fit-content') {
					elem.style.height = 'fit-content';
					elem.style.overflow = 'auto';

					[...elem.querySelectorAll(':scope > .ts-toggle-div > i')].forEach((i) => {
						if (i.classList.contains('ts-hidden')) i.classList.remove('ts-hidden');
						else i.classList.add('ts-hidden');
					});
				}
			});

			let elem = document.querySelector('.toc-collapsable');
			if (elem.style.height !== 'fit-content') {
				elem.style.height = 'fit-content';
				elem.style.overflow = 'auto';

				[...elem.querySelectorAll(':scope > .toc-toggle-div > i')].forEach((i) => {
					if (i.classList.contains('toc-hidden')) i.classList.remove('toc-hidden');
					else i.classList.add('toc-hidden');
				});
			}
		},
		// @vuese
		// Collapses all training states
		collapseAll() {
			[...document.querySelectorAll('.ts-collapsable')].forEach((elem) => {
				if (elem.clientHeight !== 30) {
					elem.style.height = '30px';
					elem.style.overflow = 'hidden';

					[...elem.querySelectorAll(':scope > .ts-toggle-div > i')].forEach((i) => {
						if (i.classList.contains('ts-hidden')) i.classList.remove('ts-hidden');
						else i.classList.add('ts-hidden');
					});
				}
			});

			let elem = document.querySelector('.toc-collapsable');
			if (elem.style.height !== '30px') {
				elem.style.height = '30px';
				elem.style.overflow = 'hidden';

				[...elem.querySelectorAll(':scope > .toc-toggle-div > i')].forEach((i) => {
					if (i.classList.contains('toc-hidden')) i.classList.remove('toc-hidden');
					else i.classList.add('toc-hidden');
				});
			}
		},
		// @vuese
		// Scrolls back up to the table of contents
		backUp() {
			let elem = document.querySelector('.ev-wrap-training-states');
			elem.scrollTo({ top: 0, behavior: 'smooth' });
		},
		// @vuese
		// Informs the user of a training rollback and swaps to the pipeline page
		trainingRollback() {
			this.$global.showToast('error', this.$t('evTrainingRollback'));
			this.evaluationFailed = true;
		},
		// @vuese
		// Returns the logs of a specific state
		// @arg state[Object] - The state object
		// @returns [Array] - The logs for the state
		getStateLogs(state) {
			let logs = this.training.logs.filter((log) => log.state == state.name);
			return logs;
		},
		// @vuese
		// Generates the full report of a training
		generateFullReport() {
			if (!this.preventClick) {
				this.preventClick = true;
				this.reportLoading = true;
				this.$global.showToast('info', this.$t('evFullGenerationStarted'), true);
				let that = this;
				this.$global.getData(
					'training',
					`/trainings/${this.$route.params.trainingID}/report`,
					{
						headers: { userid: this.$global.getUser().userID },
						auth: JSON.parse(sessionStorage.getItem('credentials')),
						params: { locale: i18n.locale },
					},
					null,
					function (err, result) {
						if (err) {
							let msg = err.response ? (err.response.data.msg ? err.response.data.msg : false) : false;
							that.$global.showToast('error', that.$t(msg ? msg : 'evReportCreationError'));
							that.reportLoading = false;
							that.preventClick = false;
						} else {
							try {
								let a = document.createElement('a');
								a.href = `data:application/zip;base64, ${result}`;
								a.download = `${that.$t('evEvaluationReport')}.zip`;
								a.click();
								a.remove();
								that.$global.showToast('success', that.$t('evFullGenerationFinished'), true);
							} catch (error) {
								console.log(error);
								that.$global.showToast('error', that.$t('evReportDownloadError'));
							} finally {
								that.reportLoading = false;
								that.preventClick = false;
							}
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.ev-wrap-content {
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	overflow: auto;
}

.ev-wrap-content h1 {
	width: fit-content;
	margin: 10px auto 0px auto;
	color: var(--main-color-6);
}

.ev-wrap-content > h1 > i {
	margin-left: 10px;
	color: var(--main-color-text-light);
}

.ev-wrap-content > h1 > i:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.ev-loading-evaluation {
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.ev-loading-evaluation p {
	font-size: 25px;
}

.ev-wrap-live-log {
	width: 100%;
	height: 600px;
	margin: auto;
}

.ev-options-bar {
	width: 100%;
	height: 35px;
}

.ev-options-bar button {
	margin: 5px 5px 5px 0px;
}

.ev-image-wrapper {
	width: 100%;
	max-height: calc(100vh - 140px);
	position: relative;
}

.ev-wrap-training-states {
	width: 100%;
	max-height: calc(100vh - 140px);
	min-height: 100px;
	margin-top: 5px;
	padding: 10px;
	box-sizing: border-box;
	background-color: var(--main-color-3);
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
	position: relative;
}

.ev-large-image img {
	max-width: 90%;
	max-height: 90%;
}

.ev-no-evaluation {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	text-align: center;
}

.ev-error {
	color: var(--main-color-error);
}

.ev-back-to-top {
	width: fit-content;
	margin: 0px;
	display: block;
	position: sticky;
	left: 100vw;
	bottom: 0px;
	text-align: center;
	opacity: 0.3;
}

.ev-back-to-top:hover {
	cursor: pointer;
	opacity: 1;
}

.ev-back-to-top i {
	font-size: 30px;
}

.ev-loading {
	animation: spin 1s infinite linear;
	-ms-animation: spin 1s infinite linear;
	-moz-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
}
</style>
